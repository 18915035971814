import React from 'react'
import { Layout, Typography } from 'antd'

import styles from './NotFound.module.css'

const { Title } = Typography

const NotFound = () =>
  <Layout className={styles.root}>
    <Title className={styles.header}>404</Title>
    <Title className={styles.subHeader} level={3}>Whoops! That page can&apos;t be found.</Title>
  </Layout>

export default NotFound
