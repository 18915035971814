import {
  CONTENT_UPDATE_STATUS,
  FETCH_CONTENTS,
  RESET_ADMIN_ACTION_STATUS,
  STORE_TASK_RESULT,
  TRIGGER_MANUAL_IMPORT
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_MANUAL_IMPORT:
      return {
        ...state,
        ...action.payload
      }
    case STORE_TASK_RESULT:
      return {
        ...state,
        ...action.payload
      }
    case RESET_ADMIN_ACTION_STATUS:
      return {
        ...state,
        ...action.payload
      }
    case CONTENT_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_CONTENTS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
