import React, { useEffect, useState } from 'react'
import { Divider } from 'antd'
import PropTypes from 'prop-types'

import { LogoUploader } from '~components'
import { CheckboxGroup, Input, Label, MultiSelectDropdown, TextArea } from '~stories'

import styles from './EventDataFields.module.css'

const AVAILABLE_CHANNELS = ['Email', 'Web', 'App']
const VIDEO_TYPES = [{
  key: 'brightcove',
  name: 'Brightcove'
}, {
  key: 'youtube',
  name: 'YouTube'
}]

const DataField = React.memo(({
  dataField,
  handleChannelUpdate,
  handleCtaLinkUpdate,
  handleCtaTextUpdate,
  handleDescriptionUpdate,
  handleImageUpload,
  handleTitleUpdate,
  handleVideoPlatformUpdate,
  handleVideoUrlUpdate,
  index
}) => {
  const [ctaLinkText, setCtaLinkText] = useState('')
  const [ctaLinkUrl, setCtaLinkUrl] = useState('')
  const [description, setDescription] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [selectedChannels, setSelectedChannels] = useState([])
  const [title, setTitle] = useState('')
  const [videoPlatform, setVideoPlatform] = useState('')
  const [videoUrl, setVideoUrl] = useState('')

  useEffect(() => {
    if (!dataField) return

    const {
      cta_link_text: ctaLinkText,
      cta_link_url: ctaLinkUrl,
      description,
      image_url: imageUrl,
      selected_channels: selectedChannels,
      title,
      video_platform: videoPlatform,
      video_url: videoUrl
    } = dataField

    setTitle(title)
    setDescription(description)
    setImageUrl(imageUrl)
    setSelectedChannels(selectedChannels)
    setCtaLinkText(ctaLinkText)
    setCtaLinkUrl(ctaLinkUrl)
    setVideoPlatform(videoPlatform)
    setVideoUrl(videoUrl)
  }, [dataField])

  return (
    <>
      <Divider className={styles.divider} plain />
      <Input
        label='Title: *'
        onBlur={event => handleTitleUpdate(event, index)}
        onChange={event => setTitle(event.target.value)}
        value={title}
      />
      <TextArea
        label='Description: *'
        onBlur={event => handleDescriptionUpdate(event, index)}
        onChange={event => setDescription(event.target.value)}
        value={description}
      />
      <Label value="Image:" />
      <Label className={styles.imageDisclaimer} value="*Image file no greater than 16 x 9 resolution" />
      <LogoUploader
        handleUpload={(thumbnailImageUrl, secureImageURL) => handleImageUpload(thumbnailImageUrl, secureImageURL, index)}
        logoUrl={imageUrl}
      />
      <Input
        label='CTA link:'
        onBlur={event => handleCtaLinkUpdate(event, index)}
        onChange={event => setCtaLinkUrl(event.target.value)}
        value={ctaLinkUrl}
      />
      <Input
        label='CTA link text:'
        onBlur={event => handleCtaTextUpdate(event, index)}
        onChange={event => setCtaLinkText(event.target.value)}
        value={ctaLinkText}
      />
      <CheckboxGroup label="Select the channels to send: *"
        onChange={selectedItems => handleChannelUpdate(selectedItems, index)}
        options={AVAILABLE_CHANNELS}
        selectedItems={selectedChannels}
      />
      <MultiSelectDropdown
        allowClear={true}
        customStyle={{ width: 200 }}
        displayField="name"
        dropdownOptions={VIDEO_TYPES}
        handleChange={selectedItems => handleVideoPlatformUpdate(selectedItems, index)}
        label='Select the Video platform type:'
        selectedItems={[videoPlatform]}
        valueField="key"
      />
      {
        videoPlatform &&
          <Input
            label='Video URL: *'
            onBlur={event => handleVideoUrlUpdate(event, index)}
            onChange={event => setVideoUrl(event.target.value)} value={videoUrl}
          />
      }
    </>
  )
})

DataField.propTypes = {
  dataField: PropTypes.object,
  handleChannelUpdate: PropTypes.func,
  handleCtaLinkUpdate: PropTypes.func,
  handleCtaTextUpdate: PropTypes.func,
  handleDescriptionUpdate: PropTypes.func,
  handleImageUpload: PropTypes.func,
  handleTitleUpdate: PropTypes.func,
  handleVideoPlatformUpdate: PropTypes.func,
  handleVideoUrlUpdate: PropTypes.func,
  index: PropTypes.number
}

DataField.displayName = 'DataField'

export default DataField
