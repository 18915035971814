import React from 'react'

import { Form, Select } from 'antd'
import PropTypes from 'prop-types'

import Label from '../Label/Label'
import styles from './MultiSelectDropdown.module.css'
const { Option } = Select
const { Item } = Form

const MultiSelectDropdown = ({
  allowClear,
  customStyle,
  disabled,
  displayField,
  dropdownOptions,
  handleChange,
  isLabelInline,
  label,
  mode,
  placeholder,
  selectedItems,
  valueField
}) => {
  const dropdownFilteredOptions = dropdownOptions.map((item, index) => (<Option key={`${item[valueField]}_${index}`} disabled={item.disabled} value={item[valueField]}>{item[displayField]}</Option>))

  return (
    <Item>
      {
        label && <Label
          isInline={isLabelInline}
          value={label}
        />
      }
      <Select
        allowClear={allowClear}
        className={styles.multiSelectDropdown}
        disabled={disabled}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        mode={mode}
        onChange={handleChange}
        placeholder={placeholder}
        showSearch
        style={customStyle}
        value={selectedItems} >
        { dropdownFilteredOptions }
      </Select>
    </Item>
  )
}

MultiSelectDropdown.propTypes = {
  allowClear: PropTypes.bool,
  customStyle: PropTypes.object,
  disabled: PropTypes.bool,
  displayField: PropTypes.string.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
  handleChange: PropTypes.func,
  isLabelInline: PropTypes.bool,
  label: PropTypes.string,
  mode: PropTypes.oneOf(['multiple', 'tags']),
  placeholder: PropTypes.string,
  selectedItems: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.string.isRequired]),
  valueField: PropTypes.string
}

MultiSelectDropdown.defaultPropTypes = {
  allowClear: false,
  disabled: false,
  displayField: 'name',
  dropdownOptions: [],
  isLabelInline: false,
  selectedItems: [],
  valueField: 'name'
}

export default MultiSelectDropdown
