import React, { useState, useEffect } from 'react'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Input, Table, Typography, Space } from 'antd'
import PropTypes from 'prop-types'

const { Link } = Typography

const ApplicationSections = ({ applications, isContentEditor, refreshToken, updateApplications, removeApplication }) => {
  const [updatedApplications, setUpdatedApplications] = useState(applications)

  useEffect(() => {
    if (applications) {
      setUpdatedApplications(applications)
    }
  }, [applications])

  const updateApplicationEditable = (record, index) => {
    const newData = [...updatedApplications]
    newData[index] = {
      ...record,
      editable: true
    }
    setUpdatedApplications(newData)
  }

  const updateApplicationName = (event, index) => {
    const newData = [...updatedApplications]
    newData[index].name = event.target.value
    setUpdatedApplications(newData)
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (text, record, index) => <Input disabled={!record.editable} onBlur={(event) => updateApplications({ ...record, name: event.target.value }, index)} onChange={(event) => updateApplicationName(event, index)} value={record.name} />
    },
    {
      title: 'Token',
      key: 'token',
      render: (text, { token, editable }, index) =>
        editable
          ? <Input disabled value={token} />
          : <Input.Password
            iconRender={visible => (
              visible
                ? <EyeTwoTone />
                : <EyeInvisibleOutlined />
            )}
            value={token}
          />
    },
    {
      title: '',
      key: 'action',
      render: (text, record, index) => {
        return (
          <Space size="middle">
            {
              record.editable
                ? <Link onClick={() => refreshToken(record, index)}>Refresh Token</Link>
                : <Link onClick={() => updateApplicationEditable(record, index)}>Edit</Link>
            }
            {
              !record.editable && <Link disabled={!isContentEditor} onClick={() => removeApplication(index)}>Delete</Link>
            }
          </Space>
        )
      }
    }
  ]
  return (
    updatedApplications && <Table
      columns={columns}
      dataSource={updatedApplications}
      pagination={false}
    />
  )
}

ApplicationSections.propTypes = {
  applications: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  isContentEditor: PropTypes.bool,
  refreshToken: PropTypes.func.isRequired,
  removeApplication: PropTypes.func.isRequired,
  updateApplications: PropTypes.func,
  updateName: PropTypes.func.isRequired
}

ApplicationSections.defaultProps = {
  applications: [],
  isContentEditor: false
}

export default ApplicationSections
