import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { GlobalHeader, Sidebar } from '~components'

const MainLayout = ({ component: Component, route, featureFlagMenus }) => {
  const [menusToRemoveFromSidebar, setMenusToRemoveFromSidebar] = useState([])

  useEffect(() => {
    if (featureFlagMenus) {
      setMenusToRemoveFromSidebar(JSON.parse(featureFlagMenus))
    }
  }, [featureFlagMenus])

  return (
    <Layout className="main-container">
      <Sidebar menusToRemoveFromSidebar={menusToRemoveFromSidebar} />
      <Layout>
        <GlobalHeader />
        <div>
          <Component route={route} />
        </div>
      </Layout>
    </Layout>
  )
}

MainLayout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  featureFlagMenus: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired
}

MainLayout.defaultProps = {
  featureFlagMenus: ''
}

const mapStateToProps = ({
  dashboard: {
    featureFlagMenus
  }
}) => ({
  featureFlagMenus
})

export default connect(
  mapStateToProps,
  null
)(MainLayout)
