const VALIDATION_ERRORS = {
  INVALID_DESCRIPTION: 'Provide valid description.',
  INVALID_ICON_URL: 'Provide valid icon url.',
  INVALID_TITLE: 'Provide valid title'
}

const isValidTitle = title => title.length > 0

export const validateFlag = flag => {
  const { title } = flag

  if (!isValidTitle(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TITLE }
  }

  return { isValid: true, message: '' }
}
