import 'isomorphic-fetch'

import { publishingPreferencesEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import { FETCH_PUBLISHING_PREFERENCES, PUBLISHING_PREFERENCES_UPDATE_STATUS } from '../actionTypes'

export const fetchPublishingPreferences = params => async dispatch => {
  const { method, url } = publishingPreferencesEndpoint.edit
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { allArtists, selectedArtists, allVenues, selectedVenues } =
    apiResponse
  dispatch({
    type: FETCH_PUBLISHING_PREFERENCES,
    payload: {
      allArtists,
      selectedArtists,
      allVenues,
      selectedVenues,
      params
    }
  })
}

export const updatePublishingPreferences = (selectedArtists, selectedVenues, accessToken) => async dispatch => {
  const { method, url } = publishingPreferencesEndpoint.update
  const idToken = getIdToken()
  const data = { selectedArtists, selectedVenues }
  const apiResponse = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${idToken}`,
      accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    })
    )
  const { success, message } = apiResponse
  dispatch({
    type: PUBLISHING_PREFERENCES_UPDATE_STATUS,
    payload: { message, success }
  })
}

export const resetPublishingPreferencesActionStatus = () => async dispatch => {
  dispatch({
    type: PUBLISHING_PREFERENCES_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}
