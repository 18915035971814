import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'

import styles from './ReactCloudinaryUploader.module.css'

const propTypes = {
  allowedFormats: PropTypes.array,
  buttonCaption: PropTypes.string,
  cloudName: PropTypes.string.isRequired,
  contextAlt: PropTypes.string,
  contextCaption: PropTypes.string,
  cropping: PropTypes.string,
  croppingAspectRatio: PropTypes.number,
  defaultSource: PropTypes.string,
  folder: PropTypes.string,
  maxFileSize: PropTypes.number,
  maxFiles: PropTypes.number,
  maxImageHeight: PropTypes.number,
  maxImageWidth: PropTypes.number,
  multiple: PropTypes.bool,
  publicId: PropTypes.string,
  resourceType: PropTypes.string,
  showPoweredBy: PropTypes.bool,
  sources: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string),
  thumbnailUrl: PropTypes.string,
  updateImageUrl: PropTypes.func.isRequired,
  uploadPreset: PropTypes.string.isRequired
}

const defaultProps = {
  allowedFormats: ['png', 'gif', 'jpeg', 'jpg', 'svg'],
  buttonCaption: 'Upload image',
  contextAlt: null,
  contextCaption: null,
  cropping: null,
  croppingAspectRation: null,
  defaultSource: 'local',
  folder: null,
  maxFiles: null,
  maxFileSize: null,
  maxImageHeight: null,
  maxImageWidth: null,
  multiple: false,
  publicId: null,
  resourceType: 'auto',
  showPoweredBy: false,
  sources: ['local', 'url'],
  tags: null
}

function getGUID () {
  function s4 () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
  s4() + '-' + s4() + s4() + s4()
}

const ReactCloudinaryUploader = (props) => {
  const [cloudName, setCloudName] = useState('')
  const [uploadPreset, setUploadPreset] = useState('')
  const [thumbnailUrl, setThumbnailUrl] = useState(null)
  const [uuid, setUUID] = useState(null)
  const [isImageUploaded, setIsImageUploaded] = useState(false)
  const [existingThumbnailUrl, setExistingThumbnailUrl] = useState(null)
  const [, setSecureUrl] = useState(null)
  const [, setErrorMessage] = useState(null)

  useEffect(() => {
    if (props) {
      setUUID(getGUID())
      setCloudName(props.cloudName)
      setUploadPreset(props.uploadPreset)
      setThumbnailUrl(props.thumbnailUrl)
      setExistingThumbnailUrl(props.thumbnailUrl)
    }
  }, [props])

  const getUploadOptions = () => {
    var options = {
      cloud_name: cloudName,
      upload_preset: uploadPreset,
      singleUploadAutoClose: true,
      showUploadMoreButton: false
    }
    options.sources = props.sources
    options.multiple = props.multiple

    if (props.maxFiles) {
      options.max_files = props.maxFiles
    }

    if (props.cropping && props.cropping === 'server') {
      options.cropping = props.cropping
    }

    if (props.publicId) {
      options.public_id = props.publicId
    }

    if (props.folder) {
      options.folder = props.folder
    }

    if (props.tags && props.tags.length > 0) {
      options.tags = props.tags
    }

    if (props.resourceType) {
      options.resourceType = props.resourceType
    }

    if (props.allowedFormats) {
      options.allowedFormats = props.allowedFormats
    }

    var context = {}
    if (props.contextAlt) {
      context.alt = props.contextAlt
    }

    if (props.contextCaption) {
      context.caption = props.contextCaption
    }

    if (Object.keys(context).length > 0) {
      options.context = context
    }

    return options
  }

  const setError = (errorMessage) => {
    setErrorMessage(errorMessage)
  }

  const setUploadResult = (uploadedImage) => {
    if (!uploadedImage) {
      return
    }
    const { secure_url: secureUrl, thumbnail_url: thumbnailUrl } = uploadedImage

    setSecureUrl(secureUrl)
    setThumbnailUrl(thumbnailUrl)
    setIsImageUploaded(true)
    props.updateImageUrl(thumbnailUrl, secureUrl)
  }

  const handleClick = (event) => {
    try {
      const options = getUploadOptions()
      const widget = window.cloudinary.createUploadWidget(
        options,
        (error, result) => {
          if (error) {
            setError(error)
            return false
          }

          if (!result || result.length === 0) {
            setError('No result from Cloudinary')
            return false
          }
          if (!error && result.event === 'success') {
            setUploadResult(result.info)
            return true
          }
        }
      )
      widget.open()
      event.preventDefault()
    } catch (e) {
      setError(e)
      return false
    }
  }

  const handleImageDelete = (event) => {
    setIsImageUploaded(false)
    setExistingThumbnailUrl('')
    props.updateImageUrl('')
    event.preventDefault()
  }

  const { buttonCaption } = props
  const uploaderId = 'uploader_' + uuid
  const image = thumbnailUrl || '#'

  return (
    <section>
      {!isImageUploaded &&
        <div className={styles.imageHolder}>
          <Button className={styles.uploadButton} href='#' id={uploaderId} onClick={handleClick}>{buttonCaption}</Button>
          {
            existingThumbnailUrl && <div className={styles.cloudinaryThumbnailImage} style={{ backgroundImage: `url(${existingThumbnailUrl})` }} />
          }
        </div>
      }
      {
        isImageUploaded && <div className={styles.cloudinaryThumbnailImage} style={{ backgroundImage: `url(${image})` }} />
      }
      {
        existingThumbnailUrl &&
        <Button className="cloudinary-delete" onClick={handleImageDelete} type='link'> Clear </Button>
      }
    </section>
  )
}

ReactCloudinaryUploader.propTypes = propTypes
ReactCloudinaryUploader.defaultProps = defaultProps

export default ReactCloudinaryUploader
