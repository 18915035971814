import React, { useCallback, useEffect, useState } from 'react'
import { Layout, Typography } from 'antd'
import PropTypes from 'prop-types'

import { history } from '~history'
import { Sidebar as SidebarMenu } from '~stories'
import MenuItems from './SidebarConfig'

import './Sidebar.modules.css'

const { Sider } = Layout
const { Link, Title } = Typography

const Sidebar = ({ menusToRemoveFromSidebar }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [menuItems, setMenuItems] = useState([])

  const getFilterMenus = useCallback(menuItems => {
    const menus = []
    menuItems.map(menu => {
      if (!menu.submenu && !menusToRemoveFromSidebar.includes(menu.key)) {
        menus.push(menu)
      } else if (menu.submenu) {
        const submenu = menu.submenu.items.filter(menu => !menusToRemoveFromSidebar.includes(menu.key))
        menus.push({
          submenu: {
            ...menu.submenu,
            items: submenu
          }
        })
      }
    })
    return menus
  }, [menusToRemoveFromSidebar])

  useEffect(() => {
    if (MenuItems && menusToRemoveFromSidebar) {
      const menus = getFilterMenus(MenuItems, menusToRemoveFromSidebar)
      setMenuItems(menus)
    }
  }, [getFilterMenus, menusToRemoveFromSidebar])

  const menuClickHandler = (selectedItem) => {
    const { key } = selectedItem
    let [selectedMenu] = menuItems.filter(menuItem => menuItem.key === key)
    if (!selectedMenu) {
      menuItems.filter(menuItem => menuItem.submenu
        ? menuItem.submenu.items.some(item => {
          if (item.key === key) {
            selectedMenu = item
          }
        })
        : false
      )
    }
    const { path } = selectedMenu
    history.push(path)
  }

  return (
    <Sider
      breakpoint="lg"
      className={collapsed ? 'sidebar-collapsed' : ''}
      collapsed={collapsed}
      collapsible
      onCollapse={(collapsed) => setCollapsed(collapsed)}
    >
      <Title className="headline" level={4}>
        <Link href="/">{collapsed ? 'EE' : 'Event Engine'}</Link>
      </Title>
      <SidebarMenu
        defaultSelectedKeys={['home']}
        menuItems={menuItems}
        mode={'inline'}
        onClick={menuClickHandler}
        theme={'dark'}
      />
    </Sider>
  )
}

Sidebar.propTypes = {
  menusToRemoveFromSidebar: PropTypes.array
}

export default Sidebar
