import React from 'react'
import { CopyOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { ItemList } from '~components'
import { PageLayout } from '~stories'

import styles from './Pricetemplates.module.css'

const getPriceTemplateCopyLink = id => <Button href={`/price_templates/${id}/copy`} icon={<CopyOutlined />} type='link'/>
const getPriceTemplateNameWithLink = ({ _id: id, name }) => <a href={`/price_templates/${id}/edit`}>{String(name).toUpperCase()}</a>

const columnData = [
  {
    render: ({
      _id: id
    }) => getPriceTemplateCopyLink(id),
    sorter: false,
    title: '',
    width: '5%'
  },
  {
    dataIndex: 'name',
    render: (text, priceTemplate) => getPriceTemplateNameWithLink(priceTemplate),
    sorter: true,
    title: 'Name',
    width: '30%',
    sortDirections: ['descend', 'ascend', 'descend']
  },
  {
    dataIndex: 'description',
    title: 'Description',
    width: '60%'
  }
]

const PAGE_TITLE = 'Fixed Price Templates'

const FixedPriceTemplates = () => {
  const addPriceTemplateButton = <Button href='/price_templates/fixed/new' type='primary'>
    Add
  </Button>

  return (
    <PageLayout extra={[addPriceTemplateButton]} title={PAGE_TITLE}>
      <div className={styles.pricetemplatesLayout}>
        <ItemList
          columns={columnData}
          defaultFilter={{ type: 'fixed' }}
          resource='priceTemplates'
        />
      </div>
    </PageLayout>
  )
}

export default FixedPriceTemplates
