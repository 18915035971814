import React from 'react'
import PropTypes from 'prop-types'

import { EDIT_MODE, PRICE_TEMPLATE } from '~constants'
import { Input, RichTextInput } from '~stories'

const PriceTemplateBasicInfo = ({
  description,
  discountPercentage,
  handleDescriptionChange,
  handleDiscountPercentageChange,
  handleNameChange,
  mode,
  name,
  type
}) => {
  const isDynamicTemplate = type === PRICE_TEMPLATE.DYNAMIC
  const isEditMode = mode === EDIT_MODE

  return (
    <>
      <Input disabled={isEditMode} label='Name' onChange={handleNameChange} value={name} />
      <RichTextInput label='Description' onChange={handleDescriptionChange} value={description} />
      {
        isDynamicTemplate && <Input label='Discount Percentage' onChange={handleDiscountPercentageChange} value={discountPercentage} />
      }
    </>
  )
}

PriceTemplateBasicInfo.propTypes = {
  description: PropTypes.string.isRequired,
  discountPercentage: PropTypes.string.isRequired,
  handleDescriptionChange: PropTypes.func,
  handleDiscountPercentageChange: PropTypes.func,
  handleNameChange: PropTypes.func,
  mode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

PriceTemplateBasicInfo.defaultProps = {
  description: '',
  discountPercentage: '',
  mode: 'new',
  name: '',
  type: 'fixed'
}

export default PriceTemplateBasicInfo
