import 'isomorphic-fetch'

import { adminEndpoint, contentEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import { CONTENT_UPDATE_STATUS, FETCH_CONTENTS, RESET_ADMIN_ACTION_STATUS, STORE_TASK_RESULT, TRIGGER_MANUAL_IMPORT } from '../actionTypes'

export const triggerAdhocTMImport = accessToken => async dispatch => {
  const { method, url } = adminEndpoint.tmImport
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  dispatch({
    type: TRIGGER_MANUAL_IMPORT,
    payload: {
      ...apiResponse
    }
  })
}

export const submitAdminTask = (formData, url, accessToken) => async dispatch => {
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'multipart/form-data',
      accessToken
    },
    body: formData,
    method: 'POST'
  })
  const response = await getApiResponseInJSON(apiResponse)
    .catch(error => {
      const { message } = error
      dispatch({
        type: STORE_TASK_RESULT,
        payload: {
          success: false,
          message
        }
      })
    })

  dispatch({
    type: STORE_TASK_RESULT,
    payload: {
      ...response
    }
  })
}

export const fetchContents = () => async dispatch => {
  const { method, url } = contentEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const contents = apiResponse
  dispatch({
    type: FETCH_CONTENTS,
    payload: {
      contents
    }
  })
}

export const updateContentBlocks = (contents, accessToken) => async dispatch => {
  const { method, url } = contentEndpoint.update
  const idToken = getIdToken()
  const data = { contents }
  const apiResponse = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${idToken}`,
      accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    })
    )
  const { success, message } = apiResponse
  dispatch({
    type: CONTENT_UPDATE_STATUS,
    payload: { message, success }
  })
}

export const getEventsEntranceInfo = async (url, accessToken) => {
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
      accessToken
    },
    method: 'GET'
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))

  return apiResponse
}

export const resetAdminActionStatus = () => async dispatch => {
  dispatch({
    type: RESET_ADMIN_ACTION_STATUS,
    payload: {
      message: '',
      status: false
    }
  })
}

export const fetchAdminTaskSampleFile = (s3Key, accessToken) => async dispatch => {
  const { method, url } = adminEndpoint.upload
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify({ s3Key }),
    headers: {
      Authorization: `Bearer ${idToken}`,
      accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))

  return apiResponse
}
