import React, { useEffect, useState } from 'react'
import { notification, Button } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import {
  fetchAllSeries,
  fetchAllPriceTemplates,
  fetchEventsBySeries,
  fetchEventsByPriceTemplate,
  addPromoCode,
  resetPromoCodeActionStatus
} from '~actions'
import {
  EditRockettesPromoCodeForm,
  EditMsgPromoCodeForm,
  ResultModal
} from '~components'
import { getCurrentUser } from '~context'
import { history } from '~history'
import { Spinner } from '~stories'
import { validatePromocode } from '~validators'

const PROMOCODE_DOMAINS = {
  ROCKETTES: 'rockettes',
  MSG: 'msg'
}
const ADD_ACTION_MODE = 'Add'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const PAGE_TITLE = {
  MSG: 'Add MSG Promo Code',
  ROCKETTES: 'Add Rockettes Promo Code'
}

const AddPromoCode = ({
  addPromoCode,
  allEventsForMsg,
  assignedEvents,
  assignedEventsToMsgPromo,
  eventsAssignedToSeries,
  fetchAllPriceTemplates,
  fetchAllSeries,
  fetchEventsByPriceTemplate,
  fetchEventsBySeries,
  promoCodeObjectId,
  priceTemplates,
  route = {},
  series,
  updateMessage,
  updateStatus
}) => {
  const [domain, setDomain] = useState('')
  const [isDataLoaded, setIsDataLoaded] = useState(null)

  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    const domain = idx(route, _ => _.match.params.domain)
    if (domain) {
      setDomain(domain)
    }
  }, [route])

  useEffect(() => {
    if (updateStatus) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
      resetPromoCodeActionStatus()
    }
  }, [updateMessage, updateStatus])

  useEffect(() => {
    setIsDataLoaded(false)
    fetchAllPriceTemplates()
    setIsDataLoaded(true)
  }, [fetchAllPriceTemplates])

  useEffect(() => {
    setIsDataLoaded(false)
    fetchAllSeries()
    setIsDataLoaded(true)
  }, [fetchAllSeries])

  const handleSubmit = promoCodeData => {
    const { isValid, message } = validatePromocode(promoCodeData)

    if (!isValid) {
      notification.error({ message })
      return
    }
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }
    setIsDataLoaded(false)
    addPromoCode(promoCodeData, accessToken)
  }

  const goToListPromocodesButton = (
    <Route key="listPromocodes" render={({ history }) => (
      <Button
        onClick={() => {
          resetPromoCodeActionStatus()
          history.push(`/promo_codes/${domain}`)
        }}
        type="primary"
      >
        Go to Promocodes List
      </Button>
    )} />
  )

  const addPromocodesButton = (
    <Route key="editPromocodes" render={({ history }) => (
      <Button
        onClick={() => {
          resetPromoCodeActionStatus()
          window.location.reload()
        }}
        type="primary"
      >
        Add More
      </Button>
    )} />
  )

  const editPromocode = (
    <Route key="editPromocode" render={({ history }) => (

      <Button
        onClick={() => {
          promoCodeObjectId && history.push(`/promo_codes/${promoCodeObjectId}/edit`)
          window.location.reload()
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const onModalClose = () => {
    resetPromoCodeActionStatus()
    history.push(`/promo_codes/${domain}`)
  }

  return (
    <>
      {
        domain === PROMOCODE_DOMAINS.ROCKETTES &&
          <EditRockettesPromoCodeForm
            actionMode={ADD_ACTION_MODE}
            assignedEvents={assignedEvents}
            domain={PROMOCODE_DOMAINS.ROCKETTES}
            fetchEventsByPriceTemplate={fetchEventsByPriceTemplate}
            handleSubmit={handleSubmit}
            pageTitle={PAGE_TITLE.ROCKETTES}
            priceTemplates={priceTemplates}
          />
      }
      {
        domain === PROMOCODE_DOMAINS.MSG &&
          <EditMsgPromoCodeForm
            actionMode={ADD_ACTION_MODE}
            allEvents={allEventsForMsg}
            assignedEvents={assignedEventsToMsgPromo}
            domain={PROMOCODE_DOMAINS.MSG}
            eventsAssignedToSeries={eventsAssignedToSeries}
            fetchEventsBySeries={fetchEventsBySeries}
            handleSubmit={handleSubmit}
            pageTitle={PAGE_TITLE.MSG}
            series={series}
          />
      }
      {
        isDataLoaded && updateStatus &&
        <ResultModal
          actions={[
            editPromocode,
            goToListPromocodesButton,
            addPromocodesButton
          ]}
          handleCancel={onModalClose}
          status="success"
          title={updateMessage}
        />
      }
      { !isDataLoaded && <Spinner isLoading={!isDataLoaded}/> }
    </>
  )
}

AddPromoCode.propTypes = {
  addPromoCode: PropTypes.func,
  allEventsForMsg: PropTypes.array,
  assignedEvents: PropTypes.array,
  assignedEventsToMsgPromo: PropTypes.array,
  eventsAssignedToSeries: PropTypes.array,
  fetchAllPriceTemplates: PropTypes.func,
  fetchAllSeries: PropTypes.func,
  fetchEventsByPriceTemplate: PropTypes.func,
  fetchEventsBySeries: PropTypes.func,
  priceTemplates: PropTypes.array,
  promoCodeObjectId: PropTypes.string,
  resetPromoCodeActionStatus: PropTypes.func,
  route: PropTypes.object,
  series: PropTypes.array,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool
}

const mapStateToProps = ({ promoCodes, priceTemplates, series }) => ({
  allEventsForMsg: promoCodes.allEvents,
  assignedEvents: priceTemplates.assignedEvents,
  assignedEventsToMsgPromo: promoCodes.assignedEventsToMsgPromo,
  eventsAssignedToSeries: series.eventsAssignedToSeries,
  promoCodeObjectId: promoCodes.promoCodeObjectId,
  priceTemplates: priceTemplates.priceTemplates,
  promoCode: promoCodes.promoCode,
  series: series.series,
  updateMessage: promoCodes.message,
  updateStatus: promoCodes.success
})

const mapDispatchToProps = {
  addPromoCode,
  fetchAllSeries,
  fetchAllPriceTemplates,
  fetchEventsByPriceTemplate,
  fetchEventsBySeries,
  resetPromoCodeActionStatus
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPromoCode)
