import {
  ADD_PROMO_CODE,
  FETCH_PROMO_CODE_TO_COPY,
  FETCH_PROMO_CODES,
  PROMO_CODES_UPDATE_STATUS,
  STORE_PROMO_CODE_TO_EDIT
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PROMO_CODE:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_PROMO_CODES:
      return {
        ...state,
        ...action.payload
      }
    case STORE_PROMO_CODE_TO_EDIT:
      return {
        ...state,
        assignedEventsToMsgPromo: action.payload.assignedEventsToMsgPromo,
        assignedEventsToRockettesPromo: action.payload.assignedEventsToRockettesPromo,
        promoCodeToEdit: action.payload.promoCode,
        priceTemplates: action.payload.priceTemplates,
        series: action.payload.series
      }
    case PROMO_CODES_UPDATE_STATUS:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message
      }
    case FETCH_PROMO_CODE_TO_COPY:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
