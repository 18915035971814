import { FETCH_TM_IMPORT_LOGS } from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TM_IMPORT_LOGS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
