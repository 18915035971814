import React from 'react'
import { Input as AntDInput, Form } from 'antd'
import PropTypes from 'prop-types'

import Label from '../Label/Label'

const { Item: AntDFormItem } = Form
const { TextArea: AntTextArea } = AntDInput

const TextArea = ({
  className,
  disabled,
  id,
  isInline,
  label,
  onBlur,
  onChange,
  placeholder,
  value
}) => {
  const inputProps = {
    className,
    disabled,
    id,
    ...(onBlur && { onBlur }),
    ...(onChange && { onChange }),
    placeholder,
    rows: 4,
    value
  }

  return (
    <>
      <Label
        isInline={isInline}
        value={label}
      />
      <AntDFormItem>
        <AntTextArea {...inputProps} />
      </AntDFormItem>
    </>
  )
}

TextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isInline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string
}

TextArea.defaultProps = {
  disabled: false,
  isInline: false,
  placeholder: '',
  value: ''
}

export default TextArea
