import 'isomorphic-fetch'

import { seriesEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import {
  ADD_SERIES_STATUS,
  FETCH_ALL_SERIES,
  FETCH_SERIES,
  INITIALIZE_SERIES,
  STORE_SERIES_TO_EDIT,
  SERIES_UPDATE_STATUS,
  STORE_EVENTS_BY_SERIES
} from '../actionTypes'

export const fetchSeriesList = params => async dispatch => {
  const { method, url } = seriesEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { series: rows, seriesCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_SERIES,
    payload: {
      params,
      rows,
      rowsCount
    }
  })
}

export const fetchAllSeries = params => async dispatch => {
  const { method, url } = seriesEndpoint.index
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { seriesWithActiveEvents: series } = apiResponse
  dispatch({
    type: FETCH_ALL_SERIES,
    payload: {
      series
    }
  })
}

export const fetchSeriesById = seriesId => async dispatch => {
  const { method, url } = seriesEndpoint.edit(seriesId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { allArtists, allCategories, allCities, allVenues, eventsAssignedToSeries, eventsAvailableForSeries, series } = apiResponse

  dispatch({
    type: STORE_SERIES_TO_EDIT,
    payload: {
      artists: allArtists,
      categories: allCategories,
      cities: allCities,
      eventsAssignedToSeries,
      eventsAvailableForSeries,
      series,
      venues: allVenues
    }
  })
}

export const updateSeriesById = (seriesData, accessToken) => async dispatch => {
  const { id: seriesId } = seriesData
  const { method, url } = seriesEndpoint.update(seriesId)
  const idToken = getIdToken()
  await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    method,
    body: JSON.stringify(seriesData)
  })
    .then(async response => await getApiResponseInJSON(response))
    .then(response => {
      dispatch({
        type: SERIES_UPDATE_STATUS,
        payload: {
          success: true
        }
      })
    })
    .catch(error => {
      dispatch({
        type: SERIES_UPDATE_STATUS,
        payload: {
          message: error.message,
          success: false
        }
      })
    })
}

export const addSeries = (seriesData, accessToken) => async dispatch => {
  const { method, url } = seriesEndpoint.add
  const idToken = getIdToken()
  await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    method,
    body: JSON.stringify(seriesData)
  })
    .then(async response => await getApiResponseInJSON(response))
    .then(response => {
      dispatch({
        type: ADD_SERIES_STATUS,
        payload: {
          success: true
        }
      })
    })
    .catch(error => {
      dispatch({
        type: ADD_SERIES_STATUS,
        payload: {
          message: error.message,
          success: false
        }
      })
    })
}

export const initializeSeries = accessToken => async dispatch => {
  const { method, url } = seriesEndpoint.new
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { allArtists, allCategories, allCities, allVenues, events, seriesId } = apiResponse
  dispatch({
    type: INITIALIZE_SERIES,
    payload: {
      artists: allArtists,
      categories: allCategories,
      cities: allCities,
      events,
      seriesId,
      venues: allVenues
    }
  })
}

export const resetAddSeriesStatus = () => async dispatch => {
  dispatch({
    type: ADD_SERIES_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}

export const resetUpdateSeriesStatus = () => async dispatch => {
  dispatch({
    type: SERIES_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}
export const fetchEventsBySeries = seriesId => async dispatch => {
  const { method, url } = seriesEndpoint.byId(seriesId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const {
    eventsAssignedToSeries
  } = apiResponse

  dispatch({
    type: STORE_EVENTS_BY_SERIES,
    payload: {
      eventsAssignedToSeries
    }
  })
}
