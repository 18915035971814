import React from 'react'
import { Card } from 'antd'
import PropTypes from 'prop-types'

import { RichTextInput } from '~stories'

const EventGeneralInfo = ({
  generalInfo: {
    cellphone_policy: cellphonePolicy,
    for_email: forEmail,
    general_admission: generalAdmission,
    special_offers: specialOffers
  },
  handleCellPhonePolicyUpdate,
  handleForEmailUpdate,
  handleGeneralAdmissionUpdate,
  handleSpecialOffersUpdate
}) => {
  return (
    <Card title="General Information">
      <RichTextInput label='General Admission' onChange={handleGeneralAdmissionUpdate} value={generalAdmission} />
      <RichTextInput label='Special Offers' onChange={handleSpecialOffersUpdate} value={specialOffers} />
      <RichTextInput label='Cell Phone Policy' onChange={handleCellPhonePolicyUpdate} value={cellphonePolicy} />
      <RichTextInput label='For Email' onChange={handleForEmailUpdate} value={forEmail} />
    </Card>
  )
}

EventGeneralInfo.propTypes = {
  generalInfo: PropTypes.object,
  handleCellPhonePolicyUpdate: PropTypes.func,
  handleForEmailUpdate: PropTypes.func,
  handleGeneralAdmissionUpdate: PropTypes.func,
  handleSpecialOffersUpdate: PropTypes.func
}

EventGeneralInfo.defaultProps = {
  generalInfo: {}
}

export default EventGeneralInfo
