import 'isomorphic-fetch'

import { priceTemplatesEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import {
  ADD_PRICE_TEMPLATE,
  FETCH_ALL_PRICE_TEMPLATES,
  FETCH_NEW_PRICE_TEMPLATE,
  FETCH_PRICE_TEMPLATE_TO_COPY,
  FETCH_PRICE_TEMPLATE_TO_EDIT,
  FETCH_PRICE_TEMPLATES,
  STORE_PRICE_TEMPLATE_EVENTS,
  UPDATE_PRICE_TEMPLATE
} from '../actionTypes'

const ERROR_TITLE = 'Error, expected title to be unique.'
const AUTH_ERROR = 'You are not authorized to perform this action.'

export const fetchNewPriceTemplate = () => async dispatch => {
  const { method, url } = priceTemplatesEndpoint.new
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { allEvents, allPriceClasses, description, discountPercentage, name, priceClasses, sections, selectedEvents } = apiResponse
  dispatch({
    type: FETCH_NEW_PRICE_TEMPLATE,
    payload: {
      priceTemplate: {
        allEvents,
        allPriceClasses,
        description,
        discountPercentage,
        name,
        priceClasses,
        sections,
        selectedEvents
      }
    }
  })
}

export const fetchPriceTemplateById = priceTemplateObjectId => async dispatch => {
  const { method, url } = priceTemplatesEndpoint.edit(priceTemplateObjectId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { allEvents, allPriceClasses, description, discountPercentage, name, priceClasses, sections, selectedEvents, type } = apiResponse
  dispatch({
    type: FETCH_PRICE_TEMPLATE_TO_EDIT,
    payload: {
      priceTemplate: {
        allEvents,
        allPriceClasses,
        description,
        discountPercentage,
        name,
        priceClasses,
        sections,
        selectedEvents,
        type
      }
    }
  })
}

export const copyPriceTemplateById = priceTemplateObjectId => async dispatch => {
  const { method, url } = priceTemplatesEndpoint.copy(priceTemplateObjectId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { allEvents, allPriceClasses, description, discountPercentage, priceClasses, sections, selectedEvents, type } = apiResponse
  dispatch({
    type: FETCH_PRICE_TEMPLATE_TO_COPY,
    payload: {
      priceTemplate: {
        allEvents,
        allPriceClasses,
        description,
        discountPercentage,
        priceClasses,
        sections,
        selectedEvents,
        type
      }
    }
  })
}

export const fetchPriceTemplatesList = params => async dispatch => {
  const { method, url } = priceTemplatesEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method,
    body: JSON.stringify(params)
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { priceTemplates: rows, priceTemplatesCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_PRICE_TEMPLATES,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchAllPriceTemplates = params => async dispatch => {
  const { method, url } = priceTemplatesEndpoint.index
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method,
    body: JSON.stringify(params)
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { priceTemplates } = apiResponse
  dispatch({
    type: FETCH_ALL_PRICE_TEMPLATES,
    payload: {
      priceTemplates
    }
  })
}

export const updatePriceTemplate = (priceTemplateObjectId, priceTemplate, accessToken) => async dispatch => {
  const { method, url } = priceTemplatesEndpoint.update(priceTemplateObjectId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify({ priceTemplate }),
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    }))
  const { message, success } = apiResponse
  dispatch({
    type: UPDATE_PRICE_TEMPLATE,
    payload: { message, success }
  })
}

export const resetPriceTemplateActionStatus = () => async dispatch => {
  dispatch({
    type: UPDATE_PRICE_TEMPLATE,
    payload: {
      error: false,
      message: '',
      success: false
    }
  })
}

export const addPriceTemplate = (priceTemplate, accessToken) => async dispatch => {
  const { method, url } = priceTemplatesEndpoint.add
  const idToken = getIdToken()
  await fetch(url, {
    body: JSON.stringify({ priceTemplate }),
    headers: {
      Authorization: `Bearer ${idToken}`,
      accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .then(response => {
      const { addedPriceTemplate: { _id: newPriceTemplateId }, message, success } = response
      dispatch({
        type: ADD_PRICE_TEMPLATE,
        payload: {
          newPriceTemplateId,
          error: false,
          message,
          success
        }
      })
    })
    .catch((error) => {
      const message = error.message === AUTH_ERROR ? error.message : ERROR_TITLE
      dispatch({
        type: ADD_PRICE_TEMPLATE,
        payload: {
          error: true,
          message,
          success: false
        }
      })
    })
}

export const fetchEventsByPriceTemplate = priceTemplateId => async dispatch => {
  const { method, url } = priceTemplatesEndpoint.eventsById(priceTemplateId)
  const idToken = getIdToken()
  await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .then(response => {
      const { events } = response
      dispatch({
        type: STORE_PRICE_TEMPLATE_EVENTS,
        payload: {
          assignedEvents: events
        }
      })
    })
    .catch(error => console.error(error))
}
