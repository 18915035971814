import React from 'react'
import idx from 'idx'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { COGNITO_LOGIN_URL } from '~constants'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userSession = localStorage.getItem('session')
  const idToken = idx(JSON.parse(userSession), _ => _.id_token)
  const idTokenExpiryTime = idx(JSON.parse(userSession), _ => _.idTokenExpiryTime)
  const isSessionValid = idToken && (idTokenExpiryTime > new Date().getTime())

  return isSessionValid
    ? <Route {...rest} render={props => (<Component {...props} />)} />
    : <Route
      component={() => {
        global.window && (global.window.location.href = COGNITO_LOGIN_URL)
        return null
      }}
    />
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType
}

export default PrivateRoute
