
const validateTitle = title => title.replace(/\s/g, '').length > 0

const validateDescription = description => description.replace(/\s/g, '').length > 0

const validateName = name => name.replace(/\s/g, '').length > 0

const validateBlocks = (blocks, contentErrors, contentIndex) => {
  blocks.forEach((block, blockIndex) => {
    contentErrors[contentIndex].fields[blockIndex] = {}
    if (!validateTitle(block.title)) {
      contentErrors[contentIndex].fields[blockIndex].title = 'Enter content block title!'
      contentErrors[contentIndex].isValid = false
    }
    if (!validateDescription(block.description)) {
      contentErrors[contentIndex].fields[blockIndex].description = 'Enter content block description!'
      contentErrors[contentIndex].isValid = false
    }
  })

  return contentErrors
}

const validateContent = (content, contentErrors, contentIndex) => {
  const {
    name,
    blocks
  } = content
  if (!validateName(name)) {
    contentErrors[contentIndex].fields.name = 'Enter content name!'
    contentErrors[contentIndex].isValid = false
  }
  if (!validateBlocks(blocks, contentErrors, contentIndex)) {
    contentErrors[contentIndex].isValid = false
  }

  return contentErrors
}

export const validateContentForm = contents => {
  let contentValidationErrorObj = {}

  contents.forEach((content, contentIndex) => {
    contentValidationErrorObj[contentIndex] = {
      isValid: true,
      fields: {}
    }
    contentValidationErrorObj = validateContent(content, contentValidationErrorObj, contentIndex)
  })

  return contentValidationErrorObj
}
