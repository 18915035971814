import React from 'react'
import PropTypes from 'prop-types'

import { Input, TextArea } from '~stories'

const VenueBoxOfficeInfo = ({
  data: {
    acceptedPaymentDetail,
    openHoursDetail,
    phoneNumberDetail,
    willCallDetail
  } = {},
  handleBoxOfficeUpdate
}) => {
  return (
    <>
      <TextArea id='willCallDetail' label='Will Call Detail' onChange={handleBoxOfficeUpdate} value={willCallDetail}/>
      <Input id='acceptedPaymentDetail' label='Accepted Payment Detail' onChange={handleBoxOfficeUpdate} value={acceptedPaymentDetail}/>
      <TextArea id='openHoursDetail' label='Open Hours Detail' onChange={handleBoxOfficeUpdate} value={openHoursDetail}/>
      <TextArea id='phoneNumberDetail' label='Phone Number Detail' onChange={handleBoxOfficeUpdate} value={phoneNumberDetail}/>
    </>
  )
}

VenueBoxOfficeInfo.propTypes = {
  data: PropTypes.object,
  handleBoxOfficeUpdate: PropTypes.func.isRequired
}

export default VenueBoxOfficeInfo
