import 'isomorphic-fetch'

import { sponsorEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import {
  ADD_NEW_SPONSOR,
  FETCH_NEW_SPONSOR,
  FETCH_SPONSORS,
  SPONSOR_UPDATE_STATUS,
  STORE_SPONSOR_TO_EDIT
} from '../actionTypes'

export const fetchSponsorsList = params => async dispatch => {
  const { method, url } = sponsorEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { sponsors: rows, sponsorsCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_SPONSORS,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchSponsorById = sponsorObjectId => async dispatch => {
  try {
    const { method, url } = sponsorEndpoint.edit(sponsorObjectId)
    const idToken = getIdToken()
    const apiResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .catch(error => console.error(error))
    dispatch({
      type: STORE_SPONSOR_TO_EDIT,
      payload: {
        ...apiResponse
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export const updateSponsor = (sponsorObjectId, sponsor, accessToken) => async dispatch => {
  try {
    const { method, url } = sponsorEndpoint.update(sponsorObjectId)
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify(sponsor),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { message, success } = response
        dispatch({
          type: SPONSOR_UPDATE_STATUS,
          payload: { message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const resetUpdateSponsorStatus = () => async dispatch => {
  dispatch({
    type: SPONSOR_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}

export const fetchNewSponsor = () => async dispatch => {
  const { method, url } = sponsorEndpoint.new
  const idToken = getIdToken()

  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { allEvents } = apiResponse
  dispatch({
    type: FETCH_NEW_SPONSOR,
    payload: {
      allEvents
    }
  })
}

export const addSponsor = (sponsor, accessToken) => async dispatch => {
  try {
    const { method, url } = sponsorEndpoint.add
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify({ sponsor }),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { addedSponsor: { _id: newSponsorId }, message, success } = response
        dispatch({
          type: ADD_NEW_SPONSOR,
          payload: { newSponsorId, message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const resetSponsorsActionStatus = () => async dispatch => {
  dispatch({
    type: ADD_NEW_SPONSOR,
    payload: {
      error: false,
      message: '',
      success: false
    }
  })
}
