import React from 'react'
import { Typography, Progress } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import styles from './EventsSales.module.css'

const { Title } = Typography

const EVENT_START_DATE_FORMAT = 'MMM DD'
const EVENT_START_TIME_12_HRS_FORMAT = 'h:mm A'
const EVENT_START_TIME_24_HRS_FORMAT = 'HH:mm:ss'
const EVENT_START_TIME_TBA = 'TBA'

const SalesSection = ({ eventName, imageUrl, eventStartDate, eventStartTime, eventVenue, availablePercentage, soldPercentage }) => {
  const eventDate = moment.utc(eventStartDate).format(EVENT_START_DATE_FORMAT)
  const eventTime = eventStartTime !== EVENT_START_TIME_TBA
    ? moment(eventStartTime, EVENT_START_TIME_24_HRS_FORMAT).format(` [at] ${EVENT_START_TIME_12_HRS_FORMAT}`)
    : EVENT_START_TIME_TBA
  const description = `${eventVenue} - ${eventDate} ${eventTime}`
  const status = soldPercentage ? '#2ecb40' : '#fe4136'
  const percentage = availablePercentage || soldPercentage
  const [salesPercent] = String(percentage).split('.')

  return <div className={styles.tile}>
    <div className={styles.thumbnail}>
      <img alt="" src={imageUrl} />
    </div>
    <div className={styles.mediaBody}>
      <Title className={styles.eventName} level={5}>
        <a className={styles.eventName}>
          { eventName }
        </a>
      </Title>
      <div className={styles.description}>
        <span>
          {
            description
          }
        </span>
      </div>
    </div>
    <div className={styles.progressBar}>
      <Progress percent={salesPercent} strokeColor={status} type="circle" width={50} />
    </div>
  </div>
}

SalesSection.propTypes = {
  availablePercentage: PropTypes.number,
  eventName: PropTypes.string,
  eventStartDate: PropTypes.string,
  eventStartTime: PropTypes.string,
  eventVenue: PropTypes.string,
  imageUrl: PropTypes.string,
  index: PropTypes.string,
  soldPercentage: PropTypes.number
}

SalesSection.defaultProps = {
  availablePercentage: 0,
  listItems: [],
  soldPercentage: 0
}

export default SalesSection
