import React, { useEffect, useState } from 'react'
import { Button, Card, notification } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { fetchNewSponsor, addSponsor, resetSponsorsActionStatus } from '~actions'
import { RecommendationsSelector, ResultModal, SponsorBasicInfo } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout, Spinner } from '~stories'
import { validateSponsor } from '~validators'

import styles from './NewSponsor.module.css'

const PAGE_TITLE = 'New Sponsor'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const NewSponsor = ({
  addSponsor,
  availableEventsForPromotion,
  fetchNewSponsor,
  newSponsorId,
  updateMessage,
  updateStatus
}) => {
  const [allEvents, setAllEvents] = useState([])
  const [selectedEvents, setSelectedEvents] = useState([])
  const [isDataLoaded, setIsDataLoaded] = useState()
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()
  const [sponsor, setSponsor] = useState({
    description: '',
    icon_url: '',
    title: ''
  })

  useEffect(() => {
    setIsDataLoaded(false)
    fetchNewSponsor()
  }, [fetchNewSponsor])

  useEffect(() => {
    if (availableEventsForPromotion) {
      setIsDataLoaded(true)
      setAllEvents(availableEventsForPromotion)
    }
  }, [availableEventsForPromotion])

  const handleSubmit = () => {
    const { isValid, message } = validateSponsor(sponsor)

    if (!isValid) {
      notification.error({ message })
      return
    }

    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    setIsDataLoaded(false)
    addSponsor({
      ...sponsor,
      events: selectedEvents.map(({ id }) => id)
    }, accessToken)
  }

  const handleLogoUpload = (thumbnailImageUrl, secureImageURL) => {
    const imageUrl = secureImageURL || thumbnailImageUrl

    setSponsor({
      ...sponsor,
      icon_url: imageUrl
    })
  }

  const handleDescriptionUpdate = description => {
    setSponsor({
      ...sponsor,
      description
    })
  }

  const handleTitleUpdate = event => {
    const title = event.target.value
    setSponsor({
      ...sponsor,
      title
    })
  }

  const handleModalClose = () => {
    resetSponsorsActionStatus()
  }

  const addSponsorsButton = (
    <Button
      onClick={() => {
        resetSponsorsActionStatus()
        window.location.reload()
      }}
      type="primary"
    >
      Add Another
    </Button>
  )

  const goToListSponsorsButton = (
    <Route key="listSponsors" render={({ history }) => (
      <Button
        onClick={() => {
          resetSponsorsActionStatus()
          history.push('/sponsors')
        }}
        type="primary"
      >
        Go to Sponsors List
      </Button>
    )} />
  )

  const editSponsor = (
    <Route key="editSponsor" render={({ history }) => (

      <Button
        onClick={() => {
          newSponsorId && history.push(`/sponsors/${newSponsorId}/edit`)
          window.location.reload()
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const addSponsorButton = <Button onClick={handleSubmit} type='primary'>
    Add
  </Button>

  return (
    <PageLayout extra={[addSponsorButton]} title={PAGE_TITLE}>
      <div className={styles.sponsorsLayoutBackground}>
        <SponsorBasicInfo
          handleDescriptionChange={handleDescriptionUpdate}
          handleLogoUpload={handleLogoUpload}
          handleTitleChange={handleTitleUpdate}
          sponsor={sponsor}
        />
      </div>
      <Card className={styles.eventsSelector} title='Events Selector'>
        <RecommendationsSelector
          allEvents={allEvents}
          displayField='title'
          promotedEvents={selectedEvents}
          resourceName='Events'
          setAllEvents={setAllEvents}
          setPromotedEvents={setSelectedEvents}
        />
      </Card>
      {
        !isDataLoaded &&
        <div className={styles.spinner}>
          <Spinner isLoading={!isDataLoaded}/>
        </div>
      }
      {
        updateStatus &&
        <ResultModal
          actions={[
            editSponsor,
            goToListSponsorsButton,
            addSponsorsButton
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

const mapStateToProps = ({ sponsors }) => ({
  availableEventsForPromotion: sponsors.allEvents,
  newSponsorId: sponsors.newSponsorId,
  updateMessage: sponsors.message,
  updateStatus: sponsors.success
})

const mapDispatchToProps = {
  addSponsor,
  fetchNewSponsor
}

NewSponsor.propTypes = {
  addSponsor: PropTypes.func,
  availableEventsForPromotion: PropTypes.array,
  fetchNewSponsor: PropTypes.func,
  newSponsorId: PropTypes.string,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewSponsor)
