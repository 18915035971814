import React, { useState, useEffect } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Upload, Button, Card, Col, notification } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchAdminTaskSampleFile, getEventsEntranceInfo, fetchVenuesList, resetAdminActionStatus, submitAdminTask } from '~actions'
import { getCurrentUser } from '~context'
import { MultiSelectDropdown, PageLayout, Input, DatePicker, Spinner } from '~stories'

import styles from './Admin.module.css'
import { adminTasks } from './AdminTaskOptions'

const NOTIFICATION_MESSAGES = {
  INVALID_SEATMAP_CONFIG: 'Invalid Seatmap Configuration',
  INVALID_VENUE_ID: 'Invalid Venue Id',
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const PAGE_TITLE = 'Admin'

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const validateConfigField = (venue, errorMessage) => {
  let validationResponse = true
  if (!venue) {
    notification.error({ message: errorMessage })
    validationResponse = false
  }
  return validationResponse
}

const Admin = ({
  adminActionMessage,
  adminActionStatus,
  fetchVenuesList,
  fetchAdminTaskSampleFile,
  resetAdminActionStatus,
  submitAdminTask,
  venues = []
}) => {
  const [disableFileUpload, setDisableFileUpload] = useState(true)
  const [endDate, setEndDate] = useState()
  const [fileList, setFileList] = useState([])
  const [seatmapConfigName, setSeatmapConfigName] = useState()
  const [selectedTask, setSelectedTask] = useState({})
  const [showProgress, setShowProgress] = useState(false)
  const [startDate, setStartDate] = useState()
  const [venueId, setSelectedVenueId] = useState()
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()
  const [fileType, setFileType] = useState(null)

  useEffect(() => {
    if (selectedTask.value === 'build_msg_seatmap') {
      fetchVenuesList()
    }
  }, [fetchVenuesList, selectedTask])

  useEffect(() => {
    if (adminActionStatus && adminActionMessage) {
      setShowProgress(false)
      notification.success({ message: adminActionMessage })
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    } else if (!adminActionStatus && adminActionMessage) {
      setShowProgress(false)
      notification.error({ message: adminActionMessage })
      resetAdminActionStatus()
      resetAdminSelections()
      setSelectedTask({})
    }
  }, [adminActionStatus, adminActionMessage, resetAdminActionStatus])

  const resetAdminSelections = () => {
    setSelectedVenueId(null)
    setFileList([])
    setShowProgress(false)
    setSeatmapConfigName(null)
  }

  const handleTaskSelection = async selectedTaskValue => {
    const [selectedTask] = adminTasks.filter(({ value }) => selectedTaskValue === value)
    const { s3Key, fileType } = selectedTask
    setSelectedTask(selectedTask)
    const [, downloadLink] = document.getElementsByTagName('a')
    downloadLink && document.getElementById('download-container').removeChild(downloadLink)
    if (s3Key) {
      const { presignedPutUrl } = await fetchAdminTaskSampleFile(s3Key, accessToken)
      var link = document.createElement('a')
      link.href = presignedPutUrl
      link.text = 'Download Sample Format'
      link.download = s3Key
      link.target = '_blank'
      document.getElementById('download-container').appendChild(link)
    }
    setDisableFileUpload(false)
    setFileType(fileType)
    resetAdminSelections()
  }

  const handleVenueChange = venueId => {
    setSelectedVenueId(venueId)
    setSeatmapConfigName(null)
  }

  const hangleSeatmapConfigNameChange = event => {
    setSeatmapConfigName(event.target.value)
  }

  const uploadProps = {
    name: 'file',
    accept: fileType || '.xls,.xlsx',
    method: 'get',
    maxCount: 1,
    beforeUpload: file => {
      let fileList = [file]
      const isFileValid2M = file.size / 1024 / 1024 > 5
      if (isFileValid2M) {
        notification.error({ message: 'Your file has not been uploaded.  Please try a file size less than 5MB or try again later.' })
        fileList = []
      }
      return setFileList(fileList)
    },
    fileList,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068'
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`
    },
    disabled: !!disableFileUpload
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const { apiUrl, value } = selectedTask
    if (startDate && endDate) {
      return fileDownload(apiUrl, startDate, endDate)
    } else {
      if (selectedTask && value === 'build_msg_seatmap') {
        if (!(validateConfigField(venueId, NOTIFICATION_MESSAGES.INVALID_VENUE_ID) && validateConfigField(seatmapConfigName, NOTIFICATION_MESSAGES.INVALID_SEATMAP_CONFIG))) {
          return
        }
      }
      setShowProgress(true)
      var formData = new FormData()
      const [file] = fileList

      const base64EncodedFile = await getBase64(file)
      let queryString = ''
      formData.append('name', file.name)
      formData.append('file', base64EncodedFile)
      if (venueId && seatmapConfigName) {
        queryString = `?venue_id=${venueId}&seatmap_configuration=${seatmapConfigName}`
        formData.append('seatmapConfiguration', seatmapConfigName)
      }
      const url = `${apiUrl}${queryString}`
      if (!isContentEditor) {
        notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
        return
      }
      await submitAdminTask(formData, url, accessToken)
    }
  }

  const replacer = (key, value) => value === null ? '' : value

  const jsonToCsv = events => {
    let csv = []
    if (events && events.length > 0) {
      const header = Object.keys(events[0])
      csv = events.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
      csv.unshift(header.join(','))
      csv = csv.join('\r\n')
    }
    return csv
  }

  const fileDownload = async (apiUrl, startDate, endDate) => {
    setShowProgress(true)
    const queryString = `?start_date=${startDate.format('MM-DD-YYYY')}&end_date=${endDate.format('MM-DD-YYYY')}`
    const apiResponse = await getEventsEntranceInfo(`${apiUrl}${queryString}`, accessToken)
    const { events } = apiResponse ?? { events: [] }
    const csv = jsonToCsv(events)
    setShowProgress(false)
    var blob = new Blob([csv], { type: 'text/csv' })
    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `EntranceInfo_${new Date().getTime()}.csv`
    link.click()
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  }

  const disabledSubmissionDate = submissionValue => {
    if (!submissionValue) {
      return false
    }
    return (submissionValue.valueOf() < startDate)
  }

  const enableSubmitButton = (selectedTask.value === 'download_event_entrance_info')
    ? !((startDate && endDate))
    : fileList && fileList.length === 0

  return (
    <PageLayout title={PAGE_TITLE}>
      <Spinner isLoading={showProgress} />
      <div className="admin-layout-background">
        <Card title="Tasks">
          <MultiSelectDropdown
            customStyle={{ width: 450 }}
            disabled={!isContentEditor}
            displayField="label"
            dropdownOptions={adminTasks}
            handleChange={handleTaskSelection}
            label='Task:'
            placeholder="Select Task"
            selectedItems={selectedTask ? [selectedTask.value] : selectedTask}
            valueField="value"
          />
          {
            selectedTask.value === 'build_msg_seatmap' &&
            <Col>
              <MultiSelectDropdown
                customStyle={{ width: 450 }}
                displayField="name"
                dropdownOptions={venues}
                handleChange={handleVenueChange}
                label='Venue:'
                placeholder="Select Venue"
                selectedItems={venueId}
                valueField="_id"
              />
              <Col style={{ width: '450px' }}>
                <Input
                  disabled={!venueId}
                  label={'Seatmap Configuration Name'}
                  onChange={hangleSeatmapConfigNameChange}
                  value={seatmapConfigName}
                />
              </Col>
            </Col>
          }
          {
            selectedTask.value === 'download_event_entrance_info' &&
            <Col>
              <DatePicker
                className={styles['component-width-90']}
                label="Start Date"
                onChange={(date) => setStartDate(date)}
                value={startDate}
              />
              <DatePicker
                className={styles['component-width-90']}
                disabledDate={disabledSubmissionDate}
                label="End Date"
                onChange={(date) => setEndDate(date)}
                value={endDate}
              />
            </Col>
          }
          {
            fileType && <p>Supported file types: {fileType}</p>
          }
          <div id='download-container' />
          {
            selectedTask.value !== 'download_event_entrance_info' &&
            <Upload {...uploadProps}>
              <Button
                className={styles.uploadButton}
                disabled={!!disableFileUpload}
                icon={<UploadOutlined />}
              >
                Click to Upload
              </Button>
            </Upload>
          }
          <Button
            className={styles.submitButton}
            disabled={enableSubmitButton}
            onClick={handleSubmit}
            type="primary"
          >
            Submit
          </Button>
        </Card>
      </div>
    </PageLayout>
  )
}

Admin.propTypes = {
  adminActionMessage: PropTypes.string,
  adminActionStatus: PropTypes.bool,
  fetchAdminTaskSampleFile: PropTypes.func,
  fetchVenuesList: PropTypes.func.isRequired,
  resetAdminActionStatus: PropTypes.func,
  submitAdminTask: PropTypes.func.isRequired,
  venues: PropTypes.array
}

const mapStateToProps = ({ venues, admin }) => {
  return {
    venues: venues.rows,
    adminActionMessage: admin.message,
    adminActionStatus: admin.success
  }
}

const mapDispatchToProps = {
  fetchVenuesList,
  fetchAdminTaskSampleFile,
  resetAdminActionStatus,
  submitAdminTask
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
