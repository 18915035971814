import React from 'react'

import { TimePicker } from 'antd'
import PropTypes from 'prop-types'

import Label from '../Label/Label'

const CustomTimePicker = ({
  disabled,
  format,
  isInline,
  label,
  labelClassName,
  onChange,
  style,
  value
}) => {
  return (
    <>
      <Label
        className={labelClassName}
        isInline={isInline}
        value={label}
      />
      <TimePicker
        disabled={disabled}
        format={format}
        {...(onChange && { onChange })}
        style={style}
        use12Hours
        value={value}
      />
    </>
  )
}

CustomTimePicker.propTypes = {
  disabled: PropTypes.bool,
  format: PropTypes.string,
  isInline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
}

CustomTimePicker.defaultProps = {
  format: 'h:mm a',
  isInline: false,
  labelClassName: ''
}

export default CustomTimePicker
