export const defaultVenueObject = {
  address: {
    line1: 'Anywhere'
  },
  availability_threshold: 0,
  boxOfficeInfo: {
    acceptedPaymentDetail: ''
  },
  city: {
    name: 'Anywhere'
  },
  do_not_override_recommendations: false,
  allow_event_auto_publish: false,
  door_open_time: {
    default: {
      name: 'default',
      doorOpenTimeDelta: 60
    }
  },
  generalInfo: {
    generalRule: '',
    childRule: ''
  },
  location: {
    longitude: '40.779925',
    latitude: '-73.980673'
  },
  logos: [],
  name: '',
  partner: true,
  postalCode: '10023',
  related_events: [],
  state: {
    name: 'New York',
    stateCode: 'NY'
  },
  legalCopy: '',
  logo_url: '',
  url: ''
}
