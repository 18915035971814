import React, { memo } from 'react'
import { Layout } from 'antd'
import { ConnectedRouter } from 'connected-react-router'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'

import { PrivateRoute } from '~components'
import { MainLayout } from '~containers'
import { UserProvider } from '~context'
import { Auth, NotFound, Logout } from '~pages'
import { RoutesList } from '~routesConfig'

import 'antd/dist/antd.min.css'

import './app.css'

const App = ({ history }) => {
  const withLayout = (Component, Layout) => route => <UserProvider><Layout component={Component} route={route} /></UserProvider> // eslint-disable-line react/display-name

  const buildRoutes = routes => routes
    .reduce((RouteList, { component, path, layout = MainLayout, redirectTo }) => {
      const routeComponent = redirectTo
        ? () => <Redirect to={redirectTo} />
        : withLayout(component, layout)

      return [
        ...RouteList,
        <PrivateRoute
          key={path}
          component={routeComponent}
          exact
          path={path}
        />
      ]
    }, [])

  const routes = buildRoutes(RoutesList)

  return (
    <ConnectedRouter history={history}>
      <Layout className="main-container">
        <div>
          <Switch>
            { routes }
            <Route component={Auth} exact path='/authorization' />
            <Route component={Logout} exact path='/logout' />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Layout>
    </ConnectedRouter>
  )
}

App.propTypes = {
  history: PropTypes.object
}

export default memo( // eslint-disable-line react/display-name
  App,
  (prevProps, nextProps) =>
    prevProps.history.location.pathname === nextProps.history.location.pathname
)
