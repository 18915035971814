import React, { useEffect, useState } from 'react'
import { Button, Card, Col, notification } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  RecommendationsSelector,
  SeriesBasicInfo,
  SeriesEventsFilter
} from '~components'
import { PageLayout } from '~stories'

const DEFAULT_SERIES_TYPE = 'series'

const AddSeriesForm = ({
  addSeries,
  artists,
  availableEvents,
  categories,
  cities,
  eventsAvailableForSeries,
  filterAllEvents,
  pageTitle,
  seriesId,
  venues
}) => {
  const [isFilteredDataLoaded, setIsFilteredDataLoaded] = useState(true)
  const [isFilteringCancelled, setIsFilteringCancelled] = useState(true)
  const [allEvents, setAllEvents] = useState([])
  const [selectedEvents, setSelectedEvents] = useState([])
  const [seriesName, setSeriesName] = useState('')
  const [seriesDescription, setSeriesDescription] = useState('')
  const [seriesType, setSeriesType] = useState(DEFAULT_SERIES_TYPE)

  useEffect(() => {
    setAllEvents(eventsAvailableForSeries)
  }, [eventsAvailableForSeries])

  useEffect(() => {
    if (availableEvents && availableEvents.length === 0) return
    setAllEvents(availableEvents)
    setIsFilteredDataLoaded(true)
    setIsFilteringCancelled(true)
  }, [availableEvents])

  const openNotificationWithIcon = (message, description) => {
    notification.error({
      message,
      description
    })
  }

  const hasSeriesName = seriesName && seriesName.length > 0
  const hasEvents = selectedEvents && selectedEvents.length > 0

  const handleSubmit = () => {
    if (!hasSeriesName) return openNotificationWithIcon('Invalid series name', 'Please provide valid series name.')
    if (!hasEvents) return openNotificationWithIcon('Missing event assignment', 'Please assign events to series.')
    addSeries({
      id: seriesId,
      name: seriesName,
      description: seriesDescription,
      type: seriesType,
      assignedEvents: selectedEvents
    })
  }

  const filterEvents = filters => {
    const eventsRecommended = [...selectedEvents]
    const assignedEvents = [...selectedEvents].map(event => event._id || event.id)
    const startDate = filters.startDate && moment(filters.startDate).format('YYYY-MM-DD')
    const endDate = filters.endDate && moment(filters.endDate).format('YYYY-MM-DD')
    const eventFilter = JSON.parse(JSON.stringify({
      ...filters,
      startDate,
      endDate,
      ...(eventsRecommended && eventsRecommended.length > 0 && { assignedEvents })
    }))

    filterAllEvents(eventFilter)
  }

  const handleFilter = filters => {
    setIsFilteredDataLoaded(false)
    filterEvents(filters)
  }

  const resetFilter = filters => {
    setIsFilteringCancelled(false)
    filterEvents(filters)
  }

  const addSeriesBUtton = <Button onClick={handleSubmit} type='primary'>
    Submit
  </Button>

  return (
    <PageLayout extra={[addSeriesBUtton]} title={pageTitle}>
      <SeriesBasicInfo
        seriesDescription={seriesDescription}
        seriesId={seriesId}
        seriesName={seriesName}
        seriesType={seriesType}
        setSeriesDescription={setSeriesDescription}
        setSeriesName={setSeriesName}
        setSeriesType={setSeriesType}
      />
      <Card>
        <Col span={24}>
          <SeriesEventsFilter
            artists={artists}
            categories={categories}
            cities={cities}
            handleFilter={handleFilter}
            isFilteredDataLoaded={isFilteredDataLoaded}
            isFilteringCancelled={isFilteringCancelled}
            resetFilter={resetFilter}
            venues={venues}
          />
          <RecommendationsSelector
            allEvents={allEvents}
            disabled={true}
            displayField="title"
            promotedEvents={selectedEvents}
            resourceName='Events'
            setAllEvents={setAllEvents}
            setPromotedEvents={setSelectedEvents}
          />
        </Col>
      </Card>
    </PageLayout>
  )
}

AddSeriesForm.propTypes = {
  addSeries: PropTypes.func,
  artists: PropTypes.array,
  availableEvents: PropTypes.array,
  categories: PropTypes.array,
  cities: PropTypes.array,
  eventsAvailableForSeries: PropTypes.array,
  filterAllEvents: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
  seriesId: PropTypes.string,
  venues: PropTypes.array
}

AddSeriesForm.defaultProps = {
  artists: [],
  availableEvents: [],
  categories: [],
  cities: [],
  eventsAvailableForSeries: [],
  venues: []
}

export default AddSeriesForm
