import React from 'react'
import { Typography } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import styles from './EventsStats.module.css'

const { Title } = Typography

const EVENT_START_DATE_FORMAT = 'MMM DD'
const EVENT_START_TIME_12_HRS_FORMAT = 'h:mm A'
const EVENT_START_TIME_24_HRS_FORMAT = 'HH:mm:ss'
const EVENT_START_TIME_TBA = 'TBA'

const EventsSection = ({ eventName, eventStartDate, eventStartTime, eventVenue, id, imageUrl }) => {
  const eventDate = moment.utc(eventStartDate).format(EVENT_START_DATE_FORMAT)
  const eventTime = eventStartTime !== EVENT_START_TIME_TBA
    ? moment(eventStartTime, EVENT_START_TIME_24_HRS_FORMAT).format(` [at] ${EVENT_START_TIME_12_HRS_FORMAT}`)
    : EVENT_START_TIME_TBA
  const description = `${eventVenue} - ${eventDate} ${eventTime}`

  return <div className={styles.tile}>
    <div className={styles.thumbnail}>
      <img alt="" src={imageUrl} />
    </div>
    <div className={styles.mediaBody}>
      <Title level={4}>
        <a className={styles.eventName} href={`/events/${id}/edit`}>
          {eventName}
        </a>
      </Title>
      <div className={styles.description}>
        <span>{description}</span>
      </div>
    </div>
  </div>
}

EventsSection.propTypes = {
  eventName: PropTypes.string,
  eventStartDate: PropTypes.string,
  eventStartTime: PropTypes.string,
  eventVenue: PropTypes.string,
  id: PropTypes.string,
  imageUrl: PropTypes.string,
  index: PropTypes.string
}

EventsSection.defaultProps = {
  listItems: []
}

export default EventsSection
