import React, { useEffect } from 'react'
import { Button, notification, Table } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { fetchNavigationsList } from '~actions'
import { getCurrentUser } from '~context'
import { PageLayout } from '~stories'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const PAGE_TITLE = 'Navigations'

const addNewNav = isContentEditor => {
  return (
    <Route render={({ history }) => (
      <Button
        key="1"
        onClick={() => {
          isContentEditor
            ? history.push('/navigations/new')
            : notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
        }}
        type="primary"
      >
        Add New Nav
      </Button>
    )} />
  )
}

const columns = [
  {
    title: 'Applications',
    dataIndex: 'app',
    key: 'app',
    render: (text, nav) => <a href={`/navigations/${nav._id}`}>{nav.app}</a>
  }]

const NavigationsList = ({ fetchNavigationsList, navigations }) => {
  const { userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    fetchNavigationsList()
  }, [fetchNavigationsList])

  return (
    <PageLayout extra={[
      addNewNav(isContentEditor)
    ]} title={PAGE_TITLE}>
      <Table bordered columns={columns} dataSource={navigations} pagination={false} />
    </PageLayout>
  )
}

NavigationsList.propTypes = {
  fetchNavigationsList: PropTypes.func,
  navigations: PropTypes.array
}

NavigationsList.defaultProps = {
  allVenues: []
}

const mapStateToProps = (state) => {
  const { message: updateMessage, success: updateStatus, navigations } = state.navigations
  const { allVenues } = state.dashboard
  return {
    updateMessage,
    updateStatus,
    allVenues,
    navigations
  }
}

const mapDispatchToProps = {
  fetchNavigationsList
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavigationsList)
)
