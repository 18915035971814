import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { Button, Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { LogoUploader } from '~components'
import { Input, Switch } from '~stories'

import styles from './VenueLogo.module.css'

const VenueLogo = ({
  addLogoMode,
  editLogoMode,
  handleAddNewLogo,
  handleCancelAddLogo,
  handleEdit,
  index,
  updateLogo,
  venueLogo
}) => {
  const [desktopLogo, setDesktopLogo] = useState()
  const [emailLogo, setEmailLogo] = useState()
  const [emailColorLogo, setEmailColorLogo] = useState()
  const [isActive, setIsActive] = useState()
  const [isDefault, setIsDefault] = useState()
  const [name, setName] = useState()
  const [newDesktopLogo, setNewDesktopLogo] = useState()
  const [newEmailLogo, setNewEmailLogo] = useState()
  const [newEmailColorLogo, setNewEmailColorLogo] = useState()
  const [resetToPreviousState, setResetToPreviousState] = useState(false)

  const setDefaultVenueLogoData = useCallback(() => {
    const {
      is_active: isActive,
      name,
      web_image_url: desktopLogo,
      email_image_url: emailLogo,
      email_image_url_color: emailColorLogo,
      is_default: isDefault
    } = venueLogo
    setDesktopLogo(desktopLogo)
    setEmailLogo(emailLogo)
    setEmailColorLogo(emailColorLogo)
    setIsActive(isActive)
    setIsDefault(isDefault)
    setName(name)
    setNewEmailLogo(null)
    setNewEmailColorLogo(null)
    setNewDesktopLogo(null)
  }, [venueLogo])

  useEffect(() => {
    if (venueLogo) {
      setDefaultVenueLogoData()
    }
  }, [venueLogo, setDefaultVenueLogoData])

  useEffect(() => {
    if (resetToPreviousState) {
      setDefaultVenueLogoData()
      setResetToPreviousState(false)
    }
  }, [resetToPreviousState, setDefaultVenueLogoData, setResetToPreviousState])

  const handleDesktopLogoUpload = (thumbnailImageUrl, secureImageURL) => {
    const imageUrl = secureImageURL || thumbnailImageUrl
    setNewDesktopLogo(imageUrl)
    if (venueLogo.isNew) setDesktopLogo(imageUrl)
  }

  const handleEmailLogoUpload = (thumbnailImageUrl, secureImageURL) => {
    const imageUrl = secureImageURL || thumbnailImageUrl
    setNewEmailLogo(imageUrl)
    if (venueLogo.isNew) setEmailLogo(imageUrl)
  }

  const handleEmailColorLogoUpload = (thumbnailImageUrl, secureImageURL) => {
    const imageUrl = secureImageURL || thumbnailImageUrl
    setNewEmailColorLogo(imageUrl)
    if (venueLogo.isNew) setEmailColorLogo(imageUrl)
  }

  const handleDefaultFlagUpdate = (isDefault) => {
    setIsDefault(isDefault)
  }

  const handleNameUpdate = (event) => {
    const name = event.target.value
    setName(name)
  }

  const handleCancel = () => {
    handleCancelAddLogo(index, venueLogo.isNew)
    setResetToPreviousState(true)
  }

  const canUpdate = editLogoMode || addLogoMode

  const handleSubmit = () => {
    const logo = {
      is_active: isActive,
      name,
      web_image_url: newDesktopLogo || desktopLogo,
      email_image_url: newEmailLogo || emailLogo,
      email_image_url_color: newEmailColorLogo || emailColorLogo,
      is_default: isDefault,
      isNew: venueLogo.isNew
    }
    addLogoMode ? handleAddNewLogo(logo) : updateLogo(logo, index)
  }

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Input disabled={!canUpdate} id='venueLogoName' isInline label={'Logo Name'} onChange={handleNameUpdate} value={name} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <label className={styles.uploaderLabel} htmlFor='venueDesktopLogoUrl'>Desktop Logo URL</label>
            { !newDesktopLogo && desktopLogo && <img alt='Desktop Logo URL' className={styles.logoPreview} height='100px' src={desktopLogo} width='300px' /> }
            <div className={styles.cloudinaryUploadContainer}>
              { canUpdate && <LogoUploader handleUpload={handleDesktopLogoUpload} logoUrl={newDesktopLogo} /> }
            </div>
          </Col>
          <Col span={8}>
            <label className={styles.uploaderLabel} htmlFor='venueEmailLogoUrl'>Email Logo URL</label>
            { !newEmailLogo && emailLogo && <img alt='Email Logo URL' className={styles.logoPreview} height='100px' src={emailLogo} width='300px' /> }
            <div className={styles.cloudinaryUploadContainer}>
              { canUpdate && <LogoUploader handleUpload={handleEmailLogoUpload} logoUrl={newEmailLogo} /> }
            </div>
          </Col>
          <Col span={8}>
            <label className={styles.uploaderLabel} htmlFor='venueEmailLogoUrl'>Email Logo URL (Color) </label>
            { !newEmailColorLogo && emailColorLogo && <img alt='Email Color Logo URL' className={styles.logoPreview} height='100px' src={emailColorLogo} width='300px' /> }
            <div className={styles.cloudinaryUploadContainer}>
              { canUpdate && <LogoUploader handleUpload={handleEmailColorLogoUpload} logoUrl={newEmailColorLogo} /> }
            </div>
          </Col>
        </Row>
        <Row>
          <div className={styles.statusFlagsContainer}>
            <Switch
              checked={isDefault}
              disabled={!canUpdate}
              isInline
              label='Is Default'
              onChange={handleDefaultFlagUpdate}
            />
          </div>
        </Row>
        <Row>
          <div className={styles.actionContainer}>
            {
              canUpdate
                ? <Fragment>
                  <Button onClick={handleSubmit} size='middle' type='primary'>
                    Submit
                  </Button>
                  <Button onClick={handleCancel} type='default'>
                    Cancel
                  </Button>
                </Fragment>
                : <Button onClick={handleEdit} type='primary'>
                  Edit
                </Button>
            }
          </div>
        </Row>
      </Col>
    </Row>
  )
}

VenueLogo.propTypes = {
  addLogoMode: PropTypes.bool,
  editLogoMode: PropTypes.bool,
  handleAddNewLogo: PropTypes.func,
  handleCancelAddLogo: PropTypes.func,
  handleEdit: PropTypes.func,
  index: PropTypes.number,
  updateLogo: PropTypes.func,
  venueLogo: PropTypes.object
}

export default VenueLogo
