import React from 'react'
import { Button, notification, Tag, Typography } from 'antd'
import { Route } from 'react-router-dom'

import { ItemList } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout } from '~stories'

import './Organizations.modules.css'

const { Link } = Typography
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const getOrganizationNameWithLink = organization => <Link href={`/organizations/${organization._id}/edit`}>{String(organization.name).toUpperCase()}</Link>
const getOrganizationTokens = tokens => tokens.map((app, index) => <Tag key={index} color='volcano'>{app.name.toUpperCase()}</Tag>)

const columnData = [
  {
    dataIndex: 'name',
    render: (text, organization) => getOrganizationNameWithLink(organization),
    title: 'Name',
    width: '15%'
  },
  {
    render: applications => getOrganizationTokens(applications),
    title: 'Applications',
    dataIndex: 'applications',
    width: '80%'
  }
]

const addNewOrganisations = isContentEditor => {
  return (
    <Route render={({ history }) => (
      <Button
        key="1"
        onClick={() => {
          isContentEditor
            ? history.push('/organizations/new')
            : notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
        }}
        type="primary"
      >
        Add Organisation
      </Button>
    )} />
  )
}

const OrganizationsList = () => {
  const { userDetails: { isContentEditor } } = getCurrentUser()
  return (
    <PageLayout extra={[addNewOrganisations(isContentEditor)]} title='Organizations'>
      <div className='organizations-layout-background'>
        <ItemList columns={columnData} resource='organizations' />
      </div>
    </PageLayout>
  )
}

export default OrganizationsList
