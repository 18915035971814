import React from 'react'
import { List, Typography } from 'antd'
import PropTypes from 'prop-types'

const { Title } = Typography

const CustomList = ({
  displayField,
  items,
  size,
  title
}) => {
  const header = <Title level={3}>{title}</Title>
  return (
    <List
      bordered
      dataSource={items}
      header={header}
      renderItem={item => <List.Item>{item[displayField]}</List.Item>}
      size={size}
    />
  )
}

CustomList.propTypes = {
  displayField: PropTypes.string,
  items: PropTypes.array.isRequired,
  size: PropTypes.string,
  title: PropTypes.string.isRequired
}

CustomList.defaultProps = {
  size: 'large',
  items: []
}
export default CustomList
