import React from 'react'
import { Row, Col, Card } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import { Checkbox, RangePicker } from '~stories'

const defaultChasePremiumPartner = {
  name: 'Chase',
  early_on_sale: {
    is_available: false,
    start_date_time: null,
    end_date_time: null
  },
  preferred_seating: {
    is_available: false
  },
  do_not_override_from_tm: false
}

const EventPromotions = ({
  premiumPartners,
  handleDoNotOverrideFromTMUpdate,
  handleEarlyOnSaleAvailabilityUpdate,
  handlePreferredSeatingAvailabilityUpdate,
  handleEarlyOnSaleDateUpdate
}) => {
  const [premiumPartner] = premiumPartners

  const {
    do_not_override_from_tm: doNotOverrideFromTM,
    early_on_sale: earlyOnSale,
    preferred_seating: preferredSeating
  } = premiumPartner || defaultChasePremiumPartner

  const {
    end_date_time: endDateTime,
    is_available: isEarlyOnSaleAvailable,
    start_date_time: startDateTime
  } = earlyOnSale ?? {}

  const {
    is_available: isPreferredSeatingAvailable
  } = preferredSeating

  const startDate = startDateTime ? moment(startDateTime) : null
  const endDate = endDateTime ? moment(endDateTime) : null

  const getOverrideFlag = () => {
    return <div onClick={ e => e.stopPropagation() }>
      <Checkbox
        checked={doNotOverrideFromTM}
        isInline
        label='DO NOT OVERRIDE FROM TM'
        onChange={handleDoNotOverrideFromTMUpdate}
      />
    </div>
  }

  return (
    <Card extra={getOverrideFlag()} title='Promotions'>
      <Row>
        <Col span={5}>
          <Checkbox
            checked={isEarlyOnSaleAvailable}
            isInline
            label='Chase Early On Sale Seating:'
            onChange={handleEarlyOnSaleAvailabilityUpdate}
          />
        </Col>
        {isEarlyOnSaleAvailable && <Col>
          <RangePicker
            format="MM/DD/YYYY h:mm A"
            isInline
            label={'Early On Sale Date Range:'}
            onChange={handleEarlyOnSaleDateUpdate}
            showTime={true}
            value={[startDate, endDate]}
          />
        </Col>}
      </Row>
      <br />
      <Row>
        <Col span={5}>
          <Checkbox
            checked={isPreferredSeatingAvailable}
            isInline
            label='Chase Preferred Seating:'
            onChange={handlePreferredSeatingAvailabilityUpdate}
          />
        </Col>
      </Row>
    </Card>
  )
}

EventPromotions.propTypes = {
  handleDoNotOverrideFromTMUpdate: PropTypes.func,
  handleEarlyOnSaleAvailabilityUpdate: PropTypes.func,
  handleEarlyOnSaleDateUpdate: PropTypes.func,
  handlePreferredSeatingAvailabilityUpdate: PropTypes.func,
  premiumPartners: PropTypes.array
}

export default EventPromotions
