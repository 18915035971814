export const adminTasks = [
  {
    apiUrl: `${process.env.REACT_APP_BASE_URL}/events`,
    fileType: '.json',
    label: 'Add Partner Events',
    s3Key: 'partner_event.json',
    value: 'create_partner_events'
  },
  {
    apiUrl: `${process.env.REACT_APP_BASE_URL}/build_msg_seatmap`,
    fileType: '.xlsx',
    label: 'Build MSG Venue Seatmap',
    partner: false,
    queryParamRequired: true,
    s3Key: 'msg-rangers.xlsx',
    value: 'build_msg_seatmap'
  },
  {
    apiUrl: `${process.env.REACT_APP_BASE_URL}/build_rcmh_seatmap`,
    fileType: '.xlsx',
    label: 'Build RCMH Seatmap',
    s3Key: 'radio_city_seatmap_2022.xlsx',
    value: 'build_rcmh_seatmap'
  },
  {
    apiUrl: `${process.env.REACT_APP_BASE_URL}/events/entrance_info`,
    fileType: '.xlsx',
    label: 'Download Events Entrace Info',
    value: 'download_event_entrance_info'
  },
  {
    apiUrl: `${process.env.REACT_APP_BASE_URL}/events/entrance_info`,
    fileType: '.xlsx',
    label: 'Events Entrace Info',
    s3Key: 'event_entrance_info.xlsx',
    value: 'event_entrance_info'
  },
  {
    apiUrl: `${process.env.REACT_APP_BASE_URL}/events/genres`,
    fileType: '.xlsx',
    label: 'Events Genres Assignment',
    s3Key: 'genre_assignments.xlsx',
    value: 'event_genres'
  },
  {
    apiUrl: `${process.env.REACT_APP_BASE_URL}/events/holidays`,
    fileType: '.xlsx',
    label: 'Holidays Info',
    s3Key: 'holidays.xlsx',
    value: 'holidays_info'
  },
  {
    apiUrl: `${process.env.REACT_APP_BASE_URL}/events/price_class`,
    fileType: '.xlsx',
    label: 'Rockettes Events Price Class Assignment',
    s3Key: 'rockettes_event_class.xlsx',
    value: 'event_price_class'
  },
  {
    apiUrl: `${process.env.REACT_APP_BASE_URL}/venues/logos`,
    fileType: '.xlsx',
    label: 'Venue Logos Assignment',
    s3Key: 'venue_logos.xlsx',
    value: 'venue_logos'
  },
  {
    apiUrl: `${process.env.REACT_APP_BASE_URL}/organizations/seeding`,
    fileType: '.xlsx',
    label: 'Seed Organisations',
    s3Key: 'organisations.xlsx',
    value: 'organizations_seeding'
  }
]
