import { UPDATE_WORK_VIVO } from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_WORK_VIVO:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
