import React, { useEffect, useState } from 'react'
import { Button, Switch, notification } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { fetchNewOptInModal, addOptInModal, resetOptInModalsActionStatus } from '~actions'
import { ResultModal, OptInInfo } from '~components'
import { FLAG_APPS } from '~constants'
import { getCurrentUser } from '~context'
import { PageLayout, Spinner } from '~stories'
import { validateOptInModal, VALIDATION_ERRORS, validateMaxHeaderCharLimit, validateMaxDescriptionCharLimit, validateMaxTitleCharLimit, validateMaxMessageCharLimit } from '~validators'

import styles from './NewOptInModal.module.css'

const PAGE_TITLE = 'New OptIn Modal'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const NewOptInModal = ({
  venuesList,
  addOptInModal,
  fetchNewOptInModal,
  newOptInModalId,
  updateMessage,
  updateStatus
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState(true)
  const [allVenues, setAllVenues] = useState()
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()
  const [optInModal, setOptInModal] = useState({
    app_name: 'msg',
    button_text: '',
    venue_id: '',
    count_down: {
      status: false,
      expiration_date_time: null
    },
    description: '',
    disclaimer: '',
    formstack_id: '',
    header: '',
    image_url: '',
    show_modal: false,
    success_screen: {
      title: '',
      message: ''
    }
  })

  useEffect(() => {
    if (!allVenues) {
      setIsDataLoaded(false)
      fetchNewOptInModal(accessToken)
    }
  }, [accessToken, allVenues, fetchNewOptInModal])

  useEffect(() => {
    if (venuesList) {
      setIsDataLoaded(true)
      setAllVenues(venuesList)
    }
  }, [venuesList])

  const handleSubmit = () => {
    const { isValid, message } = validateOptInModal(optInModal)

    if (!isValid) {
      notification.error({ message })
      return
    }

    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    setIsDataLoaded(false)

    addOptInModal({
      ...optInModal
    }, accessToken)
  }

  const handleImageUpload = (thumbnailImageUrl, secureImageURL) => {
    const optInModalObj = { ...optInModal }
    const imageUrl = secureImageURL || thumbnailImageUrl

    setOptInModal({
      ...optInModalObj,
      image_url: imageUrl
    })
  }

  const handleDescriptionChange = description => {
    const optInModalObj = { ...optInModal }
    if (validateMaxDescriptionCharLimit(description)) {
      const updatedOptInModal = { ...optInModalObj, description }
      setOptInModal(updatedOptInModal)
    } else {
      notification.error({ message: VALIDATION_ERRORS.DESC_MAX_CHAR_LIMIT })
    }
  }

  const handleDisclaimerChange = disclaimer => {
    const optInModalObj = { ...optInModal }
    setOptInModal({
      ...optInModalObj,
      disclaimer
    })
  }

  const handleHeaderChange = header => {
    const optInModalObj = { ...optInModal }
    if (validateMaxHeaderCharLimit(header)) {
      setOptInModal({
        ...optInModalObj,
        header
      })
    } else {
      notification.error({ message: VALIDATION_ERRORS.HEADER_MAX_CHAR_LIMIT })
    }
  }

  const handleFormIdUpdate = event => {
    const optInModalObj = { ...optInModal }
    const formstackId = event.target.value
    const re = /^[0-9\b]+$/
    if (formstackId === '' || re.test(formstackId)) {
      setOptInModal({
        ...optInModalObj,
        formstack_id: formstackId
      })
    }
  }

  const handleButtonTextUpdate = (event) => {
    const optInModalObj = { ...optInModal }
    const buttonText = event.target.value
    setOptInModal({
      ...optInModalObj,
      button_text: buttonText
    })
  }

  const handleModalClose = () => {
    resetOptInModalsActionStatus()
  }

  const addOptInModalsButton = (
    <Button
      onClick={() => {
        resetOptInModalsActionStatus()
        window.location.reload()
      }}
      type="primary"
    >
      Add Another
    </Button>
  )

  const goToListOptInsButton = (
    <Route key="listOptInModal" render={({ history }) => (
      <Button
        onClick={() => {
          resetOptInModalsActionStatus()
          history.push('/opt-in-modal')
        }}
        type="primary"
      >
        Go to Opt-in-modal List
      </Button>
    )} />
  )

  const editOptinModal = (
    <Route key="editOptinModal" render={({ history }) => (

      <Button
        onClick={() => {
          newOptInModalId && history.push(`/opt-in-modal/${newOptInModalId}/edit`)
          window.location.reload()
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const addOptInModalButton = <Button onClick={handleSubmit} type='primary'>
    Add
  </Button>

  const handleOptInModalStatus = (status) => {
    const optInModalObj = { ...optInModal }
    setOptInModal({
      ...optInModalObj,
      show_modal: status
    })
  }

  const handleAppNameChange = selectedItem => {
    const optInModalObj = { ...optInModal }
    const [appName] = FLAG_APPS.filter(flag => flag.value === selectedItem)

    setOptInModal({
      ...optInModalObj,
      app_name: appName.value
    })
  }

  const handleOptInModalCountDownStatus = (status) => {
    const optInModalObj = { ...optInModal }
    const { count_down: countDown } = optInModalObj
    setOptInModal({
      ...optInModalObj,
      count_down: {
        ...countDown,
        status,
        ...(!status && { expiration_date_time: null })
      }
    })
  }

  const handleCoundDownEndDate = (date) => {
    const optInModalObj = { ...optInModal }
    const { count_down: countDown } = optInModalObj
    setOptInModal({
      ...optInModalObj,
      count_down: {
        ...countDown,
        expiration_date_time: +date.format('X')
      }
    })
  }

  const handleTitleChange = (title) => {
    const optInModalObj = { ...optInModal }
    if (validateMaxTitleCharLimit(title)) {
      const { success_screen: successScreen } = optInModalObj
      setOptInModal({
        ...optInModalObj,
        success_screen: {
          ...successScreen,
          title
        }
      })
    } else {
      notification.error({ message: VALIDATION_ERRORS.TITLE_MAX_CHAR_LIMIT })
    }
  }

  const handleMessageChange = (message) => {
    const optInModalObj = { ...optInModal }
    if (validateMaxMessageCharLimit(message)) {
      const { success_screen: successScreen } = optInModalObj
      setOptInModal({
        ...optInModalObj,
        success_screen: {
          ...successScreen,
          message
        }
      })
    } else {
      notification.error({ message: VALIDATION_ERRORS.MESSAGE_MAX_CHAR_LIMIT })
    }
  }

  const handleVenueUpdate = (venueName) => {
    const [filteredVenue] = allVenues.filter(venue => venue.name === venueName)

    const { id } = filteredVenue
    const optInModalObj = { ...optInModal }

    setOptInModal({
      ...optInModalObj,
      venue_id: id
    })
  }

  const publishSwitch = <Switch checked={optInModal?.show_modal} checkedChildren="Show" defaultChecked onChange={handleOptInModalStatus} unCheckedChildren="Hide" />

  return (
    <PageLayout extra={[publishSwitch, addOptInModalButton]} title={PAGE_TITLE}>
      <div className={styles.sponsorsLayoutBackground}>
        <OptInInfo
          allVenues={allVenues}
          handleAppNameChange={handleAppNameChange}
          handleButtonTextUpdate={handleButtonTextUpdate}
          handleCoundDownEndDate={handleCoundDownEndDate}
          handleDescriptionChange={handleDescriptionChange}
          handleDisclaimerChange={handleDisclaimerChange}
          handleFormIdUpdate={handleFormIdUpdate}
          handleHeaderChange={handleHeaderChange}
          handleImageUpload={handleImageUpload}
          handleMessageChange={handleMessageChange}
          handleOptInModalCountDownStatus={handleOptInModalCountDownStatus}
          handleTitleChange={handleTitleChange}
          handleVenueUpdate={handleVenueUpdate}
          optInModal={optInModal}
        />
      </div>
      {
        !isDataLoaded &&
        <div className={styles.spinner}>
          <Spinner isLoading={!isDataLoaded}/>
        </div>
      }
      {
        updateStatus &&
        <ResultModal
          actions={[
            editOptinModal,
            goToListOptInsButton,
            addOptInModalsButton
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

const mapStateToProps = ({ optInModal }) => {
  return {
    optInModal,
    updateMessage: optInModal.message,
    newOptInModalId: optInModal.newOptInModalId,
    updateStatus: optInModal.success,
    venuesList: optInModal.allVenues
  }
}

const mapDispatchToProps = {
  addOptInModal,
  fetchNewOptInModal
}

NewOptInModal.propTypes = {
  addOptInModal: PropTypes.func,
  availableEventsForPromotion: PropTypes.array,
  fetchNewOptInModal: PropTypes.func,
  newOptInModalId: PropTypes.string,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool,
  venuesList: PropTypes.array
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewOptInModal)
