import React from 'react'
import PropTypes from 'prop-types'

import { ItemList } from '~components'
import { PageLayout } from '~stories'

import './PromoCodesList.modules.css'

const PROMO_CODES_RESOURCE = 'promoCodes'
const STATUS_FILTER_OPTIONS = [
  { name: 'Active', value: 'Active' },
  { name: 'Inactive', value: 'Inactive' }
]

const PromoCodesList = ({
  columnData,
  domain,
  extraActions
}) => {
  const defaultFilter = { domain }
  const pageTitle = `PromoCodes - ${domain.toUpperCase()}`
  const inputProps = {
    columns: columnData,
    defaultFilter: defaultFilter,
    filterKey: 'status',
    filterOptions: STATUS_FILTER_OPTIONS,
    filterValue: 'Active',
    resource: PROMO_CODES_RESOURCE,
    ...(domain === 'msg' && { showSecondaryFilter: true })
  }

  return (
    <PageLayout
      extra={extraActions}
      title={pageTitle}
    >
      <div className="promocodes-layout-background">
        <ItemList
          {...inputProps}
        />
      </div>
    </PageLayout>
  )
}

PromoCodesList.propTypes = {
  columnData: PropTypes.array.isRequired,
  domain: PropTypes.string.isRequired,
  extraActions: PropTypes.array
}

PromoCodesList.defaultProps = {
  columnData: [],
  domain: 'rockettes',
  extraActions: []
}

export default PromoCodesList
