import React from 'react'

import { Layout, PageHeader } from 'antd'
import PropTypes from 'prop-types'
import styles from './PageLayout.module.css'

const { Content } = Layout

const PageLayout = ({
  children,
  extra = [],
  title
}) => {
  return (
    <Layout>
      <PageHeader className="site-page-header-responsive" extra={extra} title={title} />
      <Content className={styles.contentContainer}>{ children }</Content>
    </Layout>
  )
}

PageLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  extra: PropTypes.array,
  title: PropTypes.string
}

export default PageLayout
