import React, { useEffect, useState } from 'react'
import { Button, Card, notification, Switch } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { fetchFlagById, resetUpdateFlagStatus, updateFlag } from '~actions'
import { FlagBasicInfo, ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout, Spinner } from '~stories'
import { validateFlag } from '~validators'
import { FLAG_APPS, FLAGS_COLOR_CODES, FLAGS_TYPES } from '../../constants'
import styles from './EditFlag.module.css'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const EditFlag = ({
  fetchFlag,
  resetUpdateFlagStatus,
  route,
  flag,
  updateMessage,
  updateFlag,
  updateStatus
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState()
  const [flagId, setFlagId] = useState('')
  const [flagToEdit, setFlagToEdit] = useState({})
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    const flagId = idx(route, _ => _.match.params.id)
    if (flagId) {
      setIsDataLoaded(false)
      fetchFlag(flagId)
      setFlagId(flagId)
    }
  }, [route, fetchFlag])

  useEffect(() => {
    if (flag) {
      setFlagToEdit(flag)
      setIsDataLoaded(true)
    }
  }, [flag])

  useEffect(() => {
    if (updateStatus && updateMessage) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
      resetUpdateFlagStatus()
    }
  }, [updateStatus, updateMessage, resetUpdateFlagStatus])

  const handleSubmit = () => {
    const { isValid, message } = validateFlag(flagToEdit)
    if (!isValid) {
      notification.error({ message })
      return
    }

    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    setIsDataLoaded(false)
    const updatedFlag = {
      ...flagToEdit
    }
    updateFlag(flagId, updatedFlag, accessToken)
  }

  const handleDescriptionChange = descriptionText => {
    let htmlDescription = flagToEdit.html_description
    htmlDescription = descriptionText || ''
    const updatedFlag = { ...flagToEdit, html_description: htmlDescription }
    setFlagToEdit(updatedFlag)
  }

  const handleTitleChange = event => {
    const updatedTitle = event.target.value
    const updatedFlag = { ...flagToEdit, title: updatedTitle }
    setFlagToEdit(updatedFlag)
  }

  const keepEditingFlag = (
    <Route key="editFlags" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateFlagStatus()
          history.push(`/flags/${flagId}/edit`)
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const goToListFlagsButton = (
    <Route key="listFlags" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateFlagStatus()
          history.push('/flags')
        }}
        type="primary"
      >
        Go to Flags List
      </Button>
    )} />
  )

  const handleModalClose = () => {
    resetUpdateFlagStatus()
  }

  const handleLogoUpload = (thumbnailImageUrl, secureImageURL) => {
    const imageUrl = secureImageURL || thumbnailImageUrl
    const clonedFlag = { ...flagToEdit }

    setFlagToEdit({
      ...clonedFlag,
      icon_url: imageUrl
    })
  }

  const handleColorChange = selectedItem => {
    const clonedFlag = { ...flagToEdit }
    const [color] = FLAGS_COLOR_CODES.filter(flag => flag.value === selectedItem)
    setFlagToEdit({
      ...clonedFlag,
      color
    })
  }

  const handleTypeChange = selectedItem => {
    const clonedFlag = { ...flagToEdit }
    const [type] = FLAGS_TYPES.filter(flag => flag.value === selectedItem)
    setFlagToEdit({
      ...clonedFlag,
      type: type.value
    })
  }

  const handleShowIconChange = event => {
    const clonedFlag = { ...flagToEdit }
    const status = event.target.checked
    setFlagToEdit({
      ...clonedFlag,
      show_icon: status
    })
  }

  const handleFlagStatus = status => {
    setFlagToEdit({
      ...flagToEdit,
      show_flag: status
    })
  }

  const handleAppNameChange = selectedItem => {
    const clonedFlag = { ...flagToEdit }
    const [appName] = FLAG_APPS.filter(flag => flag.value === selectedItem)
    setFlagToEdit({
      ...clonedFlag,
      app_name: appName.value
    })
  }

  const handlePriortyChange = sequence => {
    setFlagToEdit({
      ...flagToEdit,
      flag_priority: sequence
    })
  }

  const handleSubTypeChange = (selectedItem = '') => {
    setFlagToEdit({
      ...flagToEdit,
      sub_type: selectedItem
    })
  }

  const updateFlagButton = <Button className={styles.updateActionButton} onClick={handleSubmit} type='primary'>
    Update
  </Button>

  const toggleSwitch = <Switch checked={flagToEdit?.show_flag} checkedChildren="Show" defaultChecked onChange={handleFlagStatus} unCheckedChildren="Hide" />

  return (
    <PageLayout extra={[toggleSwitch, updateFlagButton]} title='Update Flag'>
      <Card>
        <FlagBasicInfo
          flag={flagToEdit}
          handleAppNameChange={handleAppNameChange}
          handleColorChange={handleColorChange}
          handleDescriptionChange={handleDescriptionChange}
          handleImageUrlUpdate={handleLogoUpload}
          handlePriortyChange={handlePriortyChange}
          handleShowIconChange={handleShowIconChange}
          handleSubTypeChange={handleSubTypeChange}
          handleTitleChange={handleTitleChange}
          handleTypeChange={handleTypeChange}
        />
      </Card>
      <Spinner isLoading={!isDataLoaded}/>
      {
        updateStatus &&
        <ResultModal
          actions={[
            goToListFlagsButton,
            keepEditingFlag
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

EditFlag.propTypes = {
  fetchFlag: PropTypes.func,
  flag: PropTypes.object,
  resetUpdateFlagStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  updateFlag: PropTypes.func,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.string
}

const mapStateToProps = ({ flags }) => ({
  flag: flags.flag,
  updateStatus: flags.success,
  updateMessage: flags.message
})

const mapDispatchToProps = {
  fetchFlag: fetchFlagById,
  resetUpdateFlagStatus,
  updateFlag
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFlag)
