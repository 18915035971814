/* eslint-disable camelcase */
import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Row, Col, Card, Collapse, Button, Modal, Typography } from 'antd'
import PropTypes from 'prop-types'

import { Input, MultiSelectDropdown, Label } from '~stories'
import { LINK_TARGETS } from '../../constants'
import styles from './PrimaryNavInfo.module.css'
import TertiaryNavInfo from './TertiaryNavInfo'

const { Text } = Typography
const { Panel } = Collapse

const SecondaryNavInfo = ({
  addNewSecondaryLink,
  addNewTertiaryLink,
  addNewTertiaryColumn,
  deleteNewTertiaryColumn,
  addNewTertiaryColumnList,
  handleSecondaryLinkLabelUpdate,
  handleSecondaryLinkUrlUpdate,
  handleThirdLinkColumnLabelUpdate,
  addNewTertiaryPromoItem,
  deleteNewTertiaryColumnList,
  handleThirdLinkColumnTargetUpdate,
  handleThirdLinkColumnUrlUpdate,
  handleThirdLinkDescriptionUpdate,
  handleThirdLinkImageUrlUpdate,
  handleThirdLinkNameUpdate,
  handleThirdLinkUrlUpdate,
  handlSecondaryLinkTargetUpdate,
  handleTertiaryPromoItemDelete,
  handleSecondaryNavDelete,
  handleTertiaryNavDelete,
  index,
  primaryNavLinkIndex,
  secondaryNavLinks
}) => {
  const [showSecondaryLinkDeleteModal, setSecondaryLinkDeleteModal] = useState(false)
  const [currentSecondaryLinkIndex, setCurrentSecondaryLinkIndex] = useState(-1)

  const handleDeleteSecondaryLinkModalCancel = () => {
    setCurrentSecondaryLinkIndex(-1)
    setSecondaryLinkDeleteModal(false)
  }

  const handleDeleteSecondaryLinkNavAction = () => {
    handleSecondaryNavDelete(index, primaryNavLinkIndex, currentSecondaryLinkIndex)
    setCurrentSecondaryLinkIndex(-1)
    setSecondaryLinkDeleteModal(false)
  }

  return (
    <><Card
      className={styles.secondary}
      extra={<Button
        icon={<PlusOutlined />}
        onClick={() => addNewSecondaryLink(index, primaryNavLinkIndex)}
        style={{ background: 'rgb(109, 195, 59)', border: '1px solid #f0f0f0' }} type='primary' />
      }
      headStyle={{ backgroundColor: '#6dc33b', color: '#fff' }}
      title='Secondary Links'>
      <Collapse>
        {
          secondaryNavLinks.map((secondaryNavLink, secondaryNavLinkIndex) => {
            return <Panel key={secondaryNavLinkIndex} extra={
              <div onClick={e => e.stopPropagation()}>
                <Button className={styles.deleteIcon}
                  onClick={event => {
                    setCurrentSecondaryLinkIndex(secondaryNavLinkIndex)
                    setSecondaryLinkDeleteModal(true)
                    event.stopPropagation()
                  }}
                  type='danger'>Delete Secondary Link</Button>
              </div>
            } header={`${secondaryNavLink?.label}`}>

              <Row gutter={16}>
                <Col span={18}>
                  <Input label='Label'
                    onChange={event => handleSecondaryLinkLabelUpdate(event.target.value, primaryNavLinkIndex, secondaryNavLinkIndex, index)}
                    value={secondaryNavLink.label}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={4}>
                  <Label value={'Link Target'} />
                  <MultiSelectDropdown
                    allowClear={true}
                    customStyle={{ width: 150 }}
                    displayField='label'
                    dropdownOptions={LINK_TARGETS}
                    handleChange={selectedItems => handlSecondaryLinkTargetUpdate(selectedItems, primaryNavLinkIndex, secondaryNavLinkIndex, index)}
                    selectedItems={secondaryNavLink.target}
                    valueField='value'
                  />
                </Col>
                <Col span={18}>
                  <Input label='Url'
                    onChange={event => handleSecondaryLinkUrlUpdate(event.target.value, primaryNavLinkIndex, secondaryNavLinkIndex, index)}
                    value={secondaryNavLink.url}
                  />
                </Col>
              </Row>
              <TertiaryNavInfo
                addNewTertiaryColumn={addNewTertiaryColumn}
                addNewTertiaryColumnList={addNewTertiaryColumnList}
                addNewTertiaryLink={addNewTertiaryLink}
                addNewTertiaryPromoItem={addNewTertiaryPromoItem}
                deleteNewTertiaryColumn={deleteNewTertiaryColumn}
                deleteNewTertiaryColumnList={deleteNewTertiaryColumnList}
                handleTertiaryNavDelete={handleTertiaryNavDelete}
                handleTertiaryPromoItemDelete={handleTertiaryPromoItemDelete}
                handleThirdLinkColumnLabelUpdate={handleThirdLinkColumnLabelUpdate}
                handleThirdLinkColumnTargetUpdate={handleThirdLinkColumnTargetUpdate}
                handleThirdLinkColumnUrlUpdate={handleThirdLinkColumnUrlUpdate}
                handleThirdLinkDescriptionUpdate={handleThirdLinkDescriptionUpdate}
                handleThirdLinkImageUrlUpdate={handleThirdLinkImageUrlUpdate}
                handleThirdLinkNameUpdate={handleThirdLinkNameUpdate}
                handleThirdLinkUrlUpdate={handleThirdLinkUrlUpdate}
                index={index}
                primaryNavLinkIndex={primaryNavLinkIndex}
                secondaryNavLink={secondaryNavLink}
                secondaryNavLinkIndex={secondaryNavLinkIndex}
              />
            </Panel>
          })
        }
      </Collapse>

    </Card>
    {
      showSecondaryLinkDeleteModal &&
    <Modal
      onCancel={handleDeleteSecondaryLinkModalCancel}
      onOk={handleDeleteSecondaryLinkNavAction}
      title='Delete confirmation'
      visible={showSecondaryLinkDeleteModal}
    >
      <Text>
        This Secondary link will be removed. Are you sure you want to delete this?
      </Text>
    </Modal>
    }</>
  )
}

SecondaryNavInfo.propTypes = {
  addNewSecondaryLink: PropTypes.func,
  addNewTertiaryColumn: PropTypes.func,
  addNewTertiaryColumnList: PropTypes.func,
  addNewTertiaryLink: PropTypes.func,
  addNewTertiaryPromoItem: PropTypes.func,
  allVenues: PropTypes.array,
  deleteNewTertiaryColumn: PropTypes.func,
  deleteNewTertiaryColumnList: PropTypes.func,
  handlSecondaryLinkTargetUpdate: PropTypes.func,
  handleSecondaryLinkLabelUpdate: PropTypes.func,
  handleSecondaryLinkUrlUpdate: PropTypes.func,
  handleSecondaryNavDelete: PropTypes.func,
  handleTertiaryNavDelete: PropTypes.func,
  handleTertiaryPromoItemDelete: PropTypes.func,
  handleThirdLinkColumnLabelUpdate: PropTypes.func,
  handleThirdLinkColumnTargetUpdate: PropTypes.func,
  handleThirdLinkColumnUrlUpdate: PropTypes.func,
  handleThirdLinkDescriptionUpdate: PropTypes.func,
  handleThirdLinkImageUrlUpdate: PropTypes.func,
  handleThirdLinkNameUpdate: PropTypes.func,
  handleThirdLinkUrlUpdate: PropTypes.func,
  index: PropTypes.number,
  primaryNavLinkIndex: PropTypes.number,
  secondaryNavLinks: PropTypes.array
}

SecondaryNavInfo.defaultProps = {
  secondaryNavLinks: []
}

export default SecondaryNavInfo
