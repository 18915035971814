import React, { useEffect, useState } from 'react'
import { Button, Card, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  RecommendationsSelector,
  SeriesBasicInfo,
  SeriesEventsFilter
} from '~components'

import { PageLayout } from '~stories'

const DEFAULT_SERIES_TYPE = 'series'

const EditSeriesForm = ({
  artists,
  availableEvents,
  categories,
  cities,
  eventsAssignedToSeries,
  eventsAvailableForSeries,
  filterAllEvents,
  series,
  seriesId,
  updateSeriesById,
  venues
}) => {
  const [isAutogenerated, setIsAutoGenerated] = useState(false)
  const [isFilteredDataLoaded, setIsFilteredDataLoaded] = useState(true)
  const [isFilteringCancelled, setIsFilteringCancelled] = useState(true)
  const [allEvents, setAllEvents] = useState([])
  const [selectedEvents, setSelectedEvents] = useState([])
  const [seriesName, setSeriesName] = useState('')
  const [seriesDescription, setSeriesDescription] = useState('')
  const [seriesType, setSeriesType] = useState(DEFAULT_SERIES_TYPE)

  useEffect(() => {
    if (series && series.id) {
      const {
        autogenerated,
        name,
        description,
        type
      } = series
      setSeriesName(name)
      setSeriesDescription(description)
      setSeriesType(type)
      setIsAutoGenerated(autogenerated)
    }
  }, [series])

  useEffect(() => {
    if (eventsAssignedToSeries && selectedEvents.length !== eventsAssignedToSeries.length) {
      setSelectedEvents(eventsAssignedToSeries)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsAssignedToSeries])

  useEffect(() => {
    if (eventsAvailableForSeries && allEvents.length !== eventsAvailableForSeries.length) {
      setAllEvents(eventsAvailableForSeries)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsAvailableForSeries])

  useEffect(() => {
    if (availableEvents) {
      setAllEvents(availableEvents)
      setIsFilteredDataLoaded(true)
      setIsFilteringCancelled(true)
    }
  }, [availableEvents])

  const getUnassignedEvents = () => {
    let unassignedEvents = []

    eventsAssignedToSeries.forEach(event => {
      if (selectedEvents.findIndex(selectedEvent => selectedEvent.id === event.id) === -1) {
        unassignedEvents = [...unassignedEvents, event]
      }
    })

    return unassignedEvents
  }

  const handleSubmit = () => {
    updateSeriesById({
      id: seriesId,
      name: seriesName,
      description: seriesDescription,
      type: seriesType,
      assignedEvents: selectedEvents,
      removedEvents: getUnassignedEvents()
    })
  }

  const filterEvents = filters => {
    const eventsRecommended = [...selectedEvents]
    const assignedEvents = [...selectedEvents].map(event => event._id || event.id)
    const startDate = filters.startDate && moment(filters.startDate).format('YYYY-MM-DD')
    const endDate = filters.endDate && moment(filters.endDate).format('YYYY-MM-DD')
    const eventFilter = JSON.parse(JSON.stringify({
      ...filters,
      startDate,
      endDate,
      ...(eventsRecommended && eventsRecommended.length > 0 && { assignedEvents })
    }))

    filterAllEvents(eventFilter)
  }

  const handleFilter = filters => {
    setIsFilteredDataLoaded(false)
    filterEvents(filters)
  }

  const resetFilter = filters => {
    setIsFilteringCancelled(false)
    filterEvents(filters)
  }

  const handleSubmitButton = <Button onClick={handleSubmit} type='primary'>
    Update
  </Button>

  const [assignedEvent] = eventsAssignedToSeries
  const eventEditLink = assignedEvent
    ? <>
      <a href={`/events/${assignedEvent.tmEventId}/edit`} rel='noreferrer' target='_blank'>
        {assignedEvent.title}
      </a> ({assignedEvent.tmEventId})
    </>
    : null

  return (
    <PageLayout extra={[handleSubmitButton]} title="Edit Series">
      <SeriesBasicInfo
        seriesDescription={seriesDescription}
        seriesId={series.id}
        seriesName={seriesName}
        seriesType={seriesType}
        setSeriesDescription={setSeriesDescription}
        setSeriesName={setSeriesName}
        setSeriesType={setSeriesType}
      />
      {
        isAutogenerated
          ? <Card title="Event">
            <Col span={24}>
              {eventEditLink}
            </Col>
          </Card>
          : <Col span={24}>
            <Card>
              <SeriesEventsFilter
                artists={artists}
                categories={categories}
                cities={cities}
                handleFilter={handleFilter}
                isFilteredDataLoaded={isFilteredDataLoaded}
                isFilteringCancelled={isFilteringCancelled}
                resetFilter={resetFilter}
                venues={venues}
              />
              <RecommendationsSelector
                allEvents={allEvents}
                disabled={true}
                displayField="title"
                promotedEvents={selectedEvents}
                resourceName='Events'
                setAllEvents={setAllEvents}
                setPromotedEvents={setSelectedEvents}
              />
            </Card>
          </Col>
      }
    </PageLayout>
  )
}

EditSeriesForm.propTypes = {
  artists: PropTypes.array,
  availableEvents: PropTypes.array,
  categories: PropTypes.array,
  cities: PropTypes.array,
  eventsAssignedToSeries: PropTypes.array,
  eventsAvailableForSeries: PropTypes.array,
  filterAllEvents: PropTypes.func.isRequired,
  series: PropTypes.object,
  seriesId: PropTypes.string,
  updateSeriesById: PropTypes.func,
  venues: PropTypes.array
}

EditSeriesForm.defaultProps = {
  artists: [],
  categories: [],
  cities: [],
  eventsAssignedToSeries: [],
  eventsAvailableForSeries: [],
  series: {},
  venues: []
}

export default EditSeriesForm
