/* eslint-disable camelcase */
import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Row, Col, Card, Collapse, Button, Modal, Typography } from 'antd'
import PropTypes from 'prop-types'

import { LogoUploader } from '~components'
import { Input, MultiSelectDropdown, Label } from '~stories'

import { LINK_TARGETS } from '../../constants'
import styles from './PrimaryNavInfo.module.css'
import SecondaryNavInfo from './SecondaryNavInfo'

const { Text } = Typography
const { Panel } = Collapse

const PrimaryNavInfo = ({
  addNewPrimaryLink,
  addNewTertiaryLink,
  addNewSecondaryLink,
  addNewTertiaryColumn,
  deleteNewTertiaryColumn,
  allVenues,
  handlePrimaryLinkLabelUpdate,
  addNewTertiaryPromoItem,
  handlePrimaryLinkTargetUpdate,
  handlePrimaryLinkUrlUpdate,
  handlePrimaryLogoUrlUpdate,
  handlePrimaryUrlUpdate,
  handlePrimaryNavVenueUpdate,
  handleSecondaryLinkLabelUpdate,
  handleSecondaryLinkUrlUpdate,
  deleteNewTertiaryColumnList,
  handleThirdLinkDescriptionUpdate,
  handleThirdLinkImageUrlUpdate,
  handleThirdLinkNameUpdate,
  handleThirdLinkUrlUpdate,
  handlSecondaryLinkTargetUpdate,
  handleTertiaryPromoItemDelete,
  handleThirdLinkColumnLabelUpdate,
  handleThirdLinkColumnTargetUpdate,
  handleThirdLinkColumnUrlUpdate,
  handlePrimaryNavDelete,
  handleSecondaryNavDelete,
  addNewTertiaryColumnList,
  handleTertiaryNavDelete,
  index,
  primaryNav
}) => {
  const [showPrimaryLinkDeleteModal, setPrimaryLinkDeleteModal] = useState(false)
  const [currentPrimaryLinkIndex, setCurrentPrimaryLinkIndex] = useState(-1)

  const handleDeletePrimaryLinkModalCancel = () => {
    setCurrentPrimaryLinkIndex(-1)
    setPrimaryLinkDeleteModal(false)
  }

  const handleDeletePrimaryLinkNavAction = () => {
    setPrimaryLinkDeleteModal(false)
    handlePrimaryNavDelete(index, currentPrimaryLinkIndex)
    setCurrentPrimaryLinkIndex(-1)
  }

  return (<>
    <Card style={{ border: '1px solid rgb(42, 45, 59)' }}>
      <Row gutter={16}>
        <Col span={18}>
          <Label value={'Venue Name'} />
          <MultiSelectDropdown
            allowClear={true}
            customStyle={{ width: 450 }}
            displayField='label'
            dropdownOptions={allVenues}
            handleChange={selectedItems => handlePrimaryNavVenueUpdate(selectedItems, index)}
            selectedItems={primaryNav.venue_id}
            valueField='value'

          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={18}>
          <Label value='Logo Url' />
          <div>
            <LogoUploader handleUpload={(thumbnailImageUrl, secureImageURL) => {
              const imageUrl = secureImageURL || thumbnailImageUrl
              handlePrimaryLogoUrlUpdate(imageUrl, index)
            }} logoUrl={primaryNav.logo_url} />
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={18}>
          <Input label='Url'
            onChange={event => handlePrimaryUrlUpdate(event.target.value, index)}
            value={primaryNav.url}
          />
        </Col>
      </Row>
      <Card
        className={styles.primary}
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              addNewPrimaryLink(index)
            }
            style={{ background: 'rgb(54, 66, 177)', border: '1px solid #f0f0f0' }}
            type='primary' />
        }
        headStyle={{ backgroundColor: '#3642b1', color: '#fff' }}
        title='Primary Links'
      >
        <Collapse>
          {
            primaryNav.primary_nav_links.map((primaryNavLink, primaryNavLinkIndex) => {
              return <Panel key={primaryNavLinkIndex} extra={
                <div onClick={e => e.stopPropagation()}>
                  <Button className={styles.deleteIcon}
                    onClick={event => {
                      setCurrentPrimaryLinkIndex(primaryNavLinkIndex)
                      setPrimaryLinkDeleteModal(true)
                      event.stopPropagation()
                    }}
                    type='danger'>Delete Primary Link</Button>
                </div>
              } header={`${primaryNavLink?.label}`}>

                <Row gutter={16}>
                  <Col span={18}>
                    <Input label='Label'
                      onChange={event => handlePrimaryLinkLabelUpdate(event.target.value, primaryNavLinkIndex, index)}
                      value={primaryNavLink.label}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={4}>
                    <Label value={'Link Target'} />
                    <MultiSelectDropdown
                      allowClear={true}
                      customStyle={{ width: 150 }}
                      displayField='label'
                      dropdownOptions={LINK_TARGETS}
                      handleChange={selectedItems => handlePrimaryLinkTargetUpdate(selectedItems, primaryNavLinkIndex, index)}
                      selectedItems={primaryNavLink.target}
                      valueField='value'
                    />
                  </Col>
                  <Col span={18}>
                    <Input label='Url'
                      onChange={event => handlePrimaryLinkUrlUpdate(event.target.value, primaryNavLinkIndex, index)}
                      value={primaryNavLink.url}
                    />
                  </Col>
                </Row>
                <SecondaryNavInfo
                  addNewSecondaryLink={addNewSecondaryLink}
                  addNewTertiaryColumn={addNewTertiaryColumn}
                  addNewTertiaryColumnList={addNewTertiaryColumnList}
                  addNewTertiaryLink={addNewTertiaryLink}
                  addNewTertiaryPromoItem={addNewTertiaryPromoItem}
                  deleteNewTertiaryColumn={deleteNewTertiaryColumn}
                  deleteNewTertiaryColumnList={deleteNewTertiaryColumnList}
                  handlSecondaryLinkTargetUpdate={handlSecondaryLinkTargetUpdate}
                  handleSecondaryLinkLabelUpdate={handleSecondaryLinkLabelUpdate}
                  handleSecondaryLinkUrlUpdate={handleSecondaryLinkUrlUpdate}
                  handleSecondaryNavDelete={handleSecondaryNavDelete}
                  handleTertiaryNavDelete={handleTertiaryNavDelete}
                  handleTertiaryPromoItemDelete={handleTertiaryPromoItemDelete}
                  handleThirdLinkColumnLabelUpdate={handleThirdLinkColumnLabelUpdate}
                  handleThirdLinkColumnTargetUpdate={handleThirdLinkColumnTargetUpdate}
                  handleThirdLinkColumnUrlUpdate={handleThirdLinkColumnUrlUpdate}
                  handleThirdLinkDescriptionUpdate={handleThirdLinkDescriptionUpdate}
                  handleThirdLinkImageUrlUpdate={handleThirdLinkImageUrlUpdate}
                  handleThirdLinkNameUpdate={handleThirdLinkNameUpdate}
                  handleThirdLinkUrlUpdate={handleThirdLinkUrlUpdate}
                  index={index}
                  primaryNavLinkIndex={primaryNavLinkIndex}
                  secondaryNavLinks={primaryNavLink.secondary_nav_links}
                />
              </Panel>
            })
          }
        </Collapse>
      </Card>
    </Card>
    {
      showPrimaryLinkDeleteModal &&
          <Modal
            onCancel={handleDeletePrimaryLinkModalCancel}
            onOk={handleDeletePrimaryLinkNavAction}
            title='Delete confirmation'
            visible={showPrimaryLinkDeleteModal}
          >
            <Text>
              This Primary link will be removed. Are you sure you want to delete this?
            </Text>
          </Modal>
    }
  </>
  )
}

PrimaryNavInfo.propTypes = {
  addNewPrimaryLink: PropTypes.func,
  addNewSecondaryLink: PropTypes.func,
  addNewTertiaryColumn: PropTypes.func,
  addNewTertiaryColumnList: PropTypes.func,
  addNewTertiaryLink: PropTypes.func,
  addNewTertiaryPromoItem: PropTypes.func,
  allVenues: PropTypes.array,
  deleteNewTertiaryColumn: PropTypes.func,
  deleteNewTertiaryColumnList: PropTypes.func,
  handlSecondaryLinkTargetUpdate: PropTypes.func,
  handlePrimaryLinkLabelUpdate: PropTypes.func,
  handlePrimaryLinkTargetUpdate: PropTypes.func,
  handlePrimaryLinkUrlUpdate: PropTypes.func,
  handlePrimaryLogoUrlUpdate: PropTypes.func,
  handlePrimaryNavDelete: PropTypes.func,
  handlePrimaryNavVenueUpdate: PropTypes.func,
  handlePrimaryUrlUpdate: PropTypes.func,
  handleSecondaryLinkLabelUpdate: PropTypes.func,
  handleSecondaryLinkUrlUpdate: PropTypes.func,
  handleSecondaryNavDelete: PropTypes.func,
  handleTertiaryNavDelete: PropTypes.func,
  handleTertiaryPromoItemDelete: PropTypes.func,
  handleThirdLinkColumnLabelUpdate: PropTypes.func,
  handleThirdLinkColumnTargetUpdate: PropTypes.func,
  handleThirdLinkColumnUrlUpdate: PropTypes.func,
  handleThirdLinkDescriptionUpdate: PropTypes.func,
  handleThirdLinkImageUrlUpdate: PropTypes.func,
  handleThirdLinkNameUpdate: PropTypes.func,
  handleThirdLinkUrlUpdate: PropTypes.func,
  index: PropTypes.number,
  primaryNav: PropTypes.object
}

PrimaryNavInfo.defaultProps = {
  allVenues: []
}

export default PrimaryNavInfo
