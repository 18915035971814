import React from 'react'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { Input, TextArea, MultiSelectDropdown } from '~stories'
import styles from './SeriesBasicInfo.module.css'

const SERIES_TYPES = [
  { id: 'Series', name: 'Series' },
  { id: 'Sports Series', name: 'Sports Series' },
  { id: 'National Tours', name: 'National Tours' },
  { id: 'Venue Tours', name: 'Venue Tours' }
]

const SeriesBasicInfo = ({
  seriesDescription,
  seriesId,
  seriesName,
  seriesType,
  setSeriesDescription,
  setSeriesName,
  setSeriesType
}) => {
  const handleSeriesNameChange = event => setSeriesName(event.target.value)
  const handleSeriesDescriptionChange = event => setSeriesDescription(event.target.value)
  return (
    <div className={styles['edit-series-layout-background']}>
      <Row gutter={24}>
        <Col span={24}>
          <Input
            disabled
            label="ID"
            onChange={() => {}}
            value={seriesId}
          />
        </Col>
        <Col span={24}>
          <Input
            label="Name"
            onChange={handleSeriesNameChange}
            value={seriesName}
          />
        </Col>
        <Col span={24}>
          <TextArea
            label="DESCRIPTION"
            onChange={handleSeriesDescriptionChange}
            value={seriesDescription}
          />
        </Col>
        <Col span={24}>
          <MultiSelectDropdown
            customStyle={{ width: 200 }}
            displayField="name"
            dropdownOptions={SERIES_TYPES}
            handleChange={setSeriesType}
            label='Series Type:'
            selectedItems={seriesType}
            valueField="id"
          />
        </Col>
      </Row>
    </div>
  )
}

SeriesBasicInfo.propTypes = {
  seriesDescription: PropTypes.string,
  seriesId: PropTypes.string,
  seriesName: PropTypes.string,
  seriesType: PropTypes.string,
  setSeriesDescription: PropTypes.func,
  setSeriesName: PropTypes.func,
  setSeriesType: PropTypes.func
}

SeriesBasicInfo.defaultProps = {
  seriesType: 'Series'
}

export default SeriesBasicInfo
