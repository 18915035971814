import React, { useEffect, useState } from 'react'
import { PlusOutlined, DeleteOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Card, Button, Row, Input, notification, Space, Col } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { v4 as UUID } from 'uuid'

import { addOrganisation, resetOrganisationActionStatus } from '~actions'
import { ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout, Spinner } from '~stories'
import { validateOrganisationApps } from '~validators'

import styles from './AddOrganisation.module.css'

const PAGE_TITLE = 'Add Organisation'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.',
  NAME_REQUIRED: 'Organisation name can\'t be blank'
}

const AddOrganisation = ({ addOrganisation, resetOrganisationActionStatus, newOrganisationId, updateMessage, updateStatus }) => {
  const [applications, setApplications] = useState([{
    name: '',
    token: UUID().replace(/-/g, '')
  }])
  const [name, setName] = useState('')
  const [isDataLoaded, setIsDataLoaded] = useState(true)
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    if (updateStatus) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
    }
  }, [updateMessage, updateStatus])

  const addApplication = () => {
    const clonedApplications = [...applications]
    const defaultApplication = {
      name: '',
      token: UUID().replace(/-/g, '')
    }
    clonedApplications.push({ ...defaultApplication })
    setApplications(clonedApplications)
  }

  const removeApplication = index => {
    const clonedApplications = [...applications]
    clonedApplications.splice(index, 1)
    setApplications(clonedApplications)
  }

  const updateApplicationName = (event, index) => {
    const clonedApplications = [...applications]
    const application = clonedApplications[index]
    application.name = event.target.value
    setApplications(clonedApplications)
  }

  const handleSubmit = () => {
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    if (name.length === 0) {
      return notification.error({ message: NOTIFICATION_MESSAGES.NAME_REQUIRED })
    }

    const { message } = validateOrganisationApps(applications)
    if (message) {
      notification.error({ message })
      return
    }

    setIsDataLoaded(false)
    addOrganisation({
      name,
      applications
    }, accessToken)
  }

  const goToListOrganisationsButton = (
    <Route key="listOrganisations" render={({ history }) => (
      <Button
        onClick={() => {
          resetOrganisationActionStatus()
          history.push('/organizations')
        }}
        type="primary"
      >
        Go to Organisations List
      </Button>
    )} />
  )

  const addOrganisationButton = (
    <Route render={({ history }) => (
      <Button
        onClick={() => {
          resetOrganisationActionStatus()
          window.location.reload()
        }}
        type="primary"
      >
        Add More
      </Button>
    )} />
  )

  const editOrganisation = (
    <Route key="editOrganisation" render={({ history }) => (

      <Button
        onClick={() => {
          newOrganisationId && history.push(`/organizations/${newOrganisationId}/edit`)
          window.location.reload()
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const onModalClose = () => {
    history.push('/organizations')
  }

  const submitButton = <Button onClick={handleSubmit} type='primary'>Add</Button>
  const extraButton = applications.length === 0 ? [<Button key='button' className={styles.addButton} onClick={addApplication}>Add Application</Button>] : []
  return (
    <PageLayout extra={[submitButton]} title={PAGE_TITLE}>
      <div className={styles.orgLayoutBackground}>
        <Input label='Name' onChange={event => setName(event.target.value)} placeholder='Name' value={name} />
        <Col span={24}>
          <Space className={styles.space} direction='vertical' >
            <Card className={styles.card} extra={extraButton} title='Applications'>
              {
                applications && applications.map((application, index) => <Row key={index} className={styles.applicationsContainer}>
                  <Space size="middle" >
                    <Input onChange={event => updateApplicationName(event, index)} placeholder='Application Name' value={application.name} />
                    <Input.Password
                      className={styles.password}
                      iconRender={visible => (
                        visible
                          ? <EyeTwoTone />
                          : <EyeInvisibleOutlined />
                      )}
                      placeholder='Token'
                      readOnly
                      value={application.token}
                    />
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => addApplication()}
                      shape="circle"
                      type="primary"
                    />
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => removeApplication(index)}
                      shape="circle"
                      type="danger"
                    />
                  </Space>
                </Row>)
              }
            </Card>
          </Space>
        </Col>
      </div>
      {
        isDataLoaded && updateStatus &&
        <ResultModal
          actions={[
            editOrganisation,
            goToListOrganisationsButton,
            addOrganisationButton
          ]}
          handleCancel={onModalClose}
          status="success"
          title={updateMessage}
        />
      }
      { !isDataLoaded && <Spinner isLoading={!isDataLoaded}/> }
    </PageLayout>
  )
}

const mapStateToProps = ({ organizations }) => ({
  newOrganisationId: organizations.newOrganisationId,
  updateMessage: organizations.message,
  updateStatus: organizations.success
})

const mapDispatchToProps = {
  addOrganisation,
  resetOrganisationActionStatus
}

AddOrganisation.propTypes = {
  addOrganisation: PropTypes.func,
  newOrganisationId: PropTypes.string,
  organization: PropTypes.object,
  resetOrganisationActionStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool
}

AddOrganisation.defaultProps = {
  match: {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOrganisation)
