import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { triggerAdhocTMImport } from '~actions'
import { ItemList } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout } from '~stories'

import styles from './Events.module.css'

const EVENT_START_TIME_TBA = 'TBA'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const dropdownOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'Postponed', value: 'Postponed' }
]

const getEventNameWithLink = event => <a href={`/events/${event.id}/edit`}>{String(event.name).toUpperCase()}</a>

const columnData = [
  {
    sorter: true,
    title: 'Name',
    dataIndex: 'name',
    render: (text, row) => getEventNameWithLink(row),
    width: '25%',
    sortDirections: ['descend', 'ascend', 'descend']
  },
  {
    dataIndex: 'publish',
    title: 'Pub.',
    width: '10%',
    sorter: true
  },
  {
    render: (text, event) => {
      let eventDateString = event.event_date
      const timeString = (event.start_time !== EVENT_START_TIME_TBA)
        ? moment(event.start_time, 'HH:mm:ss').format('hh:mm A')
        : ''
      if (timeString !== '') {
        eventDateString += ' at ' + timeString
      }
      return eventDateString
    },
    title: 'Event Date',
    dataIndex: 'event_date',
    sorter: true,
    width: '22%',
    sortDirections: ['descend', 'ascend', 'descend']
  },
  {
    dataIndex: 'venue_name',
    title: 'Venue Name',
    width: '20%'
  },
  {
    dataIndex: 'flags',
    title: 'MSG Flags',
    width: '20%'
  },
  {
    dataIndex: 'rox_flags',
    title: 'Rox Flags',
    width: '10%'
  },
  {
    dataIndex: 'rox_group_flags',
    title: 'Rox Group Flags',
    width: '10%'
  },
  {
    dataIndex: 'category',
    title: 'Categories',
    width: '20%'
  }
]

const EventsList = ({
  triggerAdhocTMImport,
  message,
  status,
  allVenues
}) => {
  const [tmImportInitiated, setTMImportInitiated] = useState(false)
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  const handleAdhocTMImport = () => {
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }
    setTMImportInitiated(true)
    triggerAdhocTMImport(accessToken)
  }

  const displayNotification = (status, message) => {
    if (status) {
      notification.success({ message })
    } else {
      notification.error({ message })
    }
    setTMImportInitiated(false)
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  }

  useEffect(() => {
    if (status !== undefined) {
      displayNotification(status, message)
    }
  }, [status, message])

  const buttonInputProps = {
    loading: tmImportInitiated,
    ...(isContentEditor && { onClick: handleAdhocTMImport }),
    type: 'primary'
  }
  const secondaryFilter = {
    list: allVenues,
    label: 'Venues: '
  }
  const importButton = isContentEditor && <Button {...buttonInputProps}>Import from Ticket Master</Button>
  return (
    <PageLayout extra={[importButton]} title="Events">
      <div className={styles.eventsLayoutBackground}>
        <ItemList
          columns={columnData}
          filterKey='status'
          filterOptions={dropdownOptions}
          filterValue="Active"
          resource='events'
          secondaryFilter={secondaryFilter}
        />
      </div>
    </PageLayout>
  )
}

EventsList.propTypes = {
  allVenues: PropTypes.array,
  message: PropTypes.string,
  status: PropTypes.bool,
  triggerAdhocTMImport: PropTypes.func.isRequired
}

const mapStateToProps = ({ admin, dashboard }) => ({
  message: admin.message,
  status: admin.success,
  allVenues: dashboard.allVenues
})

const mapDispatchToProps = {
  triggerAdhocTMImport
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EventsList)
)
