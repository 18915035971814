import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { signIn } from '../../store/auth/actions'

const Login = ({
  userSignedIn,
  signInAction
}) => {
  return (
    <div />
  )
}

Login.propTypes = {
  signInAction: PropTypes.func,
  userSignedIn: PropTypes.bool
}

const mapStateToProps = ({
  authData
}) => {
  return {
    ...authData
  }
}

const mapDispatchToProps = {
  signInAction: signIn
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
