import React from 'react'

import { ConnectedRouter } from 'connected-react-router'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader' /* react-hot-loader v3 */
import { Provider } from 'react-redux'

import { history } from '~history'
import configureStore from '~store'

import App from './app'

import * as serviceWorker from './serviceWorker'

import './font.css'

const target = document.querySelector('#root')
const store = configureStore()

if (target) {
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1]

  if (path) {
    history.replace(path)
  }

  render(
    <AppContainer>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App history={history} />
        </ConnectedRouter>
      </Provider>
    </AppContainer>,
    target
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept(App, () => {
    render()
  })
}
