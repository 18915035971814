import React, { useEffect, useState } from 'react'
import { Collapse, Button, Space } from 'antd'
import PropTypes from 'prop-types'
import { Input } from '~stories'
import Block from './Block'

import styles from './Contents.module.css'

const { Panel } = Collapse

const Content = React.memo(({
  activeBlockKey,
  activeKey,
  addNewBlock,
  content,
  contentIndex,
  deleteBlock,
  deleteContent,
  formError,
  handleActiveBlockPanel,
  handleActivePanel,
  handleNameChange,
  handleDescriptionChange,
  handleTitleChange
}) => {
  const [name, setName] = useState('')

  useEffect(() => {
    if (!content) return

    const {
      name
    } = content

    setName(name)
  }, [content])

  return (
    <Collapse
      accordion
      activeKey={activeKey}
      className={styles.collapsePanel}
      defaultActiveKey={[activeKey]}
      expandIconPosition="right"
      onChange={handleActivePanel}>
      <Panel key={contentIndex} extra={
        <div onClick={e => e.stopPropagation()}>
          <Button
            onClick={() => deleteContent(contentIndex)} type='danger'>Delete Content</Button></div>
      } header={content.name}>
        <Input label='Name'
          onBlur={(event) => handleNameChange(event.target.value, contentIndex)}
          onChange={event => setName(event.target.value)}
          {...(formError?.fields?.name ? { className: styles.hasError } : {})}
          placeholder='How to Enter' value={name} />
        <div
          className={styles.textAlignRight}>
          <Space>
            <Button onClick={() => addNewBlock(contentIndex)}>
                  Add Block
            </Button>
          </Space>
        </div>
        {
          content && content.blocks && content.blocks.map((block, blockIndex) => <Block
            key={`${contentIndex}_${blockIndex}`}
            activeBlockKey={activeBlockKey}
            block={block}
            blockIndex={blockIndex}
            contentIndex={contentIndex}
            deleteBlock={deleteBlock}
            formError={formError?.fields?.[blockIndex]}
            handleActiveBlockPanel={handleActiveBlockPanel}
            handleDescriptionChange={handleDescriptionChange}
            handleTitleChange={handleTitleChange}
          />)
        }
      </Panel>
    </Collapse>
  )
})

Content.propTypes = {
  activeBlockKey: PropTypes.string,
  activeKey: PropTypes.string,
  addNewBlock: PropTypes.func,
  content: PropTypes.object,
  contentBlocksLength: PropTypes.number,
  contentIndex: PropTypes.number,
  deleteBlock: PropTypes.func,
  deleteContent: PropTypes.func,
  formError: PropTypes.object,
  handleActiveBlockPanel: PropTypes.func,
  handleActivePanel: PropTypes.func,
  handleDescriptionChange: PropTypes.func,
  handleNameChange: PropTypes.func,
  handleTitleChange: PropTypes.func
}

Content.displayName = 'Content'

export default Content
