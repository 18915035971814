import { keysToCamel } from '~utils'
import {
  FETCH_EVENT_BY_ID,
  FETCH_EVENTS,
  FILTER_EVENTS,
  REMOVE_EVENT_BY_ID,
  UPDATE_EVENT_BY_ID,
  RESET_ACTION_STATUS
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENTS:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_EVENT_BY_ID:
      return {
        ...state,
        ...keysToCamel(action.payload)
      }
    case REMOVE_EVENT_BY_ID:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_EVENT_BY_ID:
      return {
        ...state,
        ...action.payload
      }
    case FILTER_EVENTS:
      return {
        ...state,
        ...action.payload
      }
    case RESET_ACTION_STATUS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
