import React from 'react'
import { Row, TimePicker } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import 'moment-duration-format'

import { Label } from '~stories'

import styles from './VenueDoorOpenSettings.module.css'

const FORMATTER = {
  DATE: 'MM/DD/YYYY',
  TIME: 'HH:mm'
}

const VenueDoorOpenSettings = ({ doorOpenTimeConfig, onChange }) => {
  const settings = Object.keys(doorOpenTimeConfig)

  return (
    settings && settings.map((data, index) => {
      const time = moment.duration(doorOpenTimeConfig[data].doorOpenTimeDelta, 'minutes').format(FORMATTER.TIME)
      return <Row key={index} className={styles.row}>
        <Label
          className={styles.label}
          isInline={true}
          value={`${doorOpenTimeConfig[data].name}`}
        />
        <TimePicker
          allowClear={true}
          className={styles.input}
          format={FORMATTER.TIME}
          onChange={selectedDate => onChange(selectedDate, data)}
          showNow={false}
          value={moment(time, FORMATTER.TIME)}
        />
        <Label className={styles.helperText} value={` (${doorOpenTimeConfig[data].doorOpenTimeDelta} minutes)`} />
      </Row>
    })
  )
}

VenueDoorOpenSettings.propTypes = {
  doorOpenTimeConfig: PropTypes.object,
  onChange: PropTypes.func
}

VenueDoorOpenSettings.default = {
  doorOpenTimeConfig: {}
}

export default VenueDoorOpenSettings
