import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Row, Spin } from 'antd'
import PropTypes from 'prop-types'

import styles from './Spinner.module.css'

const Spinner = ({
  isLoading,
  label,
  size
}) => {
  const antIndicatorIcon = <LoadingOutlined className={styles.spinner} spin />

  return (
    <Row justify="center">
      <div className={styles.spinnerContainer}>
        <Spin
          indicator={antIndicatorIcon}
          size={size}
          spinning={isLoading}
          tip={label}
        />
      </div>
    </Row>
  )
}

Spinner.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large', 'default'])
}

Spinner.defaultProps = {
  isLoading: false,
  label: '',
  size: 'large'
}

export default Spinner
