import 'isomorphic-fetch'

import { promocodesEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import {
  ADD_PROMO_CODE,
  FETCH_PROMO_CODE_TO_COPY,
  FETCH_PROMO_CODES,
  PROMO_CODES_UPDATE_STATUS,
  STORE_PROMO_CODE_TO_EDIT
} from '../actionTypes'

const ERROR_TITLE = 'Error, expected title to be unique.'
const AUTH_ERROR = 'You are not authorized to perform this action.'

export const fetchPromoCodesList = params => async dispatch => {
  const { method, url } = promocodesEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { promoCodes: rows, promoCodesCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_PROMO_CODES,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchPromoCodeById = promoCodeId => async dispatch => {
  const { method, url } = promocodesEndpoint.edit(promoCodeId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const {
    assignedEventsToMsgPromo,
    assignedEventsToRockettesPromo,
    promoCode,
    priceTemplates = {},
    series
  } = apiResponse
  dispatch({
    type: STORE_PROMO_CODE_TO_EDIT,
    payload: {
      assignedEventsToMsgPromo,
      assignedEventsToRockettesPromo,
      promoCode,
      priceTemplates,
      series
    }
  })
}

export const updatePromoCode = (params, accessToken) => async dispatch => {
  const { promoCodeId } = params
  const { method, url } = promocodesEndpoint.update(promoCodeId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`,
      accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      status: false
    }))

  const { message, success } = apiResponse
  dispatch({
    type: PROMO_CODES_UPDATE_STATUS,
    payload: {
      message,
      status: success
    }
  })
}

export const addPromoCode = (promoCode, accessToken) => async dispatch => {
  const { method, url } = promocodesEndpoint.add
  const idToken = getIdToken()
  await fetch(url, {
    body: JSON.stringify({ promoCode }),
    headers: {
      Authorization: `Bearer ${idToken}`,
      accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .then(response => {
      const { addedPromoCode: { _id: promoCodeObjectId }, message, success } = response
      dispatch({
        type: ADD_PROMO_CODE,
        payload: {
          error: false,
          message,
          promoCodeObjectId,
          success
        }
      })
    })
    .catch((error) => {
      const message = error.message === AUTH_ERROR ? error.message : ERROR_TITLE
      dispatch({
        type: ADD_PROMO_CODE,
        payload: {
          error: true,
          message,
          success: false
        }
      })
    })
}

export const copyPromocodeById = promocodeId => async dispatch => {
  const { method, url } = promocodesEndpoint.copy(promocodeId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))

  dispatch({
    type: FETCH_PROMO_CODE_TO_COPY,
    payload: {
      ...apiResponse
    }
  })
}

export const resetPromoCodeActionStatus = () => async dispatch => {
  dispatch({
    type: PROMO_CODES_UPDATE_STATUS,
    payload: {
      message: '',
      status: false
    }
  })
}
