import React from 'react'
import { Carousel } from 'antd'
import PropTypes from 'prop-types'

import './Carousel.css'

const CustomCarousel = ({
  childrens
}) => {
  return (
    <Carousel dotPosition='right'>
      { childrens }
    </Carousel>
  )
}

CustomCarousel.propTypes = {
  childrens: PropTypes.node
}

export default CustomCarousel
