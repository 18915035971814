import React from 'react'
import { List } from 'antd'
import PropTypes from 'prop-types'

import { sliceArrayIntoGroups } from '~utils'

import './Carousel.css'
import styles from './EventsSales.module.css'

import SalesSection from './SalesSection'

const EventSales = ({ listItems }) => {
  const eventSections = [...listItems]
  const eventsList = sliceArrayIntoGroups(eventSections, 5)
  const eventsListComponent = eventsList.map((events, index) => {
    return <List
      key={index}
      className={styles.list}
      dataSource={events}
      itemLayout="horizontal"
      renderItem={({ availabe_percentage: availablePercentage, id, name, sold_out_percentage: soldPercentage, start_date: startDate, start_time: startTime, thumbnail_url: imageUrl, venue_name: venue }) => (
        <List.Item className={styles.listItem}>
          <SalesSection
            availablePercentage={availablePercentage}
            eventName={name}
            eventStartDate={startDate}
            eventStartTime={startTime}
            eventVenue={venue}
            id={id}
            imageUrl={imageUrl}
            soldPercentage={soldPercentage}
          />
        </List.Item>
      )}
    />
  })

  return eventsListComponent
}

EventSales.propTypes = {
  listItems: PropTypes.array.isRequired
}

EventSales.defaultProps = {
  listItems: []
}

export default EventSales
