import React from 'react'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { VenueAddress } from '~components'
import { Input } from '~stories'

import styles from './VenueBasicInfo.module.css'

const ADD_ACTION = 'Add'

const FirstRow = ({
  handleNameChange,
  handleUrlChange,
  id: venueId,
  name,
  type,
  url
}) => {
  const isAddAction = type === ADD_ACTION

  return (
    <>
      <Col span={6}>
        <Input
          disabled
          label={'ID'}
          onChange={() => {}}
          value={venueId}
        />
      </Col>
      <Col span={6}>
        <Input
          disabled={!isAddAction}
          label="NAME"
          onChange={handleNameChange}
          value={name}
        />
      </Col>
      <Col span={12}>
        <Input
          disabled={!isAddAction}
          label="URL"
          onChange={handleUrlChange}
          value={url}
        />
      </Col>
    </>
  )
}

FirstRow.propTypes = {
  handleNameChange: PropTypes.func,
  handleUrlChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string
}

const FourthRow = ({
  availabilityThreshold,
  handleAvailabilityThresholdChange,
  latitude,
  longitude
}) => {
  return (
    <>
      <Col span={10}>
        <Input
          disabled
          label="LATITUDE"
          onChange={() => {}}
          value={latitude}
        />
      </Col>
      <Col span={8}>
        <Input
          disabled
          label="LONGITUDE"
          onChange={() => {}}
          value={longitude}
        />
      </Col>
      <Col span={6}>
        <Input
          label="AVAILABILITY THRESHOLD"
          onChange={handleAvailabilityThresholdChange}
          value={availabilityThreshold}
        />
      </Col>
    </>
  )
}
FourthRow.propTypes = {
  availabilityThreshold: PropTypes.number,
  handleAvailabilityThresholdChange: PropTypes.func,
  latitude: PropTypes.string,
  longitude: PropTypes.string
}

const FifthRow = ({
  handleLegalCopyChange,
  handleLogoUrlChange,
  legalCopy,
  logoUrl
}) => {
  return (
    <>
      <Col span={14}>
        <Input
          label="Logo Url"
          onChange={handleLogoUrlChange}
          value={logoUrl}
        />
      </Col>
      <Col span={10}>
        <Input
          label="Legal Copy"
          onChange={handleLegalCopyChange}
          value={legalCopy}
        />
      </Col>
    </>
  )
}
FifthRow.propTypes = {
  handleLegalCopyChange: PropTypes.func,
  handleLogoUrlChange: PropTypes.func,
  legalCopy: PropTypes.string,
  logoUrl: PropTypes.string
}

const VenueBasicInfo = ({
  handleAvailabilityThresholdChange,
  handleLegalCopyChange,
  handleLogoUrlChange,
  handleNameChange,
  handleUrlChange,
  type,
  venue
}) => {
  const {
    address = {},
    availability_threshold: availabilityThreshold,
    city = {},
    id,
    location = {},
    name,
    partner = false,
    state = {},
    url
  } = venue
  return (
    <div key={`basic_info_${id}`} className={styles.basicInfoBackground}>
      <Row gutter={16} >
        <FirstRow handleNameChange={handleNameChange} handleUrlChange={handleUrlChange} id={id} name={name} type={type} url={url} />
      </Row>
      <VenueAddress
        addressLine1={address.line1}
        addressLine2={''}
        cityName={city.name}
        postalCode={venue.postalCode}
        stateName={state.name}
      />
      <Row gutter={16}>
        <FourthRow
          availabilityThreshold={availabilityThreshold}
          handleAvailabilityThresholdChange={handleAvailabilityThresholdChange}
          latitude={location.latitude}
          longitude={location.longitude}
        />
      </Row>
      { partner && <Row gutter={16}>
        <FifthRow
          handleLegalCopyChange={handleLegalCopyChange}
          handleLogoUrlChange={handleLogoUrlChange}
          legalCopy={venue.legalCopy}
          logoUrl={venue.logo_url}
        />
      </Row>}
    </div>
  )
}

VenueBasicInfo.propTypes = {
  handleAvailabilityThresholdChange: PropTypes.func.isRequired,
  handleLegalCopyChange: PropTypes.func,
  handleLogoUrlChange: PropTypes.func,
  handleNameChange: PropTypes.func,
  handleUrlChange: PropTypes.func,
  type: PropTypes.string,
  venue: PropTypes.object.isRequired
}

VenueBasicInfo.defaultProps = {
  venue: {},
  type: 'Edit'
}

export default VenueBasicInfo
