import React, { useEffect } from 'react'
import { BellOutlined, LogoutOutlined } from '@ant-design/icons'
import { Avatar, Badge, Menu, Layout, Space, Typography } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchDashboardStats, logoutUser } from '~actions'

import styles from './GlobalHeader.module.css'

const { Header } = Layout
const { Text } = Typography
const { SubMenu } = Menu
const GlobalHeader = ({ eventPublishStatus, fetchDashboardStats, logoutUser }) => {
  const { userDetails } = JSON.parse(localStorage.getItem('session'))
  const [userInitial] = userDetails ? userDetails.userName : ''
  const { unpublish: unPublishedEventsCount = 0 } = eventPublishStatus

  useEffect(() => {
    fetchDashboardStats()
  }, [fetchDashboardStats])

  return <Header className={styles.mainLayoutHeader}>
    <div className={styles.layoutHeader}>
      <div className={styles.headerLeftContent} />
      <div className={styles.headerRightContent}>
        <Menu key="notifications" mode="horizontal">
          <SubMenu
            key='notifications'
            title={
              <>
                <Badge className={styles.headerNotifications}>
                  <BellOutlined />
                </Badge>
              </>
            }
          >
            <Menu.Item key="SignOut">
              <a
                className={styles.headerActions}
                onClick={logoutUser}
                rel='noopener noreferrer'
              >
                You have
                <Text type="success"> {unPublishedEventsCount} </Text>
                unpublished events
              </a>
            </Menu.Item>
          </SubMenu>
        </Menu>
        <Menu key="user" mode="horizontal">
          <SubMenu
            key='logout'
            title={
              <>
                <span className={styles.navButton}>{
                  userDetails && userDetails.userName
                }</span>
                {userInitial && <Avatar className={styles.avatar}>{userInitial}</Avatar>}
              </>
            }
          >
            <Menu.Item key="SignOut">
              <a
                className={styles.headerActions}
                onClick={logoutUser}
                rel='noopener noreferrer'
              >
                <Space>
                  <LogoutOutlined />
                  Logout
                </Space>
              </a>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </div>
  </Header>
}

GlobalHeader.propTypes = {
  eventPublishStatus: PropTypes.object,
  fetchDashboardStats: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired
}

GlobalHeader.defaultProps = {
  eventPublishStatus: {}
}

const mapDispatchToProps = {
  fetchDashboardStats,
  logoutUser
}

const mapStateToProps = ({
  dashboard: {
    eventPublishStatus
  }
}) => ({
  eventPublishStatus
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalHeader)
