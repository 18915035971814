import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Space } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  RecommendationsSelector
} from '~components'
import {
  DatePicker,
  Input,
  Modal,
  MultiSelectDropdown,
  PageLayout,
  RichTextInput,
  Switch
} from '~stories'

import styles from './EditMsgPromoCodeForm.module.css'

const ACTION_MODES = {
  ADD: 'Add',
  EDIT: 'Edit'
}

const CTA_LABELS = {
  ADD: 'Add',
  UPDATE: 'Update'
}

const EditMsgPromoCodeForm = ({
  actionMode,
  assignedEvents,
  eventsAssignedToSeries,
  domain,
  fetchEventsBySeries,
  fetchPromoCodeById,
  handleSubmit,
  promoCode,
  promoCodeId,
  series,
  pageTitle
}) => {
  const [allEvents, setAllEvents] = useState([])
  const [description, setDescription] = useState('')
  const [expirationText, setExpirationText] = useState('')
  const [expiryDateTime, setExpiryDateTime] = useState(null)
  const [selectedEvents, setSelectedEvents] = useState([])
  const [seriesId, setSeriesId] = useState()
  const [status, setStatus] = useState(false)
  const [subTitle, setSubTitle] = useState('')
  const [terms, setTerms] = useState('')
  const [title, setTitle] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedSeriesId, setSelectedSeriesId] = useState()

  useEffect(() => {
    if (promoCodeId) {
      fetchPromoCodeById(promoCodeId)
    }
  }, [fetchPromoCodeById, promoCodeId])

  useEffect(() => {
    let promoCodeSeriesId
    if (promoCode) {
      promoCodeSeriesId = promoCode.series_id
    }
    if (seriesId && seriesId !== promoCodeSeriesId) {
      setSelectedEvents([])
    }
    if (seriesId) {
      fetchEventsBySeries(seriesId)
    }
  }, [fetchEventsBySeries, seriesId, promoCode])

  useEffect(() => {
    if (assignedEvents) {
      setSelectedEvents(assignedEvents)
    }
  }, [assignedEvents])

  useEffect(() => {
    if (eventsAssignedToSeries) {
      const eventIds = selectedEvents.map(({ id }) => id)
      const filteredEvents = eventsAssignedToSeries.filter(({ id }) => !eventIds.includes(id))

      setAllEvents(filteredEvents)
    }
  }, [eventsAssignedToSeries, selectedEvents])

  useEffect(() => {
    if (promoCode) {
      const {
        active,
        description,
        expiry_date_time: expiryDateTime,
        expirationText,
        terms,
        title = '',
        series_id: seriesId,
        subTitle
      } = promoCode
      const expiryDate = expiryDateTime ? moment(expiryDateTime) : null
      setExpiryDateTime(expiryDate)
      setTitle(title)
      setSubTitle(subTitle)
      setDescription(description)
      setTerms(terms)
      setExpirationText(expirationText)
      setStatus(active)
      setSeriesId(seriesId)
    }
  }, [promoCode])

  const handleTitleChange = event => {
    const title = event.target.value
    setTitle(title)
  }

  const handleDescriptionChange = description => setDescription(description)

  const handleTermsChange = terms => setTerms(terms)

  const handleSubTitleChange = event => {
    const subTitle = event.target.value
    setSubTitle(subTitle)
  }

  const handleExpiryDateTimChange = dateTime => {
    setExpiryDateTime(dateTime)
  }

  const handleExpirationTextChange = event => {
    const expirationText = event.target.value
    setExpirationText(expirationText)
  }

  const handleSeriesChange = seriesId => {
    setSelectedSeriesId(seriesId)
    setShowModal(true)
  }

  const handleUpdate = () => {
    const data = {
      active: status,
      description,
      domain,
      events: selectedEvents,
      expirationText,
      expiryDateTime,
      seriesId,
      subTitle,
      terms,
      title
    }
    handleSubmit(data)
  }

  const buttonLabel = actionMode === ACTION_MODES.EDIT
    ? CTA_LABELS.UPDATE
    : CTA_LABELS.ADD

  const inputProps = {
    id: 'promocodeTitle',
    label: 'Code',
    maxLength: 12,
    onChange: handleTitleChange,
    value: title
  }

  const statusSwitch = <Switch
    checked={status}
    isInline={true}
    label="Status"
    onChange={setStatus}
  />

  const updatePromoCodeButton = <Button className={styles.updateActionButton} onClick={handleUpdate} type='primary'>
    { buttonLabel }
  </Button>

  const handleModalCancel = () => {
    setSelectedSeriesId()
    setShowModal(false)
  }

  const handleModalSubmit = () => {
    setSeriesId(selectedSeriesId)
    setShowModal(false)
  }

  const confirmationModal = showModal && <Modal
    description='The selected events will be removed. Are you sure you want to proceed?'
    handleCancel={handleModalCancel}
    handleSubmit={handleModalSubmit}
    showModal={showModal}
    title="Update Promocode"
  />

  return (
    <PageLayout extra={[statusSwitch, updatePromoCodeButton]} title={pageTitle}>
      <div className={styles.editPromoCodeLayoutBackground}>
        <div className={styles['edit-promocode-layout-background']}>
          <Row gutter={24}>
            <Col span={24}>
              <Input
                {...inputProps}
              />
            </Col>
            <Col span={24}>
              <Input
                id="promocodeSubTitle"
                label="Sub Title"
                onChange={handleSubTitleChange}
                value={subTitle}
              />
            </Col>
            <Col span={24}>
              <Input
                id="expirationtext"
                label="Expiration Text"
                onChange={handleExpirationTextChange}
                value={expirationText}
              />
            </Col>
            <Col span={24}>
              <RichTextInput
                id="promocodeDescription"
                label="Description"
                onChange={handleDescriptionChange}
                value={description}
              />
            </Col>
            <Col span={24}>
              <RichTextInput
                id="promocodeTerms"
                label="Terms"
                onChange={handleTermsChange}
                value={terms}
              />
            </Col>
            <Col span={24}>
              <DatePicker
                label="Expiration Date & Time: (EST)"
                onChange={handleExpiryDateTimChange}
                showTime
                value={expiryDateTime}
              />
            </Col>
          </Row>
        </div>
      </div>
      <Col span={24}>
        <Card title="Series">
          <Space direction="vertical">
            <Col>
              <MultiSelectDropdown
                customStyle={{ width: 550 }}
                displayField="name"
                dropdownOptions={series}
                handleChange={handleSeriesChange}
                selectedItems={seriesId}
                valueField="_id"
              />
            </Col>
            <Col span={24}>
              <RecommendationsSelector
                allEvents={allEvents}
                disabled={true}
                displayField="title"
                promotedEvents={selectedEvents}
                resourceName='Events'
                setAllEvents={setAllEvents}
                setPromotedEvents={setSelectedEvents}
              />
            </Col>
          </Space>
        </Card>
      </Col>
      { confirmationModal }
    </PageLayout>
  )
}

EditMsgPromoCodeForm.propTypes = {
  actionMode: PropTypes.string,
  assignedEvents: PropTypes.array,
  availableEvents: PropTypes.array,
  domain: PropTypes.string,
  eventsAssignedToSeries: PropTypes.array,
  fetchEventsBySeries: PropTypes.func,
  fetchPromoCodeById: PropTypes.func,
  fetchSeriesById: PropTypes.func,
  handleSubmit: PropTypes.func,
  pageTitle: PropTypes.string,
  promoCode: PropTypes.object,
  promoCodeId: PropTypes.string,
  series: PropTypes.array
}

EditMsgPromoCodeForm.defaultProps = {
  domain: 'msg',
  series: []
}

export default React.memo(EditMsgPromoCodeForm)
