import 'isomorphic-fetch'
import { venuesEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'
import { ADD_VENUE, FETCH_VENUES, STORE_VENUE_TO_EDIT, VENUE_UPDATE_STATUS } from '../actionTypes'

const DEFAULT_PARAMS = { pagination: { current: 1, pageSize: 10 } }

export const fetchVenuesList = (params = DEFAULT_PARAMS) => async dispatch => {
  const { method, url } = venuesEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method,
    body: JSON.stringify(params)
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { venues: rows, venuesCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_VENUES,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchVenueById = venueObjectId => async dispatch => {
  const { method, url } = venuesEndpoint.edit(venueObjectId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { allContents, availableEventsForPromotion, venue } = apiResponse
  const content = venue.content.map(block => ({
    ...block,
    _id: block.id
  }))
  dispatch({
    type: STORE_VENUE_TO_EDIT,
    payload: {
      allContents,
      availableEventsForPromotion,
      venue: {
        ...venue,
        content
      }
    }
  })
}

export const addVenue = (venue, accessToken) => async dispatch => {
  const { method, url } = venuesEndpoint.add
  const idToken = getIdToken()
  await fetch(url, {
    body: JSON.stringify({ venue }),
    headers: {
      Authorization: `Bearer ${idToken}`,
      accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .then(response => {
      const { addedVenue: { _id: newVenueId }, message, success } = response
      dispatch({
        type: ADD_VENUE,
        payload: {
          newVenueId,
          error: false,
          message,
          success
        }
      })
    })
    .catch((error) => {
      dispatch({
        type: ADD_VENUE,
        payload: {
          error: true,
          message: error.message,
          success: false
        }
      })
    })
}

export const updateVenue = (venueObjectId, venueObject, accessToken) => async dispatch => {
  const { method, url } = venuesEndpoint.update(venueObjectId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(venueObject),
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    }))
  const { message, success } = apiResponse
  dispatch({
    type: VENUE_UPDATE_STATUS,
    payload: { message, success }
  })
}

export const resetVenueActionStatus = () => async dispatch => {
  dispatch({
    type: VENUE_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}
