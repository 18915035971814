import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import {
  fetchSeriesById,
  filterEvents,
  resetUpdateSeriesStatus,
  updateSeriesById
} from '~actions'
import { EditSeriesForm, ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { Spinner } from '~stories'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const EditSeries = ({
  artists = [],
  availableEvents,
  categories = [],
  cities = [],
  eventsAssignedToSeries = [],
  eventsAvailableForSeries = [],
  fetchSeriesById,
  filterAllEvents,
  resetUpdateSeriesStatus,
  route = {},
  series = {},
  updateSeriesById,
  updateSeriesMessage,
  updateSeriesStatus,
  venues = []
}) => {
  const [seriesId, setSeriesId] = useState('')
  const [showSpinner, setShowSpinner] = useState(false)
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    const seriesId = idx(route, _ => _.match.params.id)
    if (!seriesId) return
    setSeriesId(seriesId)
    setShowSpinner(true)
  }, [route])

  useEffect(() => {
    if (!seriesId) return
    fetchSeriesById(seriesId)
  }, [seriesId, fetchSeriesById])

  useEffect(() => {
    if (series && series.id) {
      setTimeout(() => {
        setShowSpinner(false)
      }, 1000)
    }
  }, [series])

  useEffect(() => {
    if (updateSeriesStatus) {
      setShowSpinner(false)
    } else if (!updateSeriesStatus && updateSeriesMessage) {
      setShowSpinner(false)
      notification.error({ message: updateSeriesMessage })
      resetUpdateSeriesStatus()
    }
  }, [updateSeriesStatus, updateSeriesMessage, resetUpdateSeriesStatus])

  const handleSubmit = seriesData => {
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }
    setShowSpinner(true)
    updateSeriesById(seriesData, accessToken)
  }

  const handleModalClose = () => {
    resetUpdateSeriesStatus()
  }

  const goToListSeriesButton = (
    <Route key="listSeries" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateSeriesStatus()
          history.push('/series')
        }}
        type="primary"
      >
        Go to Series List
      </Button>
    )} />
  )

  const keepEditingButton = (
    <Route key="editSeries" render={() => (
      <Button
        onClick={() => {
          resetUpdateSeriesStatus()
          window.location.reload()
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  return (
    <>
      <EditSeriesForm
        artists={artists}
        availableEvents={availableEvents}
        categories={categories}
        cities={cities}
        eventsAssignedToSeries={eventsAssignedToSeries}
        eventsAvailableForSeries={eventsAvailableForSeries}
        filterAllEvents={filterAllEvents}
        series={series}
        seriesId={seriesId}
        updateSeriesById={handleSubmit}
        venues={venues}
      />
      <Spinner isLoading={showSpinner} size="large" />
      {
        updateSeriesStatus &&
        <ResultModal
          actions={[
            goToListSeriesButton,
            keepEditingButton
          ]}
          handleCancel={handleModalClose}
          status="success"
          title="Series updated successfully!"
        />
      }
    </>
  )
}

EditSeries.propTypes = {
  artists: PropTypes.array,
  availableEvents: PropTypes.array,
  categories: PropTypes.array,
  cities: PropTypes.array,
  eventsAssignedToSeries: PropTypes.array,
  eventsAvailableForSeries: PropTypes.array,
  fetchSeriesById: PropTypes.func.isRequired,
  filterAllEvents: PropTypes.func.isRequired,
  resetUpdateSeriesStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  series: PropTypes.object,
  updateSeriesById: PropTypes.func,
  updateSeriesMessage: PropTypes.string,
  updateSeriesStatus: PropTypes.bool,
  venues: PropTypes.array
}

const mapStateToProps = ({ series, events }) => {
  return {
    artists: series.artists,
    availableEvents: events.allEvents,
    categories: series.categories,
    cities: series.cities,
    eventsAssignedToSeries: series.eventsAssignedToSeries,
    eventsAvailableForSeries: series.eventsAvailableForSeries,
    series: series.seriesToEdit,
    updateSeriesMessage: series.updateSeriesMessage,
    updateSeriesStatus: series.updateSeriesStatus,
    venues: series.venues
  }
}

const mapDispatchToProps = {
  fetchSeriesById,
  filterAllEvents: filterEvents,
  resetUpdateSeriesStatus,
  updateSeriesById
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSeries)
