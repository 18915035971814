import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Skeleton, Typography } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchDashboardStats } from '~actions'
import { UpcomingEvents, EventSales } from '~components'
import { PageLayout } from '~stories'
import styles from './Dashboard.module.css'

const { Title } = Typography

const Dashboard = ({
  dashboardStats,
  eventPublishStatus,
  fetchDashboardStats,
  unPublishedEvents,
  upcomingEvents
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState()
  const { userDetails } = JSON.parse(localStorage.getItem('session'))
  const { publish: publishedEventsCount = 0, unpublish: unPublishedEventsCount = 0 } = eventPublishStatus
  const totalEventsCount = publishedEventsCount + unPublishedEventsCount

  const [stats] = dashboardStats
  const { events: { most_available: mostAvailable = [], top_selling: topSelling = [] } } = stats || { events: {} }

  useEffect(() => {
    setIsDataLoaded(true)
    fetchDashboardStats()
  }, [fetchDashboardStats])

  useEffect(() => {
    if (dashboardStats && dashboardStats.length > 0) {
      setIsDataLoaded(false)
    }
  }, [dashboardStats])

  const { userName } = userDetails
  const userText = userDetails && <Title className={styles.header} level={3}>{`Welcome, ${userName}!`}</Title>

  return (
    <PageLayout>
      { userText }
      <Row className={styles.rowContent} gutter={[16, 16]}>
        <Col lg={12} md={12} sm={12} xl={8} xs={24}>
          <Card bordered={false} title="Total Events">
            <Skeleton active loading={isDataLoaded}>
              <p className={styles.eventsCount}>{ totalEventsCount }</p>
            </Skeleton>
          </Card>
        </Col>
        <Col lg={12} md={12} sm={12} xl={8} xs={24}>
          <Card bordered={false} title="Published Events">
            <Skeleton active loading={isDataLoaded}>
              <p className={styles.eventsCount}>{ publishedEventsCount }</p>
            </Skeleton>
          </Card>
        </Col>
        <Col lg={12} md={12} sm={12} xl={8} xs={24}>
          <Card bordered={false} title="Unpublished Events">
            <Skeleton active loading={isDataLoaded}>
              <p className={styles.eventsCount}>{ unPublishedEventsCount }</p>
            </Skeleton>
          </Card>
        </Col>
      </Row>
      <Row className={styles.rowContent} gutter={[16, 16]}>
        <Col lg={24} md={24} sm={24} xl={12} xs={24}>
          <Card bodyStyle={{ paddingTop: 12, paddingLeft: 20, paddingBottom: 0, paddingRight: 20 }} bordered={false} className={styles.card} title="Unpublished Events">
            <Skeleton active avatar loading={isDataLoaded}>
              <UpcomingEvents listItems={unPublishedEvents} />
            </Skeleton>
          </Card>
        </Col>
        <Col lg={24} md={24} sm={24} xl={12} xs={24}>
          <Card bodyStyle={{ paddingTop: 12, paddingLeft: 20, paddingBottom: 0, paddingRight: 20 }} bordered={false} className={styles.card} title="Upcoming Shows">
            <Skeleton active avatar loading={isDataLoaded}>
              <UpcomingEvents listItems={upcomingEvents} />
            </Skeleton>
          </Card>
        </Col>
      </Row>
      <Row className={styles.rowContent} gutter={[16, 16]}>
        <Col lg={24} md={24} sm={24} xl={12} xs={24}>
          <Card bodyStyle={{ paddingTop: 12, paddingLeft: 20, paddingBottom: 0, paddingRight: 20 }} bordered={false} className={styles.card} title="Most Sold">
            <Skeleton active avatar loading={isDataLoaded}>
              <EventSales listItems={topSelling}/>
            </Skeleton>
          </Card>
        </Col>
        <Col lg={24} md={24} sm={24} xl={12} xs={24}>
          <Card bodyStyle={{ paddingTop: 12, paddingLeft: 20, paddingBottom: 0, paddingRight: 20 }} bordered={false} className={styles.card} title="Least Sold">
            <Skeleton active avatar loading={isDataLoaded}>
              <EventSales listItems={mostAvailable}/>
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </PageLayout>
  )
}

Dashboard.propTypes = {
  dashboardStats: PropTypes.array,
  eventPublishStatus: PropTypes.object,
  fetchDashboardStats: PropTypes.func.isRequired,
  unPublishedEvents: PropTypes.array,
  upcomingEvents: PropTypes.array
}

Dashboard.defaultProps = {
  dashboardStats: [],
  eventPublishStatus: {},
  featureFlagMenus: [],
  unPublishedEvents: [],
  upcomingEvents: []
}

const mapStateToProps = ({
  dashboard: {
    dashboardStats,
    eventPublishStatus,
    featureFlagMenus,
    unPublishedEvents,
    upcomingEvents
  }
}) => ({
  dashboardStats,
  eventPublishStatus,
  featureFlagMenus,
  unPublishedEvents,
  upcomingEvents
})

const mapDispatchToProps = {
  fetchDashboardStats
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
