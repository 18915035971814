import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import {
  fetchEventsBySeries,
  fetchEventsByPriceTemplate,
  fetchPromoCodeById,
  resetPromoCodeActionStatus,
  updatePromoCode
} from '~actions'
import {
  EditRockettesPromoCodeForm,
  EditMsgPromoCodeForm,
  ResultModal
} from '~components'
import { getCurrentUser } from '~context'
import { validatePromocode } from '~validators'

const EDIT_ACTION_MODE = 'Edit'
const PROMOCODE_DOMAINS = {
  ROCKETTES: 'rockettes',
  MSG: 'msg'
}
const PAGE_TITLE = {
  MSG: 'Edit MSG Promo Code',
  ROCKETTES: 'Edit Rockettes Promo Code'
}
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const EditPromoCode = ({
  allEventsForMsg,
  assignedEvents,
  assignedEventsToMsgPromo,
  eventsAssignedToSeries,
  fetchEventsByPriceTemplate,
  fetchEventsBySeries,
  fetchPromoCodeById,
  priceTemplates,
  promoCode = {},
  resetPromoCodeActionStatus,
  route = {},
  series,
  updateMessage,
  updatePromoCode,
  updateStatus = false
}) => {
  const [promoCodeId, setPromoCodeId] = useState('')
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    const promoCodeId = idx(route, _ => _.match.params.id)
    if (promoCodeId) {
      setPromoCodeId(promoCodeId)
    }
  }, [route])

  useEffect(() => {
    if (promoCodeId) {
      fetchPromoCodeById(promoCodeId)
    }
  }, [promoCodeId, fetchPromoCodeById])

  const { domain } = promoCode

  const handleSubmit = (promoCodeData) => {
    const { isValid, message } = validatePromocode(promoCodeData)

    if (!isValid) {
      notification.error({ message })
      return
    }
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    updatePromoCode({ promoCodeId, domain, ...promoCodeData }, accessToken)
  }

  const goToListPromoCodesCta = (
    <Route key="listVenues" render={({ history }) => (
      <Button
        onClick={() => {
          resetPromoCodeActionStatus()
          history.push(`/promo_codes/${domain}`)
        }}
        type="primary"
      >
        Go to Promo Code List
      </Button>
    )} />
  )

  const editPromoCodeCta = (
    <Button
      onClick={() => {
        resetPromoCodeActionStatus()
        window.location.reload()
      }}
      type="primary"
    >
      Keep Editing
    </Button>
  )

  return (
    <>
      {
        domain === PROMOCODE_DOMAINS.ROCKETTES &&
        <div>
          <EditRockettesPromoCodeForm
            actionMode={EDIT_ACTION_MODE}
            assignedEvents={assignedEvents}
            fetchEventsByPriceTemplate={fetchEventsByPriceTemplate}
            handleSubmit={handleSubmit}
            pageTitle={PAGE_TITLE.ROCKETTES}
            priceTemplates={priceTemplates}
            promoCode={promoCode}
          />
        </div>
      }
      {
        domain === PROMOCODE_DOMAINS.MSG &&
        <div>
          <EditMsgPromoCodeForm
            actionMode={EDIT_ACTION_MODE}
            allEvents={allEventsForMsg}
            assignedEvents={assignedEventsToMsgPromo}
            eventsAssignedToSeries={eventsAssignedToSeries}
            fetchEventsBySeries={fetchEventsBySeries}
            handleSubmit={handleSubmit}
            pageTitle={PAGE_TITLE.MSG}
            promoCode={promoCode}
            series={series}
          />
        </div>
      }
      {
        updateStatus &&
        <ResultModal
          actions={[
            goToListPromoCodesCta,
            editPromoCodeCta
          ]}
          handleCancel={resetPromoCodeActionStatus}
          status="success"
          title={updateMessage}
        />
      }
    </>
  )
}

EditPromoCode.propTypes = {
  allEventsForMsg: PropTypes.array,
  assignedEvents: PropTypes.array,
  assignedEventsToMsgPromo: PropTypes.array,
  eventsAssignedToSeries: PropTypes.array,
  fetchEventsByPriceTemplate: PropTypes.func,
  fetchEventsBySeries: PropTypes.func,
  fetchPromoCodeById: PropTypes.func,
  priceTemplates: PropTypes.array,
  promoCode: PropTypes.object,
  promoCodeId: PropTypes.string,
  resetPromoCodeActionStatus: PropTypes.func,
  route: PropTypes.object,
  series: PropTypes.array,
  updateMessage: PropTypes.string,
  updatePromoCode: PropTypes.func,
  updateStatus: PropTypes.bool
}

const mapStateToProps = ({ promoCodes, priceTemplates, series }) => ({
  allEventsForMsg: promoCodes.allEvents,
  assignedEventsToMsgPromo: promoCodes.assignedEventsToMsgPromo,
  assignedEvents: priceTemplates.assignedEvents,
  eventsAssignedToSeries: series.eventsAssignedToSeries,
  promoCode: promoCodes.promoCodeToEdit,
  priceTemplates: promoCodes.priceTemplates,
  series: promoCodes.series,
  updateStatus: promoCodes.status,
  updateMessage: promoCodes.message
})

const mapDispatchToProps = {
  fetchPromoCodeById,
  fetchEventsBySeries,
  fetchEventsByPriceTemplate,
  resetPromoCodeActionStatus,
  updatePromoCode
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPromoCode)
