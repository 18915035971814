import {
  ADD_ORGANISATION,
  FETCH_ORGANISATION_TO_EDIT,
  FETCH_ORGANIZATIONS,
  REMOVE_ORGANISATION_BY_ID,
  RESET_ORGANISATION_STATUS,
  UPDATE_ORGANISATION
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ORGANIZATIONS:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_ORGANISATION_TO_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case RESET_ORGANISATION_STATUS:
      return {
        ...state,
        ...action.payload
      }
    case ADD_ORGANISATION:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_ORGANISATION:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_ORGANISATION_BY_ID:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
