import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

import { getLocalStorage } from '~utils'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const session = getLocalStorage(null)

  return (
    <UserContext.Provider value={{ ...session }}>
      { children }
    </UserContext.Provider>
  )
}

// eslint-disable-next-line react-hooks/rules-of-hooks
export const getCurrentUser = () => useContext(UserContext)

UserProvider.propTypes = {
  children: PropTypes.object.isRequired
}
