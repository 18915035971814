import React from 'react'
import PropTypes from 'prop-types'

import { MultiSelect } from '~stories'

const VenueContentSelector = ({
  allContents = [],
  selectedContents,
  setAllContents,
  setVenueContents
}) => {
  const manageLists = (items, source, dest, sourceFn, destFn) => {
    const selectedItems = Array.isArray(items)
      ? items
      : [items]
    const clonedSource = [...source]
    let clonedDest = [...dest]
    selectedItems.forEach(item => {
      const selectedEventIndex = clonedSource.findIndex(event => event.name === item.name)
      const [itemToMove] = clonedSource.splice(selectedEventIndex, 1)
      clonedDest = [...clonedDest, itemToMove]
    })
    sourceFn([...clonedSource])
    destFn([...clonedDest])
  }

  const onAdd = selectedEvent => {
    manageLists(selectedEvent, allContents, selectedContents, setAllContents, setVenueContents)
  }

  const onRemove = selectedEvent => {
    manageLists(selectedEvent, selectedContents, allContents, setVenueContents, setAllContents)
  }

  const onFilter = (allListItems, filterValue) => {
    const testRegExp = new RegExp(filterValue, 'i')
    return allListItems.filter(item => {
      const name = `${item.name}`
      return testRegExp.test(name)
    })
  }

  return (
    <>
      <MultiSelect
        availableListItems={allContents}
        displayField="name"
        onAdd={onAdd}
        onFilter={onFilter}
        onRemove={onRemove}
        resourceName="Content Blocks"
        selectedListItems={selectedContents}
        valueField='_id'
      />
    </>
  )
}

VenueContentSelector.propTypes = {
  allContents: PropTypes.array,
  selectedContents: PropTypes.array,
  setAllContents: PropTypes.func,
  setVenueContents: PropTypes.func
}

VenueContentSelector.defaultProps = {
  allContents: [],
  selectedContents: []
}

export default VenueContentSelector
