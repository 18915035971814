import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import {
  addSeries,
  filterEvents,
  initializeSeries,
  resetAddSeriesStatus
} from '~actions'
import { AddSeriesForm, ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { history } from '~history'
import { Spinner } from '~stories'

const AddSeries = ({
  addSeries,
  addSeriesMessage,
  addSeriesStatus = false,
  artists = [],
  availableEvents,
  categories = [],
  cities = [],
  eventsAvailableForSeries = [],
  filterAllEvents,
  initializeSeries,
  resetAddSeriesStatus,
  seriesId,
  venues = []
}) => {
  const [showSpinner, setShowSpinner] = useState(true)
  const { access_token: accessToken } = getCurrentUser()

  useEffect(() => {
    initializeSeries(accessToken)
    setShowSpinner(true)
  }, [accessToken, initializeSeries])

  useEffect(() => {
    if (!seriesId) return
    setShowSpinner(false)
  }, [seriesId])

  useEffect(() => {
    if (!addSeriesStatus && addSeriesMessage) {
      setShowSpinner(false)
      notification.error({ message: addSeriesMessage })
      resetAddSeriesStatus()
    }
  }, [addSeriesStatus, addSeriesMessage, resetAddSeriesStatus])

  useEffect(() => {
    if (!addSeriesStatus) return
    setShowSpinner(false)
  }, [addSeriesStatus])

  const goToListSeriesButton = (
    <Route key="lisSeries" render={({ history }) => (
      <Button
        onClick={() => {
          resetAddSeriesStatus()
          history.push('/series')
        }}
        type="primary"
      >
        Go to Series List
      </Button>
    )} />
  )

  const addSeriesButton = (
    <Route key="addSeries" render={({ history }) => (
      <Button
        onClick={() => {
          resetAddSeriesStatus()
          window.location.reload()
        }}
        type="primary"
      >
        Add Another
      </Button>
    )} />
  )

  const editSeriesButton = (
    <Route key="editSeries" render={({ history }) => (
      <Button
        onClick={() => {
          resetAddSeriesStatus()
          history.push(`/series/${seriesId}/edit`)
        }}
        type="primary"
      >
        Edit Series
      </Button>
    )} />
  )

  const handleCloseModel = () => {
    resetAddSeriesStatus()
    history.push('/series')
  }

  const handleSubmit = seriesData => {
    setShowSpinner(true)
    addSeries(seriesData, accessToken)
  }

  return (
    <>
      <Spinner
        isLoading={showSpinner}
        size="large"
      />
      <AddSeriesForm
        addSeries={handleSubmit}
        artists={artists}
        availableEvents={availableEvents}
        categories={categories}
        cities={cities}
        eventsAvailableForSeries={eventsAvailableForSeries}
        filterAllEvents={filterAllEvents}
        seriesId={seriesId}
        venues={venues}
      />
      {
        addSeriesStatus &&
        <ResultModal
          actions={[
            editSeriesButton,
            goToListSeriesButton,
            addSeriesButton
          ]}
          handleCancel={handleCloseModel}
          status="success"
          title="Series added successfully!"
        />
      }
    </>
  )
}

AddSeries.propTypes = {
  addSeries: PropTypes.func,
  addSeriesMessage: PropTypes.string,
  addSeriesStatus: PropTypes.bool,
  artists: PropTypes.array,
  availableEvents: PropTypes.array,
  categories: PropTypes.array,
  cities: PropTypes.array,
  eventsAvailableForSeries: PropTypes.array,
  filterAllEvents: PropTypes.func.isRequired,
  initializeSeries: PropTypes.func,
  resetAddSeriesStatus: PropTypes.func,
  seriesId: PropTypes.string,
  venues: PropTypes.array
}

const mapStateToProps = ({ series, events }) => {
  return {
    addSeriesMessage: series.addSeriesMessage,
    addSeriesStatus: series.addSeriesStatus,
    artists: series.artists,
    availableEvents: events.allEvents,
    categories: series.categories,
    cities: series.cities,
    eventsAvailableForSeries: series.eventsAvailableForSeries,
    seriesId: series.seriesId,
    venues: series.venues
  }
}

const mapDispatchToProps = {
  addSeries,
  initializeSeries,
  filterAllEvents: filterEvents,
  resetAddSeriesStatus
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSeries)
