import React from 'react'
import PropTypes from 'prop-types'

import { ReactCloudinaryUploader } from '~components'

const LogoUploader = ({
  handleUpload,
  logoUrl
}) => {
  return (
    <ReactCloudinaryUploader
      cloudName='msg-suites-uat'
      folder='eventengine'
      maxFileSize={5000000}
      multiple={false}
      theme='minimal'
      thumbnailUrl={logoUrl}
      updateImageUrl={(thumbnailUrl, secureImageURL) => handleUpload(thumbnailUrl, secureImageURL)}
      uploadPreset='rxcg8xdn'
    />
  )
}

LogoUploader.propTypes = {
  handleUpload: PropTypes.func,
  logoUrl: PropTypes.string
}

export default LogoUploader
