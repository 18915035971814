import moment from 'moment-timezone'

const artistsPullDownConfig = {
  K8vZ9171K10: {
    pullDownDelta: {
      hours: 2,
      minutes: 0
    }
  },
  K8vZ9171o87: {
    pullDownDelta: {
      hours: 3,
      minutes: 30
    }
  },
  K8vZ9171o57: {
    pullDownDelta: {
      hours: 3,
      minutes: 30
    }
  },
  Others: {
    pullDownDelta: {
      hours: 3,
      minutes: 0
    }
  }
}
const DATE_FORMAT = 'MM/DD/YYYY'
const VALID_ARTISTS = ['K8vZ9171K10', 'K8vZ9171o87', 'K8vZ9171o57']

/*
  Pull_down_from_system based on the event type:
    - For Rockettes (K8vZ9171K10) use event start time + 2 hours
    - For Rangers(K8vZ9171o87) and Knicks(K8vZ9171o57) use event start time + 3.5 hours
    - For all other events use event start time + 3 hours
    - For events with TBA then use Start Date of the event
*/
const getEventPulldownValue = (startDate, startTime, artists, timezone) => {
  const startDateStr = moment.utc(startDate).format(DATE_FORMAT)
  const startDateObj = moment.tz(startDateStr, timezone)
  if (startTime === 'TBA') { return moment.tz(startDateObj, timezone).utc().format() }

  const artistIdList = artists.reduce((artist, currentValue) => [...artist, currentValue.id], [])
  const [
    hour,
    minute,
    seconds,
    eventHr = +hour,
    eventMinute = +minute,
    eventSeconds = +seconds
  ] = startTime.split(':')
  const [selectedArtist] = VALID_ARTISTS.filter(artist => artistIdList.indexOf(artist) > -1)

  const pullDownConfig = (
    selectedArtist &&
        selectedArtist.length > 0 &&
        artistsPullDownConfig[selectedArtist])
    ? artistsPullDownConfig[selectedArtist]
    : artistsPullDownConfig.Others

  const {
    pullDownDelta: {
      hours,
      minutes,
      pullDownDeltaHrs = hours,
      pullDownDeltaMin = minutes
    }
  } = pullDownConfig

  startDateObj.hour(eventHr + pullDownDeltaHrs)
  startDateObj.minute(eventMinute + pullDownDeltaMin)
  startDateObj.second(eventSeconds)

  return moment.tz(startDateObj, timezone).utc().format()
}

export {
  getEventPulldownValue
}
