const validateOrganisationApps = applications => {
  const message = 'One or more applications has invalid name.'
  const [inValidApplication] = applications.filter(({ name }) => name === '')

  return inValidApplication
    ? { message }
    : {}
}

export { validateOrganisationApps }
