import React from 'react'

import { Checkbox } from 'antd'
import PropTypes from 'prop-types'

import Label from '../Label/Label'

import styles from './Checkbox.module.css'

const CustomCheckbox = ({
  checked,
  disabled,
  isInline,
  label,
  labelClassName,
  onChange
}) => {
  const className = styles['inline-checkbox']
  return (
    <div className={styles.container}>
      <Label className={labelClassName} isInline={isInline} value={label} />
      <Checkbox
        checked={checked}
        className={className}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}

CustomCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isInline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

CustomCheckbox.defaultProps = {
  checked: false,
  disabled: false,
  isInline: false,
  labelClassName: ''
}

export default CustomCheckbox
