import React from 'react'
import PropTypes from 'prop-types'

import { Input } from '~stories'

import styles from './PriceSections.module.css'

const PriceSections = ({ index, maxPrice, minPrice, name, priceClassIndex, updateMaxPrice, updateMinPrice, handleServiceFeeMinPriceInput, handleServiceFeeMaxPriceInput, serviceFee }) => {
  const handleMinPriceInput = event => {
    const minPrice = event.target.value
    updateMinPrice(priceClassIndex, index, minPrice)
  }

  const handleMaxPriceInput = event => {
    const maxPrice = event.target.value
    updateMaxPrice(priceClassIndex, index, maxPrice)
  }

  const handleServiceMinPriceInput = event => {
    const minPrice = event.target.value
    handleServiceFeeMinPriceInput(priceClassIndex, index, minPrice)
  }

  const handleServiceMaxPriceInput = event => {
    const maxPrice = event.target.value
    handleServiceFeeMaxPriceInput(priceClassIndex, index, maxPrice)
  }

  const inputError = +minPrice > +maxPrice
  const serviceInputError = +minPrice > 0 && +maxPrice > 0
    ? !(+serviceFee?.min_price > 0 && +serviceFee?.max_price > 0 && +serviceFee?.min_price < +serviceFee?.max_price)
    : false

  return (<>
    <div className={styles.sectionContainer}>
      <div className={styles.sectionName}>{name}</div>
      <Input className={inputError ? styles.sectionFormInputItemErr : styles.sectionFormInputItem} onChange={handleMinPriceInput} placeholder="Min Price" value={minPrice} />
      <Input className={inputError ? styles.sectionFormInputItemErr : styles.sectionFormInputItem} onChange={handleMaxPriceInput} placeholder="Max Price" value={maxPrice} />
    </div>
    <div className={styles.sectionContainer}>
      <div className={styles.sectionName}>Service Fee</div>
      <Input className={serviceInputError ? styles.sectionFormInputItemErr : styles.sectionFormInputItem} onChange={handleServiceMinPriceInput} placeholder="Min Price" value={serviceFee?.min_price} />
      <Input className={serviceInputError ? styles.sectionFormInputItemErr : styles.sectionFormInputItem} onChange={handleServiceMaxPriceInput} placeholder="Max Price" value={serviceFee.max_price} />
    </div>
  </>
  )
}

PriceSections.propTypes = {
  handleServiceFeeMaxPriceInput: PropTypes.func.isRequired,
  handleServiceFeeMinPriceInput: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  maxPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  minPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  priceClassIndex: PropTypes.number.isRequired,
  serviceFee: PropTypes.object,
  updateMaxPrice: PropTypes.func.isRequired,
  updateMinPrice: PropTypes.func.isRequired
}

export default PriceSections
