import React from 'react'
import { Row, Col, Card, Button } from 'antd'
import PropTypes from 'prop-types'

import { Input } from '~stories'

const EventDefaultImage = ({
  defaultImage: {
    height,
    ratio,
    url,
    width
  } = {},
  setShowDefaultImage
}) => {
  return (
    <Card title="Default Image">
      <Row gutter={16}>
        <Col span={18}>
          <Input disabled={true} label='Url' value={url} />
        </Col>
        <Col span={6}>
          <Input disabled={true} label='Image Aspect Ratio' value={ratio || ''} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={9}>
          <Input disabled={true} label='Image Height' value={height || ''} />
        </Col>
        <Col span={9}>
          <Input disabled={true} label='Image Width' value={width || ''} />
        </Col>
        <Col span={4} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          <Button onClick={() => setShowDefaultImage(true)} type="primary">Preview</Button>
        </Col>
      </Row>
    </Card>
  )
}

EventDefaultImage.propTypes = {
  defaultImage: PropTypes.object,
  setShowDefaultImage: PropTypes.func
}

export default EventDefaultImage
