import React, { useEffect } from 'react'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchUserSessionbyAuthCode } from '~actions'
import { history } from '~history'
import { Spinner } from '~stories'

const Auth = ({ fetchUserSessionbyAuthCode, session }) => {
  useEffect(() => {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    if (code) {
      fetchUserSessionbyAuthCode(code)
    } else {
      history.push('/logout')
    }
  }, [fetchUserSessionbyAuthCode])

  useEffect(() => {
    const isSessionInvalid = idx(session, _ => _.error)
    if (session && !isSessionInvalid) {
      history.push('/dashboard')
    }
  }, [session])

  return (
    <Spinner isLoading={true} size='large'/>
  )
}

Auth.propTypes = {
  fetchUserSessionbyAuthCode: PropTypes.func.isRequired,
  session: PropTypes.object
}

const mapStateToProps = ({ auth: { session } }) => ({
  session
})

const mapDispatchToProps = {
  fetchUserSessionbyAuthCode
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth)
