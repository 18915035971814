import React, { useEffect, useState } from 'react'
import { Button, Card, notification } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { addPriceTemplate, resetPriceTemplateActionStatus, fetchNewPriceTemplate } from '~actions'
import { PriceTemplateBasicInfo, PriceTemplateClass, RecommendationsSelector, ResultModal } from '~components'
import { PRICE_TEMPLATE, PRICE_CLASSES } from '~constants'
import { getCurrentUser } from '~context'
import { PageLayout, Spinner } from '~stories'
import { isPriceSectionsValid, validatePriceSections, validatePriceTemplates, validateSectionDiscounts } from '~validators'

import styles from './NewPriceTemplate.module.css'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.',
  TEMPLATE_NAME_REQUIRED: 'Price template name can\'t be blank',
  PRICE_CLASS_NAME_REQUIRED: 'Price class name can\'t be blank'
}
const PAGE_TITLE = 'New PriceTemplate'

const NewPriceTemplate = ({
  addPriceTemplate,
  fetchNewPriceTemplate,
  newPriceTemplateId,
  match = {},
  priceTemplate,
  resetPriceTemplateActionStatus,
  route,
  updateError,
  updateMessage,
  updateStatus
}) => {
  const [allEvents, setAllEvents] = useState([])
  const [description, setDescription] = useState('')
  const [discountPercentage, setDiscountPercentage] = useState('')
  const [isDataLoaded, setIsDataLoaded] = useState()
  const [invalidPriceClasses, setInvalidPriceClasses] = useState([])
  const [name, setName] = useState('')
  const [priceClasses, setPriceClasses] = useState([])
  const [priceTemplateType, setPriceTemplateType] = useState()
  const [selectedEvents, setSelectedEvents] = useState([])
  const [showSectionPriceError, setShowSectionPriceError] = useState(false)
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    if (priceTemplate) {
      const { allEvents, description = '', discountPercentage, name, priceClasses, selectedEvents } = priceTemplate

      setAllEvents(allEvents)
      setDescription(description)
      setDiscountPercentage(discountPercentage)
      setIsDataLoaded(true)
      setName(name)
      setPriceClasses(priceClasses)
      setSelectedEvents(selectedEvents)
    }
  }, [priceTemplate])

  useEffect(() => {
    const priceTemplateType = idx(route, _ => _.match.params.type)

    if (!priceTemplateType) return

    setPriceTemplateType(priceTemplateType)
  }, [match, route])

  useEffect(() => {
    if (priceTemplateType) {
      setIsDataLoaded(false)
      fetchNewPriceTemplate(priceTemplateType)
    }
  }, [priceTemplateType, fetchNewPriceTemplate])

  useEffect(() => {
    if (updateStatus) {
      setIsDataLoaded(true)
    }
  }, [updateStatus])

  useEffect(() => {
    if (updateError) {
      notification.error({ message: updateMessage })
      setIsDataLoaded(true)
    }
  }, [updateError, updateMessage])

  const getDefaultPriceClass = sections => {
    const defaultPriceSections = sections.map(section => {
      return {
        name: section,
        min_price: '',
        max_price: '',
        service_fee: {
          min_price: '',
          max_price: ''
        }
      }
    })

    return {
      name: '',
      sections: defaultPriceSections
    }
  }

  const addPriceClass = () => {
    const updatedPriceClasses = [...priceClasses]
    const defaultPriceClass = getDefaultPriceClass(priceTemplate.sections)
    const invalidPriceClasses = validatePriceSections(priceClasses, priceTemplate.sections)
    if (invalidPriceClasses.includes('')) {
      setInvalidPriceClasses(invalidPriceClasses)
      notification.error({ message: NOTIFICATION_MESSAGES.PRICE_CLASS_NAME_REQUIRED })
    } else if (invalidPriceClasses && invalidPriceClasses.length > 0) {
      notification.error({ message: `Invalid price range in price class ${invalidPriceClasses.join(', ')}` })
    } else {
      updatedPriceClasses.push({ ...defaultPriceClass })
      setPriceClasses(updatedPriceClasses)
      setInvalidPriceClasses([])
    }
  }

  const updateMaxPriceByClassAndSection = (priceClassIndex, sectionIndex, price) => {
    const updatedPriceClasses = [...priceClasses]
    const priceClass = updatedPriceClasses[priceClassIndex]
    const section = priceClass.sections[sectionIndex]
    section.max_price = parseFloat(price) > 0 ? parseFloat(price) : ''
    setPriceClasses(updatedPriceClasses)
    setShowSectionPriceError(false)
  }

  const updateMinPriceByClassAndSection = (priceClassIndex, sectionIndex, price) => {
    const updatedPriceClasses = [...priceClasses]
    const priceClass = updatedPriceClasses[priceClassIndex]
    const section = priceClass.sections[sectionIndex]
    section.min_price = parseFloat(price) > 0 ? parseFloat(price) : ''
    setPriceClasses(updatedPriceClasses)
    setShowSectionPriceError(false)
  }

  const handleClassSelection = (priceClassIndex, className) => {
    const updatedPriceClasses = [...priceClasses]
    updatedPriceClasses[priceClassIndex].name = className
    setPriceClasses(updatedPriceClasses)
  }

  const handleDiscountPercentageChange = event => {
    const value = parseInt(event.target.value)
    const discountPercentage = isNaN(value) ? 0 : value
    setDiscountPercentage(discountPercentage)
  }

  const updateSelectedEvents = events => {
    const supportedPriceClassNames = priceClasses.map(priceClass => priceClass.name)
    const updatedEvents = events.map(event => ({
      ...((supportedPriceClassNames.indexOf(event.priceClass) === -1)
        ? {
          ...event,
          highlightItem: true
        }
        : event
      )
    }))
    setSelectedEvents(updatedEvents)
  }

  const removePriceClass = (priceClassIndex) => {
    const clonedPriceClasses = [...priceClasses]
    clonedPriceClasses.splice(priceClassIndex, 1)
    const invalidPriceClasses = validatePriceSections(clonedPriceClasses, priceTemplate.sections)

    setPriceClasses(clonedPriceClasses)
    setInvalidPriceClasses(invalidPriceClasses)
  }

  const handleDescriptionChange = description => {
    setDescription(description || '')
  }

  const handleNameChange = event => {
    const priceTemplateName = event.target.value || ''
    setName(priceTemplateName)
  }

  const getPriceRange = sections => {
    return sections.reduce((priceRange, section) => {
      const { min_price: sectionMinPrice, max_price: sectionMaxPrice } = section
      if (+sectionMinPrice > 0) {
        priceRange.min = priceRange.min > 0
          ? (+sectionMinPrice < priceRange.min ? +sectionMinPrice : priceRange.min)
          : +sectionMinPrice
      }
      if (+sectionMaxPrice > priceRange.max) {
        priceRange.max = +sectionMaxPrice
      }
      return priceRange
    }, { min: 0, max: 0 })
  }

  const calculatePriceClassPriceRange = priceClasses => {
    return priceClasses
      .map(priceClass => ({
        ...priceClass,
        price_range: getPriceRange(priceClass.sections)
      }))
      .filter(priceClass => priceClass.name !== '')
  }

  const handleModalClose = () => {
    resetPriceTemplateActionStatus()
  }

  const addPriceTemplateButton = (
    <Button
      onClick={() => {
        resetPriceTemplateActionStatus()
        window.location.reload()
      }}
      type="primary"
    >
      Add Another
    </Button>
  )

  const goToListPriceTemplatesButton = (
    <Route key="listPriceTemplate" render={({ history }) => (
      <Button
        onClick={() => {
          resetPriceTemplateActionStatus()
          history.push(`/price_templates/${priceTemplateType}`)
        }}
        type="primary"
      >
        Go to Pricetemplate List
      </Button>
    )} />
  )

  const editPriceTemplate = (
    <Route key="listPriceTemplate" render={({ history }) => (

      <Button
        onClick={() => {
          resetPriceTemplateActionStatus()
          newPriceTemplateId && history.push(`/price_templates/${newPriceTemplateId}/edit`)
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const handleSubmit = () => {
    if (name.length === 0) {
      return notification.error({ message: NOTIFICATION_MESSAGES.TEMPLATE_NAME_REQUIRED })
    }
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }
    const message = (priceTemplateType === PRICE_TEMPLATE.FIXED)
      ? validatePriceTemplates(priceClasses, selectedEvents)
      : validateSectionDiscounts(discountPercentage)
    if (message) {
      notification.error({ message })
      return
    }

    const updatedPriceClasses = calculatePriceClassPriceRange([...priceClasses])

    const updatedPriceTemplate = {
      description,
      discount_percentage: discountPercentage,
      events: selectedEvents.map(event => event.id),
      name,
      price_classes: updatedPriceClasses,
      type: priceTemplateType
    }

    setIsDataLoaded(false)
    resetPriceTemplateActionStatus()
    addPriceTemplate(updatedPriceTemplate, accessToken)
  }

  const handleServiceFeeMaxPriceInput = (priceClassIndex, sectionIndex, price) => {
    const updatedPriceClasses = [...priceClasses]
    const priceClass = updatedPriceClasses[priceClassIndex]
    const section = priceClass.sections[sectionIndex]
    const serviceFee = section?.service_fee ?? { min_price: '', max_price: '' }
    serviceFee.max_price = parseFloat(price) > 0 ? parseFloat(price) : ''
    section.service_fee = serviceFee
    setPriceClasses(updatedPriceClasses)
    setShowSectionPriceError(false)
  }

  const handleServiceFeeMinPriceInput = (priceClassIndex, sectionIndex, price) => {
    const updatedPriceClasses = [...priceClasses]
    const priceClass = updatedPriceClasses[priceClassIndex]
    const section = priceClass.sections[sectionIndex]
    const serviceFee = section?.service_fee ?? { min_price: '', max_price: '' }
    serviceFee.min_price = parseFloat(price) > 0 ? parseFloat(price) : ''
    section.service_fee = serviceFee
    setPriceClasses(updatedPriceClasses)
    setShowSectionPriceError(false)
  }

  const priceSectionsStatus = priceTemplate && isPriceSectionsValid(priceClasses, priceTemplate.sections)
  const isFixedTemplate = priceTemplateType === PRICE_TEMPLATE.FIXED
  const addMoreButton = (priceClasses.length < PRICE_CLASSES.length && priceClasses.length > 0)
    ? <div onClick={ e => e.stopPropagation() }>
      <Button className={styles.button} onClick={addPriceClass}>Add More</Button>
    </div>
    : null
  const selectedClasses = priceClasses
    .map(priceClass => priceClass.name)
    .filter(name => name !== '')
  const priceClassList = priceClasses.map((priceClass, index) =>
    <PriceTemplateClass
      key={`${index}_${priceClass.name}`}
      availableClasses={priceTemplate.allPriceClasses}
      currentClass={priceClass}
      handleClassSelection={handleClassSelection}
      handleServiceFeeMaxPriceInput={handleServiceFeeMaxPriceInput}
      handleServiceFeeMinPriceInput={handleServiceFeeMinPriceInput}
      index={index}
      invalidPriceClasses={invalidPriceClasses}
      priceClassesCount={priceClasses.length}
      removePriceClass={removePriceClass}
      selectedClasses={selectedClasses}
      showSectionPriceError={showSectionPriceError}
      updateMaxPriceByClassAndSection={updateMaxPriceByClassAndSection}
      updateMinPriceByClassAndSection={updateMinPriceByClassAndSection}
    />
  )

  const priceTemplateClassLegends = <div className={styles.priceTemplateLegends}>
    <ul className={styles.priceTemplateLegendLabels}>
      {
        PRICE_CLASSES.map((priceClass, index) => <li key={index} className={priceClass}>
          <span className={styles[`priceClass${priceClass}`]} />
          {priceClass}
        </li>)
      }
    </ul>
  </div>

  const addNewPriceTemplateButton = <Button onClick={handleSubmit} type='primary'>
    Add
  </Button>
  const eventSelector = priceTemplateType && <Card className={styles.eventsSelector} title='Events Selector'>
    {
      priceSectionsStatus ? <><RecommendationsSelector
        allEvents={allEvents}
        confirmationMessage="This event will be also removed from the promo code using this price template. Are you sure you want to remove this Event?"
        displayField='title'
        promotedEvents={selectedEvents}
        resourceName='Events'
        setAllEvents={setAllEvents}
        setPromotedEvents={updateSelectedEvents}
        showConfirmation={true}
        showPriceClassLegend={true}
      />{
        priceTemplateClassLegends
      }
      </> : <>Add a price section to enable the event selector</>
    }
  </Card>

  return (
    <PageLayout extra={[addNewPriceTemplateButton]} title={PAGE_TITLE}>
      <div className={styles.priceTemplatesLayoutBackground}>
        {
          priceTemplateType && <PriceTemplateBasicInfo
            description={description}
            discountPercentage={discountPercentage}
            handleDescriptionChange={handleDescriptionChange}
            handleDiscountPercentageChange={handleDiscountPercentageChange}
            handleNameChange={handleNameChange}
            name={name}
            type={priceTemplateType}
          />
        }
      </div>
      {
        priceTemplateType && isFixedTemplate &&
          <Card extra={addMoreButton} title='Price Sections'>
            {
              priceClassList
            }
            {
              priceClassList && priceClassList.length === 0 && <Button className={styles.addButton} onClick={addPriceClass}>Add Price Section</Button>
            }
          </Card>
      }
      {
        eventSelector
      }
      <Spinner isLoading={!isDataLoaded} />
      {
        updateStatus &&
        <ResultModal
          actions={[
            editPriceTemplate,
            goToListPriceTemplatesButton,
            addPriceTemplateButton
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

const mapStateToProps = ({ priceTemplates }) => ({
  newPriceTemplateId: priceTemplates.newPriceTemplateId,
  priceTemplate: priceTemplates.priceTemplate,
  updateError: priceTemplates.error,
  updateMessage: priceTemplates.message,
  updateStatus: priceTemplates.success
})

const mapDispatchToProps = {
  addPriceTemplate,
  fetchNewPriceTemplate,
  resetPriceTemplateActionStatus
}

NewPriceTemplate.propTypes = {
  addPriceTemplate: PropTypes.func,
  fetchNewPriceTemplate: PropTypes.func,
  match: PropTypes.object.isRequired,
  newPriceTemplateId: PropTypes.string,
  priceTemplate: PropTypes.object,
  resetPriceTemplateActionStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  updateError: PropTypes.bool,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool
}

NewPriceTemplate.defaultProps = {
  match: {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPriceTemplate)
