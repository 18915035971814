/* eslint-disable camelcase */
import React from 'react'
import { Row } from 'antd'
import PropTypes from 'prop-types'

import { LogoUploader } from '~components'
import { Input, RichTextInput } from '~stories'

import styles from './SponsorBasicInfo.module.css'

const SponsorBasicInfo = ({
  handleDescriptionChange,
  handleLogoUpload,
  handleTitleChange,
  sponsor
}) => {
  const { description, icon_url, title } = sponsor
  return (
    <>
      <Input label={'Title'} onChange={handleTitleChange} value={title} />
      <RichTextInput label='Description' onChange={handleDescriptionChange} value={description}/>
      <Row className={styles.logoContainer}>
        <LogoUploader handleUpload={handleLogoUpload} logoUrl={icon_url} />
      </Row>
    </>
  )
}

SponsorBasicInfo.propTypes = {
  handleDescriptionChange: PropTypes.func.isRequired,
  handleLogoUpload: PropTypes.func,
  handleTitleChange: PropTypes.func.isRequired,
  sponsor: PropTypes.object.isRequired
}

SponsorBasicInfo.defaultProps = {
  sponsor: {}
}

export default SponsorBasicInfo
