import {
  ADD_SERIES_STATUS,
  FETCH_ALL_SERIES,
  FETCH_SERIES,
  INITIALIZE_SERIES,
  STORE_SERIES_TO_EDIT,
  SERIES_UPDATE_STATUS,
  STORE_EVENTS_BY_SERIES
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_SERIES_STATUS:
      return {
        ...state,
        addSeriesMessage: action.payload.message,
        addSeriesStatus: action.payload.success
      }
    case FETCH_ALL_SERIES:
      return {
        ...state,
        series: action.payload.series
      }
    case FETCH_SERIES:
      return {
        ...state,
        ...action.payload,
        seriesId: null,
        addSeriesStatus: action.payload.success
      }
    case INITIALIZE_SERIES:
      return {
        ...state,
        artists: action.payload.artists,
        categories: action.payload.categories,
        cities: action.payload.cities,
        eventsAvailableForSeries: action.payload.events,
        seriesId: action.payload.seriesId,
        venues: action.payload.venues
      }
    case STORE_SERIES_TO_EDIT:
      return {
        ...state,
        seriesToEdit: action.payload.series,
        cities: action.payload.cities,
        categories: action.payload.categories,
        artists: action.payload.artists,
        venues: action.payload.venues,
        eventsAssignedToSeries: action.payload.eventsAssignedToSeries,
        eventsAvailableForSeries: action.payload.eventsAvailableForSeries
      }
    case SERIES_UPDATE_STATUS:
      return {
        ...state,
        updateSeriesMessage: action.payload.message,
        updateSeriesStatus: action.payload.success
      }
    case STORE_EVENTS_BY_SERIES:
      return {
        ...state,
        eventsAssignedToSeries: action.payload.eventsAssignedToSeries
      }
    default:
      return state
  }
}
