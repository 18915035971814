import 'isomorphic-fetch'

import { eventsEndpoint } from '~apiConfig'
import { serializeEvent } from '~serializers'
import { getApiResponseInJSON, getIdToken } from '~utils'

import { FETCH_EVENTS, FETCH_EVENT_BY_ID, FILTER_EVENTS, REMOVE_EVENT_BY_ID, RESET_ACTION_STATUS, UPDATE_EVENT_BY_ID } from '../actionTypes'

export const fetchEventsList = params => async dispatch => {
  const { method, url } = eventsEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    body: JSON.stringify(params)
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { events: rows, eventsCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_EVENTS,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchEventById = eventObjectId => async dispatch => {
  const { method, url } = eventsEndpoint.byId(eventObjectId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  dispatch({
    type: FETCH_EVENT_BY_ID,
    payload: {
      ...apiResponse
    }
  })
}

export const removeEventById = (eventObjectId, accessToken) => async dispatch => {
  const { method, url } = eventsEndpoint.delete(eventObjectId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    }
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    }))
  const { message: deleteMessage, success: deleteStatus } = apiResponse

  dispatch({
    type: REMOVE_EVENT_BY_ID,
    payload: {
      deleteMessage,
      deleteStatus
    }
  })
}

export const updateEventById = (eventId, data, accessToken) => async dispatch => {
  const { method, url } = eventsEndpoint.update(eventId)
  const event = serializeEvent(data)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    body: JSON.stringify(event)
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    }))
  const { message, success } = apiResponse
  dispatch({
    type: UPDATE_EVENT_BY_ID,
    payload: { message, success }
  })
}

export const filterEvents = params => async dispatch => {
  const { method, url } = eventsEndpoint.filter
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  dispatch({
    type: FILTER_EVENTS,
    payload: {
      ...apiResponse
    }
  })
}

export const resetEventActionStatus = () => async dispatch => {
  dispatch({
    type: RESET_ACTION_STATUS,
    payload: {
      deleteMessage: '',
      deleteStatus: false,
      message: '',
      status: false
    }
  })
}
