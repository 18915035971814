import React, { useState, useEffect } from 'react'
import { Collapse } from 'antd'
import arrayMove from 'array-move'
import PropTypes from 'prop-types'
import { SortableContainer } from 'react-sortable-hoc'

const DragDropList = ({ activeKey, dataSource, handleUpdate, handlePanelChange }) => {
  const [list, setList] = useState([])

  useEffect(() => {
    if (!dataSource) return
    setList(dataSource)
  }, [dataSource])

  const onSortEnd = item => {
    const clonedList = [...list]
    const { newIndex, oldIndex } = item
    const updatedList = arrayMove(clonedList, oldIndex, newIndex)
    const arraySortIndexList = updatedList.map(({ key }) => +key)
    setList(updatedList)
    handleUpdate && handleUpdate(arraySortIndexList)
  }

  const SortableList = SortableContainer(({ list }) => (
    <Collapse accordion activeKey={activeKey} bordered={false} onChange={handlePanelChange}>
      {
        list
      }
    </Collapse>
  ))

  return (
    <SortableList
      list={list}
      onSortEnd={onSortEnd}
      pressDelay={300}
    />
  )
}

DragDropList.propTypes = {
  activeKey: PropTypes.number,
  dataSource: PropTypes.array.isRequired,
  handlePanelChange: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired
}

DragDropList.defaultProps = {
  dataSource: []
}

export default DragDropList
