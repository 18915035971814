import React, { useEffect } from 'react'

import { COGNITO_LOGIN_URL } from '~constants'

const Logout = () => {
  useEffect(() => {
    window.location.replace(COGNITO_LOGIN_URL)
  }, [])

  return <></>
}

export default Logout
