import React from 'react'
import { Button, notification } from 'antd'
import { Route } from 'react-router-dom'

import { ItemList } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout } from '~stories'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const PAGE_TITLE = 'Opt In Modals'

const getOptInNameWithLink = optInModal => <>{optInModal.venue_info?.name ? <a href={`/opt-in-modal/${optInModal._id}/edit`}>{String(optInModal.venue_info?.name).toUpperCase()}</a> : <></>}</>

const columnData = [
  {
    dataIndex: 'venue_info',
    render: (text, optInModal) => getOptInNameWithLink(optInModal),
    sorter: true,
    title: 'Venue Name',
    width: '30%',
    sortDirections: ['descend', 'ascend', 'descend']
  },
  {
    title: 'Formstack Id',
    width: '40%',
    dataIndex: 'formstack_id'
  },
  {
    title: 'Status',
    width: '40%',
    dataIndex: 'show_modal',
    render: (text, optInModal) => <>{optInModal.show_modal ? 'ACTIVE' : 'INACTIVE'}</>
  }
]

const addNewOptInModal = isContentEditor => {
  return (
    <Route render={({ history }) => (
      <Button
        key="1"
        onClick={() => {
          isContentEditor
            ? history.push('/opt-in-modal/new')
            : notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
        }}
        type="primary"
      >
        Add OptIn Modal
      </Button>
    )} />
  )
}

const OptInModalList = () => {
  const { userDetails: { isContentEditor } } = getCurrentUser()

  return (
    <PageLayout extra={[
      addNewOptInModal(isContentEditor)
    ]} title={PAGE_TITLE}>
      <div className="opt-in-modal-layout-background">
        <ItemList columns={columnData} resource='optInModal' />
      </div>
    </PageLayout>
  )
}

export default OptInModalList
