import 'isomorphic-fetch'

import { navigationEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import {
  FETCH_NAVIGATIONS,
  NAVIGATION_ADD_STATUS,
  NAVIGATION_UPDATE_STATUS
} from '../actionTypes'

export const fetchNavigationsList = params => async dispatch => {
  const { method, url } = navigationEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { navigations } = apiResponse
  dispatch({
    type: FETCH_NAVIGATIONS,
    payload: {
      navigations
    }
  })
}

export const addNavigation = (navigations, accessToken) => async dispatch => {
  const { method, url } = navigationEndpoint.add
  const idToken = getIdToken()
  const data = { navigations }
  const apiResponse = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${idToken}`,
      accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    })
    )
  const { success, message } = apiResponse
  dispatch({
    type: NAVIGATION_ADD_STATUS,
    payload: { message, success }
  })
}

export const updateNavigation = (navObjectId, nav, accessToken) => async dispatch => {
  const { method, url } = navigationEndpoint.update(navObjectId)
  const idToken = getIdToken()
  await fetch(url, {
    body: JSON.stringify(nav),
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    method: method
  })
    .then(async response => await getApiResponseInJSON(response))
    .then(response => {
      const { message, success } = response
      dispatch({
        type: NAVIGATION_UPDATE_STATUS,
        payload: { message, success }
      })
    })
    .catch((error) => {
      console.log(error)
    })
}

export const resetUpdateNavigationStatus = () => async dispatch => {
  dispatch({
    type: NAVIGATION_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}
