import React, { useState } from 'react'

import PropTypes from 'prop-types'

import Input from '../Input/Input'
import Label from '../Label/Label'
import styles from './TagsInput.module.css'

const ENTER_KEY_KEYCODE = 13
const VALIDATION_REG_EXP = {
  plainTextRegEx: /^[a-z A-Z0-9_-]*$/gim,
  hashTagRegEx: /^[a-zA-Z0-9_#-]*$/gim
}

const VALIDATION_ERROR = {
  UNIQUE: 'Tags must be unique',
  ACCEPTED_INPUT: 'Tags must only contain letters, numbers, underscores, spaces or hyphens.'
}

const TagsInput = ({
  allExistingTags,
  isHashtagInput,
  isLabelInline,
  label,
  tags: tagsFromProps,
  updateTags
}) => {
  const [tags, setTags] = useState(tagsFromProps || [])
  const [tagInputValue, setTagInputValue] = useState('')
  const [filteredExistingTags, setFilteredExistingTags] = useState([])
  const [error, setError] = useState(null)

  const validateTag = (value) => {
    setError(null)
    if (!value || value === '') return false

    const validatorRegExp = isHashtagInput ? VALIDATION_REG_EXP.hashTagRegEx : VALIDATION_REG_EXP.plainTextRegEx
    if (!(new RegExp(validatorRegExp).test(value))) {
      setError(VALIDATION_ERROR.ACCEPTED_INPUT)
      return false
    } else if (tags.find(tag => tag === value)) {
      setError(VALIDATION_ERROR.UNIQUE)
      return false
    }
    return true
  }

  const onChange = ({ target: { value: tagInputValue } }) => {
    const isValidTag = validateTag(tagInputValue)
    const tagFilter = new RegExp(`^${tagInputValue}`, 'i')
    const filteredExistingTags = isValidTag ? allExistingTags.filter(tag => tagFilter.test(tag) && !tags.find(t => t === tag)) : []
    setFilteredExistingTags(filteredExistingTags)
    setTagInputValue(tagInputValue)
  }

  const onKeyDown = (e) => {
    if (e.keyCode !== ENTER_KEY_KEYCODE) return false

    e.preventDefault()
    e.stopPropagation()

    return addTag(tagInputValue)
  }

  const addTag = (value) => {
    if (!validateTag(value)) return false
    if (isHashtagInput && value[0] !== '#') {
      value = `#${value}`
    }

    const updatedTags = [
      ...tags,
      value
    ].sort((a, b) => b - a)

    setTags(updatedTags)
    setTagInputValue('')
    setFilteredExistingTags([])
    updateTags(updatedTags)
    return true
  }

  const removeTag = (tagToRemove) => {
    const tagFilter = new RegExp(`^${tagInputValue}`, 'i')
    const isValidTag = validateTag(tagInputValue)
    const updatedTags = tags.filter(tag => tag !== tagToRemove)
    const filteredExistingTags = isValidTag
      ? allExistingTags.filter(tag => tagFilter.test(tag) && !tags.find(t => t === tag))
      : []

    setTags(updatedTags)
    setFilteredExistingTags(filteredExistingTags)
    updateTags(updatedTags)
  }

  const tagElements = tags.map(tag => (
    <li key={tag} onClick={() => removeTag(tag)}>
      {tag}
      <span className={styles.delete}>x</span>
    </li>
  ))

  const tagLookupElements = filteredExistingTags.map(tag => (
    <li key={tag} onClick={() => addTag(tag)}>
      {tag}
    </li>
  ))

  const tagsErrorElement = error ? <div className={styles['tags-error help-block']}>{error}</div> : ''

  return (
    <div className={styles['tag-input']}>
      <Label isInline={isLabelInline} value={label}/>
      <ul className={styles['tags-list']}>{tagElements}</ul>
      <Input onChange={onChange} onKeyDown={onKeyDown} value={tagInputValue} />
      {tagsErrorElement}
      <ul className={styles['tags-lookup']}>{tagLookupElements}</ul>
    </div>
  )
}

TagsInput.defaultProps = {
  allExistingTags: [],
  isHashtagInput: false,
  isLabelInline: false,
  tags: []
}

TagsInput.propTypes = {
  allExistingTags: PropTypes.array,
  isHashtagInput: PropTypes.bool,
  isLabelInline: PropTypes.bool,
  label: PropTypes.string,
  tags: PropTypes.array,
  updateTags: PropTypes.func
}

export default TagsInput
