import {
  ADD_NEW_FLAG,
  FETCH_FLAGS,
  FLAG_UPDATE_STATUS,
  STORE_FLAG_TO_EDIT
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_FLAG:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_FLAGS:
      return {
        ...state,
        ...action.payload
      }
    case STORE_FLAG_TO_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case FLAG_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
