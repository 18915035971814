import React from 'react'
import { CopyOutlined } from '@ant-design/icons'
import { Button, notification } from 'antd'
import { Route } from 'react-router-dom'

import { PromoCodesList } from '~components'
import { getCurrentUser } from '~context'

import './PromoCodesList.modules.css'

const DOMAIN = 'msg'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const getPromoCodeNameWithLink = promocode => <a href={`/promo_codes/${promocode._id}/edit`}>{String(promocode.title).toUpperCase()}</a>
const getPromoCodeDescriptionAsHTML = promocode => <div dangerouslySetInnerHTML={{ __html: promocode.description }}/>
const getPromoCodeCopyLink = id => <Button href={`/promo_codes/${id}/copy`} icon={<CopyOutlined />} type='link'/>

const columnData = [
  {
    render: ({
      _id: id
    }) => getPromoCodeCopyLink(id),
    sorter: false,
    title: '',
    width: '5%'
  },
  {
    dataIndex: 'title',
    render: (text, promocode) => getPromoCodeNameWithLink(promocode),
    sorter: true,
    title: 'Title',
    width: '10%',
    sortDirections: ['descend', 'ascend', 'descend']
  },
  {
    render: promocode => getPromoCodeDescriptionAsHTML(promocode),
    title: 'Description',
    width: '20%'
  },
  {
    render: promocode => promocode.events ? promocode.events.length : '-',
    title: 'Events Count',
    width: '10%'
  },
  {
    render: promocode => promocode.series.length > 0 ? promocode.series[0].name : '-',
    title: 'Series',
    width: '20%'
  },
  {
    render: promocode => promocode.expiryDateTime ? promocode.expiryDateTime : '',
    title: 'Expiry Date Time',
    width: '20%'
  },
  {
    dataIndex: 'status',
    title: 'Status',
    width: '10%'
  }
]

const addNewPromoCode = isContentEditor => {
  return (
    <Route render={({ history }) => (
      <Button
        key="1"
        onClick={() => {
          isContentEditor
            ? history.push(`/promo_codes/${DOMAIN}/new`)
            : notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
        }}
        type="primary"
      >
        Add promo code
      </Button>
    )} />
  )
}

const MsgPromoCodesList = () => {
  const { userDetails: { isContentEditor } } = getCurrentUser()

  return (
    <PromoCodesList
      columnData={columnData}
      domain={DOMAIN}
      extraActions={[addNewPromoCode(isContentEditor)]}
    />
  )
}

export default MsgPromoCodesList
