import React from 'react'
import { Button, notification } from 'antd'
import { Route } from 'react-router-dom'

import { ItemList } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout } from '~stories'

import './Venues.modules.css'

const PAGE_TITLE = 'Venues'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const getVenueNameWithLink = (venue) => <a href={`/venues/${venue._id}/edit`}>{String(venue.name).toUpperCase()}</a>
const getVenueAddress = (venue) => <div dangerouslySetInnerHTML={{ __html: venue.address }} />

const columnData = [{
  dataIndex: 'name',
  render: (text, venue) => getVenueNameWithLink(venue),
  sorter: true,
  title: 'Name',
  width: '30%'
},
{
  dataIndex: 'url',
  title: 'Url',
  width: '40%'
},
{
  dataIndex: 'address',
  render: (text, venue) => getVenueAddress(venue),
  title: 'Address'
}]

const addNewVenue = isContentEditor => {
  return (
    <Route render={({ history }) => (
      <Button
        key="1"
        onClick={() => {
          isContentEditor
            ? history.push('/venues/partner/new')
            : notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
        }}
        type="primary"
      >
        Add Partner Venue
      </Button>
    )} />
  )
}

const VenuesList = () => {
  const { userDetails: { isContentEditor } } = getCurrentUser()

  return (
    <PageLayout extra={[addNewVenue(isContentEditor)]} title={PAGE_TITLE}
    >
      <div className="venues-layout-background">
        <ItemList columns={columnData} resource='venues' />
      </div>
    </PageLayout>
  )
}

export default VenuesList
