import 'isomorphic-fetch'

import { cfInvalidationEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

export const invalidateCFCache = async (app, path) => {
  const { method, url } = cfInvalidationEndpoint.invalidate
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    body: JSON.stringify({
      app,
      path
    }),
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))

  return apiResponse
}
