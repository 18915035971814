import React from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import Label from '../Label/Label'

const { RangePicker } = DatePicker

const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD h:mm A'
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'

const CustomRangePicker = ({
  className,
  format,
  isInline,
  label,
  onChange,
  showTime,
  value
}) => {
  const getDatePickerFormat = () => {
    return format || (showTime ? DEFAULT_DATE_TIME_FORMAT : DEFAULT_DATE_FORMAT)
  }

  const renderTime = format => {
    return showTime
      ? {
        format: format,
        minuteStep: 5,
        use12Hours: true
      }
      : false
  }

  return (
    <>
      <Label
        isInline={isInline}
        value={label}
      />
      <RangePicker
        allowEmpty={[true, true]}
        className={className}
        format={getDatePickerFormat()}
        onChange={onChange}
        showTime={renderTime()}
        value={value}
      />
    </>
  )
}

CustomRangePicker.propTypes = {
  className: PropTypes.string,
  format: PropTypes.string,
  isInline: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showTime: PropTypes.bool,
  value: PropTypes.array
}

CustomRangePicker.defaultProps = {
  className: '',
  format: 'MM-DD-YYYY',
  isInline: false,
  value: [moment(), moment().add(1, 'day')]
}

export default CustomRangePicker
