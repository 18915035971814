import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

/* eslint import/namespace: ['error', { allowComputed: true }] */
import * as listActions from '~actions'
import { Table } from '~stories'

const capitalize = ([firstLetter, ...restOfWord]) =>
  firstLetter.toUpperCase() + restOfWord.join('')

const ItemList = ({
  columns,
  fetchItems,
  filterKey,
  filterOptions,
  filterValue,
  params,
  rowKey,
  rows,
  rowsCount,
  showSecondaryFilter,
  secondaryFilter
}) => {
  return (
    <>
      <Table
        columns={columns}
        fetchData={fetchItems}
        filterKey={filterKey}
        filterOptions={filterOptions}
        filterValue={filterValue}
        params={params}
        results={rows}
        rowKey={rowKey}
        secondaryFilter={secondaryFilter}
        showSecondaryFilter={showSecondaryFilter}
        totalCount={rowsCount}
      />
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const resource = ownProps.resource
  const { params, rows, rowsCount } = state[`${resource}`]
  return {
    params,
    rows,
    rowsCount
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchItems: (props) => {
      let updatedProps = { ...props }
      if (ownProps.defaultFilter) {
        updatedProps = {
          ...props,
          ...ownProps.defaultFilter
        }
      }
      return dispatch(listActions[`fetch${capitalize(ownProps.resource)}List`](updatedProps))
    }
  }
}

ItemList.propTypes = {
  columns: PropTypes.array.isRequired,
  fetchItems: PropTypes.func.isRequired,
  filterKey: PropTypes.string,
  filterOptions: PropTypes.array,
  filterValue: PropTypes.string,
  params: PropTypes.object,
  rowKey: PropTypes.string,
  rows: PropTypes.array,
  rowsCount: PropTypes.number,
  secondaryFilter: PropTypes.object,
  showSecondaryFilter: PropTypes.bool
}

ItemList.defaultProps = {
  filterValue: '',
  rows: [],
  secondaryFilter: null,
  showSecondaryFilter: false
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ItemList)
)
