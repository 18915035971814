import {
  ADD_PRICE_TEMPLATE,
  FETCH_ALL_PRICE_TEMPLATES,
  FETCH_PRICE_TEMPLATE_TO_COPY,
  FETCH_PRICE_TEMPLATE_TO_EDIT,
  FETCH_PRICE_TEMPLATES,
  FETCH_NEW_PRICE_TEMPLATE,
  UPDATE_PRICE_TEMPLATE,
  STORE_PRICE_TEMPLATE_EVENTS
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PRICE_TEMPLATE:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_ALL_PRICE_TEMPLATES:
      return {
        ...state,
        priceTemplates: action.payload.priceTemplates
      }
    case FETCH_NEW_PRICE_TEMPLATE:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_PRICE_TEMPLATE_TO_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_PRICE_TEMPLATE_TO_COPY:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_PRICE_TEMPLATES:
      return {
        ...state,
        ...action.payload
      }
    case STORE_PRICE_TEMPLATE_EVENTS:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_PRICE_TEMPLATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
