import React from 'react'
import { Col, Row } from 'antd'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import { Checkbox, DatePicker, Input, Label, MultiSelectDropdown, RichTextInput, Switch, TagInput, TimePicker } from '~stories'

import styles from './EventBasicInfo.module.css'

const FORMATTER = {
  DATE: 'MM/DD/YYYY',
  TIME: 'HH:mm:ss'
}
const DEFAULT_EVENT_PULL_DOWN_HOURS = 3

const EventBasicInfo = ({
  event,
  handleDoorOpenTimeUpdate,
  handleEntranceNotesUpdate,
  handleEntranceScenatioUpdate,
  handleEventEndDateUpdate,
  handleEventPullDownTimeUpdate,
  handleNameUpdate,
  handlePostponedStatusUpdate,
  handlePostponedTextUpdate,
  handleSpanMultipleDaysUpdate,
  handleSpotifyPlaylistUrlUpdate,
  handleStartTimeUpdate,
  handleStatusTextUpdate,
  handleSubtitleUpdate,
  handleUseSponsorLogoForVenueUpdate,
  updateSocialTags,
  updateTags
}) => {
  const {
    allActiveTags,
    allEntranceScenarios,
    allSocialTags,
    dateTime,
    doorOpenTime,
    endDate,
    hostUrl,
    htmlDescription,
    id,
    msgEdpUrl,
    name,
    postponed,
    postponedText = '',
    pullDownFromSystem,
    socialTags,
    spanMultipleDays,
    spotifyPlaylistUrl = '',
    startDate,
    startTime,
    status,
    statusText = '',
    subTitle,
    tags = [],
    timezone,
    useSponsorLogoForVenue
  } = event
  const entranceInfo = event.entranceInfo ?? { notes: '', scenario: '' }
  const [series] = event.series
  const { id: seriesId } = series
  const eventStartDate = moment.utc(startDate)
  const eventStartTime = startTime !== 'TBA' && moment(startTime, FORMATTER.TIME)
  const eventDoorOpenTime = doorOpenTime !== 'TBA' && moment(doorOpenTime, FORMATTER.TIME)
  const eventPullDownTime = pullDownFromSystem ? moment.tz(pullDownFromSystem, timezone) : moment.utc(dateTime).tz(event.timezone).add(DEFAULT_EVENT_PULL_DOWN_HOURS, 'hours')
  const eventEndDate = endDate ? moment.utc(endDate) : eventStartDate
  return (
    <>
      <Input label={'Name'} onChange={handleNameUpdate} value={name} />
      <Input label={'SubTitle'} onChange={handleSubtitleUpdate} value={subTitle} />
      <TagInput allExistingTags={allActiveTags} label={'Tags'} tags={tags} updateTags={updateTags}/>
      <TagInput allExistingTags={allSocialTags} isHashtagInput={true} label={'Social Tags'} tags={socialTags} updateTags={updateSocialTags} />
      <RichTextInput isReadOnly={true} label='HTML Description' value={htmlDescription} />
      <MultiSelectDropdown
        customStyle={{ width: 200 }}
        displayField="id"
        dropdownOptions={allEntranceScenarios}
        handleChange={(event) => handleEntranceScenatioUpdate(event)}
        label='Entrance Scenario:'
        selectedItems={[entranceInfo?.scenario]}
        valueField="id"
      />
      <RichTextInput label='Entrance Notes' onChange={(event) => handleEntranceNotesUpdate(event)} value={entranceInfo?.notes}/>
      <Row>
        {series && <Col span={12}>
          <Label value='Series Id' />
          <a href={`/series/${seriesId}/edit`} rel='noreferrer' target='_blank'>{seriesId}</a>
        </Col>}
        <Col span={12}>
          <Input disabled={true} label={'Event Id'} value={id}/>
        </Col>
      </Row>
      <Input disabled={true} label={'Ticket Master URL'} value={hostUrl}/>
      <Input disabled={true} label={'MSG EDP URL'} value={msgEdpUrl}/>
      <Input label={'Spotify Playlist URL'} onChange={handleSpotifyPlaylistUrlUpdate} value={spotifyPlaylistUrl} />
      <Row>
        <Col span={8}>
          <DatePicker
            className={styles['component-width-90']}
            disabled={true}
            label="Event Date"
            value={eventStartDate}
          />
        </Col>
        <Col span={8}>
          <TimePicker
            className={styles['component-width-90']}
            disabled={!spanMultipleDays}
            format='h:mm A'
            label='Start Time'
            onChange={handleStartTimeUpdate}
            value={eventStartTime}
          />
        </Col>
        <Col span={8}>
          <TimePicker
            className={styles['component-width-90']}
            format='h:mm A'
            label='Door Open Time'
            onChange={handleDoorOpenTimeUpdate}
            value={eventDoorOpenTime}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Checkbox
            checked={useSponsorLogoForVenue}
            label="Use Sponsor Logo:"
            onChange={handleUseSponsorLogoForVenueUpdate}
          />
        </Col>
        <Col span={8}>
          <DatePicker
            className={styles.pullDownTimePickerWidth}
            format={'MM/DD/YYYY h:mm A'}
            label="Pull Down Time"
            onChange={handleEventPullDownTimeUpdate}
            showTime={true}
            value={eventPullDownTime}
          />
        </Col>
        <Col span={8}>
          <Switch
            checked={postponed}
            label="Postponed"
            onChange={handlePostponedStatusUpdate}
          />
        </Col>
      </Row>
      {postponed && <Input label={'PostponedText'} onChange={handlePostponedTextUpdate} value={postponedText}/>}
      <Input label={`Status Text (${status})`} onChange={handleStatusTextUpdate} value={statusText}/>
      <Row>
        <Col span={8}>
          <Checkbox
            checked={spanMultipleDays}
            label="Span Multiple Days:"
            onChange={handleSpanMultipleDaysUpdate}
          />
        </Col>
        { spanMultipleDays && <Col span={8}>
          <DatePicker
            className={styles['component-width-90']}
            format={FORMATTER.DATE}
            label="Event End Date:"
            onChange={handleEventEndDateUpdate}
            value={eventEndDate}
          />
        </Col>}
      </Row>
    </>
  )
}

EventBasicInfo.propTypes = {
  event: PropTypes.object.isRequired,
  handleDoorOpenTimeUpdate: PropTypes.func.isRequired,
  handleEntranceNotesUpdate: PropTypes.func.isRequired,
  handleEntranceScenatioUpdate: PropTypes.func.isRequired,
  handleEventEndDateUpdate: PropTypes.func.isRequired,
  handleEventPullDownTimeUpdate: PropTypes.func.isRequired,
  handleNameUpdate: PropTypes.func.isRequired,
  handlePostponedStatusUpdate: PropTypes.func.isRequired,
  handlePostponedTextUpdate: PropTypes.func.isRequired,
  handleSpanMultipleDaysUpdate: PropTypes.func.isRequired,
  handleSpotifyPlaylistUrlUpdate: PropTypes.func.isRequired,
  handleStartTimeUpdate: PropTypes.func.isRequired,
  handleStatusTextUpdate: PropTypes.func.isRequired,
  handleSubtitleUpdate: PropTypes.func.isRequired,
  handleUseSponsorLogoForVenueUpdate: PropTypes.func.isRequired,
  updateSocialTags: PropTypes.func.isRequired,
  updateTags: PropTypes.func.isRequired
}

EventBasicInfo.defaultProps = {
  event: {},
  postponed: false
}

export default EventBasicInfo
