import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Modal, Collapse, Typography, notification, Row, Col } from 'antd'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { fetchNavigationsList, addNavigation, resetUpdateNavigationStatus } from '~actions'
import { TopNavInfo, PrimaryNavInfo, ResultModal } from '~components'
import { VENUES_LIST } from '~constants'
import { getCurrentUser } from '~context'
import { PageLayout, Input } from '~stories'

import 'antd/dist/antd.css'
import styles from './NewNavigation.module.css'

const { Text } = Typography
const { Panel } = Collapse

const PAGE_TITLE = 'Navigations (MSG)'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const NewNavigations = ({ addNavigation, updateMessage, updateStatus, resetUpdateNavigationStatus }) => {
  const [topNavList, setTopNavList] = useState()
  const [appName, setAppName] = useState('')
  const [topNavActiveKey, setTopNavActiveKey] = useState(0)
  const [primaryNavActiveKey, setPrimaryNavActiveKey] = useState(0)

  const [primaryNavList, setPrimaryNavList] = useState([])

  const [showTopNavDeleteModal, setShowTopNavDeleteModal] = useState(false)
  const [currentTopNavIndex, setCurrentTopNavIndex] = useState(-1)

  const [showPrimaryNavDeleteModal, setShowPrimaryNavDeleteModal] = useState(false)
  const [currentPrimaryNavIndex, setCurrentPrimaryNavIndex] = useState(-1)

  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  const handleAppNameUpdate = (app) => {
    setAppName(app)
  }

  const handleUpdate = () => {
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }
    addNavigation({
      app: appName,
      top_level_nav: [topNavList],
      primary_nav: primaryNavList
    }, accessToken)
  }

  const addNewTopNav = () => {
    const topNavs = { ...topNavList }
    const venuesList = topNavList && topNavList.venues ? [...topNavs.venues] : []
    venuesList.push({
      venue_name: '',
      target: '_self',
      alt_img: '',
      img_url: '',
      url: ''
    })
    setTopNavList({
      ...topNavs,
      venues: venuesList
    })
  }

  const addNewPrimaryNav = () => {
    const primaryNavs = [...primaryNavList]
    primaryNavs.push({
      alt_img: '',
      logo_url: '',
      primary_nav_links: [{
        label: '',
        secondary_nav_links: [{
          label: '',
          target: '_self',
          third_level_nav_links: {},
          url: ''
        }],
        target: '_self',
        url: ''
      }],
      venue_id: ''
    })
    setPrimaryNavList(primaryNavs)
  }

  const updateNavigations = () => {
    return (
      <Button
        onClick={handleUpdate}
        type='primary'
      >
        Update
      </Button>
    )
  }

  const handleImageUrlUpdate = (imageUrl, index) => {
    const clonedNavList = { ...topNavList }
    const venuesList = [...clonedNavList.venues]
    const updatedNavList = [
      ...venuesList.slice(0, index),
      {
        ...venuesList[index],
        img_url: imageUrl,
        alt_img: imageUrl?.replace(/^.*(\\|\/|:)/, '').replace(/\.[^/.]+$/, '')
      },
      ...venuesList.slice(index + 1)
    ]
    setTopNavList({
      ...clonedNavList,
      venues: updatedNavList
    })
  }

  const handleLabelUpdate = (label) => {
    const clonedNavList = { ...topNavList }
    setTopNavList({
      ...clonedNavList,
      label
    })
  }

  const handleLinkTargetUpdate = (target, index) => {
    const clonedNavList = { ...topNavList }
    const venuesList = [...clonedNavList.venues]

    const updatedNavList = [
      ...venuesList.slice(0, index),
      {
        ...venuesList[index],
        target
      },
      ...venuesList.slice(index + 1)
    ]
    setTopNavList({
      ...clonedNavList,
      venues: updatedNavList
    })
  }

  const handleLinkUrlUpdate = (url, index) => {
    const clonedNavList = { ...topNavList }
    const venuesList = [...clonedNavList.venues]

    const updatedNavList = [
      ...venuesList.slice(0, index),
      {
        ...venuesList[index],
        url
      },
      ...venuesList.slice(index + 1)
    ]
    setTopNavList({
      ...clonedNavList,
      venues: updatedNavList
    })
  }

  const handleLogoUrlUpdate = (url, index) => {
    const clonedNavList = [...primaryNavList]
    const updatedNavList = [
      ...clonedNavList.slice(0, index),
      {
        ...clonedNavList[index],
        logo_url: url,
        alt_img: url?.replace(/^.*(\\|\/|:)/, '').replace(/\.[^/.]+$/, '')
      },
      ...clonedNavList.slice(index + 1)
    ]
    setPrimaryNavList(updatedNavList)
  }

  const handleVenueUpdate = (venueName, index) => {
    const clonedNavList = { ...topNavList }
    const venuesList = [...clonedNavList.venues]

    const updatedNavList = [
      ...venuesList.slice(0, index),
      {
        ...venuesList[index],
        venue_name: venueName
      },
      ...venuesList.slice(index + 1)
    ]
    setTopNavList({
      ...clonedNavList,
      venues: updatedNavList
    })
  }

  const handlePrimaryNavVenueUpdate = (venueId, index) => {
    const clonedNavList = [...primaryNavList]
    const updatedNavList = [
      ...clonedNavList.slice(0, index),
      {
        ...clonedNavList[index],
        venue_id: venueId
      },
      ...clonedNavList.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const handlePrimaryLinkTargetUpdate = (target, primaryNavLinkIndex, index) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        target
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]
    setPrimaryNavList(updatedNavList)
  }

  const handlePrimaryLinkUrlUpdate = (url, primaryNavLinkIndex, index) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        url
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const addNewPrimaryLink = (primaryNavIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[primaryNavIndex].primary_nav_links]

    updtedPrimaryLinks.push({
      label: '',
      secondary_nav_links: [{
        label: '',
        target: '_self',
        third_level_nav_links: {},
        url: ''
      }],
      target: '_self',
      url: ''
    })

    const updatedNavList = [
      ...primaryNavs.slice(0, primaryNavIndex),
      {
        ...primaryNavs[primaryNavIndex],
        primary_nav_links: updtedPrimaryLinks
      },
      ...primaryNavs.slice(primaryNavIndex + 1)
    ]
    setPrimaryNavList(updatedNavList)
  }

  const addNewSecondaryLink = (index, primaryNavLinkIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]

    updtedSecondaryLinks.push({
      label: '',
      target: '_self',
      third_level_nav_links: {},
      url: ''
    })

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: [
          ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
          {
            ...updtedPrimaryLinks[primaryNavLinkIndex],
            secondary_nav_links: updtedSecondaryLinks
          },
          ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
        ]
      },
      ...primaryNavs.slice(index + 1)
    ]
    setPrimaryNavList(updatedNavList)
  }

  const addNewTertiaryColumnList = (index, primaryNavLinkIndex, secondaryNavIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedTerniaryLinks = { ...updtedSecondaryLinks[secondaryNavIndex].third_level_nav_links }
    const updtedTerniaryLinksColumns = updtedTerniaryLinks?.columnList ? [...updtedTerniaryLinks?.columnList] : []

    updtedTerniaryLinksColumns.push([])

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: [
          ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
          {
            ...updtedPrimaryLinks[primaryNavLinkIndex],
            secondary_nav_links: [
              ...updtedSecondaryLinks.slice(0, secondaryNavIndex),
              {
                ...updtedSecondaryLinks[secondaryNavIndex],
                third_level_nav_links: {
                  ...updtedTerniaryLinks,
                  columns: updtedTerniaryLinksColumns,
                  columnList: updtedTerniaryLinksColumns
                }
              },
              ...updtedSecondaryLinks.slice(secondaryNavIndex + 1)
            ]
          },
          ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
        ]
      },
      ...primaryNavs.slice(index + 1)
    ]
    setPrimaryNavList(updatedNavList)
  }

  const addNewTertiaryColumn = (index, primaryNavLinkIndex, secondaryNavIndex, columnIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedTerniaryLinks = { ...updtedSecondaryLinks[secondaryNavIndex].third_level_nav_links }
    const updtedTerniaryLinksColumns = updtedTerniaryLinks?.columnList ? [...updtedTerniaryLinks?.columnList] : []

    const columns = updtedTerniaryLinksColumns[columnIndex]
    columns.push({
      label: '',
      target: '_self',
      url: ''
    })

    const updatedColumnList = [
      ...updtedTerniaryLinksColumns.slice(0, columnIndex),
      columns,
      ...updtedTerniaryLinksColumns.slice(columnIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: [
          ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
          {
            ...updtedPrimaryLinks[primaryNavLinkIndex],
            secondary_nav_links: [
              ...updtedSecondaryLinks.slice(0, secondaryNavIndex),
              {
                ...updtedSecondaryLinks[secondaryNavIndex],
                third_level_nav_links: {
                  ...updtedTerniaryLinks,
                  columnList: updatedColumnList,
                  columns: updatedColumnList
                }
              },
              ...updtedSecondaryLinks.slice(secondaryNavIndex + 1)
            ]
          },
          ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
        ]
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const deleteNewTertiaryColumnList = (index, primaryNavLinkIndex, secondaryNavIndex, tertiaryColumnIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedTerniaryLinks = { ...updtedSecondaryLinks[secondaryNavIndex].third_level_nav_links }
    const updtedTerniaryLinksColumns = updtedTerniaryLinks?.columnList ? [...updtedTerniaryLinks?.columnList] : []

    if (tertiaryColumnIndex !== -1) {
      updtedTerniaryLinksColumns.splice(tertiaryColumnIndex, 1)
    }

    const newThirdLevelNavLinks = {
      ...updtedTerniaryLinksColumns,
      columnList: updtedTerniaryLinksColumns,
      columns: updtedTerniaryLinksColumns
    }
    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavIndex),
      {
        ...updtedSecondaryLinks[secondaryNavIndex],
        third_level_nav_links: newThirdLevelNavLinks
      },
      ...updtedSecondaryLinks.slice(secondaryNavIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: [
          ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
          {
            ...updtedPrimaryLinks[primaryNavLinkIndex],
            secondary_nav_links: updatedSecondaryLinks
          },
          ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
        ]
      },
      ...primaryNavs.slice(index + 1)
    ]
    setPrimaryNavList(updatedNavList)
  }

  const deleteNewTertiaryColumn = (index, primaryNavLinkIndex, secondaryNavIndex, tertiaryColumnIndex, columnIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedTerniaryLinks = { ...updtedSecondaryLinks[secondaryNavIndex].third_level_nav_links }
    const updtedTerniaryLinksColumns = updtedTerniaryLinks?.columnList ? [...updtedTerniaryLinks?.columnList] : []
    const columns = updtedTerniaryLinksColumns[columnIndex]
    if (tertiaryColumnIndex !== -1) {
      columns.splice(tertiaryColumnIndex, 1)
    }

    const updatedColumnList = [
      ...updtedTerniaryLinksColumns.slice(0, columnIndex),
      columns,
      ...updtedTerniaryLinksColumns.slice(columnIndex + 1)
    ]

    const newThirdLevelNavLinks = {
      ...updtedTerniaryLinks,
      columnsList: updatedColumnList,
      columns: updatedColumnList
    }
    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavIndex),
      {
        ...updtedSecondaryLinks[secondaryNavIndex],
        third_level_nav_links: newThirdLevelNavLinks
      },
      ...updtedSecondaryLinks.slice(secondaryNavIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: [
          ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
          {
            ...updtedPrimaryLinks[primaryNavLinkIndex],
            secondary_nav_links: updatedSecondaryLinks
          },
          ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
        ]
      },
      ...primaryNavs.slice(index + 1)
    ]
    setPrimaryNavList(updatedNavList)
  }

  const addNewTertiaryLink = (index, primaryNavLinkIndex, secondaryNavIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: [
          ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
          {
            ...updtedPrimaryLinks[primaryNavLinkIndex],
            secondary_nav_links: [
              ...updtedSecondaryLinks.slice(0, secondaryNavIndex),
              {
                ...updtedSecondaryLinks[secondaryNavIndex],
                third_level_nav_links: {
                  columnList: [{
                    label: '',
                    target: '_self',
                    url: ''
                  }],
                  columns: [{
                    label: '',
                    target: '_self',
                    url: ''
                  }],
                  promo_item: null
                }
              },
              ...updtedSecondaryLinks.slice(secondaryNavIndex + 1)
            ]
          },
          ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
        ]
      },
      ...primaryNavs.slice(index + 1)
    ]
    setPrimaryNavList(updatedNavList)
  }

  const handlePrimaryLinkLabelUpdate = (label, primaryNavLinkIndex, index) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        label
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }
  const handleSecondaryLinkLabelUpdate = (label, primaryNavLinkIndex, secondaryNavLinkIndex, index) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]

    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        label
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }
  const handleSecondaryLinkUrlUpdate = (url, primaryNavLinkIndex, secondaryNavLinkIndex, index) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]

    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        url
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }
  const handleThirdLinkColumnLabelUpdate = (label, primaryNavLinkIndex, secondaryNavLinkIndex, columnIndex, innerColumnIndex, index) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedThirdLevelNavLinks = { ...updtedSecondaryLinks[secondaryNavLinkIndex].third_level_nav_links }
    const { columnList } = updtedThirdLevelNavLinks
    const column = columnList[columnIndex]

    const updatedColumns = [
      ...column.slice(0, innerColumnIndex),
      {
        ...column[innerColumnIndex],
        label
      },
      ...column.slice(innerColumnIndex + 1)
    ]

    const updatedColumnList = [
      ...columnList.slice(0, columnIndex),
      updatedColumns,
      ...columnList.slice(columnIndex + 1)
    ]

    const newThirdLevelNavLinks = {
      ...updtedThirdLevelNavLinks,
      columnList: updatedColumnList,
      columns: updatedColumnList
    }
    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        third_level_nav_links: newThirdLevelNavLinks
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }
  const handleThirdLinkColumnTargetUpdate = (target, primaryNavLinkIndex, secondaryNavLinkIndex, columnIndex, innerColumnIndex, index) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedThirdLevelNavLinks = { ...updtedSecondaryLinks[secondaryNavLinkIndex].third_level_nav_links }
    const { columnList } = updtedThirdLevelNavLinks
    const column = columnList[columnIndex]

    const updatedColumns = [
      ...column.slice(0, innerColumnIndex),
      {
        ...column[innerColumnIndex],
        target
      },
      ...column.slice(innerColumnIndex + 1)
    ]

    const updatedColumnList = [
      ...columnList.slice(0, columnIndex),
      updatedColumns,
      ...columnList.slice(columnIndex + 1)
    ]

    const newThirdLevelNavLinks = {
      ...updtedThirdLevelNavLinks,
      columnList: updatedColumnList
    }
    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        third_level_nav_links: newThirdLevelNavLinks
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }
  const handleThirdLinkColumnUrlUpdate = (url, primaryNavLinkIndex, secondaryNavLinkIndex, columnIndex, innerColumnIndex, index) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedThirdLevelNavLinks = { ...updtedSecondaryLinks[secondaryNavLinkIndex].third_level_nav_links }
    const { columnList } = updtedThirdLevelNavLinks
    const column = columnList[columnIndex]

    const updatedColumns = [
      ...column.slice(0, innerColumnIndex),
      {
        ...column[innerColumnIndex],
        url
      },
      ...column.slice(innerColumnIndex + 1)
    ]

    const updatedColumnList = [
      ...columnList.slice(0, columnIndex),
      updatedColumns,
      ...columnList.slice(columnIndex + 1)
    ]

    const newThirdLevelNavLinks = {
      ...updtedThirdLevelNavLinks,
      columns: updatedColumnList,
      columnList: updatedColumnList
    }
    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        third_level_nav_links: newThirdLevelNavLinks
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const handleThirdLinkDescriptionUpdate = (description, primaryNavLinkIndex, secondaryNavLinkIndex, index) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedThirdLevelNavLinks = { ...updtedSecondaryLinks[secondaryNavLinkIndex].third_level_nav_links }
    const { promo_item: promoItem } = updtedThirdLevelNavLinks
    const updatedPromoCode = {
      ...promoItem,
      description
    }
    const newThirdLevelNavLinks = {
      ...updtedThirdLevelNavLinks,
      promo_item: updatedPromoCode
    }
    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        third_level_nav_links: newThirdLevelNavLinks
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const handleThirdLinkImageUrlUpdate = (imageUrl, primaryNavLinkIndex, secondaryNavLinkIndex, index) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedThirdLevelNavLinks = { ...updtedSecondaryLinks[secondaryNavLinkIndex].third_level_nav_links }
    const { promo_item: promoItem } = updtedThirdLevelNavLinks
    const updatedPromoCode = {
      ...promoItem,
      image: {
        img_url: imageUrl,
        alt_img: imageUrl?.replace(/^.*(\\|\/|:)/, '').replace(/\.[^/.]+$/, '')
      }
    }
    const newThirdLevelNavLinks = {
      ...updtedThirdLevelNavLinks,
      promo_item: updatedPromoCode
    }
    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        third_level_nav_links: newThirdLevelNavLinks
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const handleThirdLinkNameUpdate = (name, primaryNavLinkIndex, secondaryNavLinkIndex, index) => {
    // secondaryNavLink.third_level_nav_links.promo_item.image.img_url
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedThirdLevelNavLinks = { ...updtedSecondaryLinks[secondaryNavLinkIndex].third_level_nav_links }
    const { promo_item: promoItem } = updtedThirdLevelNavLinks
    const updatedPromoCode = {
      ...promoItem,
      cta: {
        ...promoItem.cta,
        name
      }
    }
    const newThirdLevelNavLinks = {
      ...updtedThirdLevelNavLinks,
      promo_item: updatedPromoCode
    }
    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        third_level_nav_links: newThirdLevelNavLinks
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const handleThirdLinkUrlUpdate = (url, primaryNavLinkIndex, secondaryNavLinkIndex, index) => {
    // secondaryNavLink.third_level_nav_links.promo_item.image.img_url
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedThirdLevelNavLinks = { ...updtedSecondaryLinks[secondaryNavLinkIndex].third_level_nav_links }
    const { promo_item: promoItem } = updtedThirdLevelNavLinks
    const updatedPromoCode = {
      ...promoItem,
      cta: {
        ...promoItem.cta,
        url
      }
    }
    const newThirdLevelNavLinks = {
      ...updtedThirdLevelNavLinks,
      promo_item: updatedPromoCode
    }
    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        third_level_nav_links: newThirdLevelNavLinks
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const handlSecondaryLinkTargetUpdate = (target, primaryNavLinkIndex, secondaryNavLinkIndex, index) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]

    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        target
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const handleDeleteTopNavModalCancel = () => {
    setCurrentTopNavIndex(-1)
    setShowTopNavDeleteModal(false)
  }

  const handleDeleteTopNavAction = () => {
    const clonedNavList = { ...topNavList }
    const venuesList = [...clonedNavList.venues]

    if (currentTopNavIndex !== -1) {
      venuesList.splice(currentTopNavIndex, 1)
    }
    setCurrentTopNavIndex(-1)
    setShowTopNavDeleteModal(false)
    setTopNavList({
      ...clonedNavList,
      venues: venuesList
    })
  }

  const handleDeletePrimaryNavModalCancel = () => {
    setCurrentTopNavIndex(-1)
    setShowTopNavDeleteModal(false)
  }

  const handleDeletePrimaryNavAction = () => {
    const clonedNavList = [...primaryNavList]

    if (currentPrimaryNavIndex !== -1) {
      clonedNavList.splice(currentPrimaryNavIndex, 1)
    }
    setCurrentPrimaryNavIndex(-1)
    setShowPrimaryNavDeleteModal(false)
    setPrimaryNavList(clonedNavList)
  }

  const handlePrimaryNavDelete = (index, primaryNavLinkIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]

    const clonedNavList = [...updtedPrimaryLinks]

    if (primaryNavLinkIndex !== -1) {
      clonedNavList.splice(primaryNavLinkIndex, 1)
    }

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: clonedNavList
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const handleSecondaryNavDelete = (index, primaryNavLinkIndex, secondaryNavLinkIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]

    if (secondaryNavLinkIndex !== -1) {
      updtedSecondaryLinks.splice(secondaryNavLinkIndex, 1)
    }

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updtedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const handlePrimaryUrlUpdate = (url, index) => {
    const clonedNavList = [...primaryNavList]
    const updatedNavList = [
      ...clonedNavList.slice(0, index),
      {
        ...clonedNavList[index],
        url
      },
      ...clonedNavList.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const handleTertiaryNavDelete = (index, primaryNavLinkIndex, secondaryNavLinkIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]

    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        third_level_nav_links: {}
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const addNewTertiaryPromoItem = (index, primaryNavLinkIndex, secondaryNavIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedThirdLevelNavLinks = { ...updtedSecondaryLinks[secondaryNavIndex].third_level_nav_links }

    const newThirdLevelNavLinks = {
      ...updtedThirdLevelNavLinks,
      promo_item: {
        cta: {
          name: '',
          url: ''
        },
        description: '',
        image: {
          alt_img: '',
          img_url: ''
        }
      }
    }
    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavIndex),
      {
        ...updtedSecondaryLinks[secondaryNavIndex],
        third_level_nav_links: newThirdLevelNavLinks
      },
      ...updtedSecondaryLinks.slice(secondaryNavIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const handleTertiaryPromoItemDelete = (index, primaryNavLinkIndex, secondaryNavLinkIndex) => {
    const primaryNavs = [...primaryNavList]
    const updtedPrimaryLinks = [...primaryNavs[index].primary_nav_links]
    const updtedSecondaryLinks = [...updtedPrimaryLinks[primaryNavLinkIndex].secondary_nav_links]
    const updtedThirdLevelNavLinks = { ...updtedSecondaryLinks[secondaryNavLinkIndex].third_level_nav_links }

    const newThirdLevelNavLinks = {
      ...updtedThirdLevelNavLinks,
      promo_item: null
    }
    const updatedSecondaryLinks = [
      ...updtedSecondaryLinks.slice(0, secondaryNavLinkIndex),
      {
        ...updtedSecondaryLinks[secondaryNavLinkIndex],
        third_level_nav_links: newThirdLevelNavLinks
      },
      ...updtedSecondaryLinks.slice(secondaryNavLinkIndex + 1)
    ]

    const updatedPrimaryLinks = [
      ...updtedPrimaryLinks.slice(0, primaryNavLinkIndex),
      {
        ...updtedPrimaryLinks[primaryNavLinkIndex],
        secondary_nav_links: updatedSecondaryLinks
      },
      ...updtedPrimaryLinks.slice(primaryNavLinkIndex + 1)
    ]

    const updatedNavList = [
      ...primaryNavs.slice(0, index),
      {
        ...primaryNavs[index],
        primary_nav_links: updatedPrimaryLinks
      },
      ...primaryNavs.slice(index + 1)
    ]

    setPrimaryNavList(updatedNavList)
  }

  const goToListNavigationsButton = (
    <Route key="listnavigations" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateNavigationStatus()
          history.push('/navigations')
          window.location.reload()
        }}
        type="primary"
      >
        Go to Navigations List
      </Button>
    )} />
  )

  const handleModalClose = () => {
    resetUpdateNavigationStatus()
  }

  return (
    <PageLayout extra={[updateNavigations()]} title={PAGE_TITLE}>
      <Card
        className={styles.title}
        extra={<Button
          icon={<PlusOutlined/>}
          onClick={() => addNewTopNav()}
          style={{ background: '#0F1321', border: '1px solid #f0f0f0' }} type='primary' />
        }
        headStyle={{ backgroundColor: '#2A2D3B', color: '#fff' }}
        title='Top Level'
      >
        <Row gutter={16}>
          <Col span={18}>
            <Input label='App Name'
              onChange={event => handleAppNameUpdate(event.target.value)}
              value={appName} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={18}>
            <Input label='label'
              onChange={event => handleLabelUpdate(event.target.value)}
              value={topNavList?.label} />
          </Col>
        </Row>
        <Collapse activeKey={topNavActiveKey} onChange={(index) => setTopNavActiveKey(index)}>
          {
            topNavList && topNavList?.venues && topNavList?.venues?.map((nav, index) => {
              return <Panel key={index} extra={
                <div onClick={e => e.stopPropagation()}>
                  <Button className={styles.deleteIcon}
                    onClick={event => {
                      setCurrentTopNavIndex(index)
                      setShowTopNavDeleteModal(true)
                      event.stopPropagation()
                    }}
                    type='danger'>Delete Top Nav</Button>
                </div>
              } header={nav?.venue_name}>
                <TopNavInfo
                  allVenues={VENUES_LIST}
                  handleImageUrlUpdate={handleImageUrlUpdate}
                  handleLinkTargetUpdate={handleLinkTargetUpdate}
                  handleLinkUrlUpdate={handleLinkUrlUpdate}
                  handleVenueUpdate={handleVenueUpdate}
                  index={index}
                  nav={nav}
                />
              </Panel>
            }
            )
          }</Collapse>
      </Card>
      <br />
      <Card
        className={styles.title}
        extra={
          <Button icon={<PlusOutlined />}
            onClick={() => addNewPrimaryNav()}
            style={{ background: '#0F1321', border: '1px solid #f0f0f0' }} type='primary'
          />
        }
        headStyle={{ backgroundColor: '#2A2D3B', color: '#fff' }}
        title='Primary Nav'>
        <Collapse activeKey={primaryNavActiveKey} onChange={(index) => setPrimaryNavActiveKey(index)}>
          {
            primaryNavList && primaryNavList.map((primaryNav, index) =>
              <Panel key={index} extra={
                <div onClick={e => e.stopPropagation()}>

                  <Button className={styles.deleteIcon}
                    onClick={event => {
                      setCurrentPrimaryNavIndex(index)
                      setShowPrimaryNavDeleteModal(true)
                      event.stopPropagation()
                    }}
                    type='danger'>Delete Primary Nav</Button>
                </div>
              } header={`${primaryNav?.venue_id}`}>
                <PrimaryNavInfo key={`top_primary_nav_${index}`}
                  addNewPrimaryLink={addNewPrimaryLink}
                  addNewSecondaryLink={addNewSecondaryLink}
                  addNewTertiaryColumn={addNewTertiaryColumn}
                  addNewTertiaryColumnList={addNewTertiaryColumnList}
                  addNewTertiaryLink={addNewTertiaryLink}
                  addNewTertiaryPromoItem={addNewTertiaryPromoItem}
                  allVenues={VENUES_LIST}
                  deleteNewTertiaryColumn={deleteNewTertiaryColumn}
                  deleteNewTertiaryColumnList={deleteNewTertiaryColumnList}
                  handlSecondaryLinkTargetUpdate={handlSecondaryLinkTargetUpdate}
                  handlePrimaryLinkLabelUpdate={handlePrimaryLinkLabelUpdate}
                  handlePrimaryLinkTargetUpdate={handlePrimaryLinkTargetUpdate}
                  handlePrimaryLinkUrlUpdate={handlePrimaryLinkUrlUpdate}
                  handlePrimaryLogoUrlUpdate={handleLogoUrlUpdate}
                  handlePrimaryNavDelete={handlePrimaryNavDelete}
                  handlePrimaryNavVenueUpdate={handlePrimaryNavVenueUpdate}
                  handlePrimaryUrlUpdate={handlePrimaryUrlUpdate}
                  handleSecondaryLinkLabelUpdate={handleSecondaryLinkLabelUpdate}
                  handleSecondaryLinkUrlUpdate={handleSecondaryLinkUrlUpdate}
                  handleSecondaryNavDelete={handleSecondaryNavDelete}
                  handleTertiaryNavDelete={handleTertiaryNavDelete}
                  handleTertiaryPromoItemDelete={handleTertiaryPromoItemDelete}
                  handleThirdLinkColumnLabelUpdate={handleThirdLinkColumnLabelUpdate}
                  handleThirdLinkColumnTargetUpdate={handleThirdLinkColumnTargetUpdate}
                  handleThirdLinkColumnUrlUpdate={handleThirdLinkColumnUrlUpdate}
                  handleThirdLinkDescriptionUpdate={handleThirdLinkDescriptionUpdate}
                  handleThirdLinkImageUrlUpdate={handleThirdLinkImageUrlUpdate}
                  handleThirdLinkNameUpdate={handleThirdLinkNameUpdate}
                  handleThirdLinkUrlUpdate={handleThirdLinkUrlUpdate}
                  index={index}
                  primaryNav={primaryNav}
                /></Panel>
            )
          }
        </Collapse>
      </Card>
      {
        showTopNavDeleteModal &&
          <Modal
            onCancel={handleDeleteTopNavModalCancel}
            onOk={handleDeleteTopNavAction}
            title="Delete confirmation"
            visible={showTopNavDeleteModal}
          >
            <Text>
              This Nav item will be removed. Are you sure you want to delete this?
            </Text>
          </Modal>
      }
      {
        showPrimaryNavDeleteModal &&
          <Modal
            onCancel={handleDeletePrimaryNavModalCancel}
            onOk={handleDeletePrimaryNavAction}
            title="Delete confirmation"
            visible={showPrimaryNavDeleteModal}
          >
            <Text>
              This Primary Nav item will be removed. Are you sure you want to delete this?
            </Text>
          </Modal>
      }
      {
        updateStatus &&
        <ResultModal
          actions={[
            goToListNavigationsButton
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

NewNavigations.propTypes = {
  addNavigation: PropTypes.func,
  fetchNavigationsList: PropTypes.func,
  navigations: PropTypes.array,
  resetUpdateNavigationStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool
}

const mapStateToProps = (state) => {
  const { message: updateMessage, success: updateStatus, navigations } = state.navigations
  return {
    updateMessage,
    updateStatus,
    navigations
  }
}

const mapDispatchToProps = {
  resetUpdateNavigationStatus,
  fetchNavigationsList,
  addNavigation
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NewNavigations)
)
