import React, { useEffect, useState } from 'react'
import { Button, Collapse, notification } from 'antd'
import idx from 'idx'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { fetchVenueById, resetVenueActionStatus, updateVenue } from '~actions'
import { ResultModal, VenueBasicInfo, VenueBoxOfficeInfo, VenueContentSelector, VenueDoorOpenSettings, VenueEventRecommendationsSelector, VenueGeneralInfo, VenueLogos } from '~components'
import { getCurrentUser } from '~context'
import { doorOpenTimeConfig } from '~doorOpenTimeConfig'
import { Checkbox, PageLayout, Spinner } from '~stories'

const { Panel } = Collapse
const defaultLogo = {
  is_active: false,
  name: 'New Logo',
  is_default: false,
  isNew: true
}
const FORMATTER = {
  DATE: 'MM/DD/YYYY',
  TIME: 'HH:mm'
}
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.',
  INVALID_LOGO: 'Venue logos validation error',
  SET_DEFAULT_LOGO: 'Please set default logo.'
}
const PAGE_TITLE = 'Edit Venue'

const EditVenue = ({
  allContents: allContentsProps,
  availableEventsForPromotion,
  fetchVenueById,
  resetVenueActionStatus,
  route = {},
  updateMessage,
  updateStatus,
  updateVenue,
  venue = {}
}) => {
  const [addLogoMode, setAddLogoMode] = useState(false)
  const [allEvents, setAllEvents] = useState([])
  const [editLogoMode, setEditLogoMode] = useState(false)
  const [isDataLoaded, setIsDataLoaded] = useState()
  const [promotedEvents, setPromotedEvents] = useState([])
  const [allContents, setAllContents] = useState([])
  const [venueContents, setVenueContents] = useState([])
  const [selectedLogoIndex, setSelectedLogoIndex] = useState(0)
  const [venueId, setVenueId] = useState('')
  const [venueLogos, setVenueLogos] = useState([])
  const [venueToEdit, setVenueToEdit] = useState({})
  const [doorOpenSettings, setDoorOpenSettings] = useState({})
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    const venueId = idx(route, _ => _.match.params.id)
    if (venueId) {
      setVenueId(venueId)
    }
  }, [route, setVenueId])

  useEffect(() => {
    if (updateStatus && updateMessage) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
      resetVenueActionStatus()
    }
  }, [updateStatus, updateMessage, resetVenueActionStatus])

  const addLogo = () => { setAddLogoMode(true) }

  const genOverrideFlag = () => {
    return (
      <div onClick={e => e.stopPropagation()}>
        <Checkbox
          checked={venueToEdit.do_not_override_recommendations}
          isInline
          label='DO NOT OVERRIDE RECOMMENDATIONS'
          onChange={setDontOverrideRecommendations}
        />
      </div>
    )
  }

  const getLogoData = () => {
    return venueLogos.map(logo => {
      return {
        is_active: logo.is_active,
        name: logo.name,
        web_image_url: logo.web_image_url,
        email_image_url: logo.email_image_url,
        email_image_url_color: logo.email_image_url_color,
        is_default: logo.is_default
      }
    })
  }

  const handleAddNewLogo = (logo) => {
    let logos = [...venueLogos]
    if (logo.is_default) {
      logos = venueLogos.map(logo => {
        logo.is_default = false
        return logo
      })
    }
    logos = [...logos, logo]
    setVenueLogos(logos)
    setSelectedLogoIndex(logos.length - 1)
  }

  const handleCancelAddLogo = (index, newLogo) => {
    setEditLogoMode(false)
    setAddLogoMode(false)
  }

  const handleEdit = () => {
    setEditLogoMode(true)
  }

  const defaultLogoPresent = venueLogos.some(logo => logo.is_default)

  const handleSubmit = () => {
    const logoData = getLogoData()
    venueToEdit.logos = logoData
    if (logoData.length > 0 && !defaultLogoPresent) {
      notification.error({ message: NOTIFICATION_MESSAGES.INVALID_LOGO, description: NOTIFICATION_MESSAGES.SET_DEFAULT_LOGO })
    }
    const selectedEvents = promotedEvents.map(event => event.id)
    venueToEdit.related_events = [...selectedEvents]
    venueToEdit.door_open_time = doorOpenSettings
    venueToEdit.content = venueContents
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }
    setIsDataLoaded(false)
    updateVenue(venueId, venueToEdit, accessToken)
  }

  const handleGeneralInfoUpdate = (event) => {
    const { generalInfo } = venueToEdit
    generalInfo[event.target.id] = event.target.value
    const updatedVenue = { ...venueToEdit, generalInfo }
    setVenueToEdit(updatedVenue)
  }

  const handleBoxOfficeUpdate = (event) => {
    const { boxOfficeInfo } = venueToEdit
    boxOfficeInfo[event.target.id] = event.target.value
    const updatedVenue = { ...venueToEdit, boxOfficeInfo }
    setVenueToEdit(updatedVenue)
  }

  const handleAvailabilityThresholdChange = (event) => {
    const availabilityThreshold = event.target.value
    const updatedVenue = { ...venueToEdit, availability_threshold: availabilityThreshold }
    setVenueToEdit(updatedVenue)
  }

  const setDontOverrideRecommendations = (event) => {
    const doNotOverrideRecommendations = event.target.checked
    const updatedVenue = { ...venueToEdit, do_not_override_recommendations: doNotOverrideRecommendations }
    setVenueToEdit(updatedVenue)
  }

  const handleLogoUrlChange = event => {
    const logoUrl = event.target.value
    const updatedVenue = { ...venueToEdit, logo_url: logoUrl }
    setVenueToEdit(updatedVenue)
  }

  const handleLegalCopyChange = event => {
    const legalCopy = event.target.value
    const updatedVenue = { ...venueToEdit, legalCopy }
    setVenueToEdit(updatedVenue)
  }

  useEffect(() => {
    if (venueId) {
      setIsDataLoaded(false)
      fetchVenueById(venueId)
    }
  }, [venueId, fetchVenueById])

  useEffect(() => {
    if (venue) {
      setAllEvents(availableEventsForPromotion)
      setAllContents(allContentsProps)
      setPromotedEvents(venue.related_events)
      setVenueContents(venue.content)
      setVenueToEdit(venue)
      const defaultSettings = { ...doorOpenTimeConfig }
      venue.id && setDoorOpenSettings(venue.door_open_time ?? defaultSettings[venue.id].artists)
      setIsDataLoaded(true)
    }
  }, [allContentsProps, availableEventsForPromotion, venue])

  useEffect(() => {
    if (venueToEdit && venueToEdit.logos) {
      setVenueLogos(venueToEdit.logos)
    } else {
      setVenueLogos([])
    }
  }, [venueToEdit])

  useEffect(() => {
    setAddLogoMode(false)
  }, [venueLogos, setAddLogoMode])

  const updateLogo = (logo, index) => {
    let logos = [...venueLogos]
    if (logo.is_default) {
      logos = venueLogos.map((logo, currentIndex) => {
        if (index !== currentIndex) {
          logo.is_default = false
        }
        return logo
      })
    }
    logos[index] = logo
    setSelectedLogoIndex(index)
    setVenueLogos(logos)
    setEditLogoMode(false)
  }

  const editVenue = (
    <Button
      onClick={() => {
        resetVenueActionStatus()
        window.location.reload()
      }}
      type="primary"
    >
      Keep Editing
    </Button>
  )

  const goToListVenuesButton = (
    <Route key="listVenues" render={({ history }) => (
      <Button
        onClick={() => {
          resetVenueActionStatus()
          history.push('/venues')
        }}
        type="primary"
      >
        Go to Venues List
      </Button>
    )} />
  )

  const handleModalClose = () => {
    resetVenueActionStatus()
  }

  const onDoorOpenTimeChange = (time, artistId) => {
    const clonedDoorOpenTime = { ...doorOpenSettings }
    const { name: artistName } = clonedDoorOpenTime[artistId]
    let doorOpenTimeDelta = 0
    if (time) {
      const selectedTime = time.format(FORMATTER.TIME)
      const duration = moment.duration(selectedTime)
      doorOpenTimeDelta = moment(duration.as('minutes')).valueOf()
    }
    const doorOpenTime = {
      ...clonedDoorOpenTime,
      [artistId]: {
        name: artistName,
        doorOpenTimeDelta
      }
    }
    setDoorOpenSettings(doorOpenTime)
  }

  const handleSubmitButton = <Button onClick={handleSubmit} type='primary'>
    Update
  </Button>
  const doorOpenTime = doorOpenSettings && venue && <VenueDoorOpenSettings
    doorOpenTimeConfig={ doorOpenSettings }
    onChange={onDoorOpenTimeChange}
  />
  return (
    <PageLayout extra={[handleSubmitButton]} title={PAGE_TITLE}>
      <>
        <VenueBasicInfo
          key={`bi_${venue.id}`}
          handleAvailabilityThresholdChange={handleAvailabilityThresholdChange}
          handleLegalCopyChange={handleLegalCopyChange}
          handleLogoUrlChange={handleLogoUrlChange}
          venue={venueToEdit}
        />
        <Collapse accordion defaultActiveKey={['1']} expandIconPosition='right'>
          <Panel key='1' header='Door Open Time (Delta in 24 hours format)'>
            {
              doorOpenTime
            }
          </Panel>
          <Panel key='2' header='Box Office Info'>
            <VenueBoxOfficeInfo
              key={`boi_${venue.id}`}
              data={venueToEdit.boxOfficeInfo}
              handleBoxOfficeUpdate={handleBoxOfficeUpdate}
            />
          </Panel>
          <Panel key='3' header='General Info'>
            <VenueGeneralInfo
              key={`gi_${venue.id}`}
              data={venueToEdit.generalInfo}
              handleGeneralInfoUpdate={handleGeneralInfoUpdate}
              id={venue.id}
            />
          </Panel>
          <Panel key='4' header='Logos'>
            <VenueLogos
              key={`logos_${venue.id}`}
              addLogo={addLogo}
              addLogoMode={addLogoMode}
              defaultLogo={defaultLogo}
              editLogoMode={editLogoMode}
              handleAddNewLogo={handleAddNewLogo}
              handleCancelAddLogo={handleCancelAddLogo}
              handleEdit={handleEdit}
              selectedLogoIndex={selectedLogoIndex}
              setSelectedLogoIndex={setSelectedLogoIndex}
              updateLogo={updateLogo}
              venueLogos={venueLogos}
            />
          </Panel>
          <Panel key='5' extra={genOverrideFlag()} header='Event Promotion'>
            <VenueEventRecommendationsSelector
              allEvents={allEvents}
              promotedEvents={promotedEvents}
              setAllEvents={setAllEvents}
              setPromotedEvents={setPromotedEvents}
            />
          </Panel>
          <Panel key='6' header='Content Blocks'>
            <VenueContentSelector
              allContents={allContents}
              selectedContents={venueContents}
              setAllContents={setAllContents}
              setVenueContents={setVenueContents}
            />
          </Panel>
        </Collapse>
      </>
      <Spinner isLoading={!isDataLoaded} size="large"/>
      {
        updateStatus &&
        <ResultModal
          actions={[
            goToListVenuesButton,
            editVenue
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

EditVenue.propTypes = {
  allContents: PropTypes.array,
  availableEventsForPromotion: PropTypes.array,
  fetchVenueById: PropTypes.func,
  resetVenueActionStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.string,
  updateVenue: PropTypes.func,
  venue: PropTypes.object
}

const mapStateToProps = ({ venues }) => ({
  allContents: venues.allContents,
  availableEventsForPromotion: venues.availableEventsForPromotion,
  updateMessage: venues.message,
  updateStatus: venues.success,
  venue: venues.venueToEdit
})

const mapDispatchToProps = {
  fetchVenueById: fetchVenueById,
  resetVenueActionStatus,
  updateVenue
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditVenue)
