import 'isomorphic-fetch'

import { marqueeEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import { UPDATE_WORK_VIVO } from '../actionTypes'

export const updateWorkvivo = (employeeId, formData, accessToken) => async dispatch => {
  const { method, url } = marqueeEndpoint.upload(employeeId)
  const idToken = getIdToken()

  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'multipart/form-data',
      accessToken
    },
    body: formData,
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))

  dispatch({
    type: UPDATE_WORK_VIVO,
    payload: {
      ...apiResponse
    }
  })
}
