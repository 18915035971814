import React, { useState } from 'react'
import { Col, Row, Button, Card, Space, notification } from 'antd'
import Meta from 'antd/lib/card/Meta'
import PropTypes from 'prop-types'

import { invalidateCFCache } from '~actions'
import { MultiSelectDropdown, Input, PageLayout } from '~stories'

import 'antd/dist/antd.css'

import styles from './CFValidation.module.css'

const PAGE_TITLE = 'CF Validation'

export const APPLICATIONS = [{
  name: 'MSG.com',
  value: 'E1SHS71F2CO95R',
  roleArn: 'arn:aws:iam::691417913298:role/cloudfront-invalidation-assumed-role'
}, {
  name: 'Rockettes',
  value: 'EB50WMI2NKVXA',
  roleArn: 'arn:aws:iam::234086215851:role/cloudfront-invalidation-assumed-role'
}, {
  name: 'Sphere',
  value: 'E2QVBAYTY4G1VW',
  roleArn: 'arn:aws:iam::234086215851:role/cloudfront-invalidation-assumed-role'
}]

const CFValidation = () => {
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [selectedApplication, setSelectedApplication] = useState()
  const [path, setPath] = useState('/*')

  const handleApplicationChange = selectedItem => {
    const [application] = APPLICATIONS.filter(app => app.value === selectedItem)
    setSelectedApplication(application)
  }

  const handleCFInvalidation = async () => {
    setIsDataLoaded(true)
    const response = await invalidateCFCache(selectedApplication, path)
    notification.success({ message: response.message })
    setIsDataLoaded(false)
    setSelectedApplication()
  }

  const handleUrlUpdate = event => {
    const path = event.target.value
    setPath(path)
  }

  return (
    <PageLayout title={PAGE_TITLE}>
      <Card />
      <Card>
        <Meta
          title={<span className={styles.title}>Select the application</span>}
        /><br />
        <MultiSelectDropdown
          allowClear={true}
          customStyle={{ width: 150 }}
          displayField="name"
          dropdownOptions={APPLICATIONS}
          handleChange={handleApplicationChange}
          selectedItems={[selectedApplication]}
          valueField="value"
        />
        <br />
        <Row>
          <Col span={8}>
            <Input label={'Path'} onChange={handleUrlUpdate} value={path} />
          </Col>
        </Row>
        <br />
        <div
          className={styles.textAlignRight}>
          <Space>
            <Button disabled={!selectedApplication} loading={isDataLoaded} onClick={handleCFInvalidation} type="primary">
                Invalidate Cache
            </Button>
          </Space>
        </div>
      </Card>
    </PageLayout>
  )
}

CFValidation.propTypes = {
  handleApplicationChange: PropTypes.func,
  selectedApplication: PropTypes.object.isRequired
}

CFValidation.defaultProps = {

}

export default CFValidation
