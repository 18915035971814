import {
  ADD_NEW_OPT_IN,
  FETCH_NEW_OPT_IN,
  FETCH_OPT_INS,
  OPT_IN_UPDATE_STATUS,
  STORE_OPT_IN_TO_EDIT
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_OPT_IN:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_OPT_INS:
      return {
        ...state,
        ...action.payload
      }
    case STORE_OPT_IN_TO_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case OPT_IN_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_NEW_OPT_IN:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
