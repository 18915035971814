import React, { useState } from 'react'
import { Button, Col, Row, Space } from 'antd'
import PropTypes from 'prop-types'
import { Input, MultiSelectDropdown, RangePicker } from '~stories'
import styles from './SeriesEventsFilter.module.css'

const SeriesEventsFilter = ({
  artists,
  categories,
  cities,
  handleFilter,
  isFilteredDataLoaded,
  isFilteringCancelled,
  resetFilter,
  venues
}) => {
  const [artist, setArtist] = useState()
  const [category, setCategory] = useState()
  const [city, setCity] = useState()
  const [endDate, setEndDate] = useState()
  const [eventName, setEventName] = useState()
  const [startDate, setStartDate] = useState()
  const [venue, setVenue] = useState()

  const handleSubmit = () => {
    handleFilter({
      artist,
      category,
      city,
      endDate,
      eventName,
      startDate,
      venue
    })
  }

  const handleClear = () => {
    setArtist(null)
    setCategory(null)
    setCity(null)
    setEndDate(null)
    setEventName('')
    setStartDate(null)
    setVenue(null)
    resetFilter({})
  }

  const handleDateRangeSelection = (dates) => {
    const selectedDate = dates || null
    if (selectedDate) {
      const [selectedStartDate, selectedEndDate] = selectedDate
      setStartDate(selectedStartDate)
      setEndDate(selectedEndDate)
    } else {
      setStartDate(null)
      setEndDate(null)
    }
  }

  const handelEventNameChange = (event) => {
    setEventName(event.target.value)
  }

  return (
    <div className={styles.SeriesEventsFilterContainer}>
      <Row gutter={12}>
        <Col span={12}>
          <Input
            isInline
            label="Event Name"
            onChange={handelEventNameChange}
            placeholder="Enter Event Name"
            value={eventName}
          />
        </Col>
        <Col span={12}>
          <RangePicker
            isInline
            label={'Event Date Range'}
            onChange={handleDateRangeSelection}
            value={[startDate, endDate]}
          />
        </Col>
      </Row>
      <Row span={24}>
        <Col lg={6} md={12}>
          <Space direction="verticle" size={12}>
            <MultiSelectDropdown
              allowClear
              customStyle={{ width: 200 }}
              displayField="name"
              dropdownOptions={artists}
              handleChange={setArtist}
              isLabelInline
              label='Artist:'
              placeholder='Select Artist'
              selectedItems={artist}
              valueField="id"
            />
          </Space>
        </Col>
        <Col lg={5} md={12}>
          <MultiSelectDropdown
            allowClear
            customStyle={{ width: 160 }}
            displayField="name"
            dropdownOptions={cities}
            handleChange={setCity}
            isLabelInline
            label='City:'
            placeholder='Select City'
            selectedItems={city}
            valueField="name"
          />
        </Col>
        <Col lg={7} md={12}>
          <MultiSelectDropdown
            allowClear
            customStyle={{ width: 200 }}
            displayField="name"
            dropdownOptions={categories}
            handleChange={setCategory}
            isLabelInline
            label='Category:'
            placeholder='Select Category'
            selectedItems={category}
            valueField="name"
          />
        </Col>
        <Col lg={6} md={12}>
          <MultiSelectDropdown
            allowClear
            customStyle={{ width: 200 }}
            displayField="name"
            dropdownOptions={venues}
            handleChange={setVenue}
            isLabelInline
            label="Venue:"
            placeholder="Select Venue"
            selectedItems={venue}
            valueField="id"
          />
        </Col>
      </Row>
      <Row className={styles.filterActionContainer} gutter={24}>
        <Col lg={4} md={8}>
          <Button block disabled={!isFilteredDataLoaded} loading={!isFilteringCancelled} onClick={handleClear} type='default'>
            Clear
          </Button>
        </Col>
        <Col lg={4} md={8}>
          <Button block disabled={!isFilteringCancelled} loading={!isFilteredDataLoaded} onClick={handleSubmit} type='primary'>
            Submit
          </Button>
        </Col>
      </Row>
    </div>
  )
}

SeriesEventsFilter.propTypes = {
  artists: PropTypes.array,
  categories: PropTypes.array,
  cities: PropTypes.array,
  handleFilter: PropTypes.func.isRequired,
  isFilteredDataLoaded: PropTypes.bool,
  isFilteringCancelled: PropTypes.bool,
  resetFilter: PropTypes.func.isRequired,
  venues: PropTypes.array
}

export default SeriesEventsFilter
