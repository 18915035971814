import React from 'react'
import { Button, notification } from 'antd'
import { Route } from 'react-router-dom'

import { ItemList } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout } from '~stories'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const PAGE_TITLE = 'Sponsors'

const getSponsorNameWithLink = sponsor => <a href={`sponsors/${sponsor._id}/edit`}>{String(sponsor.title).toUpperCase()}</a>

const getSponsorDescriptionAsHTML = sponsor => <div dangerouslySetInnerHTML={{ __html: sponsor.description }}/>

const getSponsorIcon = sponsor => <img alt='Icon URL' src={sponsor.icon_url} width='60px' />
const columnData = [
  {
    dataIndex: 'title',
    render: (text, sponsor) => getSponsorNameWithLink(sponsor),
    sorter: true,
    title: 'Title',
    width: '30%',
    sortDirections: ['descend', 'ascend', 'descend']
  },
  {
    title: 'Description',
    width: '40%',
    render: sponsor => getSponsorDescriptionAsHTML(sponsor)
  },
  {
    render: sponsor => getSponsorIcon(sponsor),
    title: 'Icon'
  }
]

const addNewSponsor = isContentEditor => {
  return (
    <Route render={({ history }) => (
      <Button
        key="1"
        onClick={() => {
          isContentEditor
            ? history.push('/sponsors/new')
            : notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
        }}
        type="primary"
      >
        Add Sponsor
      </Button>
    )} />
  )
}

const Sponsors = () => {
  const { userDetails: { isContentEditor } } = getCurrentUser()

  return (
    <PageLayout extra={[
      addNewSponsor(isContentEditor)
    ]} title={PAGE_TITLE}>
      <div className="sponsors-layout-background">
        <ItemList columns={columnData} resource='sponsors' />
      </div>
    </PageLayout>
  )
}

export default Sponsors
