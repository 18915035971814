import React from 'react'
import PropTypes from 'prop-types'

import { MultiSelect } from '~stories'

const VenueEventRecommendationsSelector = ({
  allEvents = [],
  promotedEvents,
  setAllEvents,
  setPromotedEvents
}) => {
  const manageLists = (items, source, dest, sourceFn, destFn) => {
    const selectedItems = Array.isArray(items)
      ? items
      : [items]
    const clonedSource = [...source]
    let clonedDest = [...dest]
    selectedItems.forEach(item => {
      const selectedEventIndex = clonedSource.findIndex(event => event.id === item.id)
      const [itemToMove] = clonedSource.splice(selectedEventIndex, 1)
      clonedDest = [...clonedDest, itemToMove]
    })
    sourceFn([...clonedSource])
    destFn([...clonedDest])
  }

  const onAdd = selectedEvent => {
    manageLists(selectedEvent, allEvents, promotedEvents, setAllEvents, setPromotedEvents)
  }

  const onRemove = selectedEvent => {
    manageLists(selectedEvent, promotedEvents, allEvents, setPromotedEvents, setAllEvents)
  }

  const onFilter = (allListItems, filterValue) => {
    const testRegExp = new RegExp(filterValue, 'i')
    return allListItems.filter(item => {
      const name = `${item.title}`
      return testRegExp.test(name)
    })
  }

  return (
    <>
      <MultiSelect
        availableListItems={allEvents}
        displayField="title"
        onAdd={onAdd}
        onFilter={onFilter}
        onRemove={onRemove}
        resourceName="Events"
        selectedListItems={promotedEvents}
        valueField={'id'}
      />
    </>
  )
}

VenueEventRecommendationsSelector.propTypes = {
  allEvents: PropTypes.array,
  promotedEvents: PropTypes.array,
  setAllEvents: PropTypes.func,
  setPromotedEvents: PropTypes.func
}

VenueEventRecommendationsSelector.defaultProps = {
  allEvents: [],
  promotedEvents: []
}

export default VenueEventRecommendationsSelector
