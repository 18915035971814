import React, { useState, useEffect } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Empty, Modal, Space, notification } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchContents, resetAdminActionStatus, updateContentBlocks } from '~actions'
import { getCurrentUser } from '~context'
import { Spinner, PageLayout } from '~stories'
import { validateContentForm } from '~validators'

import Content from './Content'
import styles from './Contents.module.css'

const { confirm } = Modal

const CONTENT_ERROR_MESSAGE = 'Missing required attributes in data fields'
const PAGE_TITLE = 'Content Blocks'
const UNAUTHORIZED_MESSAGES = 'You are not authorized to perform this action.'

const Contents = ({ adminActionMessage, adminActionStatus, contents, fetchContents, resetAdminActionStatus, updateContentBlocks }) => {
  const [contentBlocks, setContentBlocks] = useState(null)
  const [activeKey, setActiveKey] = useState('0')
  const [activeBlockKey, setActiveBlockKey] = useState('0')
  const [formErrors, setFormErrors] = useState()
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  useEffect(() => {
    if (contents) {
      setContentBlocks(contents)
    }
  }, [contents])

  useEffect(() => {
    if (!contentBlocks) {
      setIsDataLoaded(true)
      fetchContents()
    }
  }, [contentBlocks, fetchContents])

  useEffect(() => {
    if (adminActionStatus && adminActionMessage) {
      setIsDataLoaded(false)
      notification.success({ message: adminActionMessage })
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    } else if (!adminActionStatus && adminActionMessage) {
      setIsDataLoaded(false)
      notification.error({ message: adminActionMessage })
      resetAdminActionStatus()
    }
  }, [adminActionStatus, adminActionMessage, resetAdminActionStatus])

  const handleSubmit = async () => {
    const contentErrors = validateContentForm(contentBlocks)

    const invalidAttributes = Object.keys(contentErrors).filter(error => !contentErrors[error].isValid)
    setFormErrors(contentErrors)

    if (invalidAttributes && Object.keys(invalidAttributes).length > 0) {
      notification.error({ message: CONTENT_ERROR_MESSAGE })
      return
    }
    if (!isContentEditor) {
      notification.error({ message: UNAUTHORIZED_MESSAGES })
      return
    }
    setIsDataLoaded(true)
    await updateContentBlocks(contentBlocks, accessToken)
  }

  const addNewContent = () => {
    const contents = [...contentBlocks]
    contents.push({
      blocks: [{
        description: '',
        title: ''
      }],
      isDeleted: false,
      name: ''
    })
    setContentBlocks(contents)
    setActiveKey(contents.length - 1)
    setActiveBlockKey(contents?.[0].blocks.length - 1)
  }

  const deleteContent = contentIndex => {
    confirm({
      title: 'Delete Content?',
      icon: <ExclamationCircleOutlined />,
      content: null,
      onOk () {
        const contents = [...contentBlocks]
        const updatedContentBlocks = [
          ...contents.slice(0, contentIndex),
          {
            ...contents[contentIndex],
            isDeleted: true
          },
          ...contents.slice(contentIndex + 1)
        ]
        setContentBlocks(updatedContentBlocks)
      }
    })
  }

  const addNewBlock = contentIndex => {
    const contents = [...contentBlocks]
    contents[contentIndex].blocks.push({
      title: '',
      description: ''
    })
    setContentBlocks(contents)
    setActiveBlockKey(contents[contentIndex].blocks.length - 1)
  }

  const deleteBlock = (contentIndex, blockIndex) => {
    confirm({
      title: 'Delete Block?',
      icon: <ExclamationCircleOutlined />,
      content: null,
      onOk () {
        const contents = [...contentBlocks]
        if (contentIndex !== -1) {
          contents[contentIndex].blocks.splice(blockIndex, 1)
        }
        setContentBlocks(contents)
        setActiveBlockKey(contents[contentIndex].blocks.length - 1)
      }
    })
  }

  const handleActivePanel = activeKey => {
    setActiveKey(activeKey)
  }

  const handleActiveBlockPanel = activeBlockKey => {
    setActiveBlockKey(activeBlockKey)
  }

  const handleNameChange = (value, contentIndex) => {
    const clonedContentBlocks = [...contentBlocks]
    const updatedContentBlocks = [
      ...clonedContentBlocks.slice(0, contentIndex),
      {
        ...clonedContentBlocks[contentIndex],
        name: value || ''
      },
      ...clonedContentBlocks.slice(contentIndex + 1)
    ]
    setContentBlocks(updatedContentBlocks)
  }

  const handleTitleChange = (value, contentIndex, blockIndex) => {
    const clonedContentBlocks = [...contentBlocks]
    const clonedBlocks = [...clonedContentBlocks[contentIndex].blocks]

    const blocks = [
      ...clonedBlocks.slice(0, blockIndex),
      {
        ...clonedBlocks[blockIndex],
        title: value || ''
      },
      ...clonedBlocks.slice(blockIndex + 1)
    ]

    const updatedContentBlocks = [
      ...clonedContentBlocks.slice(0, contentIndex),
      {
        ...clonedContentBlocks[contentIndex],
        blocks
      },
      ...clonedContentBlocks.slice(contentIndex + 1)
    ]
    setContentBlocks(updatedContentBlocks)
  }

  const handleDescriptionChange = (value, contentIndex, blockIndex) => {
    const clonedContentBlocks = [...contentBlocks]
    const clonedBlocks = [...clonedContentBlocks[contentIndex].blocks]

    const blocks = [
      ...clonedBlocks.slice(0, blockIndex),
      {
        ...clonedBlocks[blockIndex],
        description: value || ''
      },
      ...clonedBlocks.slice(blockIndex + 1)
    ]

    const updatedContentBlocks = [
      ...clonedContentBlocks.slice(0, contentIndex),
      {
        ...clonedContentBlocks[contentIndex],
        blocks
      },
      ...clonedContentBlocks.slice(contentIndex + 1)
    ]
    setContentBlocks(updatedContentBlocks)
  }

  const submitButton = <Button disabled={!isContentEditor} loading={!isDataLoaded} onClick={handleSubmit} type='primary'>
    Update
  </Button>

  return (
    <PageLayout extra={[submitButton]} title={PAGE_TITLE}>
      <div
        className={styles.textAlignRight}>
        <Space>
          <Button onClick={addNewContent}>
              Add Content
          </Button>
        </Space>
      </div>
      <Spinner isLoading={!isDataLoaded} />
      {
        contentBlocks && contentBlocks.length > 0 && contentBlocks.map((content, contentIndex) => !content.isDeleted && <Content
          key={contentIndex}
          activeBlockKey={activeBlockKey}
          activeKey={activeKey}
          addNewBlock={addNewBlock}
          content={content}
          contentIndex={contentIndex}
          deleteBlock={deleteBlock}
          deleteContent={deleteContent}
          formError={formErrors?.[contentIndex]}
          handleActiveBlockPanel={handleActiveBlockPanel}
          handleActivePanel={handleActivePanel}
          handleDescriptionChange={handleDescriptionChange}
          handleNameChange={handleNameChange}
          handleTitleChange={handleTitleChange}
        />)
      }
      {
        contentBlocks && contentBlocks.length === 0 && <Empty description='No Content available' image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
    </PageLayout>
  )
}

Contents.propTypes = {
  adminActionMessage: PropTypes.string,
  adminActionStatus: PropTypes.bool,
  contents: PropTypes.array,
  fetchContents: PropTypes.func,
  resetAdminActionStatus: PropTypes.func,
  updateContentBlocks: PropTypes.func
}

const mapStateToProps = ({ admin }) => {
  return {
    adminActionMessage: admin.message,
    adminActionStatus: admin.success,
    contents: admin.contents
  }
}

const mapDispatchToProps = {
  fetchContents,
  resetAdminActionStatus,
  updateContentBlocks
}

export default connect(mapStateToProps, mapDispatchToProps)(Contents)
