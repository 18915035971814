/* eslint-disable camelcase */
import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Row, Col, Card, Collapse, Button, Modal, Typography } from 'antd'
import PropTypes from 'prop-types'

import { LogoUploader } from '~components'
import { Label, Input, RichTextInput } from '~stories'
import styles from './PrimaryNavInfo.module.css'
import TertiaryColumnInfo from './TertiaryColumnInfo'

const { Text } = Typography
const { Panel } = Collapse

const TertiaryNavInfo = ({
  secondaryNavLink,
  secondaryNavLinkIndex,
  primaryNavLinkIndex,
  addNewTertiaryColumn,
  addNewTertiaryColumnList,
  deleteNewTertiaryColumn,
  addNewTertiaryPromoItem,
  deleteNewTertiaryColumnList,
  handleThirdLinkDescriptionUpdate,
  handleTertiaryPromoItemDelete,
  handleThirdLinkImageUrlUpdate,
  handleThirdLinkNameUpdate,
  handleThirdLinkUrlUpdate,
  handleThirdLinkColumnLabelUpdate,
  handleThirdLinkColumnTargetUpdate,
  handleThirdLinkColumnUrlUpdate,
  addNewTertiaryLink,
  handleTertiaryNavDelete,
  index
}) => {
  const [showTertiaryLinkDeleteModal, setTertiaryLinkDeleteModal] = useState(false)
  const [currentTertiaryLinkIndex, setCurrentTertiaryLinkIndex] = useState(-1)

  const [showTertiaryPromoItemDeleteModal, setTertiaryPromoItemDeleteModal] = useState(false)
  const [currentTertiaryPromoItemIndex, setCurrentTertiaryPromoItemIndex] = useState(-1)

  const handleDeleteTertiaryPromoItemModalCancel = () => {
    setCurrentTertiaryPromoItemIndex(-1)
    setTertiaryPromoItemDeleteModal(false)
  }

  const handleDeleteTertiaryLinkModalCancel = () => {
    setCurrentTertiaryLinkIndex(-1)
    setTertiaryLinkDeleteModal(false)
  }

  const handleDeleteTertiaryPromoItemNavAction = () => {
    console.log(currentTertiaryPromoItemIndex)
    handleTertiaryPromoItemDelete(index, primaryNavLinkIndex, secondaryNavLinkIndex)
    setCurrentTertiaryPromoItemIndex(-1)
    setTertiaryPromoItemDeleteModal(false)
  }

  const handleDeleteTertiaryLinkNavAction = () => {
    console.log(currentTertiaryLinkIndex)
    handleTertiaryNavDelete(index, primaryNavLinkIndex, secondaryNavLinkIndex)
    setCurrentTertiaryLinkIndex(-1)
    setTertiaryLinkDeleteModal(false)
  }

  const isThirdLinkValid = secondaryNavLink?.third_level_nav_links && Object.keys(secondaryNavLink?.third_level_nav_links).length

  return (<>
    {isThirdLinkValid ? <Collapse>
      <Panel key={index} extra={
        <div onClick={e => e.stopPropagation()}>
          <Button className={styles.deleteIcon}
            onClick={event => {
              setCurrentTertiaryLinkIndex(index)
              setTertiaryLinkDeleteModal(true)
              event.stopPropagation()
            }}
            type='danger'>Delete Tertiary Link</Button>
        </div>
      } >
        <Card className={styles.tertiary} headStyle={{ backgroundColor: '#8d2a8d', color: '#fff' }} title='Tertiary Links'>
          <Row gutter={16}>
            <Col span={24}>
              <Card className={styles.promoItem}
                {...(secondaryNavLink?.third_level_nav_links?.promo_item && {
                  extra: <div onClick={e => e.stopPropagation()}>
                    <Button className={styles.deleteIcon}
                      onClick={event => {
                        setCurrentTertiaryPromoItemIndex(index)
                        setTertiaryPromoItemDeleteModal(true)
                        event.stopPropagation()
                      }}
                      type='danger'>Delete PromoItem</Button>
                  </div>
                })}
                headStyle={{ backgroundColor: '#d9d9d9', color: '#000' }} title='Promo Item'>
                {secondaryNavLink?.third_level_nav_links?.promo_item ? <><Row gutter={16}>
                  <Col span={24}>
                    <Label value='Image Url' />
                    <div>
                      <LogoUploader handleUpload={(thumbnailImageUrl, secureImageURL) => {
                        const imageUrl = secureImageURL || thumbnailImageUrl
                        handleThirdLinkImageUrlUpdate(imageUrl, primaryNavLinkIndex, secondaryNavLinkIndex, index)
                      }} logoUrl={secondaryNavLink?.third_level_nav_links?.promo_item?.image?.img_url} />
                    </div>
                    <RichTextInput label='Description'
                      onChange={description => handleThirdLinkDescriptionUpdate(description, primaryNavLinkIndex, secondaryNavLinkIndex, index)}
                      value={secondaryNavLink?.third_level_nav_links?.promo_item?.description}
                    />
                  </Col>
                </Row>
                <Card className={styles.promoItem} headStyle={{ backgroundColor: '#d9d9d9', color: '#000' }} title='CTA'>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Input label='Name'
                        onChange={event => handleThirdLinkNameUpdate(event.target.value, primaryNavLinkIndex, secondaryNavLinkIndex, index)}
                        value={secondaryNavLink?.third_level_nav_links?.promo_item?.cta?.name}
                      />
                      <Input label='Url'
                        onChange={event => handleThirdLinkUrlUpdate(event?.target?.value, primaryNavLinkIndex, secondaryNavLinkIndex, index)}
                        value={secondaryNavLink?.third_level_nav_links?.promo_item?.cta.url}
                      />
                    </Col>
                  </Row>
                </Card></> : <Button icon={<PlusOutlined />}
                  onClick={() => addNewTertiaryPromoItem(index, primaryNavLinkIndex, secondaryNavLinkIndex)}
                  style={{ background: '#000', border: '1px solid #000' }}
                  type='primary'>Add PromoItem</Button> }
              </Card><br />
              <Button icon={<PlusOutlined />}
                onClick={() => addNewTertiaryColumnList(index, primaryNavLinkIndex, secondaryNavLinkIndex)}
                style={{ background: '#000', border: '1px solid #000' }}
                type='primary'>Add Column</Button>
              {
                secondaryNavLink?.third_level_nav_links?.columnList?.map((column, columnIndex) => <TertiaryColumnInfo
                  key={columnIndex}
                  addNewTertiaryColumn={addNewTertiaryColumn}
                  columnIndex={columnIndex}
                  deleteNewTertiaryColumn={deleteNewTertiaryColumn}
                  deleteNewTertiaryColumnList={deleteNewTertiaryColumnList}
                  handleThirdLinkColumnLabelUpdate={handleThirdLinkColumnLabelUpdate}
                  handleThirdLinkColumnTargetUpdate={handleThirdLinkColumnTargetUpdate}
                  handleThirdLinkColumnUrlUpdate={handleThirdLinkColumnUrlUpdate}
                  index={index}
                  primaryNavLinkIndex={primaryNavLinkIndex}
                  secondaryNavLinkIndex={secondaryNavLinkIndex}
                  thirdLevelNavLinksColumns={column}
                />)
              }

            </Col>
          </Row>
        </Card>
      </Panel>
    </Collapse> : <Button icon={<PlusOutlined />}
      onClick={() => addNewTertiaryLink(index, primaryNavLinkIndex, secondaryNavLinkIndex)}
      style={{ background: '#000', border: '1px solid #000' }}
      type='primary'>Add Tertiary Link</Button>}
    {
      showTertiaryLinkDeleteModal &&
        <Modal
          onCancel={handleDeleteTertiaryLinkModalCancel}
          onOk={handleDeleteTertiaryLinkNavAction}
          title='Delete confirmation'
          visible={showTertiaryLinkDeleteModal}
        >
          <Text>
            This Tertiary link will be removed. Are you sure you want to delete this?
          </Text>
        </Modal>
    }
    {
      showTertiaryPromoItemDeleteModal &&
        <Modal
          onCancel={handleDeleteTertiaryPromoItemModalCancel}
          onOk={handleDeleteTertiaryPromoItemNavAction}
          title='Delete confirmation'
          visible={showTertiaryPromoItemDeleteModal}
        >
          <Text>
            This Tertiary PromoItem will be removed. Are you sure you want to delete this?
          </Text>
        </Modal>
    }
  </>
  )
}

TertiaryNavInfo.propTypes = {
  addNewTertiaryColumn: PropTypes.func,
  addNewTertiaryColumnList: PropTypes.func,
  addNewTertiaryLink: PropTypes.func,
  addNewTertiaryPromoItem: PropTypes.func,
  deleteNewTertiaryColumn: PropTypes.func,
  deleteNewTertiaryColumnList: PropTypes.func,
  handlSecondaryLinkTargetUpdate: PropTypes.func,
  handleTertiaryNavDelete: PropTypes.func,
  handleTertiaryPromoItemDelete: PropTypes.func,
  handleThirdLinkColumnLabelUpdate: PropTypes.func,
  handleThirdLinkColumnTargetUpdate: PropTypes.func,
  handleThirdLinkColumnUrlUpdate: PropTypes.func,
  handleThirdLinkDescriptionUpdate: PropTypes.func,
  handleThirdLinkImageUrlUpdate: PropTypes.func,
  handleThirdLinkNameUpdate: PropTypes.func,
  handleThirdLinkUrlUpdate: PropTypes.func,
  index: PropTypes.number,
  primaryNavLinkIndex: PropTypes.number,
  secondaryNavLink: PropTypes.object,
  secondaryNavLinkIndex: PropTypes.number
}

TertiaryNavInfo.defaultProps = {

}

export default TertiaryNavInfo
