import React from 'react'
import { DatePicker, Form } from 'antd'
import PropTypes from 'prop-types'
import Label from '../Label/Label'

const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD h:mm A'
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'

const { Item } = Form

const CustomDatePicker = ({
  allowClear,
  className,
  disabled,
  disabledDate,
  format,
  isInline,
  label,
  labelClassName,
  onChange,
  showTime,
  style,
  value
}) => {
  const getDatePickerFormat = () => {
    return format || (showTime ? DEFAULT_DATE_TIME_FORMAT : DEFAULT_DATE_FORMAT)
  }

  const renderTime = format => {
    return showTime
      ? {
        format: format,
        minuteStep: 5,
        use12Hours: true
      }
      : false
  }

  return (
    <Item>
      <Label className={labelClassName} isInline={isInline} value={label} />
      <DatePicker
        {...(disabledDate && { disabledDate })}
        {...(onChange && { onChange })}
        allowClear={allowClear}
        className={className}
        disabled={disabled}
        format={getDatePickerFormat()}
        showTime={renderTime()}
        style={style}
        value={value}
      />
    </Item>
  )
}

CustomDatePicker.propTypes = {
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disabledDate: PropTypes.func,
  format: PropTypes.string,
  isInline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  showTime: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.object
}

CustomDatePicker.defaultProps = {
  allowClear: false,
  disabled: false,
  labelClassName: '',
  showTime: false,
  style: {},
  value: null
}

export default CustomDatePicker
