import React from 'react'

import { Menu } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'

import styles from './Sidebar.module.css'

const { SubMenu, Item: MenuItem } = Menu

const Sidebar = ({
  customMenuStyles,
  defaultOpenKeys,
  defaultSelectedKeys,
  isCollapsed,
  menuItems,
  mode,
  onClick,
  theme
}) => {
  const getMenuItem = (menuItem) => {
    const { disabled, icon, key, label } = menuItem
    return <MenuItem
      key = { key }
      className={styles.menuItem}
      disabled = {disabled}
      icon = { icon }
      onClick = { onClick }
    >
      { label }
    </MenuItem>
  }

  const getMenuListItems = (menuItems) => menuItems.map(menuItem => getSubMenuItem(menuItem))

  const getSubMenuItem = (menuItem) => {
    const hasSubmenu = idx(menuItem, _ => _.submenu.items)

    if (hasSubmenu) {
      const { submenu: { disabled, icon, items, key, label } } = menuItem
      const SubMenuItems = getMenuListItems(items)
      return (
        <SubMenu
          key={key}
          className={styles.menuItem}
          disabled={disabled}
          icon={icon}
          title={label}>
          {SubMenuItems}
        </SubMenu>
      )
    }
    return getMenuItem(menuItem)
  }

  return (
    <Menu
      className={styles.menuContainer}
      defaultOpenKeys={defaultOpenKeys}
      defaultSelectedKeys={defaultSelectedKeys}
      inlineCollapsed={isCollapsed}
      mode={mode}
      style={customMenuStyles}
      theme={theme}
    >
      {
        getMenuListItems(menuItems)
      }
    </Menu>
  )
}

Sidebar.propTypes = {
  customMenuStyles: PropTypes.object,
  defaultOpenKeys: PropTypes.array,
  defaultSelectedKeys: PropTypes.array,
  isCollapsed: PropTypes.bool.isRequired,
  menuItems: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  theme: PropTypes.string.isRequired
}

Sidebar.defaultProps = {
  isCollapsed: false,
  menuItems: [],
  mode: 'inline',
  theme: 'light'
}

export default Sidebar
