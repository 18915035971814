import React, { useEffect, useState } from 'react'

import {
  SearchOutlined,
  SolutionOutlined,
  SwapOutlined
} from '@ant-design/icons'
import { Button, Col, Input, List, Row } from 'antd'
import PropTypes from 'prop-types'

import 'antd/dist/antd.css'
import styles from './MultiSelect.module.css'
import './CustomList.css'
const { Item: ListItem } = List

const MultiSelect = ({
  allowSelectAll,
  availableListHeaderString,
  availableListItems,
  displayField,
  displayTextFilter,
  onAdd,
  onFilter,
  onHeadlinerClick,
  onRemove,
  resourceName,
  selectedListHeaderString,
  selectedListItems,
  showHeadlinerSelection,
  showPriceClassLegend
}) => {
  const [availableItemsFilterValue, setavailableItemsFilterValue] = useState('')
  const [selectedItemsFilterValue, setSelectedItemsFilterValue] = useState('')
  const [filteredAllItems, setFilteredAllItems] = useState([])
  const [filteredSelectedItems, setFilteredSelectedItems] = useState([])

  useEffect(() => {
    if (!availableListItems) return
    const filteredAllItems = onFilter(
      availableListItems,
      availableItemsFilterValue
    )
    setFilteredAllItems(filteredAllItems)
  }, [availableListItems, availableItemsFilterValue, onFilter])

  useEffect(() => {
    if (!selectedListItems) return
    const filteredSelectedItems = onFilter(
      selectedListItems,
      selectedItemsFilterValue
    )
    setFilteredSelectedItems(filteredSelectedItems)
  }, [selectedListItems, selectedItemsFilterValue, onFilter])

  const filterAvailableItems = e => {
    const availableItemsFilterValue = e.target.value

    const filteredAllItems =
      availableItemsFilterValue !== ''
        ? onFilter(availableListItems, availableItemsFilterValue)
        : availableListItems

    setFilteredAllItems(filteredAllItems)
    setavailableItemsFilterValue(availableItemsFilterValue)
  }

  const filterSelectedItems = e => {
    const selectedItemsFilterValue = e.target.value

    const filteredSelectedItems =
      selectedItemsFilterValue !== ''
        ? onFilter(selectedListItems, selectedItemsFilterValue)
        : selectedListItems

    setFilteredSelectedItems(filteredSelectedItems)
    setSelectedItemsFilterValue(selectedItemsFilterValue)
  }

  const onSelectAllClick = e => {
    const filteredItems = [...filteredAllItems]

    const itemIds = filteredItems.reduce((ids, item) => {
      return [...ids, item]
    }, [])

    onAdd(itemIds)

    e.preventDefault()
    return false
  }

  const onDeselectAllClick = e => {
    const filteredSelectedListItems = [...filteredSelectedItems]

    const itemIds = filteredSelectedListItems.reduce((ids, item) => {
      return [...ids, item]
    }, [])

    onRemove(itemIds)
    e.preventDefault()
    return false
  }

  const handleHeadlinerClick = (item, event) => {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    item.is_headliner = !item.is_headliner
    onHeadlinerClick(item)
  }

  const getListComponent = (
    dataSource,
    displaySelectAllOption = false,
    filterValue,
    header,
    onClick,
    onFilterValueChange,
    showHeadlinerSelection = false
  ) => (
    <Col span={10}>
      {displayTextFilter && (
        <Row>
          <Input
            className={styles.multiSelectFilter}
            onChange={onFilterValueChange}
            prefix={<SearchOutlined />}
            value={filterValue}
          />
        </Row>
      )}
      <Row>
        <List
          className={styles.multiSelectList}
          dataSource={dataSource}
          header={header}
          renderItem={item =>
            getListItem(item, onClick, showHeadlinerSelection, displaySelectAllOption, showPriceClassLegend)
          }
        />
      </Row>
      {displaySelectAllOption && (
        <Row className={styles.selectionRow}>
          <Button onClick={onSelectAllClick} type="link">Select All</Button> <span className={styles.delimiter}>/</span>
          <Button onClick={onDeselectAllClick} type="link">Deselect All</Button>
        </Row>
      )}
    </Col>
  )

  const getHeadLinerListItem = (item, onClick) => {
    const listClassName = item.highlightItem
      ? styles.highlightListItem
      : styles.multiSelectListItem
    const headlinerClassName = item.is_headliner && styles.headliner
    return <ListItem
      className={
        listClassName
      }
      extra={
        <Button
          className={headlinerClassName}
          icon={<SolutionOutlined />}
          onClick={event => handleHeadlinerClick(item, event)}
          shape="circle"
        />
      }
      onClick={() => onClick(item)}
    >
      {item[displayField]}
    </ListItem>
  }

  const getHighlightListItem = (item, onClick, displaySelectAllOption) => {
    const listClassName = (!displaySelectAllOption && item.highlightItem)
      ? styles.highlightListItem
      : styles.multiSelectListItem

    return <ListItem
      className={listClassName}
      onClick={() => onClick(item)}
    >
      {item[displayField]}
    </ListItem>
  }

  const getPriceClassLegend = (item, onClick) => {
    const listClassName = item.priceClass
      ? styles.priceClassLegend
      : styles.multiSelectListItem
    const legendClass = item.priceClass ? item.priceClass : ''
    return <ListItem
      className={
        listClassName
      }
      extra={
        (<span
          className={styles[`legend${legendClass}`]}
        />)
      }
      onClick={() => onClick(item)}
    >
      {item[displayField]}
    </ListItem>
  }

  const getListItem = (item, onClick, showHeadlinerSelection, displaySelectAllOption, showPriceClassLegend) =>
    showHeadlinerSelection ? (
      getHeadLinerListItem(item, onClick, displaySelectAllOption)
    ) : showPriceClassLegend ? (
      getPriceClassLegend(item, onClick, displaySelectAllOption)
    ) : (
      getHighlightListItem(item, onClick, displaySelectAllOption)
    )

  const filteredAllEventsHeaderText = availableItemsFilterValue.length > 0 ? `${filteredAllItems.length} / ${availableListItems.length}` : availableListItems.length
  const filteredSelectedHeaderText = selectedItemsFilterValue.length > 0 ? `${filteredSelectedItems.length} / ${selectedListItems.length}` : selectedListItems.length
  const availableHeaderText = availableListHeaderString || `Available ${resourceName} - ${filteredAllEventsHeaderText}`
  const selectedHeaderText = selectedListHeaderString || `Selected ${resourceName} - ${filteredSelectedHeaderText}`

  const availableListsHeader = (
    <Row className={styles.multiSelectHeader}>
      { availableHeaderText }
    </Row>
  )
  const selectedListsHeader = (
    <Row className={styles.multiSelectHeader}>
      { selectedHeaderText }
    </Row>
  )

  const availableList = getListComponent(
    filteredAllItems,
    allowSelectAll,
    availableItemsFilterValue,
    availableListsHeader,
    onAdd,
    filterAvailableItems,
    false
  )

  const selectedList = getListComponent(
    filteredSelectedItems,
    false,
    selectedItemsFilterValue,
    selectedListsHeader,
    onRemove,
    filterSelectedItems,
    showHeadlinerSelection
  )

  return (
    <Row>
      {availableList}
      <Col className={styles.switchImage} span={2}>
        <SwapOutlined />
      </Col>
      {selectedList}
    </Row>
  )
}

MultiSelect.propTypes = {
  allowSelectAll: PropTypes.bool.isRequired,
  availableListHeaderString: PropTypes.string,
  availableListItems: PropTypes.array.isRequired,
  displayField: PropTypes.string.isRequired,
  displayTextFilter: PropTypes.bool,
  onAdd: PropTypes.func,
  onFilter: PropTypes.func,
  onHeadlinerClick: PropTypes.func,
  onRemove: PropTypes.func,
  resourceName: PropTypes.string.isRequired,
  selectedListHeaderString: PropTypes.string,
  selectedListItems: PropTypes.array.isRequired,
  showHeadlinerSelection: PropTypes.bool,
  showPriceClassLegend: PropTypes.bool
}

MultiSelect.defaultProps = {
  allowSelectAll: true,
  availableListItems: [],
  displayField: 'label',
  displayTextFilter: true,
  resourceName: 'Items',
  selectedListItems: [],
  showHeadlinerSelection: false,
  showPriceClassLegend: false
}

export default MultiSelect
