const VALIDATION_ERRORS = {
  INVALID_DESCRIPTION: 'Provide valid description.',
  INVALID_ICON_URL: 'Provide valid icon url.',
  INVALID_TITLE: 'Provide valid title'
}

const isValidTitle = title => title.length > 0

const isValidDescription = description => description.length > 0

const isValidIconUrl = iconUrl => iconUrl.length > 0

export const validateSponsor = sponsor => {
  const { description, icon_url: iconUrl, title } = sponsor

  if (!isValidTitle(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TITLE }
  }

  if (!isValidDescription(description)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_DESCRIPTION }
  }

  if (!isValidIconUrl(iconUrl)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_ICON_URL }
  }

  return { isValid: true, message: '' }
}
