import 'isomorphic-fetch'

import { tmImportLogsEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import { FETCH_TM_IMPORT_LOGS } from '../actionTypes'

export const fetchTmImportLogsList = params => async dispatch => {
  const { method, url } = tmImportLogsEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-type': 'application/json; charset=UTF-8'
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { tmImportLogs: rows, tmImportLogsCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_TM_IMPORT_LOGS,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}
