const VALIDATION_ERRORS = {
  INVALID_NAME: 'Provide valid name.',
  INVALID_LOGO_URL: 'Provide valid logo url.',
  INVALID_URL: 'Provide valid url.',
  INVALID_LEGAL_COPY: 'Provide valid legal copy'
}

const isValidField = field => field.length > 0

export const validateVenue = venue => {
  const { legalCopy, logo_url: logoUrl, name, url } = venue

  if (!isValidField(name)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_NAME }
  }

  if (!isValidField(url)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_URL }
  }

  if (!isValidField(logoUrl)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_LOGO_URL }
  }

  if (!isValidField(legalCopy)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_LEGAL_COPY }
  }

  return { isValid: true, message: '' }
}
