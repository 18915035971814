export const doorOpenTimeConfig = {
  KovZpZA7AAEA: {
    artists: {
      K8vZ9171o87: {
        name: 'New York Rangers',
        doorOpenTimeDelta: 90
      },
      K8vZ9171o57: {
        name: 'New York Knicks',
        doorOpenTimeDelta: 90
      },
      default: {
        name: 'default',
        doorOpenTimeDelta: 60
      }
    },
    name: 'Madison Square Garden'
  },
  KovZpZA6AJ6A: {
    artists: {
      default: {
        name: 'default',
        doorOpenTimeDelta: 60
      }
    },
    name: 'The Chicago Theatre'
  },
  KovZ917A96V: {
    artists: {
      default: {
        name: 'default',
        doorOpenTimeDelta: 60
      }
    },
    name: 'Club Bar & Grill at Madison Square Garden'
  },
  KovZpZAE7vdA: {
    artists: {
      K8vZ9171K10: {
        name: 'Rockettes',
        doorOpenTimeDelta: 90
      },
      default: {
        name: 'default',
        doorOpenTimeDelta: 60
      }
    },
    name: 'Radio City Music Hall'
  },
  KovZpZAFdlAA: {
    artists: {
      default: {
        name: 'default',
        doorOpenTimeDelta: 60
      }
    },
    name: 'Westchester County Center'
  },
  KovZpZAEAd6A: {
    artists: {
      default: {
        name: 'default',
        doorOpenTimeDelta: 60
      }
    },
    name: 'Beacon Theatre'
  },
  KovZ917AQXF: {
    artists: {
      default: {
        name: 'default',
        doorOpenTimeDelta: 60
      }
    },
    name: 'Marquee New York'
  },
  ZFr9jZ11F6: {
    artists: {
      default: {
        name: 'default',
        doorOpenTimeDelta: 60
      }
    },
    name: 'Marquee New York'
  },
  KovZ917AOzz: {
    artists: {
      default: {
        name: 'default',
        doorOpenTimeDelta: 60
      }
    },
    name: 'The Highlight Room at Dream Hollywood'
  },
  KovZpZA7kvlA: {
    default: {
      name: 'default',
      doorOpenTimeDelta: 60
    },
    name: 'Hulu Theater at Madison Square Garden'
  }
}
