import React, { useState } from 'react'
import { Button, notification, Switch } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { addFlag, resetFlagsActionStatus } from '~actions'
import { ResultModal, FlagBasicInfo } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout } from '~stories'
import { validateFlag } from '~validators'

import { FLAG_APPS, FLAGS_COLOR_CODES, FLAGS_TYPES } from '../../constants'
import styles from './NewFlag.module.css'

const PAGE_TITLE = 'New Flag'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const NewFlag = ({
  addFlag,
  newFlagId,
  updateMessage,
  updateStatus
}) => {
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()
  const [flag, setFlag] = useState({
    app_name: 'msg',
    color: null,
    flag_priority: 1,
    html_description: '',
    icon_url: '',
    show_flag: true,
    show_icon: false,
    sub_type: '',
    title: '',
    type: 'manual'
  })

  const handleSubmit = () => {
    const { isValid, message } = validateFlag(flag)

    if (!isValid) {
      notification.error({ message })
      return
    }

    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    addFlag({
      ...flag
    }, accessToken)
  }

  const handleLogoUpload = (thumbnailImageUrl, secureImageURL) => {
    const imageUrl = secureImageURL || thumbnailImageUrl

    setFlag({
      ...flag,
      icon_url: imageUrl
    })
  }

  const handleDescriptionUpdate = htmlDescription => {
    setFlag({
      ...flag,
      html_description: htmlDescription
    })
  }

  const handleTitleUpdate = event => {
    const title = event.target.value
    setFlag({
      ...flag,
      title
    })
  }

  const handlePriortyChange = sequence => {
    setFlag({
      ...flag,
      flag_priority: sequence
    })
  }

  const handleFlagStatus = status => {
    setFlag({
      ...flag,
      show_flag: status
    })
  }

  const handleSubTypeChange = (selectedItem = '') => {
    setFlag({
      ...flag,
      sub_type: selectedItem
    })
  }

  const handleModalClose = () => {
    resetFlagsActionStatus()
  }

  const handleColorChange = selectedItem => {
    const [color] = FLAGS_COLOR_CODES.filter(flag => flag.value === selectedItem)

    setFlag({
      ...flag,
      color
    })
  }

  const handleTypeChange = selectedItem => {
    const [type] = FLAGS_TYPES.filter(flag => flag.value === selectedItem)
    setFlag({
      ...flag,
      type: type.value
    })
  }

  const handleShowIconChange = event => {
    const status = event.target.checked
    setFlag({
      ...flag,
      show_icon: status
    })
  }

  const handleAppNameChange = selectedItem => {
    const [appName] = FLAG_APPS.filter(flag => flag.value === selectedItem)
    const subType = appName.value === 'rox' && flag?.sub_type ? flag?.sub_type : 'indy'
    setFlag({
      ...flag,
      app_name: appName.value,
      sub_type: subType
    })
  }

  const addFlagsButton = (
    <Button
      onClick={() => {
        resetFlagsActionStatus()
        window.location.reload()
      }}
      type="primary"
    >
      Add Another
    </Button>
  )

  const goToListFlagsButton = (
    <Route key="listFlags" render={({ history }) => (
      <Button
        onClick={() => {
          resetFlagsActionStatus()
          history.push('/flags')
          window.location.reload()
        }}
        type="primary"
      >
        Go to Flags List
      </Button>
    )} />
  )

  const editFlag = (
    <Route key="editFlag" render={({ history }) => (

      <Button
        onClick={() => {
          newFlagId && history.push(`/flags/${newFlagId}/edit`)
          window.location.reload()
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const addFlagButton = <Button onClick={handleSubmit} type='primary'>
    Add
  </Button>

  const toggleSwitch = <Switch checked={flag?.show_flag} checkedChildren="Show" defaultChecked onChange={handleFlagStatus} unCheckedChildren="Hide" />

  return (
    <PageLayout extra={[toggleSwitch, addFlagButton]} title={PAGE_TITLE}>
      <div className={styles.FlagsLayoutBackground}>
        <FlagBasicInfo
          flag={flag}
          handleAppNameChange={handleAppNameChange}
          handleColorChange={handleColorChange}
          handleDescriptionChange={handleDescriptionUpdate}
          handleImageUrlUpdate={handleLogoUpload}
          handlePriortyChange={handlePriortyChange}
          handleShowIconChange={handleShowIconChange}
          handleSubTypeChange={handleSubTypeChange}
          handleTitleChange={handleTitleUpdate}
          handleTypeChange={handleTypeChange}
        />
      </div>
      {
        updateStatus &&
        <ResultModal
          actions={[
            editFlag,
            goToListFlagsButton,
            addFlagsButton
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

const mapStateToProps = ({ flags }) => ({
  newFlagId: flags.newFlagId,
  updateMessage: flags.message,
  updateStatus: flags.success
})

const mapDispatchToProps = {
  addFlag
}

NewFlag.propTypes = {
  addFlag: PropTypes.func,
  newFlagId: PropTypes.string,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewFlag)
