import {
  FETCH_ARTISTS,
  STORE_ARTIST_TO_EDIT,
  ARTIST_UPDATE_STATUS
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTISTS:
      return {
        ...state,
        ...action.payload
      }
    case STORE_ARTIST_TO_EDIT:
      return {
        ...state,
        artistToEdit: action.payload.artist
      }
    case ARTIST_UPDATE_STATUS:
      return {
        ...state,
        status: action.payload.status
      }
    default:
      return state
  }
}
