import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'antd'

import PropTypes from 'prop-types'

import Label from '../Label/Label'
import styles from './RichTextInput.module.css'

const { Item } = Form
const TextAreaHTMLEditor = ({
  id,
  isError,
  isReadOnly,
  label,
  labelClassName,
  onChange,
  value
}) => {
  const [showPreview, setShowPreview] = useState(true)
  const [innerHTML, setInnerHTML] = useState(value)

  const textInput = useRef()

  useEffect(() => {
    if (value) {
      setInnerHTML(value)
    }
  }, [value])

  const handleShowPreview = (event) => {
    event.preventDefault()
    const innerHTML = textInput.current.innerText
    setShowPreview(true)
    setInnerHTML(innerHTML)
  }

  const showEditor = (event) => {
    event.preventDefault()
    setShowPreview(false)
  }

  const onBlur = (event) => {
    const updatedText = textInput.current.innerText
    setInnerHTML(updatedText)
    onChange && onChange(updatedText)
  }

  const buttonText = showPreview ? 'Edit' : 'Preview'

  const buttonClick = showPreview ? showEditor : handleShowPreview

  const htmlEditor = !isReadOnly && (
    <div className={styles.htmlEditorHeader}>
      <button
        className={styles.htmlEditorActionButton}
        onClick={buttonClick}
      >
        {buttonText}
      </button>
    </div>
  )

  return (
    <Item>
      <Label className={labelClassName} value={label} />
      <div id={id}
        {...(isError ? { className: styles.errorHtmlEditorContainer } : { className: styles.htmlEditorContainer })}>
        {htmlEditor}
        {
          showPreview
            ? <div
              className={styles.previewContainer}
              dangerouslySetInnerHTML={{ __html: innerHTML }}
            />
            : <div
              ref={textInput}
              className={styles.htmlTextContainer}
              contentEditable="true"
              onBlur={onBlur}
              suppressContentEditableWarning={true}
            >
              {innerHTML}
            </div>
        }
      </div>
    </Item>
  )
}

TextAreaHTMLEditor.propTypes = {
  id: PropTypes.string,
  isError: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}

TextAreaHTMLEditor.defaultProps = {
  labelClassName: ''
}

export default TextAreaHTMLEditor
