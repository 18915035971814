import React from 'react'
import { Typography } from 'antd'
import moment from 'moment'
import { ItemList } from '~components'
import { PageLayout } from '~stories'

import './TMImportLogs.modules.css'

const { Link, Text } = Typography

const getTMImportLogWithLink = tmLog => <Text>{moment(tmLog.importedAt).format('MM-DD-YYYY HH:mm')}</Text>
const getTMImportNewEventsLink = tmLog => tmLog.newEvents.map((eventId, index) => <><Link href={`/events/${eventId}/edit`}>{eventId}</Link>{index === tmLog.newEvents.length - 1 ? '' : ', '}</>)
const getTMImportUpdatedEventsLink = tmLog => tmLog.updatedEvents.map((eventId, index) => <><Link href={`/events/${eventId}/edit`}>{eventId}</Link>{index === tmLog.updatedEvents.length - 1 ? '' : ', '}</>)

const columnData = [
  {
    dataIndex: 'importedAt',
    render: (text, tmLog) => getTMImportLogWithLink(tmLog),
    title: 'Imported At',
    width: '30%'
  },
  {
    title: 'New Events',
    dataIndex: 'newEvents',
    render: (text, tmLog, index) => getTMImportNewEventsLink(tmLog),
    width: '35%'
  },
  {
    title: 'Updated Events',
    dataIndex: 'updatedEvents',
    render: (text, tmLog, index) => getTMImportUpdatedEventsLink(tmLog),
    width: '35%'
  }
]
const TMImportLogsList = () => {
  return (
    <PageLayout title='TM Import Logs'>
      <div className='tmImportLogsList-layout-background'>
        <ItemList columns={columnData} resource='tmImportLogs' />
      </div>
    </PageLayout>
  )
}

export default TMImportLogsList
