import {
  FETCH_NAVIGATIONS,
  NAVIGATION_ADD_STATUS,
  NAVIGATION_UPDATE_STATUS
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_NAVIGATIONS:
      return {
        ...state,
        ...action.payload
      }
    case NAVIGATION_ADD_STATUS:
      return {
        ...state,
        ...action.payload
      }
    case NAVIGATION_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
