import 'isomorphic-fetch'

import { flagEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import {
  ADD_NEW_FLAG,
  FETCH_FLAGS,
  FLAG_UPDATE_STATUS,
  STORE_FLAG_TO_EDIT
} from '../actionTypes'

export const fetchFlagsList = params => async dispatch => {
  const { method, url } = flagEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { flags: rows, flagsCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_FLAGS,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchFlagById = flagObjectId => async dispatch => {
  try {
    const { method, url } = flagEndpoint.edit(flagObjectId)
    const idToken = getIdToken()
    const apiResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .catch(error => console.error(error))
    dispatch({
      type: STORE_FLAG_TO_EDIT,
      payload: {
        ...apiResponse
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export const updateFlag = (flagObjectId, flag, accessToken) => async dispatch => {
  try {
    const { method, url } = flagEndpoint.update(flagObjectId)
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify(flag),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { message, success } = response
        dispatch({
          type: FLAG_UPDATE_STATUS,
          payload: { message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const resetUpdateFlagStatus = () => async dispatch => {
  dispatch({
    type: FLAG_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}

export const addFlag = (flag, accessToken) => async dispatch => {
  try {
    const { method, url } = flagEndpoint.add
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify({ flag }),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { addedFlag: { _id: newFlagId }, message, success } = response
        dispatch({
          type: ADD_NEW_FLAG,
          payload: { newFlagId, message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const resetFlagsActionStatus = () => async dispatch => {
  dispatch({
    type: ADD_NEW_FLAG,
    payload: {
      error: false,
      message: '',
      success: false
    }
  })
}
