import React from 'react'
import PropTypes from 'prop-types'

import { TextArea } from '~stories'

const VenueGeneralInfo = ({
  data: {
    childRule,
    generalRule
  } = {},
  handleGeneralInfoUpdate
}) => {
  return (
    <>
      <TextArea id='childRule' label={'Child Rule'} onChange={handleGeneralInfoUpdate} value={childRule} />
      <TextArea id='generalRule' label={'General Rule'} onChange={handleGeneralInfoUpdate} value={generalRule} />
    </>
  )
}

VenueGeneralInfo.propTypes = {
  data: PropTypes.object,
  handleGeneralInfoUpdate: PropTypes.func.isRequired
}

export default VenueGeneralInfo
