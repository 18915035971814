import 'isomorphic-fetch'

import { dashboardEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import { FETCH_DASHBOARD_STATS } from '../actionTypes'

export const fetchDashboardStats = () => async dispatch => {
  const { method, url } = dashboardEndpoint.stats
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  dispatch({
    type: FETCH_DASHBOARD_STATS,
    payload: {
      ...apiResponse
    }
  })
}
