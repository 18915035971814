/* eslint-disable camelcase */
import React from 'react'
import { Col, Row, InputNumber } from 'antd'
import PropTypes from 'prop-types'

import { LogoUploader } from '~components'
import { MultiSelectDropdown, Label, Checkbox, Input, RichTextInput } from '~stories'

import { FLAGS_COLOR_CODES, FLAG_APPS, ROX_FLAG_SUB_TYPE } from '../../constants'

const FlagBasicInfo = ({
  flag,
  handleAppNameChange,
  handleColorChange,
  handleDescriptionChange,
  handleImageUrlUpdate,
  handlePriortyChange,
  handleShowIconChange,
  handleSubTypeChange,
  handleTitleChange
}) => {
  const {
    html_description,
    title,
    color,
    show_icon,
    icon_url: imageUrl,
    app_name = 'msg',
    flag_priority: flagPriority,
    sub_type: subType
  } = flag
  return (
    <>
      <Input label={'Title'} onChange={handleTitleChange} value={title} />
      <RichTextInput label='Description' onChange={handleDescriptionChange} value={html_description}/>
      <Row>
        <Col span={8}>
          <Checkbox
            checked={show_icon}
            label="Show Icon"
            onChange={handleShowIconChange}
          />
        </Col>
        <Col span={8}>
          <Row>
            <Label
              isInline={false}
              value='Colors'
            /></Row>
          <Row>
            <MultiSelectDropdown
              allowClear={true}
              customStyle={{ width: 150 }}
              displayField="name"
              dropdownOptions={FLAGS_COLOR_CODES}
              handleChange={handleColorChange}
              selectedItems={[color]}
              valueField="value"
            />
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Label
              isInline={false}
              value='App Name'
            /></Row>
          <Row>
            <MultiSelectDropdown
              allowClear={true}
              customStyle={{ width: 150 }}
              displayField="name"
              dropdownOptions={FLAG_APPS}
              handleChange={handleAppNameChange}
              selectedItems={[app_name]}
              valueField="value"
            />
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={18}>
          <Label value='Image Url' />
          <div>
            <LogoUploader handleUpload={(thumbnailImageUrl, secureImageURL) => {
              const imageUrl = secureImageURL || thumbnailImageUrl
              handleImageUrlUpdate(imageUrl)
            }} logoUrl={imageUrl} />
          </div>
        </Col>
      </Row>
      {app_name === 'rox' && <Row gutter={16}>
        <Col span={8}>
          <Row>
            <Label
              isInline={false}
              value='Sequence'
            /></Row>
          <InputNumber defaultValue={1} min={1} onChange={handlePriortyChange} value={flagPriority} />
        </Col>
        <Col span={8}>
          <Row>
            <Label
              isInline={false}
              value='Sub Type'
            /></Row>
          <Row>
            <MultiSelectDropdown
              allowClear={true}
              customStyle={{ width: 150 }}
              displayField="name"
              dropdownOptions={ROX_FLAG_SUB_TYPE}
              handleChange={handleSubTypeChange}
              selectedItems={[subType]}
              valueField="value"
            />
          </Row>
        </Col>
      </Row>}
    </>
  )
}

FlagBasicInfo.propTypes = {
  flag: PropTypes.object.isRequired,
  handleAppNameChange: PropTypes.func,
  handleColorChange: PropTypes.func,
  handleDescriptionChange: PropTypes.func.isRequired,
  handleImageUrlUpdate: PropTypes.func,
  handleLogoUpload: PropTypes.func,
  handlePriortyChange: PropTypes.func,
  handleShowIconChange: PropTypes.func,
  handleSubTypeChange: PropTypes.func,
  handleTitleChange: PropTypes.func.isRequired,
  handleTypeChange: PropTypes.func
}

FlagBasicInfo.defaultProps = {
  flag: {}
}

export default FlagBasicInfo
