import React, { useEffect, useState } from 'react'
import { Button, Card, notification, Switch } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { fetchOptInModalById, resetUpdateOptInModalStatus, updateOptInModal } from '~actions'
import { OptInInfo, ResultModal } from '~components'
import { FLAG_APPS } from '~constants'
import { getCurrentUser } from '~context'
import { PageLayout, Spinner } from '~stories'
import { validateOptInModal, VALIDATION_ERRORS, validateMaxHeaderCharLimit, validateMaxDescriptionCharLimit, validateMaxTitleCharLimit, validateMaxMessageCharLimit } from '~validators'

import styles from './EditOptInModal.module.css'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const EditOptInModal = ({
  venuesList,
  fetchOptInModal,
  resetUpdateOptInModalStatus,
  route,
  optInModal,
  updateMessage,
  updateOptInModal,
  updateStatus
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState()
  const [optInModalId, setOptInModalId] = useState('')
  const [optInModalToEdit, setOptInModalToEdit] = useState()
  const [allVenues, setAllVenues] = useState()
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    const optInModalId = idx(route, _ => _.match.params.id)

    if (optInModalId) {
      setIsDataLoaded(false)
      fetchOptInModal(optInModalId)
      setOptInModalId(optInModalId)
    }
  }, [route, fetchOptInModal])

  useEffect(() => {
    if (optInModal) {
      setOptInModalToEdit(optInModal)
      setAllVenues(venuesList)
      setIsDataLoaded(true)
    }
  }, [optInModal, venuesList])

  useEffect(() => {
    if (updateStatus && updateMessage) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
      resetUpdateOptInModalStatus()
    }
  }, [updateStatus, updateMessage, resetUpdateOptInModalStatus])

  const handleSubmit = () => {
    const { isValid, message } = validateOptInModal(optInModalToEdit)
    if (!isValid) {
      notification.error({ message })
      return
    }

    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    setIsDataLoaded(false)

    updateOptInModal(optInModalId, optInModalToEdit, accessToken)
  }

  const handleDescriptionChange = descriptionText => {
    const optInModalObj = { ...optInModalToEdit }
    let description = optInModalObj.description
    description = descriptionText || ''
    if (validateMaxDescriptionCharLimit(description)) {
      const updatedOptInModal = { ...optInModalObj, description }
      setOptInModalToEdit(updatedOptInModal)
    } else {
      notification.error({ message: VALIDATION_ERRORS.DESC_MAX_CHAR_LIMIT })
    }
  }

  const keepEditingOptInModal = (
    <Route key="editOptInModals" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateOptInModalStatus()
          history.push(`/opt-in-modal/${optInModalId}/edit`)
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const goToListOptInModalsButton = (
    <Route key="listOptInModals" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateOptInModalStatus()
          history.push('/opt-in-modal')
        }}
        type="primary"
      >
        Go to OptInModals List
      </Button>
    )} />
  )

  const handleModalClose = () => {
    resetUpdateOptInModalStatus()
  }

  const handleAppNameChange = selectedItem => {
    const [appName] = FLAG_APPS.filter(flag => flag.value === selectedItem)

    const updatedOptInModal = { ...optInModalToEdit, app_name: appName.value }

    setOptInModalToEdit(updatedOptInModal)
  }

  const handleOptInModalCountDownStatus = (status) => {
    const optInModalObj = { ...optInModalToEdit }
    const { count_down: countDown } = optInModalObj
    setOptInModalToEdit({
      ...optInModalObj,
      count_down: {
        ...countDown,
        status,
        ...(!status && { expiration_date_time: null })
      }
    })
  }

  const handleCoundDownEndDate = (date) => {
    const optInModalObj = { ...optInModalToEdit }
    const { count_down: countDown } = optInModalObj
    setOptInModalToEdit({
      ...optInModalObj,
      count_down: {
        ...countDown,
        expiration_date_time: +date.format('X')
      }
    })
  }

  const handleTitleChange = (title) => {
    const optInModalObj = { ...optInModalToEdit }
    if (validateMaxTitleCharLimit(title)) {
      const { success_screen: successScreen } = optInModalObj
      setOptInModalToEdit({
        ...optInModalObj,
        success_screen: {
          ...successScreen,
          title
        }
      })
    } else {
      notification.error({ message: VALIDATION_ERRORS.TITLE_MAX_CHAR_LIMIT })
    }
  }

  const handleMessageChange = (message) => {
    const optInModalObj = { ...optInModalToEdit }
    if (validateMaxMessageCharLimit(message)) {
      const { success_screen: successScreen } = optInModalObj
      setOptInModalToEdit({
        ...optInModalObj,
        success_screen: {
          ...successScreen,
          message
        }
      })
    } else {
      notification.error({ message: VALIDATION_ERRORS.MESSAGE_MAX_CHAR_LIMIT })
    }
  }

  const handleButtonTextUpdate = (event) => {
    const optInModalObj = { ...optInModalToEdit }
    const buttonText = event.target.value
    setOptInModalToEdit({
      ...optInModalObj,
      button_text: buttonText
    })
  }

  const handleDisclaimerChange = disclaimer => {
    const optInModalObj = { ...optInModalToEdit }
    setOptInModalToEdit({
      ...optInModalObj,
      disclaimer
    })
  }

  const handleFormIdUpdate = event => {
    const optInModalObj = { ...optInModalToEdit }
    const formstackId = event.target.value
    const re = /^[0-9\b]+$/
    if (formstackId === '' || re.test(formstackId)) {
      setOptInModalToEdit({
        ...optInModalObj,
        formstack_id: formstackId
      })
    }
  }

  const handleHeaderChange = header => {
    const optInModalObj = { ...optInModalToEdit }
    if (validateMaxHeaderCharLimit(header)) {
      setOptInModalToEdit({
        ...optInModalObj,
        header
      })
    } else {
      notification.error({ message: VALIDATION_ERRORS.HEADER_MAX_CHAR_LIMIT })
    }
  }

  const handleImageUpload = (thumbnailImageUrl, secureImageURL) => {
    const optInModalObj = { ...optInModalToEdit }
    const imageUrl = secureImageURL || thumbnailImageUrl

    setOptInModalToEdit({
      ...optInModalObj,
      image_url: imageUrl
    })
  }

  const handleOptInModalStatus = (status) => {
    const optInModalObj = { ...optInModalToEdit }
    setOptInModalToEdit({
      ...optInModalObj,
      show_modal: status
    })
  }

  const handleVenueUpdate = (venueName) => {
    const [filteredVenue] = allVenues.filter(venue => venue.name === venueName)

    const { id } = filteredVenue
    const optInModalObj = { ...optInModal }

    setOptInModalToEdit({
      ...optInModalObj,
      venue_id: id
    })
  }

  const updateOptInModalButton = <Button className={styles.updateActionButton} onClick={handleSubmit} type='primary'>
    Update
  </Button>

  const publishSwitch = <Switch checked={optInModalToEdit?.show_modal} checkedChildren="Show" defaultChecked onChange={handleOptInModalStatus} unCheckedChildren="Hide" />

  return (
    <PageLayout extra={[publishSwitch, updateOptInModalButton]} title='Update Opt-In Modal'>
      <Card>
        {isDataLoaded && optInModalToEdit && <OptInInfo
          allVenues={allVenues}
          handleAppNameChange={handleAppNameChange}
          handleButtonTextUpdate={handleButtonTextUpdate}
          handleCoundDownEndDate={handleCoundDownEndDate}
          handleDescriptionChange={handleDescriptionChange}
          handleDisclaimerChange={handleDisclaimerChange}
          handleFormIdUpdate={handleFormIdUpdate}
          handleHeaderChange={handleHeaderChange}
          handleImageUpload={handleImageUpload}
          handleMessageChange={handleMessageChange}
          handleOptInModalCountDownStatus={handleOptInModalCountDownStatus}
          handleTitleChange={handleTitleChange}
          handleVenueUpdate={handleVenueUpdate}
          optInModal={optInModalToEdit}
        />}
      </Card>
      <Spinner isLoading={!isDataLoaded}/>
      {
        updateStatus &&
        <ResultModal
          actions={[
            goToListOptInModalsButton,
            keepEditingOptInModal
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

EditOptInModal.propTypes = {
  fetchOptInModal: PropTypes.func,
  optInModal: PropTypes.object,
  resetUpdateOptInModalStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  updateMessage: PropTypes.string,
  updateOptInModal: PropTypes.func,
  updateStatus: PropTypes.string,
  venuesList: PropTypes.array
}

const mapStateToProps = ({ optInModal }) => ({
  optInModal: optInModal.optInModal,
  venuesList: optInModal.allVenues,
  updateMessage: optInModal.message,
  updateStatus: optInModal.success
})

const mapDispatchToProps = {
  fetchOptInModal: fetchOptInModalById,
  resetUpdateOptInModalStatus,
  updateOptInModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOptInModal)
