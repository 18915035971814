import {
  FETCH_PUBLISHING_PREFERENCES,
  PUBLISHING_PREFERENCES_UPDATE_STATUS
} from '../actionTypes'
const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PUBLISHING_PREFERENCES:
      return {
        ...state,
        ...action.payload
      }
    case PUBLISHING_PREFERENCES_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
