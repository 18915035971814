import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Divider, Typography } from 'antd'
import PropTypes from 'prop-types'

import { PriceSections } from '~components'
import { MultiSelectDropdown } from '~stories'

import styles from './PriceTemplateClass.module.css'

const { Text } = Typography

const PriceTemplateClass = ({
  availableClasses,
  currentClass,
  handleClassSelection,
  index,
  invalidPriceClasses,
  priceClassesCount,
  removePriceClass,
  selectedClasses,
  showSectionPriceError,
  updateMaxPriceByClassAndSection,
  updateMinPriceByClassAndSection,
  handleServiceFeeMaxPriceInput,
  handleServiceFeeMinPriceInput
}) => {
  const [sections, setSections] = useState([])
  const [currentClassName, setCurrentClassName] = useState('')

  useEffect(() => {
    if (currentClass) {
      setSections(currentClass.sections)
      setCurrentClassName(currentClass.name)
    }
  }, [currentClass])

  const removePriceClassFromTemplate = () => {
    removePriceClass(index)
  }

  const onClassSelect = className => {
    handleClassSelection(index, className)
  }

  let availableClassesToSelect = availableClasses.filter(availableClass => !selectedClasses.includes(availableClass))

  availableClassesToSelect = currentClassName.length > 0
    ? [currentClassName, ...availableClassesToSelect]
    : availableClassesToSelect

  const options = availableClassesToSelect.map(priceClass => ({
    label: priceClass,
    value: priceClass
  }))

  const priceSectionList = sections.map((section, sectionIndex) => (
    <Col key={sectionIndex} className={styles.sectionTemplate} span={3}>
      <PriceSections
        key={`${section.name}_${sectionIndex}`}
        handleServiceFeeMaxPriceInput={handleServiceFeeMaxPriceInput}
        handleServiceFeeMinPriceInput={handleServiceFeeMinPriceInput}
        index={sectionIndex}
        maxPrice={section.max_price}
        minPrice={section.min_price}
        name={section.name}
        priceClassIndex={index}
        serviceFee={section.service_fee ?? {}}
        showSectionPriceError={showSectionPriceError}
        updateMaxPrice={updateMaxPriceByClassAndSection}
        updateMinPrice={updateMinPriceByClassAndSection}
      />
    </Col>
  ))

  const showError = currentClassName !== '' && invalidPriceClasses.includes(currentClassName)

  return (
    <>
      <Col span={3}>
        <MultiSelectDropdown displayField='label' dropdownOptions={options} handleChange={onClassSelect} selectedItems={currentClassName} valueField='value' />
        {
          showError && <Text type='danger'>*Provide valid Price range</Text>
        }
      </Col>
      <Row>
        {
          priceSectionList
        }
        {
          priceClassesCount > 1 && <Button className={styles.button} onClick={removePriceClassFromTemplate}>Remove</Button>
        }
      </Row>
      <Divider className={styles.divider} plain />
    </>
  )
}

PriceTemplateClass.propTypes = {
  availableClasses: PropTypes.array.isRequired,
  currentClass: PropTypes.object.isRequired,
  handleClassSelection: PropTypes.func.isRequired,
  handleServiceFeeMaxPriceInput: PropTypes.func.isRequired,
  handleServiceFeeMinPriceInput: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  invalidPriceClasses: PropTypes.array,
  priceClassesCount: PropTypes.number.isRequired,
  removePriceClass: PropTypes.func.isRequired,
  selectedClasses: PropTypes.array.isRequired,
  showSectionPriceError: PropTypes.bool.isRequired,
  updateMaxPriceByClassAndSection: PropTypes.func.isRequired,
  updateMinPriceByClassAndSection: PropTypes.func.isRequired
}

PriceTemplateClass.defaultProps = {
  availableClasses: [],
  currentClass: {
    sections: [],
    name: ''
  }
}

export default PriceTemplateClass
