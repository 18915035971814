import React from 'react'
import { Button, notification } from 'antd'
import { Route } from 'react-router-dom'

import { ItemList } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout } from '~stories'

import './SeriesList.modules.css'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const PAGE_TITLE = 'series'
const SERIES_TYPE_OPTIONS = [
  { label: 'All', value: 'All' },
  { label: 'User Created', value: 'User Created' },
  { label: 'System Created', value: 'System Created' }
]

const getSeriesNameWithLink = (series) => <a href={`/series/${series.id}/edit`}>{String(series.name).toUpperCase()}</a>

const columnData = [
  {
    dataIndex: 'name',
    render: (text, series) => getSeriesNameWithLink(series),
    sorter: true,
    title: 'Name',
    width: '30%',
    sortDirections: ['descend', 'ascend', 'descend']
  },
  {
    dataIndex: 'series_dates_text',
    title: 'Series Dates Text',
    width: '30%'
  },
  {
    dataIndex: 'created_at',
    title: 'Created At',
    width: '20%',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend']
  },
  {
    dataIndex: 'updated_at',
    title: 'Updated At',
    width: '20%',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend']
  }
]

const addNewSeries = isContentEditor => {
  return (
    <Route render={({ history }) => (
      <Button
        key="1"
        onClick={() => {
          isContentEditor
            ? history.push('/series/new')
            : notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
        }}
        type="primary"
      >
        Add Series
      </Button>
    )} />
  )
}

const SeriesList = () => {
  const { userDetails: { isContentEditor } } = getCurrentUser()

  return (
    <PageLayout extra={[addNewSeries(isContentEditor)]} title={PAGE_TITLE}>
      <div className="series-layout-background">
        <ItemList
          columns={columnData}
          filterKey="seriesType"
          filterOptions={SERIES_TYPE_OPTIONS}
          filterValue="User Created"
          resource="series"
        />
      </div>
    </PageLayout>
  )
}

export default SeriesList
