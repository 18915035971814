import React, { useState, useEffect } from 'react'
import { Button, Card, notification } from 'antd'
import Meta from 'antd/lib/card/Meta'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { fetchPublishingPreferences, resetPublishingPreferencesActionStatus, updatePublishingPreferences } from '~actions'
import { RecommendationsSelector, ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout, Spinner } from '~stories'

import 'antd/dist/antd.css'

import styles from './PublishingPreferences.module.css'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const PAGE_TITLE = 'Publishing Preferences'

const PublishingPreferences = ({
  allArtists: allArtistsFromProps,
  allVenues: allVenuesFromProps,
  fetchPublishingPreferences,
  resetPublishingPreferencesActionStatus,
  selectedArtists: selectedArtistsFromProps,
  selectedVenues: selectedVenuesFromProps,
  updateMessage,
  updatePublishingPreferences,
  updateStatus
}) => {
  const [allArtists, setAllArtists] = useState([])
  const [allVenues, setAllVenues] = useState([])
  const [isDataLoaded, setIsDataLoaded] = useState()
  const [selectedArtists, setSelectedArtists] = useState([])
  const [selectedVenues, setSelectedVenues] = useState([])
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    setIsDataLoaded(false)
    fetchPublishingPreferences()
  }, [fetchPublishingPreferences])

  useEffect(() => {
    if (allArtistsFromProps && selectedArtistsFromProps) {
      setAllArtists(allArtistsFromProps)
      setSelectedArtists(selectedArtistsFromProps)
      setIsDataLoaded(true)
    }
  }, [allArtistsFromProps, selectedArtistsFromProps])

  useEffect(() => {
    if (allVenuesFromProps && selectedVenuesFromProps) {
      setAllVenues(allVenuesFromProps)
      setSelectedVenues(selectedVenuesFromProps)
      setIsDataLoaded(true)
    }
  }, [allVenuesFromProps, selectedVenuesFromProps])

  useEffect(() => {
    if (updateStatus) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
      resetPublishingPreferencesActionStatus()
    }
  }, [updateStatus, updateMessage, resetPublishingPreferencesActionStatus])

  const handleUpdate = () => {
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }
    setIsDataLoaded(false)
    updatePublishingPreferences(selectedArtists, selectedVenues, accessToken)
  }

  const editPublishingPreferences = (
    <Button
      onClick={() => {
        resetPublishingPreferencesActionStatus()
        window.location.reload()
      }}
      type="primary"
    >
      Keep Editing
    </Button>
  )

  const goToListEventsButton = (
    <Route key="listPreferences" render={({ history }) => (
      <Button
        onClick={() => {
          resetPublishingPreferencesActionStatus()
          history.push('/events')
        }}
        type="primary"
      >
        Go to Events List
      </Button>
    )} />
  )

  const handleModalClose = () => {
    resetPublishingPreferencesActionStatus()
  }

  const updatePreferences = () => {
    return (
      <Button
        onClick={handleUpdate}
        type="primary"
      >
        Update
      </Button>
    )
  }
  return (
    <PageLayout extra={[updatePreferences()]} title={PAGE_TITLE}>
      <Card>
        <Meta
          description={<span className={styles.disclaimer}>*Select venues to auto publish events</span>}
          title={<span className={styles.title}>Venues Selection</span>}
        />
        <RecommendationsSelector
          allEvents={allVenues}
          availableListHeaderString='Available Venues'
          displayField='name'
          promotedEvents={selectedVenues}
          resourceName='Venues'
          selectedListHeaderString='Selected Venues'
          setAllEvents={setAllVenues}
          setPromotedEvents={setSelectedVenues}
        />
      </Card>
      <Card>
        <Meta
          description={<span className={styles.disclaimer}>*Select artists not to auto publish events</span>}
          title={<span className={styles.title}>Artists Selection</span>}
        />
        <RecommendationsSelector
          allEvents={allArtists}
          availableListHeaderString='Available Artists'
          displayField='name'
          promotedEvents={selectedArtists}
          resourceName='Artists'
          selectedListHeaderString='Unselected Artists'
          setAllEvents={setAllArtists}
          setPromotedEvents={setSelectedArtists}
        />
      </Card>
      <Spinner
        isLoading={!isDataLoaded}
        size="large"
      />
      {
        updateStatus &&
        <ResultModal
          actions={[
            goToListEventsButton,
            editPublishingPreferences
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

PublishingPreferences.propTypes = {
  allArtists: PropTypes.array.isRequired,
  allVenues: PropTypes.array.isRequired,
  fetchPublishingPreferences: PropTypes.func.isRequired,
  resetPublishingPreferencesActionStatus: PropTypes.func,
  selectedArtists: PropTypes.array.isRequired,
  selectedVenues: PropTypes.array.isRequired,
  updateMessage: PropTypes.string,
  updatePublishingPreferences: PropTypes.func,
  updateStatus: PropTypes.bool
}

PublishingPreferences.defaultProps = {
  allArtists: [],
  allVenues: [],
  selectedArtists: [],
  selectedVenues: []
}

const mapStateToProps = (state) => {
  const { allArtists, allVenues, message: updateMessage, selectedArtists, selectedVenues, success: updateStatus } = state.publishingPreferences
  return {
    allArtists,
    allVenues,
    selectedArtists,
    selectedVenues,
    updateMessage,
    updateStatus
  }
}

const mapDispatchToProps = {
  fetchPublishingPreferences,
  resetPublishingPreferencesActionStatus,
  updatePublishingPreferences
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublishingPreferences)
)
