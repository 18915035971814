
import React, { useMemo } from 'react'
import { Card } from 'antd'
import PropTypes from 'prop-types'

import { MultiSelectDropdown } from '~stories'

import styles from './EventVenueInfo.module.css'

const EventMultiSelectDropdown = ({
  allVenues = [],
  eventLogo: eventLogoProps,
  handleEventLogoUpdate,
  selectedVenue = {}
}) => {
  const allLogos = useMemo(() => (selectedVenue && selectedVenue.logos) ? selectedVenue.logos.filter(logo => logo.is_active) : [], [selectedVenue])
  const selectedLogo = useMemo(() => (selectedVenue && selectedVenue.logos) ? selectedVenue.logos.filter(logo => logo.is_default)[0] : [], [selectedVenue])
  const eventLogo = eventLogoProps ?? {}
  const selectedEventLogo = Object.keys(eventLogo).length > 0 ? eventLogoProps : selectedLogo

  return (
    <Card title="Venue">
      <MultiSelectDropdown
        allowClear={true}
        customStyle={{ width: 450 }}
        disabled
        displayField="name"
        dropdownOptions={allVenues}
        handleChange={() => {}}
        selectedItems={[selectedVenue.name]}
        valueField="name"
      />
      <MultiSelectDropdown
        allowClear={true}
        customStyle={{ width: 450 }}
        displayField="name"
        dropdownOptions={allLogos}
        handleChange={handleEventLogoUpdate}
        selectedItems={[selectedEventLogo.name]}
        valueField="name"
      />
      <img alt='Web Logo URL' className={styles.logoPreview} height='auto' src={selectedLogo.web_image_url} width='250px' />
    </Card>
  )
}

EventMultiSelectDropdown.propTypes = {
  allVenues: PropTypes.array,
  eventLogo: PropTypes.object,
  handleEventLogoUpdate: PropTypes.func,
  selectedVenue: PropTypes.object
}

export default EventMultiSelectDropdown
