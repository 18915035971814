import React from 'react'

import { Checkbox, Form } from 'antd'
import PropTypes from 'prop-types'

import Label from '../Label/Label'
import styles from './CheckboxGroup.module.css'
const CheckboxGroup = Checkbox.Group
const { Item } = Form

const CustomCheckboxGroup = ({
  disabled,
  isInline,
  label,
  labelClassName,
  onChange,
  options,
  selectedItems
}) => {
  const className = styles['inline-checkbox']
  return (
    <Item>
      <Label className={labelClassName} isInline={isInline} value={label} />
      <CheckboxGroup
        className={className}
        disabled={disabled}
        onChange={onChange}
        options={options}
        value={selectedItems}
      />
    </Item>
  )
}

CustomCheckboxGroup.propTypes = {
  disabled: PropTypes.bool,
  isInline: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired
}

CustomCheckboxGroup.defaultProps = {
  isInline: false,
  labelClassName: '',
  options: [],
  selectedItems: []
}

export default CustomCheckboxGroup
