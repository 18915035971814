const validateSectionPrices = priceClasses => {
  const message = 'Please provide valid min/max price value.'
  const [inValidPriceClass] = priceClasses.filter(({ sections }) => sections.some(section => (section.min_price !== '' && section.min_price === 0) || (section.max_price !== '' && section.max_price === 0)))

  return inValidPriceClass
    ? { message }
    : {}
}

const validateMissingSectionPrices = priceClasses => {
  const message = 'One or more sections does not have both min and max prices. Please fix this before proceeding'
  const [inValidPriceClass] = priceClasses.filter(({ sections }) => sections.some(({ min_price: minPrice, max_price: maxPrice }) => (minPrice && !maxPrice) || (!minPrice && maxPrice)))

  return inValidPriceClass
    ? { message }
    : {}
}

const validateSectionPriceRange = priceClasses => {
  const message = 'One or more price sections has min price greater than max price. Please fix this before proceeding.'
  const [inValidPriceClass] = priceClasses.filter(({ sections }) => sections.some(({ min_price: minPrice, max_price: maxPrice }) => (minPrice && maxPrice && (+minPrice > +maxPrice))))

  return inValidPriceClass
    ? { message }
    : {}
}

const validatePriceClassName = priceClasses => {
  const priceClassesWithoutName = priceClasses.filter(priceClass => priceClass.name === '')
  let validationResult = {}
  if (priceClassesWithoutName.length > 0) {
    validationResult = {
      message: 'Price class name can\'t be blank'
    }
  }
  return validationResult
}

const validateUnsupportedPriceClasses = (priceClasses, selectedEvents) => {
  let validationResult = {}
  const supportedPriceClasses = priceClasses.map(pc => pc.name).filter(name => name !== '')
  const unsupportedEvents = selectedEvents.filter(event => supportedPriceClasses.includes(event.priceClass))
  if (unsupportedEvents.length !== selectedEvents.length) {
    validationResult = {
      message: 'Events with unsupported price class can\'t be submitted'
    }
  }
  return validationResult
}

const validatePriceSections = (priceClasses, supportedPriceClasses) => {
  const emptyPriceClasses = priceClasses.filter(priceClass => {
    const { sections, name } = priceClass
    const status = {
      [name]: []
    }
    sections.map(section => {
      if (section.min_price === '' || section.max_price === '') {
        status[name].push(section.name)
      }
    })
    return status[name].length === supportedPriceClasses.length
  })
  const emptyPriceClassNames = emptyPriceClasses ? emptyPriceClasses.map(priceClass => priceClass.name) : []
  return emptyPriceClassNames
}

const validatePriceTemplates = (priceClasses, selectedEvents = []) => {
  return validatePriceClassName(priceClasses).message ||
    validateSectionPrices(priceClasses).message ||
    validateMissingSectionPrices(priceClasses).message ||
    validateSectionPriceRange(priceClasses).message ||
    validateUnsupportedPriceClasses(priceClasses, selectedEvents).message
}

const validateSectionDiscounts = discountPercentage => {
  let response = {}
  if (parseInt(discountPercentage) < 0 || isNaN(parseInt(discountPercentage))) {
    response = { message: 'Please provide valid discount.' }
  } else if (discountPercentage > 99) {
    response = { message: 'Discount can\'t be greater than 99.' }
  }
  return response.message
}

const isPriceSectionsValid = (priceClasses, sections) => {
  const invalidPriceClasses = validatePriceSections(priceClasses, sections)
  let priceSectionsStatus = false
  if (priceClasses && priceClasses.length > 0) {
    priceSectionsStatus = true
    if (invalidPriceClasses.includes('')) {
      priceSectionsStatus = false
    } else if (invalidPriceClasses && invalidPriceClasses.length > 0) {
      priceSectionsStatus = false
    }
  }
  return priceSectionsStatus
}

export {
  isPriceSectionsValid,
  validatePriceSections,
  validatePriceTemplates,
  validateSectionDiscounts
}
