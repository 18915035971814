const VALIDATION_ERRORS = {
  INVALID_DESCRIPTION: 'Provide valid description.',
  INVALID_EXPIRY_DATE: 'Provide valid expiration date',
  INVALID_PRICE_TEMPLATE: 'Provide valid pricetemplate.',
  INVALID_PROMOCODE: 'Provide valid promo code.',
  INVALID_SERIES: 'Provide valid series name.',
  INVALID_TERMS: 'Provide valid legal terms.',
  MISSING_EXPIRY_DATE: 'Expiration date and time are mandatory fields',
  TITLE_TEXT_MAX_LIMIT_ERR: 'Promocode title exceeds the max limit 12 characters'
}

const PROMOCODE_DOMAINS = {
  ROCKETTES: 'rockettes',
  MSG: 'msg'
}
const PROMO_CODE_TITLE_MAX_LENGTH = 12

const isValidTitle = title => title.length > 0

const isValidDescription = description => description.length > 0

const isValidTerms = terms => terms.length > 0

const isValidSeries = selectedSeries => selectedSeries

const isValidPriceTemplate = selectedPriceTemplate => selectedPriceTemplate

const isValidExpiryDate = expiryDateTime => expiryDateTime

const validateTitleTextLimit = title => title.length > PROMO_CODE_TITLE_MAX_LENGTH

const validateRockettesPromocode = ({ description, priceTemplateId, terms, title }) => {
  if (!isValidTitle(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_PROMOCODE }
  }

  if (validateTitleTextLimit(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.TITLE_TEXT_MAX_LIMIT_ERR }
  }

  if (!isValidDescription(description)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_DESCRIPTION }
  }

  if (!isValidTerms(terms)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TERMS }
  }

  if (!isValidPriceTemplate(priceTemplateId)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_PRICE_TEMPLATE }
  }

  return { isValid: true, message: '' }
}

const validateMSGPromocode = ({ description, expiryDateTime, seriesId, terms, title }) => {
  if (!isValidTitle(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_PROMOCODE }
  }

  if (validateTitleTextLimit(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.TITLE_TEXT_MAX_LIMIT_ERR }
  }

  if (!isValidDescription(description)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_DESCRIPTION }
  }

  if (!isValidTerms(terms)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TERMS }
  }

  if (!isValidSeries(seriesId)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_SERIES }
  }

  if (!isValidExpiryDate(expiryDateTime)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_EXPIRY_DATE }
  }

  return { isValid: true, message: '' }
}

const validatePromocode = promoCode => {
  const { domain } = promoCode

  return domain === PROMOCODE_DOMAINS.ROCKETTES
    ? validateRockettesPromocode(promoCode)
    : validateMSGPromocode(promoCode)
}

export {
  validatePromocode
}
