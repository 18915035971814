import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { addVenue, resetVenueActionStatus } from '~actions'
import { ResultModal, VenueBasicInfo } from '~components'

import { getCurrentUser } from '~context'
import { PageLayout } from '~stories'
import { validateVenue } from '~validators'

import { defaultVenueObject } from './constants'

const ADD_ACTION_MODE = 'Add'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const PAGE_TITLE = 'Add Partner Venue'

const AddPartnerVenue = ({
  addVenue,
  newVenueId,
  resetVenueActionStatus,
  updateError,
  updateMessage,
  updateStatus
}) => {
  const [isDataUpdated, setIsDataUpdated] = useState(true)

  const venueId = uuid().replace('-', '').slice(0, 10).toUpperCase()
  const [venue, setVenue] = useState({
    ...defaultVenueObject,
    id: `PV${venueId}`
  })
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    if (updateStatus) {
      setIsDataUpdated(true)
    }
  }, [updateStatus])

  useEffect(() => {
    if (updateError) {
      notification.error({ message: updateMessage })
      setIsDataUpdated(true)
    }
  }, [updateError, updateMessage])

  const handleSubmit = () => {
    const { isValid, message } = validateVenue(venue)

    if (!isValid) {
      notification.error({ message })
      return
    }

    if (message) {
      notification.error({ message })
      return
    }

    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    setIsDataUpdated(false)
    addVenue(venue, accessToken)
  }

  const handleAvailabilityThresholdChange = (event) => {
    const availabilityThreshold = event.target.value
    const updatedVenue = { ...venue, availability_threshold: availabilityThreshold }
    setVenue(updatedVenue)
  }

  const handleUrlChange = event => {
    const url = event.target.value
    const updatedEvent = { ...venue, url }
    setVenue(updatedEvent)
  }

  const handleNameChange = event => {
    const name = event.target.value
    const updatedVenue = { ...venue, name }
    setVenue(updatedVenue)
  }

  const handleLogoUrlChange = event => {
    const logoUrl = event.target.value
    const updatedVenue = { ...venue, logo_url: logoUrl }
    setVenue(updatedVenue)
  }

  const handleLegalCopyChange = event => {
    const legalCopy = event.target.value
    const updatedVenue = { ...venue, legalCopy }
    setVenue(updatedVenue)
  }

  const handleModalClose = () => {
    resetVenueActionStatus()
  }

  const addVenueButton = (
    <Button
      onClick={() => {
        resetVenueActionStatus()
        window.location.reload()
      }}
      type="primary"
    >
      Add Another
    </Button>
  )

  const goToListVenuesButton = (
    <Route key="listVenues" render={({ history }) => (
      <Button
        onClick={() => {
          resetVenueActionStatus()
          history.push('/venues')
        }}
        type="primary"
      >
        Go to Venues List
      </Button>
    )} />
  )

  const editVenue = (
    <Route key="listVenue" render={({ history }) => (

      <Button
        onClick={() => {
          resetVenueActionStatus()
          newVenueId && history.push(`/venues/${newVenueId}/edit`)
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const submitButton = <Button loading={!isDataUpdated} onClick={handleSubmit} type='primary'>
    Add
  </Button>

  return (
    <PageLayout extra={[submitButton]} title={PAGE_TITLE}>
      <VenueBasicInfo
        handleAvailabilityThresholdChange={handleAvailabilityThresholdChange}
        handleLegalCopyChange={handleLegalCopyChange}
        handleLogoUrlChange={handleLogoUrlChange}
        handleNameChange={handleNameChange}
        handleUrlChange={handleUrlChange}
        type={ADD_ACTION_MODE}
        venue={venue}
      />
      {
        updateStatus &&
        <ResultModal
          actions={[
            editVenue,
            goToListVenuesButton,
            addVenueButton
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

AddPartnerVenue.propTypes = {
  addVenue: PropTypes.func,
  newVenueId: PropTypes.string,
  resetVenueActionStatus: PropTypes.func,
  updateError: PropTypes.string,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool
}

const mapStateToProps = ({ venues }) => ({
  newVenueId: venues.newVenueId,
  updateError: venues.error,
  updateMessage: venues.message,
  updateStatus: venues.success
})

const mapDispatchToProps = {
  addVenue,
  resetVenueActionStatus
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPartnerVenue)
