/* eslint-disable camelcase */
import React from 'react'
import { Card, Col, Row, Switch } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import { LogoUploader } from '~components'
import { MultiSelectDropdown, DatePicker, Label, Input, RichTextInput } from '~stories'

import { FLAG_APPS } from '../../constants'
import styles from './OptInInfo.module.css'

// const FORMATTER = {
//   DATE: 'YYYY-MM-DD HH:mm:ss',
//   TIME: 'HH:mm:ss'
// }

const OptInInfo = ({
  allVenues,
  handleAppNameChange,
  handleFormIdUpdate,
  handleDescriptionChange,
  handleHeaderChange,
  handleImageUpload,
  handleDisclaimerChange,
  handleOptInModalCountDownStatus,
  handleTitleChange,
  handleMessageChange,
  handleCoundDownEndDate,
  handleVenueUpdate,
  handleButtonTextUpdate,
  optInModal
}) => {
  const {
    app_name = 'msg',
    button_text,
    venue_id: venueId,
    count_down: {
      status,
      expiration_date_time
    },
    description,
    disclaimer,
    formstack_id,
    header,
    image_url,
    success_screen: {
      title,
      message
    }
  } = optInModal

  const expiryDate = expiration_date_time ? moment(expiration_date_time, 'X') : null
  const [venueName] = allVenues.filter(venue => venue.id === venueId)
  return (
    <>
      <Card>
        <Row gutter={8}>
          <Col span={16}>
            <Row>
              <Label
                isInline={false}
                value='App Name'
              /></Row>
            <Row>
              <MultiSelectDropdown
                allowClear={false}
                customStyle={{ width: 252 }}
                disabled
                displayField="name"
                dropdownOptions={FLAG_APPS}
                handleChange={handleAppNameChange}
                selectedItems={[app_name]}
                valueField="value"
              />
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={18}>
            <Label value={'Venue Name'} />
            <MultiSelectDropdown
              allowClear={false}
              customStyle={{ width: 450 }}
              displayField="name"
              dropdownOptions={allVenues}
              handleChange={selectedItems => handleVenueUpdate(selectedItems)}
              selectedItems={venueName?.name}
              valueField="name"
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={5}>
            <Input label={'Formstack Id'}
              onChange={handleFormIdUpdate}
              value={formstack_id}
            />
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={8}>
            <Input label={'Submit Button Text'}
              onChange={handleButtonTextUpdate}
              value={button_text}
            />
          </Col>
        </Row>

        <RichTextInput label='Header' onChange={handleHeaderChange} value={header}/>
        <RichTextInput label='Description' onChange={handleDescriptionChange} value={description}/>
        <Row className={styles.logoContainer}>
          <LogoUploader handleUpload={handleImageUpload} logoUrl={image_url} />
        </Row>
        <RichTextInput label='Disclaimer' onChange={handleDisclaimerChange} value={disclaimer}/>
      </Card>< br/>
      <Card title="Success Screen">
        <RichTextInput label='Title' onChange={handleTitleChange} value={title}/>
        <RichTextInput label='Message' onChange={handleMessageChange} value={message}/>
      </Card>< br/>
      <Card title="Countdown">
        <Row>
          <Col span={3}>
            <Switch
              checked={status} checkedChildren="Show" defaultChecked onChange={handleOptInModalCountDownStatus} unCheckedChildren="Hide" />
          </Col>
        </Row>< br />< br />
        <Row>
          <Col span={8}>
            <DatePicker
              allowClear={true}
              format={'MM/DD/YYYY h:mm A'}
              label="Expiration Date: (ET)"
              onChange={handleCoundDownEndDate}
              showTime={true}
              value={expiryDate}
            />
          </Col>
        </Row>
      </Card>
    </>
  )
}

OptInInfo.propTypes = {
  allVenues: PropTypes.array,
  handleAppNameChange: PropTypes.func,
  handleButtonTextUpdate: PropTypes.func,
  handleCoundDownEndDate: PropTypes.func,
  handleDescriptionChange: PropTypes.func.isRequired,
  handleDisclaimerChange: PropTypes.func,
  handleFormIdUpdate: PropTypes.func,
  handleHeaderChange: PropTypes.func.isRequired,
  handleImageUpload: PropTypes.func,
  handleMessageChange: PropTypes.func,
  handleOptInModalCountDownStatus: PropTypes.func,
  handleTitleChange: PropTypes.func,
  handleVenueUpdate: PropTypes.func,
  optInModal: PropTypes.object.isRequired
}

OptInInfo.defaultProps = {
  allVenues: [],
  optInModal: {}
}

export default OptInInfo
