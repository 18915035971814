const ERROR_MESSAGES = {
  ENTRANCE_NOTES_MAX_LIMIT_ERR: 'Entrance notes exceeds the max limit 250 characters',
  MISSING_NAME: 'Please provide valid name',
  MISSING_GENRES: 'Missing required genres',
  MISSING_CATEGORY: 'Missing required category',
  MISSING_FLAGS: 'Missing required flags',
  MISSING_SUB_CATEGORY: 'Missing required sub-category',
  POSTPONED_TEXT_ERR: 'Postponed events require a postpone text to be present. Please enter valid postponed text',
  POSTPONED_TEXT_MAX_LIMIT_ERR: 'Postponed text exceeds the max limit 12 characters',
  PUBLISH_WARNING: 'Changes to publish status will not be saved until you click update.',
  SPOTIFY_URL_ERR: 'Please provide valid URL for Spotify playlist',
  STATUS_TEXT_ERR: 'Postponed events require a status text to be present. Please enter valid status text',
  STATUS_TEXT_MAX_LIMIT_ERR: 'Status text exceeds the max limit 12 characters',
  SUBTITLE_MAX_LIMIT_ERR: 'Subtitle exceeds the max limit 128 characters'
}
const MAX_NOTES_TEXT_LIMIT = 256
const MAX_POSTPONED_TEXT_LIMIT = 12
const MAX_SUBTITLE_TEXT_LIMIT = 128
const SPOTIFY_URL_REG_EX = /^(spotify:|https:\/\/[a-z]+\.spotify\.com\/)/

const validateTitle = title => title.replace(/\s/g, '').length > 0

const validateDescription = description => description.replace(/\s/g, '').length > 0

const validateSelectedChannels = selectedChannels => selectedChannels.length > 0

const validateVideoUrl = (videoPlatform, videoUrl) =>
  videoPlatform.length > 0
    ? videoUrl.replace(/\s/g, '').length > 0
    : true

const validateDataField = (dataField, dataFieldErrors, dataFieldIndex) => {
  const {
    title,
    description,
    selected_channels: selectedChannels,
    video_url: videoUrl,
    video_platform: videoPlatform
  } = dataField

  dataFieldErrors[dataFieldIndex].isValid = validateTitle(title) &&
    validateDescription(description) &&
    validateSelectedChannels(selectedChannels) &&
    validateVideoUrl(videoPlatform, videoUrl)

  return dataFieldErrors
}

const validateSpotifyUrl = spotifyPlaylistUrl => {
  const response = {}
  const urlValidator = new RegExp(SPOTIFY_URL_REG_EX)

  if (spotifyPlaylistUrl.length > 0 && !urlValidator.test(spotifyPlaylistUrl)) {
    response.message = ERROR_MESSAGES.SPOTIFY_URL_ERR
  }

  return response
}

const validateGenres = (genres) => {
  const response = {}

  if (genres && genres.length === 0) {
    response.message = ERROR_MESSAGES.MISSING_GENRES
  }

  return response
}

const validateEntranceNotes = (entranceInfo) => {
  const response = {}

  if (entranceInfo && entranceInfo?.notes.length > MAX_NOTES_TEXT_LIMIT) {
    response.message = ERROR_MESSAGES.ENTRANCE_NOTES_MAX_LIMIT_ERR
  }

  return response
}

const validateSubtitle = (subTitle) => {
  const response = {}

  if (subTitle.length > MAX_SUBTITLE_TEXT_LIMIT) {
    response.message = ERROR_MESSAGES.SUBTITLE_MAX_LIMIT_ERR
  }

  return response
}

const validatePostponedText = (postponed, postponedText) => {
  const response = {}

  if (postponed && postponedText.length === 0) {
    response.message = ERROR_MESSAGES.POSTPONED_TEXT_ERR
  } else if (postponed && postponedText.length > MAX_POSTPONED_TEXT_LIMIT) {
    response.message = ERROR_MESSAGES.POSTPONED_TEXT_MAX_LIMIT_ERR
  }

  return response
}

const validateStatusText = (postponed, statusText) => {
  const response = {}

  if (postponed && statusText.length === 0) {
    response.message = ERROR_MESSAGES.STATUS_TEXT_ERR
  } else if (postponed && statusText.length > MAX_POSTPONED_TEXT_LIMIT) {
    response.message = ERROR_MESSAGES.STATUS_TEXT_MAX_LIMIT_ERR
  }

  return response
}

export const validateDataFields = dataFields => {
  let dataFieldsValidationErrorObj = {}

  dataFields.forEach((dataField, dataFieldIndex) => {
    dataFieldsValidationErrorObj[dataFieldIndex] = {
      isValid: true
    }
    dataFieldsValidationErrorObj = validateDataField(dataField, dataFieldsValidationErrorObj, dataFieldIndex)
  })

  return dataFieldsValidationErrorObj
}

export const validateName = name => {
  const response = {}

  if (!name || name.length === 0) {
    response.message = ERROR_MESSAGES.MISSING_NAME
  }

  return response
}

const validateCategory = (category) => {
  const response = {}
  if (category && category.length === 0) {
    response.message = ERROR_MESSAGES.MISSING_CATEGORY
  }
  return response
}

export const validateEventAttributes = (entranceInfo, genres, name, postponed, postponedText, spotifyPlaylistUrl, statusText, subTitle, category, flags) =>
  validateSpotifyUrl(spotifyPlaylistUrl).message ||
  validateGenres(genres).message ||
  validateEntranceNotes(entranceInfo).message ||
  validateName(name).message ||
  validateSubtitle(subTitle).message ||
  validatePostponedText(postponed, postponedText).message ||
  validateStatusText(postponed, statusText).message ||
  validateCategory(category).message
