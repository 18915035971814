import React, { useState } from 'react'
import { Modal, Typography } from 'antd'
import PropTypes from 'prop-types'

import { MultiSelect } from '~stories'

const { Text } = Typography

const RecommendationsSelector = ({
  allEvents = [],
  availableListHeaderString,
  displayField,
  onHeadlinerClick,
  promotedEvents,
  resourceName,
  selectedListHeaderString,
  setAllEvents,
  setPromotedEvents,
  showConfirmation,
  confirmationMessage,
  showHeadlinerSelection,
  showPriceClassLegend
}) => {
  const [showEventSelectionWarning, setShowEventSelectionWarning] = useState(false)
  const [selectedEventToRemove, setSelectedEventToRemove] = useState(null)

  const manageLists = (items, source, dest, sourceFn, destFn) => {
    const selectedItems = Array.isArray(items)
      ? items
      : [items]
    const clonedSource = [...source]
    let clonedDest = [...dest]
    selectedItems.forEach(item => {
      const selectedEventIndex = clonedSource.findIndex(event => event.id === item.id)
      const [itemToMove] = clonedSource.splice(selectedEventIndex, 1)
      clonedDest = [...clonedDest, itemToMove]
    })
    sourceFn([...clonedSource])
    destFn([...clonedDest])
  }

  const onAdd = selectedEvent => {
    manageLists(selectedEvent, allEvents, promotedEvents, setAllEvents, setPromotedEvents)
  }

  const onRemove = selectedEvent => {
    setSelectedEventToRemove(selectedEvent)
    if (showConfirmation) {
      setShowEventSelectionWarning(true)
    } else {
      manageLists(selectedEvent, promotedEvents, allEvents, setPromotedEvents, setAllEvents)
    }
  }

  const onFilter = (allListItems, filterValue) =>
    allListItems.filter(({ title = '', name = '' }) => {
      const filterField = title || name
      const filterAttribute = filterField.toLowerCase()
      return filterValue === '' || filterAttribute.indexOf(filterValue.toLowerCase()) > -1
    })

  const handleModalCancel = () => {
    setShowEventSelectionWarning(false)
  }

  const handleAction = () => {
    manageLists(selectedEventToRemove, promotedEvents, allEvents, setPromotedEvents, setAllEvents)
    setShowEventSelectionWarning(false)
  }

  return (
    <>
      <MultiSelect
        availableListHeaderString={availableListHeaderString}
        availableListItems={allEvents}
        displayField={displayField}
        onAdd={onAdd}
        onFilter={onFilter}
        onHeadlinerClick={onHeadlinerClick}
        onRemove={onRemove}
        resourceName={resourceName}
        selectedListHeaderString={selectedListHeaderString}
        selectedListItems={promotedEvents}
        showHeadlinerSelection={showHeadlinerSelection}
        showPriceClassLegend={showPriceClassLegend}
        valueField={'id'}
      />
      {showEventSelectionWarning && <Modal
        onCancel={handleModalCancel}
        onOk={handleAction}
        title="Remove Event"
        visible={showEventSelectionWarning}
      >
        <Text>
          {
            confirmationMessage
          }
        </Text>
      </Modal>}
    </>
  )
}

RecommendationsSelector.propTypes = {
  allEvents: PropTypes.array,
  availableListHeaderString: PropTypes.string,
  confirmationMessage: PropTypes.string,
  displayField: PropTypes.string.isRequired,
  onHeadlinerClick: PropTypes.func,
  promotedEvents: PropTypes.array,
  resourceName: PropTypes.string,
  selectedListHeaderString: PropTypes.string,
  setAllEvents: PropTypes.func,
  setPromotedEvents: PropTypes.func,
  showConfirmation: PropTypes.bool,
  showHeadlinerSelection: PropTypes.bool,
  showPriceClassLegend: PropTypes.bool
}

RecommendationsSelector.defaultProps = {
  allEvents: [],
  confirmationMessage: 'Are you sure you want to remove this Event?',
  displayField: 'title',
  promotedEvents: [],
  resourceName: 'Events',
  showConfirmation: false,
  showHeadlinerSelection: false,
  showPriceClassLegend: false
}

export default RecommendationsSelector
