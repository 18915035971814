import React, { useEffect, useState } from 'react'
import { UploadOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Card, Col, notification, Upload } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateWorkvivo } from '~actions'
import { getCurrentUser } from '~context'
import { Input, PageLayout, Spinner } from '~stories'
import { getBase64 } from '~utils'

import styles from './MarqueeImageUpload.module.css'

const PAGE_TITLE = 'Marquee Image Upload'
const NOTIFICATION_MESSAGES_UNAUTHORIZED = 'You are not authorized to perform this action.'

const MarqueeImageUpload = ({ updateWorkvivo, statusCode, statusMessage }) => {
  const [employeeId, setEmployeeId] = useState('')
  const [fileList, setFileList] = useState()
  const [showProgress, setShowProgress] = useState(false)
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    if (statusCode) {
      showWorkvivoResponse(statusCode, statusMessage)
    }
  }, [statusCode, statusMessage])

  const showWorkvivoResponse = (statusCode, statusMessage) => {
    setShowProgress(false)
    statusCode === 200
      ? notification.info({ message: statusMessage })
      : notification.error({ message: `${statusMessage} (${statusCode})` })
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  }
  const handleSubmit = async event => {
    event.preventDefault()

    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES_UNAUTHORIZED })
      return
    }
    setShowProgress(true)

    const [file] = fileList

    const base64EncodedFile = await getBase64(file)
    const formData = new FormData()
    formData.append('name', file.name)
    formData.append('file', base64EncodedFile)

    updateWorkvivo(employeeId, formData, accessToken)
  }

  const removeImage = () => {
    setFileList([])
  }

  const uploadProps = {
    name: 'file',
    accept: '.jpg,.jpeg,.png',
    method: 'get',
    maxCount: 1,
    beforeUpload: file => {
      let fileList = [file]
      const fileSizeTooLarge = file.size / 1024 / 1024 > 5
      if (fileSizeTooLarge) {
        notification.error({ message: 'Your file has not been uploaded.  Please try a file size less than 5MB or try again later.' })
        fileList = []
      }
      return setFileList(fileList)
    },
    fileList,
    disabled: !employeeId
  }

  const enableSubmitButton = employeeId && fileList && fileList.length > 0
  const previewImage = (fileList && fileList.length > 0) ? URL.createObjectURL(fileList[0]) : null
  return (
    <PageLayout title={PAGE_TITLE}>
      <Spinner isLoading={showProgress} />
      <div className={styles.marqueeLayoutBackground}>
        <Card title="Workvivo Integration">
          <Col className={styles.column}>
            <Input
              label={'Employee Id'}
              onChange={event => setEmployeeId(event.target.value.replace(/\s+/g, ''))}
              value={employeeId}
            />
          </Col>
          <Col className={styles.column}>
            <Upload {...uploadProps}>
              <Button
                className={styles.uploadButton}
                disabled={!employeeId}
                icon={<UploadOutlined />}
              >
                Click to Upload
              </Button>
            </Upload>
          </Col>
          <Col style={{ width: 200 }}>
            {previewImage && (
              <div className={styles.previewImageContainer}>
                <img alt="preview-image" src={previewImage} />
                <Button className={styles.closeBtn} onClick={removeImage} type="link"><CloseOutlined /></Button>
              </div>
            )}
          </Col>
          <Button
            className={styles.button}
            disabled={!enableSubmitButton}
            onClick={handleSubmit}
            type="primary"
          >
            Submit
          </Button>
        </Card>
      </div>
    </PageLayout>
  )
}

const mapStateToProps = ({ marquee }) => ({
  statusCode: marquee.statusCode,
  statusMessage: marquee.message
})

const mapDispatchToProps = {
  updateWorkvivo
}

MarqueeImageUpload.propTypes = {
  statusCode: PropTypes.number,
  statusMessage: PropTypes.string,
  updateWorkvivo: PropTypes.func
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarqueeImageUpload)
