/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Row, Col, Card } from 'antd'
import PropTypes from 'prop-types'

import { LogoUploader } from '~components'
import { Input, MultiSelectDropdown, Label } from '~stories'
import { LINK_TARGETS } from '../../constants'

const TopNavInfo = ({ allVenues, nav, handleImageUrlUpdate, handleLinkTargetUpdate, handleLinkUrlUpdate, handleVenueUpdate, index }) => {
  const [linkUrl, setLinkUrl] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [venue, setVenue] = useState('')
  const [linkTarget, setLinkTarget] = useState('')

  useEffect(() => {
    if (!nav) return

    const { venue_name: venueName, target: linkTarget, img_url: imageUrl, url: linkUrl } = nav

    setVenue(venueName)
    setLinkTarget(linkTarget)
    setImageUrl(imageUrl)
    setLinkUrl(linkUrl)
  }, [nav])

  return (
    <Card style={{ border: '1px solid rgb(42, 45, 59)' }}>
      <Row gutter={16}>
        <Col span={18}>
          <Label value={'Venue Name'} />
          <MultiSelectDropdown
            allowClear={true}
            customStyle={{ width: 450 }}
            displayField="label"
            dropdownOptions={allVenues}
            handleChange={selectedItems => handleVenueUpdate(selectedItems, index)}
            selectedItems={venue}
            valueField="label"
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={4}>
          <Label value={'Link Target'} />
          <MultiSelectDropdown
            allowClear={true}
            customStyle={{ width: 150 }}
            displayField="label"
            dropdownOptions={LINK_TARGETS}
            handleChange={selectedItems => handleLinkTargetUpdate(selectedItems, index)}
            selectedItems={linkTarget}
            valueField="value"
          />
        </Col>
        <Col span={18}>
          <Input label=' Link Url'
            onBlur={event => handleLinkUrlUpdate(event.target.value, index)}
            onChange={event => setLinkUrl(event.target.value)}
            value={linkUrl} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={18}>
          <Label value='Image Url' />
          <div>
            <LogoUploader handleUpload={(thumbnailImageUrl, secureImageURL) => {
              const imageUrl = secureImageURL || thumbnailImageUrl
              handleImageUrlUpdate(imageUrl, index)
            }} logoUrl={imageUrl} />
          </div>
        </Col>
      </Row>
    </Card>
  )
}

TopNavInfo.propTypes = {
  allVenues: PropTypes.array,
  handleImageUrlUpdate: PropTypes.func,
  handleLinkTargetUpdate: PropTypes.func,
  handleLinkUrlUpdate: PropTypes.func,
  handleVenueUpdate: PropTypes.func,
  index: PropTypes.number,
  nav: PropTypes.object
}

TopNavInfo.defaultProps = {
  allVenues: [],
  nav: {}
}

export default TopNavInfo
