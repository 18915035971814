import React from 'react'
import { Modal, Typography } from 'antd'
import PropTypes from 'prop-types'

const { Text } = Typography

const CustomModal = ({
  description,
  handleCancel,
  handleSubmit,
  showModal,
  title
}) =>
  <Modal
    onCancel={handleCancel}
    onOk={handleSubmit}
    title={title}
    visible={showModal}
  >
    <Text>{
      description
    }
    </Text>
  </Modal>

CustomModal.propTypes = {
  description: PropTypes.string,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  showModal: PropTypes.bool,
  title: PropTypes.string
}

CustomModal.defaultProps = {
  showModal: false
}

export default CustomModal
