import React from 'react'

import PropTypes from 'prop-types'

import styles from './Label.module.css'

const CustomLabel = ({
  className,
  isInline,
  value
}) => {
  const labelClassName = isInline ? `${styles['inline-label']} ${className}` : `${styles.label} ${className}`
  return (
    <div className={labelClassName}>
      {value}
    </div>
  )
}

CustomLabel.propTypes = {
  className: PropTypes.string,
  isInline: PropTypes.bool,
  value: PropTypes.string.isRequired
}

CustomLabel.defaultPropTypes = {
  className: '',
  isInline: false,
  value: ''
}

export default CustomLabel
