import 'isomorphic-fetch'

import { optInModalEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import {
  ADD_NEW_OPT_IN,
  FETCH_NEW_OPT_IN,
  FETCH_OPT_INS,
  OPT_IN_UPDATE_STATUS,
  STORE_OPT_IN_TO_EDIT
} from '../actionTypes'

export const fetchOptInModalList = params => async dispatch => {
  const { method, url } = optInModalEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { optInModals: rows, optInModalsCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_OPT_INS,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchOptInModalById = optInModalObjectId => async dispatch => {
  try {
    const { method, url } = optInModalEndpoint.edit(optInModalObjectId)
    const idToken = getIdToken()
    const apiResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .catch(error => console.error(error))

    dispatch({
      type: STORE_OPT_IN_TO_EDIT,
      payload: {
        ...apiResponse
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export const updateOptInModal = (optInModalObjectId, optInModal, accessToken) => async dispatch => {
  try {
    const { method, url } = optInModalEndpoint.update(optInModalObjectId)
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify(optInModal),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { message, success } = response
        dispatch({
          type: OPT_IN_UPDATE_STATUS,
          payload: { message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const resetUpdateOptInModalStatus = () => async dispatch => {
  dispatch({
    type: OPT_IN_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}

export const fetchNewOptInModal = (accessToken) => async dispatch => {
  const { method, url } = optInModalEndpoint.new
  const idToken = getIdToken()
  await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    method: method
  })
    .then(async response => await getApiResponseInJSON(response))
    .then(response => {
      const { allVenues } = response
      dispatch({
        type: FETCH_NEW_OPT_IN,
        payload: {
          allVenues,
          app_name: 'msg',
          button_text: '',
          count_down: {
            status: false,
            expiration_date_time: null
          },
          description: '',
          disclaimer: '',
          formstack_id: '',
          header: '',
          image_url: '',
          show_modal: false,
          success_screen: {
            title: '',
            message: ''
          }
        }
      })
    })
    .catch((error) => {
      console.log(error)
    })
}

export const addOptInModal = (optInModal, accessToken) => async dispatch => {
  try {
    const { method, url } = optInModalEndpoint.add
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify({ optInModal }),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { optInModal: { _id: newOptInModalId }, message, success } = response

        dispatch({
          type: ADD_NEW_OPT_IN,
          payload: { newOptInModalId, message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const resetOptInModalsActionStatus = () => async dispatch => {
  dispatch({
    type: ADD_NEW_OPT_IN,
    payload: {
      error: false,
      message: '',
      success: false
    }
  })
}
