import React from 'react'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { Input } from '~stories'

const VenueAddress = ({
  addressLine1,
  addressLine2,
  cityName,
  postalCode,
  stateName
}) => {
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Input
            disabled
            label="ADDRESS LINE 1"
            onChange={() => {}}
            value={addressLine1}
          />
        </Col>
        <Col span={12}>
          <Input
            disabled
            label="ADDRESS LINE 2"
            onChange={() => {}}
            value={addressLine2}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={10}>
          <Input
            disabled
            label="CITY"
            onChange={() => {}}
            value={cityName}
          />
        </Col>
        <Col span={8}>
          <Input
            disabled
            label="STATE"
            onChange={() => {}}
            value={stateName}
          />
        </Col>
        <Col span={6}>
          <Input
            disabled
            label="POSTAL CODE / ZIP"
            onChange={() => {}}
            value={postalCode}
          />
        </Col>
      </Row>
    </>
  )
}

VenueAddress.propTypes = {
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  cityName: PropTypes.string,
  postalCode: PropTypes.string,
  stateName: PropTypes.string
}

export default VenueAddress
