import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Select } from 'antd'
import PropTypes from 'prop-types'
import { VenueLogo as Logo } from '~components'

import styles from './VenueLogos.module.css'

const { Option } = Select

const VenueLogos = ({
  addLogo,
  addLogoMode,
  defaultLogo,
  editLogoMode,
  handleChange,
  handleAddNewLogo,
  handleCancelAddLogo,
  handleEdit,
  selectedLogoIndex,
  setSelectedLogoIndex,
  updateLogo,
  venueLogos = []
}) => {
  const [selectedLogo, setSelectedLogo] = useState()

  useEffect(() => {
    setSelectedLogo(venueLogos[selectedLogoIndex])
  }, [venueLogos, selectedLogoIndex])

  const veneuHasLogos = venueLogos && venueLogos.length > 0

  return (
    <>
      {
        !addLogoMode && veneuHasLogos &&
        <Row>
          <Col span={2}>
            <span>Select Logo</span>
          </Col>
          <Col span={20}>
            <div className={styles.logoSelector}>
              <Select
                disabled={editLogoMode}
                onChange={(value) => {
                  setSelectedLogoIndex(value)
                }}
                style={{ width: '300px' }}
                value={selectedLogoIndex}
              >
                {
                  venueLogos.map((venueLogo, index) => <Option key={index} value={index}>{venueLogo.name}</Option>)
                }
              </Select>
            </div>
          </Col>
          <Col span={2}>
            <div onClick={(event) => event.stopPropagation()}>
              <Button onClick={addLogo}>Add Logo</Button>
            </div>
          </Col>
        </Row>
      }
      {
        !addLogoMode && selectedLogo &&
          <Logo
            editLogoMode={editLogoMode}
            handleCancelAddLogo={handleCancelAddLogo}
            handleEdit={handleEdit}
            index={selectedLogoIndex}
            updateLogo={updateLogo}
            venueLogo={selectedLogo}
          />
      }
      { addLogoMode &&
        <Logo
          addLogoMode={addLogoMode}
          editLogoMode={editLogoMode}
          handleAddNewLogo={handleAddNewLogo}
          handleCancelAddLogo={handleCancelAddLogo}
          index={venueLogos.length}
          updateLogo={updateLogo}
          venueLogo={defaultLogo}
        />
      }
    </>
  )
}

VenueLogos.propTypes = {
  addLogo: PropTypes.func,
  addLogoMode: PropTypes.bool,
  defaultLogo: PropTypes.object,
  editLogoMode: PropTypes.bool,
  handleAddNewLogo: PropTypes.func,
  handleCancelAddLogo: PropTypes.func,
  handleChange: PropTypes.func,
  handleEdit: PropTypes.func,
  selectedLogoIndex: PropTypes.number,
  setSelectedLogoIndex: PropTypes.func,
  updateLogo: PropTypes.func,
  venueLogos: PropTypes.array
}

export default VenueLogos
