import { SIGN_IN_SUCCESS, SET_USER_SESSION, SIGN_IN_ERROR } from '../actionTypes'

export default function signInReducer (state = {}, action) {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        userSignedIn: true
      }
    case SET_USER_SESSION:
      return {
        ...state,
        ...action.payload
      }
    case SIGN_IN_ERROR:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
