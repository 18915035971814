import React from 'react'
import { Modal, Result } from 'antd'
import PropTypes from 'prop-types'

const ResultModal = ({
  actions,
  handleCancel,
  status,
  title
}) => {
  return (
    <Modal
      centered
      closable={false}
      footer={null}
      onCancel={handleCancel}
      visible
      width={600}
    >
      <Result
        extra={actions}
        status={status}
        subTitle=""
        title={title}
      />
    </Modal>
  )
}

ResultModal.propTypes = {
  actions: PropTypes.array,
  handleCancel: PropTypes.func,
  status: PropTypes.string,
  title: PropTypes.string
}

export default ResultModal
