import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import {
  addPromoCode,
  copyPromocodeById,
  fetchEventsByPriceTemplate,
  fetchEventsBySeries,
  resetPromoCodeActionStatus
} from '~actions'
import {
  EditMsgPromoCodeForm,
  EditRockettesPromoCodeForm,
  ResultModal
} from '~components'
import { getCurrentUser } from '~context'
import { history } from '~history'
import { Spinner } from '~stories'
import { validatePromocode } from '~validators'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const PROMOCODE_DOMAINS = {
  MSG: 'msg',
  ROCKETTES: 'rockettes'
}
const PAGE_TITLE = {
  MSG: 'Copy MSG Promo Code',
  ROCKETTES: 'Copy Rockettes Promo Code'
}

const CopyPromoCode = ({
  addPromoCode,
  allEventsForMsg = [],
  assignedEvents = [],
  assignedEventsToMsgPromo = [],
  eventsAssignedToSeries = [],
  fetchEventsByPriceTemplate,
  fetchEventsBySeries,
  copyPromocodeById,
  priceTemplates,
  promoCode = {},
  promoCodeObjectId,
  route = {},
  series,
  updateMessage,
  updateStatus
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState(null)
  const [promoCodeId, setPromoCodeId] = useState('')
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()
  const { domain } = promoCode

  useEffect(() => {
    const promoCodeId = idx(route, _ => _.match.params.id)
    if (promoCodeId) {
      setIsDataLoaded(false)
      setPromoCodeId(promoCodeId)
    }
  }, [route])

  useEffect(() => {
    if (updateStatus) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
      resetPromoCodeActionStatus()
    }
  }, [updateMessage, updateStatus])

  useEffect(() => {
    if (promoCodeId) {
      setIsDataLoaded(false)
      copyPromocodeById(promoCodeId)
    }
  }, [promoCodeId, copyPromocodeById])

  useEffect(() => {
    if (promoCode && promoCode.domain) {
      setIsDataLoaded(true)
    }
  }, [promoCode])

  const handleSubmit = promoCodeData => {
    const { isValid, message } = validatePromocode(promoCodeData)

    if (!isValid) {
      notification.error({ message })
      return
    }
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    setIsDataLoaded(false)
    addPromoCode(promoCodeData, accessToken)
  }

  const goToListPromocodesButton = (
    <Route key="listPromocodes" render={({ history }) => (
      <Button
        onClick={() => {
          resetPromoCodeActionStatus()
          history.push(`/promo_codes/${promoCode.domain}`)
        }}
        type="primary"
      >
        Go to Promocodes List
      </Button>
    )} />
  )

  const editPromocodesButton = (
    <Route key="editPromocodes" render={({ history }) => (
      <Button
        onClick={() => {
          resetPromoCodeActionStatus()
          promoCodeObjectId && history.push(`/promo_codes/${promoCodeObjectId}/edit`)
        }}
        type="primary"
      >
        Edit Promocode
      </Button>
    )} />
  )

  const handleModalClose = () => {
    resetPromoCodeActionStatus()
    promoCodeObjectId && history.push(`/promo_codes/${promoCodeObjectId}/edit`)
  }

  return (
    <>
      {
        isDataLoaded && domain === PROMOCODE_DOMAINS.ROCKETTES &&
          <EditRockettesPromoCodeForm
            actionMode='Copy'
            assignedEvents={assignedEvents}
            fetchEventsByPriceTemplate={fetchEventsByPriceTemplate}
            handleSubmit={handleSubmit}
            pageTitle={PAGE_TITLE.ROCKETTES}
            priceTemplates={priceTemplates}
            promoCode={promoCode}
          />
      }
      {
        isDataLoaded && domain === PROMOCODE_DOMAINS.MSG &&
          <EditMsgPromoCodeForm
            actionMode='Copy'
            allEvents={allEventsForMsg}
            assignedEvents={assignedEventsToMsgPromo}
            eventsAssignedToSeries={eventsAssignedToSeries}
            fetchEventsBySeries={fetchEventsBySeries}
            handleSubmit={handleSubmit}
            pageTitle={PAGE_TITLE.MSG}
            promoCode={promoCode}
            series={series}
          />
      }
      {
        isDataLoaded && updateStatus &&
        <ResultModal
          actions={[
            goToListPromocodesButton,
            editPromocodesButton
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
      <Spinner isLoading={!isDataLoaded} />
    </>
  )
}

CopyPromoCode.propTypes = {
  addPromoCode: PropTypes.func,
  allEventsForMsg: PropTypes.array,
  assignedEvents: PropTypes.array,
  assignedEventsToMsgPromo: PropTypes.array,
  copyPromocodeById: PropTypes.func,
  eventsAssignedToSeries: PropTypes.array,
  fetchEventsByPriceTemplate: PropTypes.func,
  fetchEventsBySeries: PropTypes.func,
  priceTemplates: PropTypes.array,
  promoCode: PropTypes.object,
  promoCodeId: PropTypes.string,
  promoCodeObjectId: PropTypes.string,
  resetPromoCodeActionStatus: PropTypes.func,
  route: PropTypes.object,
  series: PropTypes.array,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.string
}

const mapStateToProps = ({ promoCodes, priceTemplates, series }) => ({
  allEventsForMsg: promoCodes.allEvents,
  assignedEvents: priceTemplates.assignedEvents,
  assignedEventsToMsgPromo: promoCodes.assignedEventsToMsgPromo,
  eventsAssignedToSeries: series.eventsAssignedToSeries,
  priceTemplates: promoCodes.priceTemplates,
  promoCode: promoCodes.promoCode,
  promoCodeObjectId: promoCodes.promoCodeObjectId,
  series: promoCodes.series,
  updateMessage: promoCodes.message,
  updateStatus: promoCodes.success
})

const mapDispatchToProps = {
  addPromoCode,
  copyPromocodeById,
  fetchEventsByPriceTemplate,
  fetchEventsBySeries,
  resetPromoCodeActionStatus
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CopyPromoCode))
