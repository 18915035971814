import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Card, Space } from 'antd'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Input,
  List,
  Modal,
  MultiSelectDropdown,
  PageLayout,
  RichTextInput,
  Switch
} from '~stories'

import styles from './EditRockettesPromoCodeForm.module.css'

const ACTION_MODES = {
  ADD: 'Add',
  EDIT: 'Edit'
}

const CTA_LABELS = {
  ADD: 'Add',
  UPDATE: 'Update'
}

const EditRockettesPromoCodeForm = ({
  actionMode,
  assignedEvents,
  domain,
  fetchEventsByPriceTemplate,
  fetchPromoCodeById,
  handleSubmit,
  priceTemplates,
  promoCode,
  promoCodeId,
  pageTitle
}) => {
  const [description, setDescription] = useState('')
  const [hidePricing, setHidePricing] = useState(false)
  const [priceTemplateId, setPriceTemplateId] = useState('')
  const [status, setStatus] = useState(false)
  const [terms, setTerms] = useState('')
  const [title, setTitle] = useState('')
  const [copy, setCopy] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedPriceTemplateId, setSelectedPriceTemplateId] = useState()

  useEffect(() => {
    if (promoCodeId) {
      fetchPromoCodeById(promoCodeId)
    }
  }, [fetchPromoCodeById, promoCodeId])

  useEffect(() => {
    if (promoCode) {
      const {
        active,
        hide_pricing: hidePricing,
        description,
        price_template_id: priceTemplateId,
        terms,
        copy,
        title = ''
      } = promoCode
      setTitle(title)
      setDescription(description)
      setTerms(terms)
      setPriceTemplateId(priceTemplateId)
      setStatus(active)
      setCopy(copy)
      setHidePricing(hidePricing)
    }
  }, [promoCode, priceTemplates])

  useEffect(() => {
    if (priceTemplateId) {
      fetchEventsByPriceTemplate(priceTemplateId)
    }
  }, [fetchEventsByPriceTemplate, priceTemplateId])

  const handleTitleChange = event => {
    const title = event.target.value
    setTitle(title)
  }

  const handleDescriptionChange = description => setDescription(description)

  const handleTermsChange = terms => setTerms(terms)
  const handleCopyChange = copy => setCopy(copy)

  const handlePriceTemplateChange = priceTemplateObjId => {
    const isEventsAssigned = priceTemplateId && assignedEvents.length > 0
    if (isEventsAssigned) {
      setSelectedPriceTemplateId(priceTemplateObjId)
      setShowModal(true)
    } else {
      setPriceTemplateId(priceTemplateObjId)
    }
  }

  const handleModalCancel = () => {
    setSelectedPriceTemplateId()
    setShowModal(false)
  }

  const handleModalSubmit = () => {
    setPriceTemplateId(selectedPriceTemplateId)
    setShowModal(false)
  }

  const handleHidePricing = event => {
    const hidePricing = event.target.checked
    setHidePricing(hidePricing)
  }

  const handleUpdate = () => {
    handleSubmit({
      active: status,
      description,
      domain,
      hidePricing,
      priceTemplateId,
      copy,
      terms,
      title
    })
  }

  const buttonLabel = actionMode === ACTION_MODES.EDIT
    ? CTA_LABELS.UPDATE
    : CTA_LABELS.ADD

  const inputProps = {
    id: 'promocodeTitle',
    label: 'Code',
    maxLength: 12,
    onChange: handleTitleChange,
    value: title
  }

  const statusSwitch = <Switch
    checked={status}
    isInline={true}
    label="Status"
    onChange={setStatus}
  />

  const updatePromoCodeButton = <Button className={styles.updateActionButton} onClick={handleUpdate} type='primary'>
    { buttonLabel }
  </Button>

  const confirmationModal = showModal && <Modal
    description='The selected events will be removed. Are you sure you want to proceed?'
    handleCancel={handleModalCancel}
    handleSubmit={handleModalSubmit}
    showModal={showModal}
    title="Update Promocode"
  />

  return (
    <PageLayout extra={[statusSwitch, updatePromoCodeButton]} title={pageTitle}>
      <div className={styles.editPromoCodeLayoutBackground}>
        <Row gutter={24}>
          <Col span={24}>
            <Input
              {...inputProps}
            />
          </Col>
          <Col span={24}>
            <RichTextInput
              id="promocodeDescription"
              label="Description"
              onChange={handleDescriptionChange}
              value={description}
            />
          </Col>
          <Col span={24}>
            <RichTextInput
              id="promocodeTerms"
              label="Terms"
              onChange={handleTermsChange}
              value={terms}
            />
          </Col>
          <Col span={24}>
            <RichTextInput
              label="Copy"
              onChange={handleCopyChange}
              value={copy}
            />
          </Col>
          <Col className={styles.hidePricingCheckboxContainer} span={24}>
            <Checkbox
              checked={hidePricing}
              isInline
              label="Hide Pricing"
              onChange={handleHidePricing}
            />
          </Col>
        </Row>
      </div>
      <Card title="Price Template">
        <Space direction="vertical">
          <MultiSelectDropdown
            customStyle={{ width: 550 }}
            displayField="name"
            dropdownOptions={priceTemplates}
            handleChange={handlePriceTemplateChange}
            selectedItems={priceTemplateId}
            valueField="_id"
          />
          <List
            displayField="title"
            items={assignedEvents}
            title="Assigned Events"
          />
        </Space>
      </Card>
      { confirmationModal }
    </PageLayout>
  )
}

EditRockettesPromoCodeForm.propTypes = {
  actionMode: PropTypes.string,
  assignedEvents: PropTypes.array,
  domain: PropTypes.string,
  fetchEventsByPriceTemplate: PropTypes.func,
  fetchPromoCodeById: PropTypes.func,
  handleSubmit: PropTypes.func,
  pageTitle: PropTypes.string,
  priceTemplates: PropTypes.array,
  promoCode: PropTypes.object,
  promoCodeId: PropTypes.string
}

EditRockettesPromoCodeForm.defaultProps = {
  actionMode: ACTION_MODES.EDIT,
  domain: 'rockettes',
  priceTemplates: []
}

export default EditRockettesPromoCodeForm
