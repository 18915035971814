import {
  ADD_VENUE,
  FETCH_VENUES,
  STORE_VENUE_TO_EDIT,
  VENUE_UPDATE_STATUS
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_VENUES:
      return {
        ...state,
        ...action.payload
      }
    case STORE_VENUE_TO_EDIT:
      return {
        ...state,
        venueToEdit: action.payload.venue,
        availableEventsForPromotion: action.payload.availableEventsForPromotion,
        allContents: action.payload.allContents
      }
    case ADD_VENUE:
      return {
        ...state,
        ...action.payload
      }
    case VENUE_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
