/* eslint-disable camelcase */
import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Row, Col, Collapse, Card, Button, Modal, Typography } from 'antd'
import PropTypes from 'prop-types'

import { Input, MultiSelectDropdown, Label } from '~stories'
import { LINK_TARGETS } from '../../constants'
import styles from './PrimaryNavInfo.module.css'

const { Text } = Typography
const { Panel } = Collapse

const TertiaryColumnInfo = ({
  primaryNavIndex,
  columnIndex,
  primaryNavLinkIndex,
  secondaryNavLinkIndex,
  thirdLevelNavLinksColumns,
  deleteNewTertiaryColumnList,
  addNewTertiaryColumn,
  deleteNewTertiaryColumn,
  handleThirdLinkColumnLabelUpdate,
  handleThirdLinkColumnTargetUpdate,
  handleThirdLinkColumnUrlUpdate,
  index
}) => {
  const [showTertiaryColumnDeleteModal, setTertiaryColumnDeleteModal] = useState(false)
  const [currentTertiaryColumnIndex, setCurrentTertiaryColumnIndex] = useState(-1)

  const [showTertiaryColumnListDeleteModal, setTertiaryColumnListDeleteModal] = useState(false)
  const [currentTertiaryColumnListIndex, setCurrentTertiaryColumnListIndex] = useState(-1)

  const handleDeleteTertiaryColumnModalCancel = () => {
    setCurrentTertiaryColumnIndex(-1)
    setTertiaryColumnDeleteModal(false)
  }

  const handleDeleteTertiaryColumnNavAction = () => {
    deleteNewTertiaryColumn(index, primaryNavLinkIndex, secondaryNavLinkIndex, currentTertiaryColumnIndex, columnIndex)
    setCurrentTertiaryColumnIndex(-1)
    setTertiaryColumnDeleteModal(false)
  }

  const handleDeleteTertiaryColumnListModalCancel = () => {
    setCurrentTertiaryColumnIndex(-1)
    setTertiaryColumnDeleteModal(false)
  }

  const handleDeleteTertiaryColumnListNavAction = () => {
    deleteNewTertiaryColumnList(index, primaryNavLinkIndex, secondaryNavLinkIndex, currentTertiaryColumnListIndex)
    setCurrentTertiaryColumnListIndex(-1)
    setTertiaryColumnListDeleteModal(false)
  }

  return (<> <Card
    className={styles.promoItem}
    extra={<><Button icon={<PlusOutlined />}
      onClick={() => addNewTertiaryColumn(index, primaryNavLinkIndex, secondaryNavLinkIndex, columnIndex)}
      style={{ background: '#000', border: '1px solid #000' }}
      type='primary' /> <Button className={styles.deleteIcon} onClick={event => { setCurrentTertiaryColumnListIndex(columnIndex); setTertiaryColumnListDeleteModal(true); event.stopPropagation() }} type='danger'>Delete Column</Button></>
    }
    headStyle={{ backgroundColor: '#d9d9d9', color: '#000' }}
    title='Columns'><Collapse>
      {
        thirdLevelNavLinksColumns.map((column, innerColumnIndex) => {
          return <Panel key={innerColumnIndex} extra={
            <div onClick={e => e.stopPropagation()}>
              <Button className={styles.deleteIcon}
                onClick={event => {
                  setCurrentTertiaryColumnIndex(innerColumnIndex)
                  setTertiaryColumnDeleteModal(true)
                  event.stopPropagation()
                }}
                type='danger'>Delete Column Item</Button>
            </div>
          } header={`${column?.label}`}>

            <Row gutter={16}>
              <Col span={24}>
                <Input label='Label'
                  onChange={event => handleThirdLinkColumnLabelUpdate(event.target.value, primaryNavLinkIndex, secondaryNavLinkIndex, columnIndex, innerColumnIndex, index)}
                  value={column.label}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={4}>
                <Label value={'Link Target'}
                />
                <MultiSelectDropdown
                  allowClear={true}
                  customStyle={{ width: 150 }}
                  displayField='label'
                  dropdownOptions={LINK_TARGETS}
                  handleChange={selectedItems => handleThirdLinkColumnTargetUpdate(selectedItems, primaryNavLinkIndex, secondaryNavLinkIndex, columnIndex, innerColumnIndex, index)}
                  selectedItems={column.target}
                  valueField='value'
                />
              </Col>
              <Col span={22}>
                <Input label='Url'
                  onChange={event => handleThirdLinkColumnUrlUpdate(event.target.value, primaryNavLinkIndex, secondaryNavLinkIndex, columnIndex, innerColumnIndex, index)}
                  value={column.url}
                />
              </Col>
            </Row>

          </Panel>
        })
      }
    </Collapse>
  </Card>
  {
    showTertiaryColumnDeleteModal &&
        <Modal
          onCancel={handleDeleteTertiaryColumnModalCancel}
          onOk={handleDeleteTertiaryColumnNavAction}
          title='Delete confirmation'
          visible={showTertiaryColumnDeleteModal}
        >
          <Text>
            This Column item will be removed. Are you sure you want to delete this?
          </Text>
        </Modal>
  }
  {
    showTertiaryColumnListDeleteModal &&
        <Modal
          onCancel={handleDeleteTertiaryColumnListModalCancel}
          onOk={handleDeleteTertiaryColumnListNavAction}
          title='Delete confirmation'
          visible={showTertiaryColumnListDeleteModal}
        >
          <Text>
            This Column will be removed. Are you sure you want to delete this?
          </Text>
        </Modal>
  }
  </>
  )
}

TertiaryColumnInfo.propTypes = {
  addNewTertiaryColumn: PropTypes.func,
  columnIndex: PropTypes.number,
  deleteNewTertiaryColumn: PropTypes.func,
  deleteNewTertiaryColumnList: PropTypes.func,
  handleTertiaryNavDelete: PropTypes.func,
  handleThirdLinkColumnLabelUpdate: PropTypes.func,
  handleThirdLinkColumnTargetUpdate: PropTypes.func,
  handleThirdLinkColumnUrlUpdate: PropTypes.func,
  index: PropTypes.number,
  primaryNavIndex: PropTypes.number,
  primaryNavLinkIndex: PropTypes.number,
  secondaryNavLinkIndex: PropTypes.number,
  thirdLevelNavLinksColumns: PropTypes.array
}

TertiaryColumnInfo.defaultProps = {
  thirdLevelNavLinksColumns: []
}

export default TertiaryColumnInfo
