import React, { useEffect, useState } from 'react'
import { Collapse, Button } from 'antd'
import PropTypes from 'prop-types'
import { RichTextInput, Input } from '~stories'

import styles from './Contents.module.css'

const { Panel } = Collapse

const Block = React.memo(({
  activeBlockKey,
  block,
  blockIndex,
  contentIndex,
  formError,
  deleteBlock,
  handleActiveBlockPanel,
  handleDescriptionChange,
  handleTitleChange
}) => {
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)

  useEffect(() => {
    if (!block) return

    const {
      title,
      description
    } = block

    setTitle(title)
    setDescription(description)
  }, [block])

  const onDescriptionChange = value => {
    setDescription(value)
    handleDescriptionChange(value, contentIndex, blockIndex)
  }

  return (
    <div key={`${contentIndex}_${blockIndex}`}>
      <Collapse
        accordion
        activeKey={activeBlockKey}
        className={styles.collapseBorderedPanel}
        defaultActiveKey={[activeBlockKey]}
        expandIconPosition="right"
        onChange={handleActiveBlockPanel}>
        <Panel key={blockIndex} extra={
          <div onClick={e => e.stopPropagation()}>
            <Button
              onClick={() => deleteBlock(contentIndex, blockIndex)} type='danger'>
                Delete Block
            </Button>
          </div>
        } header={block.title}>
          <Input label='Title'
            onBlur={(event) => handleTitleChange(event.target.value, contentIndex, blockIndex)}
            onChange={event => setTitle(event.target.value)}
            placeholder='COVID-19 Health Documentation'
            {...(formError?.title ? { className: styles.hasError } : {})}
            value={title}
          />
          <RichTextInput
            label='Description'
            onChange={description => onDescriptionChange(description)}
            {...(formError?.description ? { isError: true } : {})}
            value={description}
          />
        </Panel>
      </Collapse>
    </div>
  )
})

Block.propTypes = {
  activeBlockKey: PropTypes.string,
  block: PropTypes.object,
  blockIndex: PropTypes.number,
  blocksLength: PropTypes.number,
  contentIndex: PropTypes.number,
  deleteBlock: PropTypes.func,
  formError: PropTypes.object,
  handleActiveBlockPanel: PropTypes.func,
  handleDescriptionChange: PropTypes.func,
  handleTitleChange: PropTypes.func
}

Block.displayName = 'Block'

export default Block
