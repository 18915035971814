import {
  ADD_NEW_SPONSOR,
  FETCH_NEW_SPONSOR,
  FETCH_SPONSORS,
  STORE_SPONSOR_TO_EDIT,
  SPONSOR_UPDATE_STATUS
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_SPONSOR:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_SPONSORS:
      return {
        ...state,
        ...action.payload
      }
    case STORE_SPONSOR_TO_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case SPONSOR_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_NEW_SPONSOR:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
