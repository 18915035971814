import React, { useEffect, useState } from 'react'

import { Input, Select, Table as AntDTable, Typography } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'

import Checkbox from '../Checkbox/Checkbox'
import Label from '../Label/Label'
import styles from './Table.module.css'
const { Text } = Typography
const { Search } = Input

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [10, 20, 50]
}

const Table = ({
  bordered,
  columns,
  fetchData,
  filterKey,
  filterOptions,
  filterValue,
  params,
  results,
  rowKey,
  secondaryFilter,
  showSecondaryFilter,
  totalCount
}) => {
  const [data, setData] = useState([])
  const [filteredInfo, setFilteredInfo] = useState(null)
  const [filteredItem, setFilteredItem] = useState('')
  const [secondaryFilteredItem, setSecondaryFilteredItem] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [searchItem, setSearchItem] = useState(null)
  const [sortedInfo, setSortedInfo] = useState(null)
  const [footerText, setFooterText] = useState('')
  const [isSecondaryFilterSelected, setIsSecondaryFilterSelected] = useState(false)

  const handleFilterChange = value => {
    setFilteredItem(value)
    setLoading(true)
    fetchData({
      [filterKey]: value,
      pagination: DEFAULT_PAGINATION,
      ...(searchItem && { searchBy: searchItem }),
      ...(showSecondaryFilter && { showExpired: isSecondaryFilterSelected }),
      ...(secondaryFilter && { venueId: secondaryFilteredItem })
    })
  }

  const handleSecondaryFilterChange = value => {
    setSecondaryFilteredItem(value)
    setLoading(true)
    fetchData({
      [filterKey]: filteredItem,
      pagination: DEFAULT_PAGINATION,
      ...(searchItem && { searchBy: searchItem }),
      ...(showSecondaryFilter && { showExpired: isSecondaryFilterSelected }),
      ...(secondaryFilter && { venueId: value })
    })
  }

  useEffect(() => {
    if (filterValue) {
      setFilteredItem(filterValue)
    }
  }, [filterValue, filterKey])

  useEffect(() => {
    if (results) {
      setLoading(false)
      setData(results)
      setPagination({
        ...params.pagination,
        total: totalCount
      })
    }
  }, [fetchData, params, results, totalCount])

  useEffect(() => {
    if (params && params.pagination) {
      updatePaginationText(params.pagination, totalCount)
    }
  }, [params, totalCount])

  useEffect(() => {
    if (secondaryFilter && secondaryFilter.list) {
      const venuesList = secondaryFilter.list.map(venue => venue.value)
      setSecondaryFilteredItem(venuesList)
    }
  }, [secondaryFilter])
  useEffect(() => {
    setLoading(true)
    const options = filterValue
      ? { pagination, [filterKey]: filterValue }
      : { pagination }
    fetchData(options)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const updatePaginationText = (paginationParams, totalCount) => {
    const { current, pageSize } = paginationParams
    const footerText = `Showing ${(pageSize * (current - 1)) + 1} to ${(totalCount < pageSize) ? totalCount : (current * pageSize)} of ${totalCount} entries`
    setFooterText(footerText)
  }

  const handleSearch = value => {
    setSearchItem(value)
    setLoading(true)
    fetchData({
      ...(filteredItem && { [filterKey]: filteredItem }),
      pagination: DEFAULT_PAGINATION,
      searchBy: value,
      ...(showSecondaryFilter && { showExpired: isSecondaryFilterSelected }),
      ...(secondaryFilter && { venueId: secondaryFilteredItem })
    })
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters)
    setSortedInfo(sorter)
    setLoading(true)
    fetchData({
      ...filters,
      ...(filteredItem && { [filterKey]: filteredItem }),
      ...(searchItem && { searchBy: searchItem }),
      ...(showSecondaryFilter && { showExpired: isSecondaryFilterSelected }),
      ...(secondaryFilter && { venueId: secondaryFilteredItem }),
      pagination,
      sortField: sorter.field,
      sortOrder: sorter.order
    })
  }

  const columnsWithFilterSort = columns.map(column => {
    const dataIndex = idx(column, _ => _.dataIndex)
    const hasFilter = Boolean(idx(column, _ => _.filters))
    const hasSort = Boolean(idx(column, _ => _.sorter))
    const filteredValue = idx(filteredInfo, _ => _[dataIndex]) || null
    const sortOrder = (
      idx(sortedInfo, _ => _.field) === dataIndex &&
      idx(sortedInfo, _ => _.order)
    )

    return {
      ...column,
      ...(hasFilter && { filteredValue }),
      ...(hasSort && { sortOrder })
    }
  })

  const onSecondaryFilterChange = event => {
    setIsSecondaryFilterSelected(event.target.checked)
    setLoading(true)
    fetchData({
      ...(filteredItem && { [filterKey]: filteredItem }),
      pagination: DEFAULT_PAGINATION,
      ...(searchItem && { searchBy: searchItem }),
      ...(showSecondaryFilter && { showExpired: event.target.checked })
    })
  }

  const hasFilter = filterKey && filterOptions
  const filterJsx = hasFilter && (
    <>
      <Label value="Filter" />
      <Select
        className={styles.filter}
        onChange={handleFilterChange}
        options={filterOptions}
        value={filteredItem}
      />
    </>
  )

  const secondaryFilterDropdown = secondaryFilter && (
    <>
      <Label value={secondaryFilter.label} />
      <Select
        className={styles.filter}
        mode="multiple"
        onChange={handleSecondaryFilterChange}
        options={secondaryFilter.list}
        value={secondaryFilteredItem}
      />
    </>
  )
  const showPaginationText = totalCount > 0
  const paginationText = showPaginationText && <Text className={styles.tableEntries}>{footerText}</Text>
  const secondaryFilterComponent = secondaryFilter
    ? secondaryFilterDropdown
    : showSecondaryFilter && <Checkbox checked={isSecondaryFilterSelected} isInline label='Show Expired:' onChange={onSecondaryFilterChange} />

  return (
    <div className={styles.root}>
      <div className={styles.controls}>
        {filterJsx}
        { secondaryFilterComponent }
        <Search
          allowClear
          className={styles.search}
          enterButton
          onSearch={handleSearch}
          placeholder="Search..."
        />
      </div>
      <AntDTable
        bordered={bordered}
        columns={columnsWithFilterSort}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        pagination={pagination}
        rowKey={rowKey}
        showSorterTooltip={false}
        sticky
      />
      {
        paginationText
      }
    </div>
  )
}

Table.propTypes = {
  bordered: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({
    dataIndex: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })),
    onFilter: PropTypes.func,
    render: PropTypes.func,
    sorter: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    title: PropTypes.node,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })).isRequired,
  fetchData: PropTypes.func.isRequired,
  filterKey: PropTypes.string,
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  filterValue: PropTypes.string,
  params: PropTypes.object,
  results: PropTypes.array,
  rowKey: PropTypes.oneOfType([PropTypes.func, PropTypes.number, PropTypes.string]),
  secondaryFilter: PropTypes.object,
  showSecondaryFilter: PropTypes.bool,
  totalCount: PropTypes.number
}

Table.defaultProps = {
  bordered: true,
  filterOptions: null,
  filterValue: '',
  params: {},
  rowKey: '_id',
  secondaryFilter: {},
  showSecondaryFilter: false
}

export default Table
