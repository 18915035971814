import React from 'react'
import { List } from 'antd'
import PropTypes from 'prop-types'

import { Carousel } from '~stories'
import { sliceArrayIntoGroups } from '~utils'

import EventsSection from './EventsSection'

import './Carousel.css'
import styles from './EventsStats.module.css'

const UpcomingEvents = ({ listItems }) => {
  const eventSections = [...listItems]

  const eventsList = sliceArrayIntoGroups(eventSections, 5)

  const eventsListComponent = eventsList.map((events, index) => <List
    key={index}
    className={styles.list}
    dataSource={events}
    itemLayout="horizontal"
    renderItem={({ id, imageUrl, name, start_date: startDate, start_time: startTime, venue }) => (
      <List.Item className={styles.listItem}>
        <EventsSection
          eventName={name}
          eventStartDate={startDate}
          eventStartTime={startTime}
          eventVenue={venue}
          id={id}
          imageUrl={imageUrl}
        />
      </List.Item>
    )}
  />)

  return (
    <Carousel childrens={eventsListComponent} />
  )
}

UpcomingEvents.propTypes = {
  listItems: PropTypes.array.isRequired
}

UpcomingEvents.defaultProps = {
  listItems: []
}

export default UpcomingEvents
