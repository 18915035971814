import { COGNITO_LOGIN_URL } from '~constants'

const validateTokenError = status => {
  if (status === 401) {
    global.window && (global.window.location.href = COGNITO_LOGIN_URL)
  }
}

export const getApiResponseInJSON = async response => {
  const { ok, status: code } = response
  const json = await response.json()
  validateTokenError(code)
  if (!ok) {
    const { message } = json
    throw new Error(message)
  }
  return json
}
