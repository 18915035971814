import React, { useEffect, useState } from 'react'
import { Button, Card, Modal, notification, Typography } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { v4 as UUID } from 'uuid'

import { deleteOrganisation, fetchOrganisationById, updateOrganisationById, resetOrganisationActionStatus } from '~actions'
import { ApplicationSections, ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { history } from '~history'
import { Input, PageLayout, Spinner } from '~stories'
import { validateOrganisationApps } from '~validators'

import styles from './EditOrganisation.module.css'

const { Text } = Typography
const PAGE_TITLE = 'Edit Organisation'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.',
  NAME_REQUIRED: 'Organisation name can\'t be blank'
}

const EditOrganisation = ({ deleteOrganisation, deleteMessage, deleteStatus, fetchOrganisationById, organization, route = {}, updateOrganisationById, updateMessage, updateStatus }) => {
  const [applications, setApplications] = useState([])
  const [isDataLoaded, setIsDataLoaded] = useState()
  const [name, setName] = useState('')
  const [isDataDeleted, setIsDataDeleted] = useState(true)
  const [organisationId, setOrganisationId] = useState()
  const [showOrganisationDeleteModal, setShowOrganisationDeleteModal] = useState(false)
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    if (organization) {
      const {
        name,
        applications = []
      } = organization
      setApplications(applications)
      setIsDataLoaded(true)
      setName(name)
    }
  }, [organization])

  useEffect(() => {
    const organisationId = idx(route, _ => _.match.params.id)
    if (organisationId) {
      setOrganisationId(organisationId)
    }
  }, [route])

  useEffect(() => {
    if (organisationId) {
      setIsDataLoaded(false)
      fetchOrganisationById(organisationId)
    }
  }, [organisationId, fetchOrganisationById])

  useEffect(() => {
    if (updateStatus) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
      resetOrganisationActionStatus()
    }
  }, [updateStatus, updateMessage])

  useEffect(() => {
    if (deleteStatus && deleteMessage) {
      setIsDataLoaded(false)
      notification.success({ message: deleteMessage })
      setTimeout(() => {
        history.push('/organizations')
      }, 1500)
    } else if (!deleteStatus && deleteMessage) {
      setIsDataLoaded(false)
      setIsDataDeleted(true)
      notification.error({ message: deleteMessage })
      resetOrganisationActionStatus()
    }
  }, [deleteStatus, deleteMessage])

  const addApplication = () => {
    const clonedApplications = [...applications]
    const defaultApplication = {
      name: '',
      token: UUID().replace(/-/g, ''),
      editable: true
    }
    clonedApplications.push({ ...defaultApplication })
    setApplications(clonedApplications)
  }

  const updateName = (event, index) => {
    const clonedApplications = [...applications]
    const updatedApplications = [
      ...clonedApplications.slice(0, index),
      {
        ...clonedApplications[index],
        name: event.target.value || ''
      },
      ...clonedApplications.slice(index + 1)
    ]
    setApplications(updatedApplications)
  }

  const refreshToken = (record, index) => {
    const clonedApplications = [...applications]
    const updatedApplications = [
      ...clonedApplications.slice(0, index),
      {
        ...record,
        token: UUID().replace(/-/g, ''),
        editable: true
      },
      ...clonedApplications.slice(index + 1)
    ]
    setApplications(updatedApplications)
  }

  const updateApplications = (record, index) => {
    const clonedApplications = [...applications]
    const updatedApplications = [
      ...clonedApplications.slice(0, index),
      {
        ...clonedApplications[index],
        ...record,
        editable: false
      },
      ...clonedApplications.slice(index + 1)
    ]
    setApplications(updatedApplications)
  }

  const removeApplication = index => {
    const clonedApplications = [...applications]

    clonedApplications.splice(index, 1)
    setApplications(clonedApplications)
  }

  const updateOrganisation = () => {
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    if (name.length === 0) {
      return notification.error({ message: NOTIFICATION_MESSAGES.NAME_REQUIRED })
    }

    const { message } = validateOrganisationApps(applications)
    if (message) {
      notification.error({ message })
      return
    }

    setIsDataLoaded(false)

    const organisationId = idx(route, _ => _.match.params.id)

    const clonedApplications = applications.map(({ name, token }) => ({ name, token }))
    updateOrganisationById(organisationId, { name, applications: clonedApplications }, accessToken)
  }

  const editOrganisation = (
    <Button
      onClick={() => {
        resetOrganisationActionStatus()
        window.location.reload()
      }}
      type="primary"
    >
      Keep Editing
    </Button>
  )

  const goToListOrganisationButton = (
    <Route key="listOrganisation" render={({ history }) => (
      <Button
        onClick={() => {
          resetOrganisationActionStatus()
          history.push('/organizations')
        }}
        type="primary"
      >
        Go to Organisation List
      </Button>
    )} />
  )

  const handleModalClose = () => {
    resetOrganisationActionStatus()
  }

  const handleDeleteOrganisationModalCancel = () => {
    setShowOrganisationDeleteModal(false)
  }

  const handleDeleteOrganisation = () => {
    setShowOrganisationDeleteModal(true)
  }

  const handleDeleteOrganisationAction = () => {
    const organisationId = idx(route, _ => _.match.params.id)
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }
    setIsDataDeleted(false)
    setIsDataLoaded(false)
    deleteOrganisation(organisationId, accessToken)
    setShowOrganisationDeleteModal(false)
  }

  const submitButton = <Button disabled={!isContentEditor} onClick={updateOrganisation} type='primary'>Update</Button>
  const deleteButton = <Button disabled={!isContentEditor} loading={!isDataDeleted} onClick={handleDeleteOrganisation} type='danger'>Delete</Button>
  const extraButton = [<Button key='button' className={styles.addButton} disabled={!isContentEditor} onClick={addApplication}>Add Application</Button>]

  return (
    <PageLayout extra={[deleteButton, submitButton]} title={PAGE_TITLE}>
      <div className={styles.orgLayoutBackground}>
        <Input label='Name' onChange={event => setName(event.target.value)} value={name} />
        <Card className={styles.card} extra={extraButton} title='Applications'>
          <ApplicationSections applications={applications} isContentEditor={isContentEditor} refreshToken={refreshToken} removeApplication={removeApplication} updateApplications={updateApplications} updateName={updateName} />
        </Card>
      </div>
      <Spinner isLoading={!isDataLoaded} />
      {
        updateStatus &&
          <ResultModal
            actions={[
              goToListOrganisationButton,
              editOrganisation
            ]}
            handleCancel={handleModalClose}
            status="success"
            title={updateMessage}
          />
      }
      {showOrganisationDeleteModal && <Modal
        onCancel={handleDeleteOrganisationModalCancel}
        onOk={handleDeleteOrganisationAction}
        title="Delete Organisation"
        visible={showOrganisationDeleteModal}
      >
        <Text>
          Are you sure you want to delete this organisation?
        </Text>
      </Modal>}
    </PageLayout>
  )
}

const mapStateToProps = ({ organizations }) => ({
  deleteMessage: organizations.deleteMessage,
  deleteStatus: organizations.deleteStatus,
  organization: organizations,
  updateStatus: organizations.success,
  updateMessage: organizations.message
})

const mapDispatchToProps = {
  deleteOrganisation,
  fetchOrganisationById,
  updateOrganisationById
}

EditOrganisation.propTypes = {
  deleteMessage: PropTypes.string,
  deleteOrganisation: PropTypes.func.isRequired,
  deleteStatus: PropTypes.bool,
  fetchOrganisationById: PropTypes.func,
  organization: PropTypes.object,
  route: PropTypes.object.isRequired,
  updateMessage: PropTypes.string,
  updateOrganisationById: PropTypes.func.isRequired,
  updateStatus: PropTypes.string
}

EditOrganisation.defaultProps = {
  match: {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOrganisation)
