import React, { useEffect, useState } from 'react'
import { Button, Card, notification } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { fetchSponsorById, resetUpdateSponsorStatus, updateSponsor } from '~actions'
import { RecommendationsSelector, SponsorBasicInfo, ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout, Spinner } from '~stories'
import { validateSponsor } from '~validators'
import styles from './EditSponsor.module.css'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const EditSponsor = ({
  availableEventsForPromotion,
  fetchSponsor,
  resetUpdateSponsorStatus,
  route,
  sponsor,
  sponsoredEvents,
  updateMessage,
  updateSponsor,
  updateStatus
}) => {
  const [allEvents, setAllEvents] = useState([])
  const [isDataLoaded, setIsDataLoaded] = useState()
  const [promotedEvents, setPromotedEvents] = useState([])
  const [sponsorId, setSponsorId] = useState('')
  const [sponsorToEdit, setSponsorToEdit] = useState({})
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    const sponsorId = idx(route, _ => _.match.params.id)
    if (sponsorId) {
      setIsDataLoaded(false)
      fetchSponsor(sponsorId)
      setSponsorId(sponsorId)
    }
  }, [route, fetchSponsor])

  useEffect(() => {
    if (sponsor) {
      setSponsorToEdit(sponsor)
      setIsDataLoaded(true)
    }
  }, [sponsor])

  useEffect(() => {
    if (availableEventsForPromotion) {
      setAllEvents(availableEventsForPromotion)
    }
  }, [availableEventsForPromotion])

  useEffect(() => {
    if (sponsoredEvents) {
      setPromotedEvents(sponsoredEvents)
    }
  }, [sponsoredEvents])

  useEffect(() => {
    if (updateStatus && updateMessage) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
      resetUpdateSponsorStatus()
    }
  }, [updateStatus, updateMessage, resetUpdateSponsorStatus])

  const handleSubmit = () => {
    const { isValid, message } = validateSponsor(sponsorToEdit)
    if (!isValid) {
      notification.error({ message })
      return
    }

    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    setIsDataLoaded(false)
    const updatedSponsor = {
      ...sponsorToEdit,
      events: promotedEvents.map(({ id }) => id)
    }
    updateSponsor(sponsorId, updatedSponsor, accessToken)
  }

  const handleDescriptionChange = descriptionText => {
    let description = sponsorToEdit.description
    description = descriptionText || ''
    const updatedSponsor = { ...sponsorToEdit, description }
    setSponsorToEdit(updatedSponsor)
  }

  const handleTitleChange = event => {
    const updatedTitle = event.target.value
    const updatedSponsor = { ...sponsorToEdit, title: updatedTitle }
    setSponsorToEdit(updatedSponsor)
  }

  const keepEditingSponsor = (
    <Route key="editSponsors" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateSponsorStatus()
          history.push(`/sponsors/${sponsorId}/edit`)
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const goToListSponsorsButton = (
    <Route key="listSponsors" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateSponsorStatus()
          history.push('/sponsors')
        }}
        type="primary"
      >
        Go to Sponsors List
      </Button>
    )} />
  )

  const handleModalClose = () => {
    resetUpdateSponsorStatus()
  }

  const handleLogoUpload = (thumbnailImageUrl, secureImageURL) => {
    const imageUrl = secureImageURL || thumbnailImageUrl

    setSponsorToEdit({
      ...sponsor,
      icon_url: imageUrl
    })
  }

  const updateSponsorButton = <Button className={styles.updateActionButton} onClick={handleSubmit} type='primary'>
    Update
  </Button>

  return (
    <PageLayout extra={[updateSponsorButton]} title='Update Sponsor'>
      <Card>
        <SponsorBasicInfo
          handleDescriptionChange={handleDescriptionChange}
          handleLogoUpload={handleLogoUpload}
          handleTitleChange={handleTitleChange}
          sponsor={sponsorToEdit}
        />
      </Card>

      <Card title="Events">
        <RecommendationsSelector
          allEvents={allEvents}
          displayField='title'
          promotedEvents={promotedEvents}
          resourceName='Events'
          setAllEvents={setAllEvents}
          setPromotedEvents={setPromotedEvents}
        />
      </Card>
      <Spinner isLoading={!isDataLoaded}/>
      {
        updateStatus &&
        <ResultModal
          actions={[
            goToListSponsorsButton,
            keepEditingSponsor
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

EditSponsor.propTypes = {
  availableEventsForPromotion: PropTypes.array,
  fetchSponsor: PropTypes.func,
  resetUpdateSponsorStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  sponsor: PropTypes.object,
  sponsoredEvents: PropTypes.array,
  updateMessage: PropTypes.string,
  updateSponsor: PropTypes.func,
  updateStatus: PropTypes.string
}

const mapStateToProps = ({ sponsors }) => ({
  availableEventsForPromotion: sponsors.allEvents,
  sponsor: sponsors.sponsor,
  sponsoredEvents: sponsors.sponsoredEvents,
  updateStatus: sponsors.success,
  updateMessage: sponsors.message
})

const mapDispatchToProps = {
  fetchSponsor: fetchSponsorById,
  resetUpdateSponsorStatus,
  updateSponsor
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSponsor)
