import 'isomorphic-fetch'

import { cognitoAuthEndpoint } from '~apiConfig'
import { getApiResponseInJSON, setLocalStorage } from '~utils'

import { SET_USER_SESSION, SIGN_IN_ERROR, SIGN_IN_SUCCESS } from '../actionTypes'

const { REACT_APP_CLIENT_ID, REACT_APP_DOMAIN_NAME } = process.env
const EDITORS = 'Editors'

const getCognitoUserInfo = async accessToken => {
  const { method, url } = cognitoAuthEndpoint.userInfo
  const requestOptions = {
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
  const response = await fetch(url, requestOptions)
  const userDetails = await getApiResponseInJSON(response)
  const groupName = userDetails['custom:group']
  const userName = userDetails.email
  const isContentEditor = groupName.indexOf(EDITORS) > -1
  return {
    userName,
    isContentEditor
  }
}

export const fetchUserSessionbyAuthCode = code => async dispatch => {
  const { method, url } = cognitoAuthEndpoint.token
  const requestOptions = {
    method,
    body: JSON.stringify({
      code
    })
  }
  fetch(url, requestOptions)
    .then(async response => await getApiResponseInJSON(response))
    .then(async result => {
      const { error } = result
      let session = null
      let userDetails = null
      if (!error) {
        const { responseBody } = result
        const { expires_in: expiresIn, access_token: accessToken } = responseBody
        userDetails = await getCognitoUserInfo(accessToken)
        const idTokenExpiryTime = new Date().getTime() + expiresIn * 1000
        session = {
          ...responseBody,
          idTokenExpiryTime,
          userDetails
        }
        setLocalStorage(session)
        return dispatch({
          type: SET_USER_SESSION,
          payload: {
            session: responseBody,
            userDetails
          }
        })
      }
    })
    .catch(error => {
      setLocalStorage(null)
      dispatch({
        type: SIGN_IN_ERROR,
        payload: {
          session: null,
          error
        }
      })
    })
}

export const signIn = () => dispatch => {
  dispatch({ type: SIGN_IN_SUCCESS })
}

export const logoutUser = () => dispatch => {
  const { url } = cognitoAuthEndpoint.logout
  localStorage.removeItem('session')
  window.location.href = `${url}?client_id=${REACT_APP_CLIENT_ID}&logout_uri=${REACT_APP_DOMAIN_NAME}/logout`
}
