import React from 'react'
import { Typography } from 'antd'
import idx from 'idx'

import { ItemList } from '~components'
import { PageLayout } from '~stories'

import './Artists.modules.css'

const { Text } = Typography

const getArtistImage = (artist) => {
  const imageUrl = idx(artist, _ => _.images[0].url)
  return imageUrl && <img alt='Artist' height='100px' src={imageUrl} width='200px' />
}

const getArtistNameWithLink = artist => <Text>{String(artist.name).toUpperCase()}</Text>

const columnData = [
  {
    render: artist => getArtistImage(artist),
    title: 'Image',
    width: '15%'
  },
  {
    dataIndex: 'name',
    render: (text, artist) => getArtistNameWithLink(artist),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'],
    title: 'Name',
    width: '30%'
  },
  {
    dataIndex: 'url',
    title: 'Ticketmaster Url',
    width: '25%'
  },
  {
    render: artist => {
      if (artist.classifications && artist.classifications.length > 0) {
        const classification = artist.classifications[0]
        return <div>
          <div>Segment:{classification.segment.name}</div>
          <div>Genre: {classification.genre.name}</div>
          <div>SubGenre: {classification.subGenre.name}</div>
          <div>Type: {classification.type.name}</div>
          <div>SubType: {classification.subType.name}</div>
        </div>
      } else {
        return <div/>
      }
    },
    sorter: true,
    title: 'Classifications',
    width: '30%'
  }
]

const Artists = () => {
  return (
    <PageLayout title="Artists">
      <div className="artists-layout-background">
        <ItemList columns={columnData} resource='artists' />
      </div>
    </PageLayout>
  )
}

export default Artists
