import 'isomorphic-fetch'

import { organizationEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import { ADD_ORGANISATION, FETCH_ORGANIZATIONS, FETCH_ORGANISATION_TO_EDIT, REMOVE_ORGANISATION_BY_ID, RESET_ORGANISATION_STATUS, UPDATE_ORGANISATION } from '../actionTypes'

const ERROR_TITLE = 'Error, expected title to be unique.'
const AUTH_ERROR = 'You are not authorized to perform this action.'

export const fetchOrganizationsList = params => async dispatch => {
  const { method, url } = organizationEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-type': 'application/json; charset=UTF-8'
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { organizations: rows, organizationsCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_ORGANIZATIONS,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchOrganisationById = organisationObjectId => async dispatch => {
  const { method, url } = organizationEndpoint.edit(organisationObjectId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { _id: id, name, applications } = apiResponse
  dispatch({
    type: FETCH_ORGANISATION_TO_EDIT,
    payload: {
      applications,
      id,
      name
    }
  })
}

export const addOrganisation = (organisation, accessToken) => async dispatch => {
  const { method, url } = organizationEndpoint.add
  const idToken = getIdToken()
  await fetch(url, {
    body: JSON.stringify({ organisation }),
    headers: {
      Authorization: `Bearer ${idToken}`,
      accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .then(response => {
      const { addedOrganisation: { _id: newOrganisationId }, message, success } = response
      dispatch({
        type: ADD_ORGANISATION,
        payload: {
          newOrganisationId,
          error: false,
          message,
          success
        }
      })
    })
    .catch((error) => {
      const message = error.message === AUTH_ERROR ? error.message : ERROR_TITLE
      dispatch({
        type: ADD_ORGANISATION,
        payload: {
          error: true,
          message,
          success: false
        }
      })
    })
}

export const resetOrganisationActionStatus = () => async dispatch => {
  dispatch({
    type: RESET_ORGANISATION_STATUS,
    payload: {
      error: false,
      message: '',
      success: false
    }
  })
}

export const updateOrganisationById = (organisationId, organisationObj, accessToken) => async dispatch => {
  const { method, url } = organizationEndpoint.update(organisationId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify({ organisationObj }),
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    }))
  const { message, success } = apiResponse
  dispatch({
    type: UPDATE_ORGANISATION,
    payload: { message, success }
  })
}

export const deleteOrganisation = (organisationId, accessToken) => async dispatch => {
  const { method, url } = organizationEndpoint.delete(organisationId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    }
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    }))
  const { message: deleteMessage, success: deleteStatus } = apiResponse

  dispatch({
    type: REMOVE_ORGANISATION_BY_ID,
    payload: {
      deleteMessage,
      deleteStatus
    }
  })
}
